var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"render-config-view-wrapper"},[_c('AppHeader',{staticClass:"sticky-header",scopedSlots:_vm._u([{key:"options-bar",fn:function(){return [_c('div',{staticClass:"pb-8"})]},proxy:true}])}),_c('div',{staticClass:"overview max-w-6xl xl:mx-auto"},[_c('RenderConfigTitleRow',{attrs:{"can-reset":_vm.isDirty,"can-save":_vm.isDirty &&
        _vm.isTimeRangeValid &&
        _vm.isPreviewInstancesDayValid &&
        _vm.isPreviewInstancesNightValid &&
        _vm.isRenderInstancesNightValid &&
        _vm.isRenderInstancesDayValid},on:{"clickReset":_vm.handleClickReset,"clickSave":_vm.handleClickSave}}),_c('AppLoading',{attrs:{"loading":_vm.isLoading}}),(!_vm.isLoading)?[_c('v-card',{staticClass:"p-8"},[_c('div',{staticClass:"grid grid-cols-2 gap-8"},[_c('div',[_c('div',{staticClass:"section-heading"},[_vm._v(" "+_vm._s(_vm.$t('config.general.dayStart'))+" ")]),_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"value":_vm.currentConfig.DAY_START,"outlined":"","dense":"","hide-details":"","single-line":"","type":"time"},on:{"change":function($event){return _vm.handleChangeCurrentConfig({ ..._vm.currentConfig, DAY_START: $event })}}})],1),_c('div',[_c('div',{staticClass:"section-heading"},[_vm._v(" "+_vm._s(_vm.$t('config.general.dayEnd'))+" ")]),_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"value":_vm.currentConfig.DAY_END,"outlined":"","dense":"","hide-details":"","single-line":"","type":"time"},on:{"change":function($event){return _vm.handleChangeCurrentConfig({ ..._vm.currentConfig, DAY_END: $event })}}})],1)]),(!_vm.isTimeRangeValid)?_c('div',{staticClass:"section-error mt-3"},[_vm._v(" "+_vm._s(_vm.$t('config.general.invalidValues'))+" ")]):_vm._e()]),_c('v-card',{staticClass:"p-8 mt-8"},[_c('div',{staticClass:"grid grid-cols-2 gap-8"},[_c('div',[_c('div',{staticClass:"section-heading"},[_vm._v(" "+_vm._s(_vm.$t('config.general.previewInstancesDay'))+" ")]),_c('v-range-slider',{attrs:{"value":[_vm.currentConfig.MIN_PREVIEW_INSTANCES_DAY, _vm.currentConfig.MAX_PREVIEW_INSTANCES_DAY],"min":0,"max":_vm.currentConfig.MAX_INSTANCES},on:{"change":function($event){return _vm.handleChangeCurrentConfig({
                  ..._vm.currentConfig,
                  MIN_PREVIEW_INSTANCES_DAY: $event[0],
                  MAX_PREVIEW_INSTANCES_DAY: $event[1],
                })}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"80px"},attrs:{"value":_vm.currentConfig.MIN_PREVIEW_INSTANCES_DAY,"outlined":"","dense":"","hide-details":"","single-line":"","type":"number"},on:{"change":function($event){return _vm.handleChangeCurrentConfig({ ..._vm.currentConfig, MIN_PREVIEW_INSTANCES_DAY: $event })}}})]},proxy:true},{key:"append",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"80px"},attrs:{"value":_vm.currentConfig.MAX_PREVIEW_INSTANCES_DAY,"outlined":"","dense":"","hide-details":"","single-line":"","type":"number"},on:{"change":function($event){return _vm.handleChangeCurrentConfig({ ..._vm.currentConfig, MAX_PREVIEW_INSTANCES_DAY: $event })}}})]},proxy:true}],null,false,1108037375)}),(!_vm.isPreviewInstancesDayValid)?_c('div',{staticClass:"section-error"},[_vm._v(" "+_vm._s(_vm.$t('config.general.invalidValues'))+" ")]):_vm._e()],1),_c('div',[_c('div',{staticClass:"section-heading"},[_vm._v(" "+_vm._s(_vm.$t('config.general.previewInstancesNight'))+" ")]),_c('v-range-slider',{attrs:{"value":[_vm.currentConfig.MIN_PREVIEW_INSTANCES_NIGHT, _vm.currentConfig.MAX_PREVIEW_INSTANCES_NIGHT],"min":0,"max":_vm.currentConfig.MAX_INSTANCES},on:{"change":function($event){return _vm.handleChangeCurrentConfig({
                  ..._vm.currentConfig,
                  MIN_PREVIEW_INSTANCES_NIGHT: $event[0],
                  MAX_PREVIEW_INSTANCES_NIGHT: $event[1],
                })}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"80px"},attrs:{"value":_vm.currentConfig.MIN_PREVIEW_INSTANCES_NIGHT,"outlined":"","dense":"","hide-details":"","single-line":"","type":"number"},on:{"change":function($event){return _vm.handleChangeCurrentConfig({ ..._vm.currentConfig, MIN_PREVIEW_INSTANCES_NIGHT: $event })}}})]},proxy:true},{key:"append",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"80px"},attrs:{"value":_vm.currentConfig.MAX_PREVIEW_INSTANCES_NIGHT,"outlined":"","dense":"","hide-details":"","single-line":"","type":"number"},on:{"change":function($event){return _vm.handleChangeCurrentConfig({ ..._vm.currentConfig, MAX_PREVIEW_INSTANCES_NIGHT: $event })}}})]},proxy:true}],null,false,1838099583)}),(!_vm.isPreviewInstancesNightValid)?_c('div',{staticClass:"section-error"},[_vm._v(" "+_vm._s(_vm.$t('config.general.invalidValues'))+" ")]):_vm._e()],1)])]),_c('v-card',{staticClass:"p-8 mt-8"},[_c('div',{staticClass:"grid grid-cols-2 gap-8"},[_c('div',[_c('div',{staticClass:"section-heading"},[_vm._v(" "+_vm._s(_vm.$t('config.general.renderInstancesDay'))+" ")]),_c('v-range-slider',{attrs:{"value":[_vm.currentConfig.MIN_RENDER_INSTANCES_DAY, _vm.currentConfig.MAX_RENDER_INSTANCES_DAY],"min":0,"max":_vm.currentConfig.MAX_INSTANCES},on:{"change":function($event){return _vm.handleChangeCurrentConfig({
                  ..._vm.currentConfig,
                  MIN_RENDER_INSTANCES_DAY: $event[0],
                  MAX_RENDER_INSTANCES_DAY: $event[1],
                })}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"80px"},attrs:{"value":_vm.currentConfig.MIN_RENDER_INSTANCES_DAY,"outlined":"","dense":"","hide-details":"","single-line":"","type":"number"},on:{"change":function($event){return _vm.handleChangeCurrentConfig({ ..._vm.currentConfig, MIN_RENDER_INSTANCES_DAY: $event })}}})]},proxy:true},{key:"append",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"80px"},attrs:{"value":_vm.currentConfig.MAX_RENDER_INSTANCES_DAY,"outlined":"","dense":"","hide-details":"","single-line":"","type":"number"},on:{"change":function($event){return _vm.handleChangeCurrentConfig({ ..._vm.currentConfig, MAX_RENDER_INSTANCES_DAY: $event })}}})]},proxy:true}],null,false,1392485055)}),(!_vm.isRenderInstancesDayValid)?_c('div',{staticClass:"section-error"},[_vm._v(" "+_vm._s(_vm.$t('config.general.invalidValues'))+" ")]):_vm._e()],1),_c('div',[_c('div',{staticClass:"section-heading"},[_vm._v(" "+_vm._s(_vm.$t('config.general.renderInstancesNight'))+" ")]),_c('v-range-slider',{attrs:{"value":[_vm.currentConfig.MIN_RENDER_INSTANCES_NIGHT, _vm.currentConfig.MAX_RENDER_INSTANCES_NIGHT],"min":0,"max":_vm.currentConfig.MAX_INSTANCES},on:{"change":function($event){return _vm.handleChangeCurrentConfig({
                  ..._vm.currentConfig,
                  MIN_RENDER_INSTANCES_NIGHT: $event[0],
                  MAX_RENDER_INSTANCES_NIGHT: $event[1],
                })}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"80px"},attrs:{"value":_vm.currentConfig.MIN_RENDER_INSTANCES_NIGHT,"outlined":"","dense":"","hide-details":"","single-line":"","type":"number"},on:{"change":function($event){return _vm.handleChangeCurrentConfig({ ..._vm.currentConfig, MIN_RENDER_INSTANCES_NIGHT: $event })}}})]},proxy:true},{key:"append",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"80px"},attrs:{"value":_vm.currentConfig.MAX_RENDER_INSTANCES_NIGHT,"outlined":"","dense":"","hide-details":"","single-line":"","type":"number"},on:{"change":function($event){return _vm.handleChangeCurrentConfig({ ..._vm.currentConfig, MAX_RENDER_INSTANCES_NIGHT: $event })}}})]},proxy:true}],null,false,2489988159)}),(!_vm.isRenderInstancesNightValid)?_c('div',{staticClass:"section-error"},[_vm._v(" "+_vm._s(_vm.$t('config.general.invalidValues'))+" ")]):_vm._e()],1)])])]:_vm._e(),(_vm.isConfirmSaveModalShown)?_c('DiscardModal',{attrs:{"is-discard-modal-open":_vm.isConfirmSaveModalShown,"is-icon-hidden":true,"title":_vm.$t('config.general.confirmSaveTitle'),"message":_vm.$t('config.general.confirmSaveMessage'),"extra-message":_vm.changesText,"left-button-text":_vm.$t('general.buttons.cancel'),"right-button-text":_vm.$t('general.buttons.save')},on:{"clickLeftButton":function($event){return _vm.closeConfirmSaveModal()},"clickRightButton":function($event){return _vm.saveConfig()},"closeModal":function($event){return _vm.closeConfirmSaveModal()}}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }