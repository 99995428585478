<template>
  <div class="popup" @click.stop>
    <h2 class="popup-title">
      <slot />
    </h2>
    <div class="popup-body">
      <div :class="[options.length > popupMaxVisibleItems ? 'optionList' : '']">
        <FilterPopupCheckboxField
          v-for="option in options"
          :key="option"
          :category="option"
          :category-type="categoryType"
          :rounded="roundedCheckbox"
          @inclusiveFilterClicked="onInclusiveFilterClicked"
        >
          {{ $t(option) }}
        </FilterPopupCheckboxField>
      </div>
    </div>
  </div>
</template>

<script>
import FilterPopupCheckboxField from '@/components/shared/FilterPopupCheckboxField.vue';

export default {
  name: 'CheckboxFilterPopup',
  components: {
    FilterPopupCheckboxField,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    categoryType: {
      type: String,
      required: true,
    },
    roundedCheckbox: {
      type: Boolean,
      required: false,
      default: true,
    },
    popupMaxVisibleItems: {
      type: Number,
      required: false,
      default: 12,
    },
  },
  methods: {
    onInclusiveFilterClicked(payload) {
      this.$emit('inclusiveFilterClicked', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  @apply .absolute .flex .flex-col .w-64 .h-auto .bg-white .z-10 .cursor-auto;
  box-shadow: 0 0 5px 0 rgba(71, 67, 102, 0.19);
  top: 11rem;
  filter: drop-shadow(0 0 5px rgba(71, 67, 102, 0.19));
}

.popup::after {
  @apply .absolute .border-solid;
  content: '';
  bottom: 99%;
  margin-left: -5px;
  border-width: 1.5rem;
  border-color: transparent transparent white transparent;
  pointer-events: none;
  @media (min-width: 640px) {
    left: 20%;
  }
  left: 40%;
}

.optionList {
  overflow-y: scroll;
  max-height: 500px;
}

.popup-title {
  @apply .font-extrabold .font-sans .text-center .m-6 .mb-8 .text-black;
}
.popup-body {
  padding: 0 1.5rem;
}
</style>
