var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalBase',{attrs:{"title":_vm.$t('assets.general.addAsset'),"show":_vm.show,"override-style":{
    width: _vm.scssVariables.modalSideOffset,
    height: _vm.scssVariables.modalSideOffset,
    'margin-bottom': '0 rem',
    position: 'absolute',
    'z-index': 1000,
  },"override-header-style":{ 'margin-bottom': '1rem' }},on:{"closeClicked":_vm.onCloseClicked},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"content-container"},[_c('span',{staticClass:"content-container-title"},[_vm._v(_vm._s(_vm.fileName))]),_c('div',{staticClass:"image-container"},[_c('img',{ref:"image"})]),_c('div',{staticClass:"image-resolution"},[_vm._v(" "+_vm._s(_vm.$t('contentWizard.assets.selectedArea'))+" "+_vm._s(Math.floor(_vm.selectedWidth))+" × "+_vm._s(Math.floor(_vm.selectedHeight))+" ")]),(_vm.hasInsufficientResolution)?_c('ErrorBox',{staticClass:"error-box",attrs:{"message":"contentWizard.validation.imageResolution"}}):_vm._e()],1),(_vm.isShowingOverlay)?_c('div',{staticClass:"overlay-loader"},[_c('div',{staticClass:"spinner"})]):_vm._e()]},proxy:true},{key:"button-row",fn:function(){return [_c('div',{staticClass:"actions-row"},[_c('AppButton',{attrs:{"size":"small-auto","type":"outlined"},on:{"click":function($event){return _vm.backToAssets()}}},[_vm._v(" "+_vm._s(_vm.$t('contentWizard.assets.backToAssets'))+" ")]),_c('AppButton',{attrs:{"size":"small-auto"},on:{"click":function($event){return _vm.cropAsset()}}},[_vm._v(" "+_vm._s(_vm.$t('contentWizard.assets.selectArea'))+" ")])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }