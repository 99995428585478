<template>
  <div>
    <div class="base-data-wrapper">
      <div class="title-container">
        <span>{{ $t('templates.creation.formats') }}</span>
        <AppButton class="add-format-button" @click="addFormat">+ {{ $t('templates.creation.newFormat') }}</AppButton>
      </div>
      <FormatRow
        v-for="(format, index) in displayedFormats"
        :key="format.id"
        :class="index"
        :format="format"
        class="format-row"
        @removeClick="removeFormat(index)"
        @input="updateFormat(index, $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as uuid from 'uuid';
import FormatRow from '@/components/templates/templateCreation/formats/FormatRow.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import { toast } from '@/services/toast';

export default {
  components: {
    FormatRow,
    AppButton,
  },
  computed: {
    ...mapGetters({
      formats: 'templateCreation/getFormats',
    }),

    displayedFormats() {
      if (this.formats.length === 0) {
        this.addFormat();
      }

      return this.formats;
    },
  },
  methods: {
    emitErrorMessage(errorMessage) {
      toast.$emit('showToast', {
        type: 'error',
        groupName: 'template-creation-validation',
        message: this.$t(`templates.errors.${errorMessage}`),
      });
    },
    validate() {
      let pass = true;
      if (this.formats.length === 0) {
        return false;
      }
      this.formats.forEach((format) => {
        if (!format.name || !format.type || !format.humanReadableName || (!format.video && format.isNew)) {
          this.emitErrorMessage('fillAll');
          pass = false;
        }
      });
      return pass;
    },
    save() {
      this.$store.commit('templateCreation/setFormats', this.formats);
    },
    addFormat() {
      this.$store.commit('templateCreation/addFormat', {
        id: uuid.v1(),
        type: null,
        name: null,
        humanReadableName: null,
        isNew: true,
        video: null,
      });
    },
    updateFormat(index, newFormat) {
      this.$store.commit('templateCreation/updateFormat', {
        updateAt: index,
        newFormat,
      });
    },
    removeFormat(index) {
      this.$store.commit('templateCreation/removeFormat', index);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.base-data-wrapper {
  .title-container {
    display: flex;
    width: 100%;
    margin-bottom: rem(30px);
    font-family: $cch-font-family;
    font-size: rem(36px);
    font-weight: 800;
    color: #c5cde2;
    justify-content: space-between;
    align-items: baseline;
    .add-format-button {
      width: rem(160px);
      height: rem(50px);
    }
  }
  .selection-field {
    margin-bottom: rem(30px);
  }
}
</style>
