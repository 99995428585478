import getLogger from '@/services/logger';
import HTTP from '../http';
import { CmsConfigEntity, CreateCmsConfigEntity, Organization, UpdateCmsConfigEntity } from '@/types';
import store from '@/store';

const LOG = getLogger('resource/cms');

export const uploadCms = async (renderIds: string[]): Promise<void> => {
  LOG.debug(`Attempting to upload to CMS`);
  try {
    const organizationId = store.getters['auth/getUserOrganization'];
    await HTTP.post(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/cms/organization/${organizationId}/upload`,
      {
        renderIds,
      },
    );
  } catch (e) {
    LOG.error(`Error while uploading to CMS`);
  }
};

export const getNonCmsConfiguredOrganizations = async (): Promise<Organization[]> => {
  LOG.debug(`Attempting to get non CMS configured organizations`);
  try {
    const response = await HTTP.get(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/cms-configuration/available-organizations`,
    );
    return response.data;
  } catch (e) {
    LOG.error(`Error while getting non CMS configured organizations`);
    return [];
  }
};

export const getCmsConfigEntities = async (): Promise<CmsConfigEntity[]> => {
  LOG.debug(`Attempting to get CMS configuration entities`);
  try {
    const response = await HTTP.get(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/cms-configuration`,
    );
    return response.data;
  } catch (e) {
    LOG.error(`Error while getting CMS configuration entities`);
    return [];
  }
};

export const deleteCmsConfigEntity = async (id: string) => {
  LOG.debug(`Attempting to delete CMS configuration entity`);
  try {
    await HTTP.delete(`${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/cms-configuration/${id}`);
  } catch (e) {
    LOG.error(`Error while deleting CMS configuration entity`);
    throw e;
  }
};

export const createCmsConfigEntity = async (params: CreateCmsConfigEntity) => {
  LOG.debug(`Attempting to create CMS configuration entity`);
  try {
    await HTTP.post(`${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/cms-configuration`, params);
  } catch (e) {
    LOG.error(`Error while creating CMS configuration entity`);
    throw e;
  }
};

export const updateCmsConfigEntity = async (id: string, params: UpdateCmsConfigEntity) => {
  LOG.debug(`Attempting to update CMS configuration entity`);
  try {
    await HTTP.put(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/cms-configuration/${id}`,
      params,
    );
  } catch (e) {
    LOG.error(`Error while updating CMS configuration entity`);
    throw e;
  }
};
