<template>
  <ModalBase
    :title="$t('general.credits.confirmation-title')"
    :show="show"
    :override-style="{
      width: '552px',
      height: 'auto',
      overflow: 'hidden',
      'background-color': scssVariables.cchLightGray,
    }"
    :override-header-style="{ 'margin-bottom': '1rem' }"
    @closeClicked="onCloseClicked"
  >
    <template #content>
      <div class="text">
        <div class="asset-container">
          {{ $t('general.credits.confirmationText', { amount: numberOfCredits }) }}
        </div>
      </div>
    </template>
    <template #button-row>
      <div class="actions-row">
        <AppButton size="small-auto" type="outlined" @click="onCloseClicked()">
          {{ $t('general.credits.confirmationAbort') }}
        </AppButton>
        <AppButton size="small-auto" @click="onConfirmBuyClicked">{{
          $t('general.credits.confirmationYes')
        }}</AppButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import scssVariables from '@/styles/variables.module.scss';

export default {
  components: {
    ModalBase,
    AppButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    numberOfCredits: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      scssVariables,
    };
  },

  mounted() {},
  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    onConfirmBuyClicked() {
      this.$emit('confirm');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.asset-name {
  width: 100%;
  height: rem(51px);
}

.actions-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $cch-light-gray;
  width: 100%;
  margin-top: rem(24px);
}
</style>
