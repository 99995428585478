<template>
  <div class="asset-select-container">
    <div class="asset-selection-field" :class="{ 'has-asset': !!getAssetById(assetID) }">
      <div class="media-label-container" :class="{ 'with-asset': !!getAssetById(assetID) }">
        <span class="media-label" :class="{ 'label-with-asset': !!getAssetById(assetID) }">{{ placeholder }}</span>
        <RemoveMediaIcon v-if="!!getAssetById(assetID)" class="close-icon" @click="removeAsset" />
      </div>

      <img
        v-if="!!getAssetById(assetID)"
        class="thumbnail"
        :src="getAssetThumbnailSrcByAssetId(assetID)"
        alt="Preview"
      />
      <div v-else class="plus-sign" @click="mediaClicked">
        <PlusIcon />
      </div>
    </div>
  </div>
</template>

<script>
import { helperMixin } from '@/helpers/helpers';
import PlusIcon from '@/assets/images/plus.svg';
import RemoveMediaIcon from '@/assets/images/remove_media.svg';

export default {
  components: {
    PlusIcon,
    RemoveMediaIcon,
  },
  mixins: [helperMixin],
  props: {
    assetID: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  methods: {
    submitSelection() {
      this.$emit('input', this.asset);
    },
    getAssetById(assetID) {
      return this.$store.getters['assets/getAssetById'](assetID);
    },
    removeAsset() {
      this.$emit('removeAsset');
    },
    mediaClicked() {
      this.$emit('mediaClicked');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';
.asset-select-container {
}

.asset-selection-field {
  @apply .flex .justify-center .items-center;
  cursor: pointer;
  position: relative;
  width: rem(280px);
  height: rem(175px);
  color: rgba(51, 51, 51, 0.5);
  background-color: #ffffff;
  .media-label-container {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(50px);
    &.with-asset {
      background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
    }
  }
  .media-label {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-family: $cch-font-family;
    font-size: 0.75rem;
  }
  .label-with-asset {
    color: #ffffff;
  }

  &.has-asset {
    background: $cch-light-steel-blue;
    cursor: default !important;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
  }
  .close-icon {
    position: absolute;
    color: #ffffff;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    cursor: pointer;
  }
}

input {
  display: none;
}
.plus-sign {
  @apply .text-xl .font-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $cch-light-blue;
}
</style>
