<template>
  <div class="loading-wrapper">
    <div class="text-wrapper">
      <span class="small-text">{{ $t('contentWizard.preview.oneMoment') }}</span>
      <span class="large-text">{{ $t('contentWizard.preview.htmlIsRendering') }}</span>
    </div>
    <div class="spinning-bar">
      <div class="spinner" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.loading-wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: rem(0, 72px);
  margin-bottom: 7.5rem;
  .text-wrapper {
    max-width: rem(300px);
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;

    .small-text {
      font-family: $cch-font-family;
      font-size: rem(16px);
      color: $cch-dark-grey;
    }
    .large-text {
      font-family: $cch-font-family;
      font-size: rem(24px);
      font-weight: 800;
      color: $cch-dark-grey;
    }
  }

  .spinning-bar {
    width: 100%;
    height: rem(3px);
    background-color: $cch-light-gray;
    overflow: hidden;
    margin-top: 1.5rem;
    .spinner {
      width: 30%;
      height: 100%;
      animation: marquee 2s linear infinite;
      animation-name: spin;
      background-color: $cch-buttons-blue;
    }
  }
}

@keyframes spin {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(350%, 0, 0);
  }
}
</style>
