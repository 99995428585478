
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import ErrorBox from '@/components/shared/ErrorBox.vue';
import scssVariables from '@/styles/variables.module.scss';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ModalBase,
    AppButton,
    ErrorBox,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputAsset: {
      type: Object,
      required: true,
    },
    assetRestrictions: {
      type: Object,
      required: false,
      default: null,
    },
    hasApiData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cropper: Cropper,
      hasInsufficientResolution: false,
      isCropperReady: false,
      scssVariables,
      selectedWidth: 0,
      selectedHeight: 0,
      imageFilePath: '',
    };
  },
  computed: {
    fileName(): string {
      const fileName = this.inputAsset.name;
      const { width } = this.inputAsset.metadata;
      const { height } = this.inputAsset.metadata;

      return `${fileName} (${width} × ${height})`;
    },
    isShowingOverlay(): boolean {
      return !this.isCropperReady;
    },
  },
  async mounted() {
    if (this.$refs.image) {
      const image = this.$refs.image as HTMLImageElement;
      if (this.inputAsset?.signedUrl) {
        image.src = await this.getImageFromCanvas(this.inputAsset.signedUrl);
      } else {
        image.src = this.inputAsset.signedUrl;
      }
      this.setupCropper(image);
    }
  },
  methods: {
    async getImageFromCanvas(srcUrl: string): Promise<string> {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.naturalWidth;
          canvas.height = img.naturalHeight;
          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
            resolve(canvas.toDataURL());
          } else {
            resolve('');
          }
        };
        img.crossOrigin = 'anonymous';
        img.src = srcUrl;
      });
    },
    backToAssets() {
      this.$emit('backToAssets');
    },
    onCloseClicked() {
      this.$emit('closeClick');
    },
    minHeight() {
      if (this.assetRestrictions) {
        return this.assetRestrictions.minHeight;
      }

      return 0;
    },
    minWidth() {
      if (this.assetRestrictions) {
        return this.assetRestrictions.minWidth;
      }

      return 0;
    },
    aspectRatio() {
      if (this.assetRestrictions) {
        return this.assetRestrictions.aspectRatio;
      }

      return NaN;
    },
    cropAsset() {
      return this.hasApiData || this.inputAsset?.signedUrl ? this.cropAssetBinary() : this.getCroppedAssetData();
    },
    async cropAssetBinary() {
      let canvas;

      if (this.assetRestrictions) {
        canvas = this.cropper.getCroppedCanvas({
          width: this.assetRestrictions.minWidth,
          height: this.assetRestrictions.minHeight,
          imageSmoothingQuality: 'high',
        });
      } else {
        canvas = this.cropper.getCroppedCanvas();
      }

      const croppedAsset = canvas.toDataURL('image/png');
      this.$emit('assetBinaryCropped', croppedAsset);
    },
    getCroppedAssetData() {
      const croppedImageDimensions = this.cropper.getData();

      const top = Math.round(croppedImageDimensions.y);
      const left = Math.round(croppedImageDimensions.x);

      const cropData = {
        id: this.inputAsset._id,
        target: {
          width: this.minWidth(),
          height: this.minHeight(),
        },
        crop: {
          width: Math.round(croppedImageDimensions.width),
          height: Math.round(croppedImageDimensions.height),
          top: top > 0 ? top : 0,
          left: left > 0 ? left : 0,
        },
      };
      this.$emit('assetCropped', cropData);
    },
    setupCropper(image: HTMLImageElement): void {
      image.addEventListener('ready', () => {
        this.isCropperReady = true;
      });

      // Documentation is here: https://github.com/fengyuanchen/cropperjs/blob/master/README.md
      this.cropper = new Cropper(image, {
        zoomable: true,
        scalable: true,
        aspectRatio: this.aspectRatio(),
        movable: true,
        viewMode: 2,
        autoCropArea: 1,

        crop: (event) => {
          this.selectedWidth = event.detail.width;
          this.selectedHeight = event.detail.height;

          this.hasInsufficientResolution =
            Math.round(event.detail.width + 2) < this.minWidth() ||
            Math.round(event.detail.height + 2) < this.minHeight();
        },
      });
    },
  },
});
