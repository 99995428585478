
import { defineComponent, PropType } from 'vue';
import { Campaign } from '@/types';
import { mapGetters } from 'vuex';
import { formatDate } from '@/helpers/helpers';
import DeleteIcon from '@/assets/images/delete_FILL0_wght200_GRAD0_opsz24.svg';
import EditIcon from '@/assets/images/edit_FILL0_wght200_GRAD0_opsz24.svg';
import DownloadIcon from '@/assets/images/file_download_FILL0_wght200_GRAD0_opsz24.svg';
import ShareIcon from '@/assets/images/share_FILL0_wght200_GRAD0_opsz24.svg';
import { ITEMS_PER_PAGE_OPTIONS, DEFAULT_ITEMS_PER_PAGE } from '@/constants/pagination';

export default defineComponent({
  components: {
    EditIcon,
    DownloadIcon,
    ShareIcon,
    DeleteIcon,
  },
  props: {
    items: {
      type: Array as PropType<Array<Campaign>>,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('campaigns.general.campaignName'),
          value: 'name',
          align: 'start',
        },
        {
          text: this.$t('campaigns.general.files'),
          value: 'contentIds',
          align: 'end',
        },
        {
          text: this.$t('campaigns.general.lastUpdated'),
          value: 'updatedAt',
          align: 'end',
        },
        {
          text: this.$t('general.users.actions'),
          value: 'actions',
          sortable: false,
          align: 'end',
        },
      ],
      formatDate,
      inputDebounce: null as number | null,
      ITEMS_PER_PAGE_OPTIONS,
      DEFAULT_ITEMS_PER_PAGE,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'campaigns/getLoading',
    }),
    searchTerm: {
      get() {
        return this.$store.getters['campaigns/getSearchTerm'];
      },
      set(value: string) {
        if (this.inputDebounce) {
          clearTimeout(this.inputDebounce);
        }
        this.inputDebounce = setTimeout(() => {
          this.$store.dispatch('campaigns/addSearchTerm', value);
        }, 250);
      },
    },
  },
  methods: {
    handleClickEdit(item: Campaign) {
      this.$emit('edit', item);
    },
    handleClickDownload(item: Campaign) {
      this.$emit('download', item);
    },
    handleClickShare(item: Campaign) {
      this.$emit('share', item);
    },
    handleClickDelete(item: Campaign) {
      this.$emit('delete', item);
    },
    handleClickRow(item: Campaign) {
      this.$router.push({ name: 'campaign', params: { id: item._id } });
    },
    handleClickCellActions(e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();
    },
  },
});
