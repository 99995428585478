<template>
  <div class="file-count-container">
    <AppText>{{ $t('campaigns.general.files') }}</AppText>
    <AppText type="contentcount">{{ contentCount }}</AppText>
  </div>
</template>

<script>
import AppText from '@/components/shared/AppText.vue';

export default {
  components: {
    AppText,
  },
  props: {
    contentCount: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.file-count-container {
  height: rem(60px);
  width: rem(60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  padding: rem(5px 0);
}
</style>
