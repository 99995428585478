
import ChevronIcon from '@/assets/images/chevron_right.svg';
import { Breadcrumb } from '@/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    ChevronIcon,
  },
  props: {
    breadcrumbs: {
      type: Array as PropType<Array<Breadcrumb>>,
      required: true,
    },
  },
  methods: {
    click(item: { path: string[] }) {
      this.$emit('breadcrumbClicked', item.path);
    },
  },
});
