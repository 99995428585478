import { editMedia, EditMediaRequestParams } from '@/services/api/edit-media.resource';
import { ActionContext } from 'vuex';

const SET_IS_EDITING = 'SET_IS_EDITING';
const SET_EDITED_MEDIA_ID = 'SET_EDITED_MEDIA_ID';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

interface State {
  isEditing: boolean;
  editedMediaId: string;
  errorMessage: string;
}

type Context = ActionContext<State, State>;

const state: State = {
  isEditing: false,
  editedMediaId: '',
  errorMessage: '',
};

const actions = {
  async editMedia({ commit, rootGetters }: $TSFixMe, params: Pick<EditMediaRequestParams, 'id' | 'timeline'>) {
    commit(SET_IS_EDITING, true);
    commit(SET_ERROR_MESSAGE, '');
    try {
      const organizationId = rootGetters['auth/getUserOrganization'];
      const editedMedia = await editMedia({ ...params, organizationId });
      commit(SET_EDITED_MEDIA_ID, editedMedia._id);
    } catch (e) {
      commit(SET_ERROR_MESSAGE, (e as $TSFixMe).data?.error?.data?.message);
    } finally {
      commit(SET_IS_EDITING, false);
    }
  },
};

const mutations = {
  [SET_IS_EDITING](state: $TSFixMe, payload: boolean) {
    state.isEditing = payload;
  },
  [SET_EDITED_MEDIA_ID](state: $TSFixMe, payload: string) {
    state.editedMediaId = payload;
  },
  [SET_ERROR_MESSAGE](state: $TSFixMe, payload: string) {
    state.errorMessage = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
