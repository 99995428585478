<template>
  <div v-if="show" class="modal-container">
    <div v-click-outside="closeModalConfig" class="modal-content lg:w-2/5" :style="overrideStyle">
      <ModalHeader
        v-if="!headerHidden"
        :override-header-style="overrideHeaderStyle"
        :override-header-title-style="overrideHeaderTitleStyle"
        @closeClicked="closeModal"
        >{{ $t(title) }}</ModalHeader
      >
      <div class="content">
        <slot name="content" />
      </div>
      <div class="button-row">
        <slot name="button-row" />
      </div>
    </div>
  </div>
</template>

<script>
import { helperMixin } from '@/helpers/helpers';
import ModalHeader from '@/components/shared/modal/ModalHeader.vue';

export default {
  components: {
    ModalHeader,
  },
  mixins: [helperMixin],
  props: {
    show: Boolean,
    title: { type: String, required: false, default: null },
    headerHidden: {
      type: Boolean,
      default: false,
    },
    overrideStyle: {
      type: Object,
      required: false,
      default: null,
    },
    overrideHeaderStyle: {
      type: Object,
      required: false,
      default: null,
    },
    overrideHeaderTitleStyle: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      closeModalConfig: {
        handler: this.closeModal,
        include: this.include,
      },
    };
  },

  methods: {
    closeModal() {
      this.$emit('closeClicked');
    },
    include() {
      return document.querySelector('.v-select-list') ? [document.querySelector('.v-select-list')] : [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';

.modal-container {
  @apply .w-full .h-full .block .fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgb(241, 244, 255);
  background-color: rgba(122, 129, 141, 0.5);
  overflow: hidden;
  cursor: auto;
}

.content {
  height: calc(100% - 48px);
}

.modal-content {
  @apply .bg-white .p-6 .absolute;
  height: auto;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
  overflow: hidden;
}
</style>
