<template>
  <div class="status-wrapper">
    <div class="text-wrapper">
      <template v-if="isLoading">
        <span class="small-text">{{ $t('contentWizard.renderStatus.oneMoment') }}</span>
        <span class="large-text">{{ $t('contentWizard.renderStatus.previewIsRendering') }}</span>
        <span class="small-text">{{ statusMessage }}</span>
      </template>
    </div>
    <div v-if="isLoading" class="status-bar">
      <div v-if="isWaiting" class="spinner" />
      <div v-if="isActive" class="progress-bar" :style="{ width: `${renderProgress}%` }" />
    </div>
    <div v-if="showQueueMessage" class="small-text">
      {{ $t('contentWizard.renderStatus.previewsInQueueMessage', [jobsInQueue]) }}
      <v-tooltip bottom open-delay="500">
        <template #activator="{ on, attrs }">
          <span class="info-icon mx-1" v-bind="attrs" v-on="on">?</span>
        </template>
        <span>{{ $t('contentWizard.renderStatus.previewsInQueueInfo') }} </span>
      </v-tooltip>
    </div>
    <div v-if="isLoading && attempts > 0" class="smaller-text my-2">{{ errorAttemptsMessage }}</div>
    <PreviewErrorMessage v-if="isFinishedWithError" :error="error" :user-error-message="statusMessage" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { RENDER_WS_STATUS, RENDER_ATTEMPTS_MAX } from '@/constants/content/contentCreation';
import PreviewErrorMessage from '@/components/campaignDetail/contentCreation/contentSpecification/PreviewErrorMessage.vue';

export default {
  name: 'VideoPreviewStatus',
  components: { PreviewErrorMessage },
  props: {
    attempts: {
      type: Number,
      default: 0,
    },
    error: {
      type: String,
      default: '',
    },
    renderProgress: {
      type: Number,
      default: 0,
    },
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      queueStatus: 'contentCreation/getVideoJobQueueStatus',
    }),
    statusMessage() {
      if (this.status === RENDER_WS_STATUS.WAITING) {
        return this.$t('contentWizard.renderStatus.jobIsWaiting');
      }
      if ([RENDER_WS_STATUS.ACTIVE, RENDER_WS_STATUS.RENDERING].includes(this.status)) {
        return this.$t('contentWizard.renderStatus.previewIsRendering');
      }
      if (this.status === RENDER_WS_STATUS.FAILED) {
        return this.$t('contentWizard.renderStatus.renderFailed');
      }
      if (this.status === RENDER_WS_STATUS.FINISHED_ERROR) {
        return this.$t('contentWizard.renderStatus.renderFinishedWithError');
      }
      return this.status;
    },
    errorAttemptsMessage() {
      return this.$t('content.general.errorAttemptsMessage', [this.attempts + 1, RENDER_ATTEMPTS_MAX]);
    },
    jobsInQueue() {
      return this.queueStatus.preview.enqueued + this.queueStatus.preview.waiting + this.queueStatus.preview.active;
    },
    isLoading() {
      return ![RENDER_WS_STATUS.FINISHED_ERROR, RENDER_WS_STATUS.COMPLETED].includes(this.status);
    },
    isWaiting() {
      return this.status === RENDER_WS_STATUS.WAITING;
    },
    isActive() {
      return [
        RENDER_WS_STATUS.ACTIVE,
        RENDER_WS_STATUS.RENDERING_POSTRENDER,
        RENDER_WS_STATUS.RENDERING_CLEANUP,
      ].includes(this.status);
    },
    isFinishedWithError() {
      return this.status === RENDER_WS_STATUS.FINISHED_ERROR && this.attempts === RENDER_ATTEMPTS_MAX;
    },
    showQueueMessage() {
      return this.jobsInQueue > 1 && this.isWaiting;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.status-wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: rem(0, 72px);
  margin-bottom: 7.5rem;

  .text-wrapper {
    @apply w-1/2;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    color: $cch-dark-grey;
  }
  .small-text {
    font-size: rem(16px);
  }
  .smaller-text {
    font-size: rem(14px);
  }
  .large-text {
    font-size: rem(24px);
    font-weight: 800;
  }

  .status-bar {
    width: 100%;
    height: rem(3px);
    background-color: $cch-light-gray;
    overflow: hidden;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    .spinner {
      width: 30%;
      height: 100%;
      animation: marquee 2s linear infinite;
      animation-name: spin;
      background-color: $cch-buttons-blue;
    }
    .progress-bar {
      height: rem(2px);
      background-color: $cch-buttons-blue !important;
    }
  }
}

.info-icon {
  display: inline-block;
  border-radius: 1rem;
  border: 1px solid $cch-light-steel-blue;
  color: $cch-light-steel-blue;
  width: 1rem;
  height: 1rem;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  vertical-align: middle;
  cursor: pointer;
}

@keyframes spin {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(350%, 0, 0);
  }
}
</style>
