export const setLocalstorageItem = (key: $TSFixMe, value: $TSFixMe) => {
  return new Promise((resolve) => {
    resolve(window.localStorage.setItem(key, value));
  });
};

export const getLocalstorageItem = (key: $TSFixMe): Promise<string> => {
  return new Promise((resolve, reject) => {
    const item = window.localStorage.getItem(key);
    if (item !== null) {
      resolve(item);
    }
    reject();
  });
};

export const removeLocalstorageItem = (key: $TSFixMe) => {
  return new Promise((resolve) => {
    resolve(window.localStorage.removeItem(key));
  });
};

export const clearLocalstorage = () => {
  return new Promise((resolve) => {
    resolve(window.localStorage.clear());
  });
};
