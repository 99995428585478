// eslint-disable-next-line import/no-cycle
import HTTP from '@/services/http';
import getLogger from '@/services/logger';

const LOG = getLogger('resource/users');
const urlPrefix = `${process.env.VUE_APP_AUTH_URL}/user`;

export const getUser = async (id: $TSFixMe) => {
  LOG.info(`Attempting to get user ${id}`);
  try {
    const response = await HTTP.get(`${urlPrefix}/${id}`);
    return response.data;
  } catch (e) {
    LOG.error(`Error fetching user ${id}`);
    return null;
  }
};

export const getAllUsers = async () => {
  LOG.log(`Attempting to get all users`);
  const response = await HTTP.get(urlPrefix);
  return response.data;
};

export const updateUser = async (id: $TSFixMe, user: $TSFixMe) => {
  LOG.log(`Attempting to update user with id: ${id}`);
  return HTTP.patch(`${urlPrefix}/${id}`, user);
};

export const createNewUser = async (user: $TSFixMe) => {
  LOG.log(`Attempting to create a new user with name ${user.firstName} ${user.lastName}`);
  return HTTP.post(urlPrefix, user, {});
};

export const deactivateUser = async (user: $TSFixMe) => {
  LOG.log(`Attempting to deactivate user with name ${user.firstName} ${user.lastName}`);
  return HTTP.delete(`${process.env.VUE_APP_AUTH_URL}/deactivate-user/${user._id}`);
};

export const deleteUser = async (user: $TSFixMe) => {
  LOG.log(`Attempting to delete user with name ${user.firstName} ${user.lastName}`);
  return HTTP.delete(`${process.env.VUE_APP_AUTH_URL}/delete-user/${user._id}`);
};

export const activateUser = async (user: $TSFixMe) => {
  LOG.log(`Attempting to activate user with name ${user.firstName} ${user.lastName}`);
  return HTTP.patch(`${process.env.VUE_APP_AUTH_URL}/activate-user/${user._id}`);
};
