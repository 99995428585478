<template>
  <ModalBase
    :show="isDiscardModalOpen"
    :header-hidden="headerHidden"
    :title="title"
    :override-style="{ width: 'auto' }"
    :override-header-style="{ 'margin-bottom': '3rem' }"
    @closeClicked="closeModal"
  >
    <template #content>
      <div class="modal-content-container mb-6">
        <div class="content-wrapper">
          <div class="content-container">
            <EditIcon v-if="!isIconHidden" class="edit-icon" />
            <div class="text-wrapper">{{ $t(message) }}</div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-if="extraMessage" class="extra-message" v-html="extraMessage" />
          </div>
        </div>
      </div>
    </template>
    <template #button-row>
      <div class="modal-button-container">
        <AppButton color="warning" size="small-auto" uppercase @click="onClickLeftButton">
          {{ $t(leftButtonText) }}
        </AppButton>
        <AppButton size="small-auto" uppercase @click="onClickRightButton">{{ $t(rightButtonText) }}</AppButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import EditIcon from '@/assets/images/edit_big.svg';

export default {
  components: {
    ModalBase,
    EditIcon,
    AppButton,
  },
  props: {
    headerHidden: {
      type: Boolean,
      default: false,
    },
    isDiscardModalOpen: {
      type: Boolean,
      default: false,
    },
    isIconHidden: {
      type: Boolean,
      default: false,
    },
    leftButtonText: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    rightButtonText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    extraMessage: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    onClickLeftButton() {
      this.$emit('clickLeftButton');
    },
    onClickRightButton() {
      this.$emit('clickRightButton');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .content-container {
    max-width: rem(312px);
  }
  .edit-icon {
    @apply .mx-auto;
    margin-bottom: 1rem;
  }
  .text-wrapper {
    font-family: $cch-font-family;
    font-size: rem(20px);
    font-weight: bold;
    text-align: center;
    color: #c5cde2;
  }
  .extra-message {
    font-family: $cch-font-family;
    font-size: rem(13px);
    text-align: center;
    margin-top: 1rem;
    color: $cch-status-color-error;
  }
}
.modal-button-container {
  @apply .flex .items-center .justify-between;
  gap: 3rem;
}
</style>
