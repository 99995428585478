var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModalBase',{attrs:{"title":_vm.title,"show":_vm.show,"override-style":{
    width: 'auto',
    'min-width': '500px',
    height: 'auto',
    'margin-bottom': '0 rem',
    overflow: 'hidden',
  },"override-header-style":{ 'margin-bottom': '1rem' }},on:{"closeClicked":_vm.onCloseClicked},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"error"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.escapedErrorWithLineBreaks)}})])]},proxy:true},{key:"button-row",fn:function(){return [_c('div',{staticClass:"actions-row"},[_c('div',{staticClass:"actions-row-item",on:{"click":_vm.copyErrorMessage}},[_c('div',{staticClass:"actions-row-item-label"},[_vm._v(_vm._s(_vm.$t('content.general.copyErrorMessage')))]),_c('GroupIcon',{staticClass:"actions-row-item-icon"})],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }