
import { mapGetters } from 'vuex';
import FormInput from '@/components/shared/FormInput.vue';
import ErrorMessage from '@/components/shared/ErrorMessage.vue';
import AppButton from '../shared/buttons/AppButton.vue';
import { toast } from '@/services/toast';

export default {
  name: 'ResetPasswordForm',
  components: { ErrorMessage, FormInput, AppButton },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
    };
  },
  computed: {
    ...mapGetters({
      authError: 'auth/getErrorMessage',
      isLoading: 'auth/isLoading',
    }),
    isDisabled() {
      return !this.newPassword || !this.confirmPassword;
    },
  },
  methods: {
    async onChangePasswordClicked() {
      if (this.newPassword !== this.confirmPassword) {
        this.$store.commit('auth/setErrorMessage', 'password_mismatch');
        return;
      }
      await this.$store.dispatch('auth/resetPassword', {
        resetPasswordJwt: this.$route.params.token,
        newPassword: this.newPassword,
      });
      toast.$emit('showToast', {
        type: 'success',
        groupName: 'auth',
        message: this.$t('login.general.passwordChangedSuccessfully'),
      });
      this.$router.push({ name: 'login' });
    },
  },
};
