import getLogger from '@/services/logger';
import { SUPERADMIN } from '@/constants/user/roles';

const LOG = getLogger('shared/functions');

export function asyncCopyToClipboard(textToCopy: $TSFixMe) {
  navigator.clipboard.writeText(textToCopy).then(() => {
    LOG.log('Copied text to clipboard');
  });
}

export function asyncReadFromClipboard() {
  return navigator.clipboard.readText().then((data) => {
    LOG.log('Read content from clipboard');
    return data;
  });
}

export function isSuperAdmin(role: $TSFixMe) {
  return role === SUPERADMIN;
}
