<template>
  <div class="login-form">
    <form>
      <FormInput
        v-model="username"
        class="input"
        placeholder="login.general.emailAddress"
        name="username"
        autocomplete="on"
      />
      <FormInput
        v-model="password"
        class="input"
        placeholder="login.general.password"
        type="password"
        name="current-password"
        autocomplete="on"
        @enterPressed="onPasswordEnterPressed"
      />
    </form>
    <ErrorMessage v-if="authError" class="error-message">{{ $t('login.errors.' + authError) }}</ErrorMessage>
    <div class="form-buttons">
      <AppButton :disabled="isDisabled" @click="onLoginClicked">
        {{ $t('login.general.login') }}
      </AppButton>
      <AppButton type="outlined" class="button-forgot-password" :loading="isLoading" @click="onForgotPasswordClicked">
        {{ $t('login.general.forgotPassword') }}
      </AppButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormInput from '@/components/shared/FormInput.vue';
import ErrorMessage from '@/components/shared/ErrorMessage.vue';
import AppButton from '../shared/buttons/AppButton.vue';
import { toast } from '@/services/toast';

export default {
  name: 'LoginForm',
  components: { ErrorMessage, FormInput, AppButton },
  data() {
    return {
      username: '',
      password: '',
      error: this.authError,
    };
  },
  computed: {
    ...mapGetters({
      authError: 'auth/getErrorMessage',
      isPasswordChangeRequired: 'auth/isPasswordChangeRequired',
      isLoading: 'auth/isLoading',
    }),
    isDisabled() {
      return !this.username || !this.password;
    },
  },
  methods: {
    async onLoginClicked() {
      try {
        await this.$store.dispatch('auth/login', {
          username: this.username,
          password: this.password,
        });

        if (this.isPasswordChangeRequired) {
          this.$router.push({ name: 'passwordChangePrompt' });
        } else {
          this.$router.push({ name: 'home' });
        }
      } catch {
        this.$store.commit('auth/setErrorMessage', 'wrong_credentials');
      }
    },
    onPasswordEnterPressed() {
      this.onLoginClicked();
    },
    async onForgotPasswordClicked() {
      if (!this.username) {
        this.$store.commit('auth/setErrorMessage', 'email_required');
        return;
      }
      await this.$store.dispatch('auth/sendPasswordResetLink', this.username);
      toast.$emit('showToast', {
        type: 'success',
        groupName: 'auth',
        message: this.$t('login.general.passwordResetLinkSent'),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';
.login-form {
  .input {
    @apply .mb-4 .w-3/5;
  }

  .error-message {
    @apply .mb-4;
  }

  .form-buttons {
    @apply .flex .flex-row .w-3/5;
  }
  .button-forgot-password {
    @apply .ml-4;
  }
}
</style>
