<template>
  <div class="language-selection-wrapper">
    <select @input="$emit('input', $event.target.value)" @change="$emit('changeLanguage', $event.target.value)">
      <option
        v-for="(language, index) in languages"
        :key="index"
        :value="language"
        :selected="language === languages[0]"
        >{{ capitalizeFirst($t(language)) }}</option
      >
    </select>
    <ArrowDownIcon class="selection-icon" />
  </div>
</template>

<script>
import ArrowDownIcon from '@/assets/images/arrow-down.svg';
import { helperMixin } from '@/helpers/helpers';

export default {
  components: {
    ArrowDownIcon,
  },
  mixins: [helperMixin],
  props: {
    languages: {
      type: Array,
      default: null,
    },
    selectedLanguage: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.language-selection-wrapper {
  @apply .font-sans .font-semibold .text-blue-light .w-full .relative .bg-white;
  height: rem(45px);
  overflow: hidden;
  padding: rem(0 0 0 10px);
  width: rem(130px);

  select:focus {
    outline: none;
  }
  select {
    @apply .absolute;
    bottom: 0;
    height: 100%;
    width: rem(calc(100% - 10px));
    left: rem(10px);
    background-color: #ffffff;
    color: $cch-light-steel-blue;
    font-weight: bold;
    appearance: none;
  }

  .selection-icon {
    float: right;
    pointer-events: none;
    position: relative;
    right: rem(15px);
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
  }
}
</style>
