import { render, staticRenderFns } from "./OptionsBox.vue?vue&type=template&id=894f6590&scoped=true&"
import script from "./OptionsBox.vue?vue&type=script&lang=ts&"
export * from "./OptionsBox.vue?vue&type=script&lang=ts&"
import style0 from "./OptionsBox.vue?vue&type=style&index=0&id=894f6590&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "894f6590",
  null
  
)

export default component.exports