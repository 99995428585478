
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import FormSelectionField from '@/components/shared/formFields/FormSelectionField.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import SmallSpinner from '@/components/shared/SmallSpinner.vue';
import scssVariables from '@/styles/variables.module.scss';
import { defineComponent, PropType } from 'vue';
import { Organization, OrganizationGroup } from '@/types';
import { getOrganizationGroups } from '@/services/api/organization-groups.resource';

const DEFAULT_NAME = '';
const DEFAULT_ORGANIZATION_GROUP_ID = 'createNewOrganizationGroup';
const DEFAULT_CREDITS = 1000;

export default defineComponent({
  components: {
    ModalBase,
    FormInputField,
    FormSelectionField,
    AppButton,
    SmallSpinner,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    organization: {
      type: Object as PropType<Organization | null>,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'cancel', 'submit'],
  data() {
    return {
      scssVariables,
      organizationGroups: [] as Array<OrganizationGroup>,
      name: DEFAULT_NAME,
      selectedOrganizationGroupId: DEFAULT_ORGANIZATION_GROUP_ID,
      credits: DEFAULT_CREDITS,
    };
  },
  computed: {
    title(): string {
      return this.organization
        ? this.$t('organizations.general.editOrganization')
        : this.$t('organizations.general.createOrganization');
    },
    submitText(): string {
      return this.organization ? this.$t('general.buttons.edit') : this.$t('general.buttons.create');
    },
    isFormValid(): boolean {
      if (!this.name || this.name.length === 0) {
        return false;
      }
      if (!this.credits || isNaN(this.credits)) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    await this.loadAvailableOrganizationGroups();
    this.initializeForm();
    this.focusFirstField();
  },
  methods: {
    async loadAvailableOrganizationGroups() {
      const realGroups = await getOrganizationGroups();
      const fakeGroup: OrganizationGroup = {
        id: DEFAULT_ORGANIZATION_GROUP_ID,
        name: this.$t('organizations.general.createNewOrganizationGroup'),
      };
      this.organizationGroups = [fakeGroup, ...realGroups];
    },
    initializeForm() {
      if (this.organization) {
        this.name = this.organization.name;
        this.credits = this.organization.credits;
        this.selectedOrganizationGroupId = this.organization?.organizationGroupId || DEFAULT_ORGANIZATION_GROUP_ID;
      } else {
        this.name = DEFAULT_NAME;
        this.credits = DEFAULT_CREDITS;
      }
    },
    focusFirstField() {
      if (this.$refs.firstInput) {
        (this.$refs.firstInput as HTMLElement).focus();
      }
    },
    onChangeOrganizationGroup(groupId: string) {
      this.selectedOrganizationGroupId = groupId;
    },
    onClose() {
      this.$emit('close');
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSubmit() {
      if (this.isFormValid) {
        this.$emit('submit', {
          name: this.name,
          credits: Number(this.credits),
          organizationGroupId:
            this.selectedOrganizationGroupId === DEFAULT_ORGANIZATION_GROUP_ID
              ? null
              : this.selectedOrganizationGroupId,
        });
      }
    },
  },
});
