<template>
  <ModalBase
    :title="isFolder ? $t('assets.general.editFolder') : $t('assets.general.editAsset')"
    :show="show"
    :override-style="{
      width: '552px',
      height: 'auto',
      overflow: 'hidden',
      'background-color': scssVariables.cchLightGray,
    }"
    :override-header-style="{ 'margin-bottom': '1rem' }"
    @closeClicked="onCloseClicked"
  >
    <template #content>
      <div class="text">
        <FormInputField
          v-model="assetName"
          class="asset-name"
          :placeholder="isFolder ? $t('assets.general.folderName') : $t('assets.general.assetName')"
        />
        <div v-if="!isFolder" class="asset-container">
          <div v-if="!canUploadFile" class="current-asset">
            <div class="info-row">
              <div class="remove-button-container">
                <CloseIcon class="remove-button ignore" viewBox="0 0 16 16" @click="removeAsset()" />
              </div>
            </div>
            <div>
              <video v-if="isVideo && show" class="asset-video" controls preload>
                <source :src="getAssetSource()" />
              </video>
            </div>
            <audio v-if="isAudio && show" class="asset-audio" controls>
              <source ref="audioPlayer" :src="getAssetSource()" />
            </audio>
            <img v-if="isImage && show" class="asset-image" :src="getAssetSource()" />
            <div v-if="!isImage && !isVideo && !isAudio" class="asset-unknown">
              {{ $t('assets.general.previewNotAvailable') }}
            </div>
          </div>
          <div v-if="canUploadFile" class="add-asset-button">
            <input
              id="file-input"
              ref="file"
              type="file"
              :accept="inputAcceptTypes"
              class="file-input"
              @change="handleUploadAsset"
            />
            <div class="add-asset-label">{{ $t('assets.general.addAsset') }}</div>
            <PlusIcon class="plus-icon" />
          </div>
        </div>
      </div>
    </template>
    <template #button-row>
      <div class="actions-row">
        <AppButton size="small-auto" type="outlined" @click="onCloseClicked()">
          {{ $t('general.buttons.cancel') }}
        </AppButton>
        <AppButton size="small-auto" @click="editAsset()">
          {{ $t(isFolder ? 'assets.general.editFolder' : 'assets.general.editAsset') }}
        </AppButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import PlusIcon from '@/assets/images/plus.svg';
import CloseIcon from '@/assets/images/close.svg';
import scssVariables from '@/styles/variables.module.scss';
import { toast } from '@/services/toast';
import { AssetType } from '@/types';

export default {
  components: {
    ModalBase,
    FormInputField,
    AppButton,
    PlusIcon,
    CloseIcon,
  },
  props: {
    asset: {
      type: Object,
      default: null,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      assetName: this.asset.name,
      isEditingAsset: false,
      file: null,
      assetUrl: '',
      scssVariables,
      acceptedFileFormats: ['image/jpg', 'image/jpeg', 'image/png', 'video/mp4'],
    };
  },
  computed: {
    ...mapGetters({}),
    isVideo() {
      return this.asset.type === AssetType.VIDEO;
    },
    isAudio() {
      return this.asset.type === AssetType.AUDIO;
    },
    isImage() {
      return this.asset.type === AssetType.IMAGE && this.asset.extension !== 'psd';
    },
    isFolder() {
      return this.asset.type === AssetType.FOLDER;
    },
    canUploadFile() {
      return this.isEditingAsset;
    },
    inputAcceptTypes() {
      if (this.isImage) {
        return 'image/*, .psd';
      }
      if (this.isAudio) {
        return 'audio/*';
      }
      if (this.isVideo) {
        return 'video/*';
      }

      return 'image/*, audio/*, video/*, .psd';
    },
    isAssetNameProvided() {
      return this.assetName.length > 0;
    },
    isAssetProvided() {
      return this.file !== null || !this.canUploadFile;
    },
  },
  watch: {
    asset(newAsset) {
      this.assetName = newAsset.name;
    },
  },
  methods: {
    onCloseClicked() {
      this.isEditingAsset = false;
      this.assetUrl = '';
      this.file = null;
      this.$emit('close');
    },
    getAssetSource() {
      if (!this.file) {
        return this.asset?.thumbSignedUrl;
      }
      return this.assetUrl;
    },
    removeAsset() {
      this.isEditingAsset = true;
      this.assetUrl = '';
    },

    editAsset() {
      if (this.validate()) {
        this.$emit('editClick', {
          id: this.asset._id,
          name: this.assetName,
          file: this.file,
          type: this.asset.type,
        });
      }
    },
    uploadContainsSupportedImageFormats() {
      let contains = false;
      this.acceptedFileFormats.forEach((format) => {
        if (this.$refs.file.files && this.$refs.file.files[0] && this.$refs.file.files[0].type.includes(format)) {
          contains = true;
        }
      });
      return contains;
    },
    handleUploadAsset() {
      if (this.uploadContainsSupportedImageFormats()) {
        this.isEditingAsset = false;
        [this.file] = this.$refs.file.files;
        this.assetUrl = URL.createObjectURL(this.file);
      } else {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'asset-creation',
          message: this.$t(`assets.general.wrongAssetType`),
        });
      }
    },
    validate() {
      if (!this.isAssetNameProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'asset-creation',
          message: this.$t(`assets.general.noAssetNameProvided`),
        });
        return false;
      }
      if (!this.isAssetProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'asset-creation',
          message: this.$t(`assets.general.assetIsNotProvided`),
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.confirmation-button {
  position: relative;
  width: rem(134px);
  height: rem(40px);
}
.asset-name {
  width: 100%;
  height: rem(51px);
}
.asset-container {
  width: 100%;
  max-height: rem(313px);
  background-color: #ffffff;
  margin-top: rem(24px);

  .current-asset {
    min-height: rem(100px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .info-row {
      color: #ffffff;
      mix-blend-mode: difference;
      position: absolute;
      top: rem(10px);
      height: rem(16px);
      display: flex;
      justify-content: flex-end;
      width: 100%;
      z-index: 1;

      .remove-button-container {
        margin-right: rem(16px);
        width: rem(12px);

        .remove-button {
          fill: #ffffff !important;
          width: rem(12px);
          cursor: pointer;
        }
      }
    }

    .asset-image,
    .asset-video {
      margin: 0 auto;
      max-height: rem(313px);
      box-shadow: 0 0 5px 0 rgba(71, 67, 102, 0.19);
    }
    .asset-audio {
      margin: 0 auto;
    }
  }
  .add-asset-button {
    position: relative;
    width: 100%;
    height: rem(300px);

    .file-input {
      position: absolute;
      width: 100%;
      height: rem(310px);
      opacity: 0;
      cursor: pointer;
      z-index: 10;
    }

    .add-asset-label {
      position: absolute;
      top: rem(8px);
      left: rem(16px);
      font-family: $cch-font-family;
      font-size: rem(12px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: rem(0.4px);
      color: rgba(51, 51, 51, 0.5);
    }

    .plus-icon {
      position: relative;
      top: rem(156px);
      margin: 0 auto;
    }
  }
}

.actions-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $cch-light-gray;
  width: 100%;
  margin-top: rem(24px);
}
</style>
