<template>
  <div class="small-spinner" />
</template>

<script>
export default {
  name: 'SmallSpinner',
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.module.scss';
@import '~@/styles/animations.scss';

.small-spinner,
.small-spinner:after {
  border-radius: 50%;
  width: rem(30px);
  height: rem(30px);
}
.small-spinner {
  @apply relative;
  @apply .spin;
  border-top: rem(2px) solid $cch-light-gray;
  border-right: rem(2px) solid $cch-light-blue;
  border-bottom: rem(2px) solid $cch-light-blue;
  border-left: rem(2px) solid $cch-light-blue;
  transform: translateZ(0);
}
</style>
