
import { ViewModeValue } from '@/types/ViewModeValue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: String as PropType<ViewModeValue>,
      default: 'grid',
      required: false,
    },
  },
  methods: {
    handleChange(value: ViewModeValue) {
      this.$emit('update:value', value);
      this.$emit('change', value);
    },
  },
});
