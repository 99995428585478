<template>
  <div>
    <div class="asset-creation-wrapper">
      <div class="title-container">
        <span class="title-container">{{ $t('templates.creation.assets') }}</span>
        <AppButton class="add-asset-button" @click="addAsset">+ {{ $t('templates.creation.newAsset') }}</AppButton>
      </div>
      <div v-if="assets.length === 0" class="empty-assets-wrapper">
        <div class="empty-assets-container">
          <EmptyContentIcon class="empty-content-icon" viewBox="0 0 45 45" />
          <div class="text-wrapper">{{ $t('templates.creation.emptyAssets') }}</div>
        </div>
      </div>
      <AssetRow
        v-for="(asset, index) in assets"
        :key="asset.id.toString()"
        class="asset-row"
        :class="index"
        :asset="asset"
        @removeClick="removeAsset(index)"
        @input="updateAsset(index, $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AssetRow from '@/components/templates/templateCreation/assets/AssetRow.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import EmptyContentIcon from '@/assets/images/empty_video.svg';
import { toast } from '@/services/toast';

export default {
  components: {
    AssetRow,
    AppButton,
    EmptyContentIcon,
  },
  data() {
    return {
      assets: [],
    };
  },
  computed: {
    ...mapGetters({
      assetsStore: 'templateCreation/getDefaultAssets',
    }),
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    this.assets = this.assetsStore.map(({ id, ...otherProperties }) => {
      return {
        id: Math.floor(Math.random() * 99999) + 1,
        ...otherProperties,
      };
    });
  },
  methods: {
    emitErrorMessage(errorMessage) {
      toast.$emit('showToast', {
        type: 'error',
        groupName: 'template-creation-validation',
        message: this.$t(`templates.errors.${errorMessage}`),
      });
    },
    validate() {
      let pass = true;
      this.assets.forEach((asset) => {
        if (!asset.layerName || !asset.type || (!asset.file && asset.isNew)) {
          this.emitErrorMessage('fillAll');
          pass = false;
        }
      });
      return pass;
    },
    save() {
      this.$store.commit('templateCreation/setDefaultAssets', this.assets);
    },
    addAsset() {
      this.assets.push({
        id: Math.floor(Math.random() * 99999) + 1,
        layerName: null,
        type: null,
        file: null,
        isNew: true,
      });
    },
    removeAsset(index) {
      this.assets.splice(index, 1);
    },
    updateAsset(index, event) {
      this.assets.splice(index, 1, event);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.asset-creation-wrapper {
  .title-container {
    display: flex;
    width: 100%;
    margin-bottom: rem(30px);
    font-family: $cch-font-family;
    font-size: rem(36px);
    font-weight: 800;
    color: #c5cde2;
    justify-content: space-between;
    align-items: baseline;
    .add-asset-button {
      width: rem(141px);
      height: rem(40px);
    }
  }
  .asset-row {
    margin-bottom: rem(0px);
  }
  .empty-assets-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(522px);
    background-color: white;
    .empty-assets-container {
      width: rem(210px);
      .empty-content-icon {
        @apply .mx-auto;
        margin-bottom: 1rem;
      }
      .text-wrapper {
        font-family: $cch-font-family;
        font-size: rem(20px);
        font-weight: bold;
        text-align: center;
        color: #c5cde2;
      }
    }
  }
}
</style>
