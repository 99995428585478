<template>
  <div>
    <div class="base-data-wrapper">
      <span class="title-container">{{ $t('templates.creation.basedata') }}</span>
      <FormSelectionField
        :placeholder="$t('templates.creation.type')"
        class="selection-field"
        :list="templateTypes"
        :selected-item="templateType"
        :disabled="isEditing"
        :title="isEditing ? $t('templates.creation.noChangeAllowed') : ''"
        @input="onTypeSelected"
      />
      <FormInputField
        v-model="name"
        :placeholder="$t('templates.creation.templateName')"
        :disabled="false"
        class="input-field"
      />
      <FileInputFormField
        v-model="template"
        class="input-field"
        :type-description="typeDescription"
        :disabled="!templateType"
        :accept="templateAcceptTypes"
        :placeholder="templateFilePlaceholder"
      />
      <FileInputFormField
        v-if="isVideo"
        v-model="fonts"
        class="input-field"
        type-description="Fonts zip"
        :disabled="!templateType"
        :accept="['application/zip']"
        :placeholder="templateFontsPlaceHolder"
      />
      <FormInputField
        v-if="isHTML5"
        v-model="functionKey"
        :placeholder="$t('templates.creation.functionKey')"
        class="input-field"
      />
      <label v-if="isHTML5" class="form-field checkbox-label">
        <input v-model="hasApiData" type="checkbox" class="input-field" />
        {{ $t('templates.creation.hasApiData') }}
      </label>
      <FormNumberInputField
        v-if="isHTML5"
        v-model="takePreviewScreenshotAtMS"
        :min="0"
        :placeholder="$t('templates.creation.previewScreenshotMS')"
        class="input-field"
      />
      <FormMultipleSelectionField
        v-if="isSuperAdmin"
        :placeholder="$t('templates.creation.organizations')"
        :list="organizationNames"
        :selected-items="selectedOrganizations"
        class="selection-field"
        @selected="onOrganizationNameSelected"
      />
      <div class="asset-selection-field" :class="{ 'has-asset': thumbURL }">
        <input
          id="file-input"
          ref="thumb"
          :accept="thumbInputAcceptTypes"
          type="file"
          class="file-input"
          @change="handleThumbUpload($event)"
        />
        <div class="media-label-container" :class="{ 'with-asset': thumbURL }">
          <span class="media-label">{{ $t('templates.creation.preview') }}</span>
          <RemoveMediaIcon v-if="thumbURL" class="close-icon" @click="removeThumb" />
        </div>
        <img v-if="thumb" class="thumbnail" alt="Preview" :src="thumbURL" />
        <div v-else-if="isEditing" class="change-text">
          {{ $t('templates.creation.clickToChange') }}
        </div>
        <div v-else class="plus-sign">
          <PlusIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormMultipleSelectionField from '@/components/shared/formFields/FormMultipleSelectionField.vue';
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import FormSelectionField from '@/components/shared/formFields/FormSelectionField.vue';
import PlusIcon from '@/assets/images/plus.svg';
import RemoveMediaIcon from '@/assets/images/remove_media.svg';
import FileInputFormField from '@/components/shared/formFields/FileInputFormField.vue';
import FormNumberInputField from '@/components/shared/formFields/FormNumberInputField.vue';
import { TEMPLATE_TYPE } from '@/constants/template/template';
import { isSuperAdmin } from '@/services/shared';
import { toast } from '@/services/toast';

export default {
  components: {
    FileInputFormField,
    FormInputField,
    FormSelectionField,
    PlusIcon,
    RemoveMediaIcon,
    FormMultipleSelectionField,
    FormNumberInputField,
  },
  data() {
    return {
      name: null,
      organizationIds: [],
      selectedOrganizations: [],
      template: null,
      thumb: null,
      thumbURL: null,
      templateType: '',
      functionKey: '',
      hasApiData: false,
      fonts: null,
      templateFileName: '',
      takePreviewScreenshotAtMS: '0',
    };
  },
  computed: {
    ...mapGetters({
      baseData: 'templateCreation/getBaseData',
      baseDataOptions: 'templateCreation/getBaseDataOptions',
      isEditing: 'templateCreation/getIsEditing',
      userRole: 'auth/getUserRole',
      userOrganization: 'auth/getUserOrganization',
    }),
    thumbInputAcceptTypes() {
      return 'image/*';
    },
    templateAcceptTypes() {
      if (this.templateType === TEMPLATE_TYPE.VIDEO) {
        return ['application/vnd.audiograph'];
      }
      return ['application/zip'];
    },
    typeDescription() {
      if (!this.templateType) {
        return this.$t('templates.creation.selectTypeFirst');
      }
      return this.templateType === TEMPLATE_TYPE.VIDEO
        ? this.$t('templates.creation.aepFile')
        : this.$t('templates.creation.zipArchive');
    },
    templateFontsPlaceHolder() {
      if (this.isEditing) {
        return this.$t('templates.creation.noChanges');
      }
      return this.$t('templates.creation.noFileSelected');
    },
    templateFilePlaceholder() {
      if (this.templateFileName) {
        return this.templateFileName;
      }
      if (this.isEditing) {
        return this.$t('templates.creation.noChanges');
      }
      return this.$t('templates.creation.noFileSelected');
    },
    organizationNames() {
      return this.baseDataOptions.organizations.map((organization) => {
        return organization.name;
      });
    },
    canChooseOrganization() {
      return this.baseDataOptions.organizations.length > 1;
    },
    templateTypes() {
      return Object.values(TEMPLATE_TYPE);
    },
    isHTML5() {
      return this.templateType === TEMPLATE_TYPE.HTML5;
    },
    isVideo() {
      return this.templateType === TEMPLATE_TYPE.VIDEO;
    },
    isSuperAdmin() {
      if (this.userRole) {
        return isSuperAdmin(this.userRole);
      }

      return false;
    },
  },
  watch: {
    name(val) {
      if (!val) {
        this.$store.dispatch('templateCreation/setHasUnsavedData', false);
      } else {
        this.$store.dispatch('templateCreation/setHasUnsavedData', true);
      }
    },
  },
  beforeMount() {
    this.initializeWithExistingData();
  },
  mounted() {
    if (!this.isSuperAdmin) {
      this.addUserOrganizationToSelectedOrganizations();
    }
  },
  methods: {
    emitErrorMessage(errorMessage) {
      toast.$emit('showToast', {
        type: 'error',
        groupName: 'template-creation-validation',
        message: this.$t(`templates.errors.${errorMessage}`),
      });
    },
    validate() {
      if (this.isHTML5) {
        if (!this.functionKey) {
          this.emitErrorMessage('fillAll');
          return false;
        }
        if (/\D/g.test(this.takePreviewScreenshotAtMS)) {
          this.emitErrorMessage('numberIsNotPositiveInteger');
          return false;
        }
        if (parseInt(this.takePreviewScreenshotAtMS, 10) <= 0) {
          this.emitErrorMessage('numberIsNotPositiveInteger');
          return false;
        }
      }
      if (this.name && this.organizationIds.length > 0 && this.thumb && this.template) {
        return true;
      }
      if (this.isEditing && this.name && this.organizationIds.length > 0 && this.templateType) {
        return true;
      }
      this.emitErrorMessage('fillAll');

      return false;
    },
    save() {
      const baseData = {
        type: this.templateType,
        name: this.name,
        organizations: this.organizationIds,
        thumb: this.thumb,
        template: this.template,
        functionKey: this.functionKey,
        hasApiData: this.hasApiData,
        takePreviewScreenshotAtMS: this.takePreviewScreenshotAtMS,
        fonts: this.fonts,
      };
      this.$store.commit('templateCreation/setBaseData', baseData);
    },
    onTypeSelected(templateType) {
      this.templateType = templateType;
    },
    addUserOrganizationToSelectedOrganizations() {
      if (!this.organizationIds.includes(this.userOrganization)) {
        this.organizationIds.push(this.userOrganization);
      }
    },
    onOrganizationNameSelected(selectedOrganizationNames) {
      this.organizationIds = selectedOrganizationNames.map((organizationName) =>
        this.findOrganizationId(organizationName),
      );

      this.selectedOrganizations = selectedOrganizationNames;
    },
    handleThumbUpload() {
      [this.thumb] = this.$refs.thumb.files;
      this.thumbURL = URL.createObjectURL(this.thumb);
    },
    removeThumb() {
      this.thumb = null;
      this.thumbURL = null;
    },
    findOrganizationName(id) {
      const foundOrganization = this.baseDataOptions.organizations
        .filter((organization) => {
          if (organization && organization._id) {
            return organization._id === id;
          }
          return organization === id;
        })
        .pop();

      if (foundOrganization && foundOrganization.name) {
        return foundOrganization.name;
      }
      return foundOrganization;
    },
    findOrganizationId(name) {
      const foundOrganization = this.baseDataOptions.organizations
        .filter((organization) => {
          return organization.name === name;
        })
        .pop();
      return foundOrganization._id;
    },
    initializeWithExistingData() {
      if (this.baseData.type) {
        this.templateType = this.baseData.type;
      }
      if (this.baseData.name) {
        this.name = this.baseData.name;
      }
      if (this.baseData.functionKey) {
        this.functionKey = this.baseData.functionKey;
      }
      if (this.baseData.hasApiData) {
        this.hasApiData = this.baseData.hasApiData;
      }
      if (this.baseData.takePreviewScreenshotAtMS) {
        this.takePreviewScreenshotAtMS = this.baseData.takePreviewScreenshotAtMS;
      }
      if (this.baseData.organizationIds) {
        this.organizationIds = this.baseData.organizationIds;
        this.selectedOrganizations = this.organizationIds.map((organization) =>
          this.findOrganizationName(organization),
        );
      }
      if (this.baseData.template) {
        this.template = this.baseData.template;
      }
      if (this.baseData.fonts) {
        this.fonts = this.baseData.fonts;
      }
      if (this.baseData.thumb) {
        this.thumb = this.baseData.thumb;
        this.thumbURL = URL.createObjectURL(this.thumb);
      }
      if (this.baseData.templateFileName) {
        this.templateFileName = this.baseData.templateFileName;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.base-data-wrapper {
  max-width: rem(600px);
  .title-container {
    display: block;
    margin-bottom: rem(30px);
    font-family: $cch-font-family;
    font-size: rem(36px);
    font-weight: 800;
    color: #c5cde2;
  }
  .input-field {
    margin-bottom: rem(24px);
  }
  .selection-field {
    margin-bottom: rem(24px);
  }
}

.asset-selection-field {
  @apply .flex .justify-center .items-center;
  cursor: pointer;
  position: relative;
  width: rem(280px);
  height: rem(175px);
  color: rgba(51, 51, 51, 0.5);
  background-color: #ffffff;
  .file-input {
    position: absolute;
    width: 100%;
    height: rem(175px);
    opacity: 0;
    cursor: pointer;
    z-index: 10;
  }
  .media-label-container {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(50px);
    &.with-asset {
      background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
    }
  }
  .media-label {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-family: $cch-font-family;
    font-size: 0.75rem;
  }
  .label-with-asset {
    color: #ffffff;
  }

  &.has-asset {
    background: $cch-light-steel-blue;
    cursor: default !important;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
  }
  .close-icon {
    position: absolute;
    color: #ffffff;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    cursor: pointer;
  }
}

.plus-sign {
  @apply .text-xl .font-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $cch-light-blue;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: rem(50px);
  height: rem(50px);
}
.spinner {
  @apply .spin;
  position: relative;
  border-top: rem(4px) solid $cch-light-gray;
  border-right: rem(4px) solid $cch-light-blue;
  border-bottom: rem(4px) solid $cch-light-blue;
  border-left: rem(4px) solid $cch-light-blue;
  transform: translateZ(0);
}

.change-text {
  @apply .text-xl .font-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $cch-dark-grey;
  font-size: 1rem;
  font-weight: 400;
}
</style>
