<template>
  <div class="asset-select-container">
    <div class="asset-selection-field" :class="{ 'has-asset': hasAsset }">
      <div class="media-label-container" :class="{ 'with-asset': hasAsset }">
        <span class="media-label" :class="{ 'label-with-asset': hasAsset }">{{ placeholder }}</span>
        <RemoveMediaIcon v-if="hasAsset" class="close-icon" @click="removeAsset" />
      </div>
      <img v-if="hasAsset && !isCropping" class="thumbnail" :src="finalAsset" alt="Preview" />
      <div v-else-if="isCropping">
        <div class="spinner" />
      </div>
      <div v-else class="plus-sign" @click="selectAsset()">
        <PlusIcon />
      </div>
    </div>
    <AssetSelectionModal
      v-if="isAssetSelectionModalShown"
      :show="isAssetSelectionModalShown"
      :type="assetSelectionMediaType"
      :asset-restrictions="assetRestrictions"
      @selectedAsset="onAssetSelected"
      @closeClick="closeAssetSelection()"
    />
    <AssetCroppingModal
      v-if="isAssetCroppingModalShown"
      :has-api-data="hasApiData"
      :show="isAssetCroppingModalShown"
      :input-asset="selectedAsset"
      :asset-restrictions="assetRestrictions"
      @assetCropped="onAssetCropped"
      @assetBinaryCropped="onAssetBinaryCropped"
      @closeClick="closeAssetCropping()"
      @backToAssets="navigateBackToAssetSelection"
    />
  </div>
</template>

<script>
import { dataURItoBlob, getEditedAssetLink, getAssetThumbnailSrcByAssetId } from '@/helpers/helpers';
import PlusIcon from '@/assets/images/plus.svg';
import RemoveMediaIcon from '@/assets/images/remove_media.svg';
import AssetSelectionModal from '@/components/assets/AssetSelectionModal.vue';
import AssetCroppingModal from '@/components/campaignDetail/contentCreation/contentSpecification/AssetCroppingModal.vue';
import { toast } from '@/services/toast';

export default {
  components: {
    PlusIcon,
    RemoveMediaIcon,
    AssetSelectionModal,
    AssetCroppingModal,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    assetRestrictions: {
      type: Object,
      default: null,
      required: false,
    },
    asset: {
      type: Object,
      default: null,
      required: false,
    },
    hasApiData: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isAssetSelectionModalShown: false,
      isAssetCroppingModalShown: false,
      selectedAsset: null,
      assetSelectionMediaType: 'image',
      finalAsset: null,
      hasAsset: false,
      isCropping: false,
    };
  },
  watch: {
    asset() {
      if (this.asset.value) {
        this.hasAsset = true;
        if (this.asset.isEdited) {
          this.finalAsset = getEditedAssetLink(this.asset.value);
        } else {
          this.finalAsset = getAssetThumbnailSrcByAssetId(this.asset.value, this.asset.type);
        }
      }
    },
  },
  mounted() {
    if (this.asset && this.asset.value) {
      this.hasAsset = true;
      if (this.asset.isEdited) {
        this.finalAsset = getEditedAssetLink(this.asset.value);
      } else {
        this.finalAsset = getAssetThumbnailSrcByAssetId(this.asset.value, this.asset.type);
      }
    }
  },
  methods: {
    removeAsset() {
      this.$emit('removeAsset');
      this.finalAsset = null;
      this.hasAsset = false;
    },
    selectAsset() {
      this.isAssetSelectionModalShown = true;
    },
    closeAssetSelection() {
      this.isAssetSelectionModalShown = false;
    },
    closeAssetCropping() {
      this.isAssetCroppingModalShown = false;
    },
    onAssetSelected(selectedAsset) {
      this.selectedAsset = selectedAsset;
      this.isAssetSelectionModalShown = false;

      if (selectedAsset.extension === 'psd') {
        this.hasAsset = true;
        this.finalAsset = getAssetThumbnailSrcByAssetId(selectedAsset._id, selectedAsset.type);
        this.$emit('assetSelected', { assetId: selectedAsset._id, isEdited: false });
        return;
      }
      this.isAssetCroppingModalShown = true;
    },
    async onAssetCropped(cropData) {
      this.$emit('startedCropping');
      this.isAssetCroppingModalShown = false;

      this.isCropping = true;
      await this.$store.dispatch('editedAssets/create', cropData);
      this.isCropping = false;

      if (this.$store.state.editedAssets.hasError) {
        const { errorMessage } = this.$store.state.editedAssets;
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'content-wizard-validation',
          message: errorMessage,
        });
      } else {
        this.hasAsset = true;
        const assetId = this.$store.state.editedAssets.createdEditedAssetId;
        this.finalAsset = getEditedAssetLink(assetId);
        this.$emit('assetSelected', { assetId, isEdited: true });
        this.$emit('finishedCropping');
      }
    },
    async onAssetBinaryCropped(croppedAsset) {
      this.$emit('startedCropping');
      this.isAssetCroppingModalShown = false;

      const croppedAssetBlob = dataURItoBlob(croppedAsset);

      this.isCropping = true;
      await this.$store.dispatch('editedAssets/createBinary', croppedAssetBlob);
      this.isCropping = false;

      if (this.$store.state.editedAssets.hasError) {
        const { errorMessage } = this.$store.state.editedAssets;
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'content-wizard-validation',
          message: errorMessage,
        });
      } else {
        this.hasAsset = true;
        const assetId = this.$store.state.editedAssets.createdEditedAssetId;
        this.finalAsset = getEditedAssetLink(assetId);
        this.$emit('assetSelected', { assetId, isEdited: true });
        this.$emit('finishedCropping');
      }
    },
    navigateBackToAssetSelection() {
      this.isAssetCroppingModalShown = false;
      this.isAssetSelectionModalShown = true;
    },
    setFinalAsset(finalAsset) {
      this.hasAsset = true;
      this.finalAsset = getEditedAssetLink(finalAsset);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';
.asset-select-container {
}

.asset-selection-field {
  @apply .flex .justify-center .items-center;
  cursor: pointer;
  position: relative;
  width: rem(280px);
  height: rem(175px);
  color: rgba(51, 51, 51, 0.5);
  background-color: #ffffff;
  .media-label-container {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(50px);
    &.with-asset {
      background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
    }
  }
  .media-label {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-family: $cch-font-family;
    font-size: 0.75rem;
  }
  .label-with-asset {
    color: #ffffff;
  }

  &.has-asset {
    background: $cch-light-steel-blue;
    cursor: default !important;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
  }
  .close-icon {
    position: absolute;
    color: #ffffff;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    cursor: pointer;
  }
}

input {
  display: none;
}
.plus-sign {
  @apply .text-xl .font-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $cch-light-blue;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: rem(50px);
  height: rem(50px);
}
.spinner {
  @apply .spin;
  position: relative;
  border-top: rem(4px) solid $cch-light-gray;
  border-right: rem(4px) solid $cch-light-blue;
  border-bottom: rem(4px) solid $cch-light-blue;
  border-left: rem(4px) solid $cch-light-blue;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
</style>
