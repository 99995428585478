<template>
  <div class="format-detail-container">
    <div class="format-detail">
      <div class="setting-row">
        <FormInputField
          v-model="property"
          :placeholder="$t('templates.creation.property')"
          class="input-field"
          @input="updateDataDetail"
        />
        <FormSelectionField
          :placeholder="$t('templates.creation.rawType')"
          :list="rawTypes"
          :selected-item="rawType"
          class="input-field format-type-selection"
          @input="onRawTypeSelected"
        />
        <FormSelectionField
          v-if="hasApiData && columnNames && columnNames.length > 0"
          :placeholder="$t('templates.creation.columnNames')"
          :list="columnNames"
          :selected-item="columnName"
          class="input-field format-type-selection"
          @input="onApiLayerSelected"
        />
        <FormSelectionField
          :placeholder="$t('templates.creation.valueIsOptional')"
          :list="['required', 'optional']"
          :selected-item="isOptional ? 'optional' : 'required'"
          class="input-field format-type-selection"
          @input="onUpdateValueIsOptional"
        />
      </div>
    </div>
    <div>
      <StringDetail v-if="rawType === templateLayerRawType.URL" :data="settings" @settingsUpdated="onSettingsUpdated" />
      <StringDetail
        v-if="rawType === templateLayerRawType.STRING"
        :data="settings"
        @settingsUpdated="onSettingsUpdated"
      />
      <NumberDetail
        v-if="rawType === templateLayerRawType.NUMBER"
        :data="settings"
        @settingsUpdated="onSettingsUpdated"
      />
      <OptionDetail v-if="rawType === templateLayerRawType.OPTION" :data="options" @optionsUpdated="onOptionsUpdated" />
    </div>
  </div>
</template>

<script>
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import FormSelectionField from '@/components/shared/formFields/FormSelectionField.vue';
import NumberDetail from '@/components/templates/templateCreation/layers/NumberDetail.vue';
import StringDetail from '@/components/templates/templateCreation/layers/StringDetail.vue';
import OptionDetail from '@/components/templates/templateCreation/layers/OptionDetail.vue';
import { TEMPLATE_LAYER_RAW_TYPE } from '@/constants/template/template';

export default {
  components: {
    FormInputField,
    FormSelectionField,
    NumberDetail,
    StringDetail,
    OptionDetail,
  },
  props: {
    formats: {
      type: Array,
      required: true,
    },
    dataDetailInput: {
      type: Object,
      required: true,
    },
    hasApiData: {
      type: Boolean,
      required: false,
      default: false,
    },
    columnName: {
      type: String,
      required: false,
      default: '',
    },
    isOptional: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      rawType: null,
      options: [],
      settings: [],
      property: null,
    };
  },
  computed: {
    rawTypes() {
      return Object.values(TEMPLATE_LAYER_RAW_TYPE);
    },
    templateLayerRawType() {
      return TEMPLATE_LAYER_RAW_TYPE;
    },
    columnNames() {
      return this.dataDetailInput.columnNames;
    },
  },
  mounted() {
    this.rawType = this.dataDetailInput.rawType;
    this.options = this.dataDetailInput.options;
    this.settings = this.dataDetailInput.settings;
    this.property = this.dataDetailInput.property;
  },

  methods: {
    onSettingsUpdated(settings) {
      this.settings = settings;
      this.updateDataDetail();
    },
    onOptionsUpdated(options) {
      this.options = options;
      this.updateDataDetail();
    },
    onApiLayerSelected(layer) {
      this.$emit('updatedColumnName', layer);
    },
    onRawTypeSelected(rawType) {
      this.rawType = rawType;
      this.settings = [];

      if (
        this.rawType === TEMPLATE_LAYER_RAW_TYPE.STRING ||
        this.rawType === TEMPLATE_LAYER_RAW_TYPE.NUMBER ||
        this.rawType === TEMPLATE_LAYER_RAW_TYPE.URL
      ) {
        this.options = [];
        this.formats.forEach((format) => {
          this.settings.push({
            format: format.name,
            minValue: '0',
            maxValue: '0',
          });
        });
      } else {
        this.settings = [];
      }

      this.updateDataDetail();
    },
    onUpdateValueIsOptional(value) {
      this.$emit('updateValueIsOptional', value);
    },
    updateDataDetail() {
      this.$emit('dataDetailUpdated', {
        property: this.property,
        options: this.options,
        settings: this.settings,
        rawType: this.rawType,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/templateCreation.scss';
</style>
