<template>
  <ModalBase
    :title="$t('assets.assetSelection.createFolder')"
    :show="show"
    :override-style="{
      width: scssVariables.cchMediumExtended,
      height: 'auto',
      'background-color': scssVariables.cchLightGray,
    }"
    :override-header-style="{ 'margin-bottom': '1rem' }"
    @closeClicked="onCloseClicked"
  >
    <template #content>
      <div class="asset">
        <FormInputField
          ref="firstInput"
          v-model="folderName"
          class="folder-name"
          :placeholder="$t('assets.general.folderName')"
        />
      </div>
    </template>
    <template #button-row>
      <div class="actions-row">
        <AppButton size="small-auto" type="outlined" @click="onCloseClicked()">
          {{ $t('general.buttons.cancel') }}
        </AppButton>
        <AppButton size="small-auto" @click="createFolder()">{{ $t('assets.general.create') }}</AppButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import { toast } from '@/services/toast';
import scssVariables from '@/styles/variables.module.scss';

export default {
  components: {
    ModalBase,
    FormInputField,
    AppButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      folderName: '',
      scssVariables,
    };
  },
  computed: {
    isFolderNameProvided() {
      return this.folderName.length > 0;
    },
  },
  mounted() {
    this.$refs.firstInput.focus();
  },
  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    createFolder() {
      if (this.validate()) {
        this.$emit('createClick', {
          name: this.folderName,
        });

        this.folderName = '';
      }
    },
    validate() {
      if (!this.isFolderNameProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'asset-creation',
          message: this.$t(`assets.general.noFolderNameProvided`),
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.folder-name {
  width: 100%;
  height: rem(51px);
}

.actions-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $cch-light-gray;
  width: 100%;
  margin-top: rem(24px);
}
</style>
