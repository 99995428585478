<template>
  <label class="radio-container">
    <slot />
    <input class="checkbox-input" type="radio" :name="categoryType" :checked="true" @click="onFilterClicked" />
    <span class="custom-checkbox" />
  </label>
</template>

<script>
export default {
  name: 'FilterPopupAllField',
  props: {
    category: {
      type: String,
      required: false,
      default: null,
    },
    categoryType: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    onFilterClicked() {
      this.$emit('resetFilterClicked', this.categoryType);
    },
  },
};
</script>

<style scoped>
.radio-container {
  @apply .text-blue-light .font-sans .font-semibold .text-base .mb-6 .relative .text-left .pl-10 .block .cursor-pointer;
}

.checkbox-input {
  @apply .opacity-0 .w-0 .h-0 .absolute;
}

.custom-checkbox {
  @apply .absolute .w-6 .h-6 .rounded-full .cursor-pointer;
  top: -0.1rem;
  left: 0.1rem;
  border: solid 1.5px #909cb0;
}

.checkbox-input:checked + .custom-checkbox {
  border: solid 1.5px black;
}

.checkbox-input:checked + .custom-checkbox::after {
  @apply .absolute .bg-black .rounded-full;
  content: '';
  top: 0.2rem;
  left: 0.2rem;
  width: 0.9rem;
  height: 0.9rem;
  border: solid 1.5px black;
}
</style>
