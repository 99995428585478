<template>
  <div class="errors-page">
    <div class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script>
import i18next from 'i18next';

export default {
  components: {},
  data() {
    return {
      errorMessage: '',
    };
  },
  mounted() {
    this.errorMessage = i18next.t(`ERRORS.codes.${this.$route.query.code}`);
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.error-message {
  color: red;
  font-size: rem(24px);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
