// eslint-disable-next-line import/no-cycle
import { getAllOrganizations, getCreditsPacksInfo, getOrganization } from '@/services/api/organizations.resource';
// eslint-disable-next-line import/no-cycle
import { buyCreditsPacks, fetchCredits } from '@/services/api/credit.resource';
import { Organization } from '@/types';
import { Commit } from 'vuex';

interface State {
  organizations: Organization[];
  remainingCredits: number;
  creditsPackages: any[];
}

const state: State = {
  organizations: [],
  remainingCredits: 0,
  creditsPackages: [],
};

const getters = {
  getOrganizations(state: State) {
    return state.organizations;
  },
  getRemainingCredits(state: State) {
    return state.remainingCredits;
  },
  getOrganizationById: (state: State) => (id: string) => {
    return state.organizations.find((organization) => organization._id === id);
  },
  getCreditsPacks(state: State) {
    return state.creditsPackages;
  },
  getHasCreditsLeft(state: State) {
    return state.remainingCredits > 0;
  },
};

const actions = {
  async loadOrganizations({ commit }: { commit: Commit }) {
    const organizations = await getAllOrganizations();
    commit('setOrganizations', organizations);
  },
  async loadOrganization({ commit }: { commit: Commit }, id: string) {
    const organization = await getOrganization(id);
    commit('addOrganization', organization);
  },
  async loadCreditsForLoggedInOrganization(context: $TSFixMe) {
    const loggedInOrganization = context.rootGetters['auth/getUserOrganization'];
    const availableCredits = await fetchCredits(loggedInOrganization);
    context.commit('setRemainingCredits', availableCredits);
  },
  async loadCreditsPackages(context: $TSFixMe) {
    const creditPackages = await getCreditsPacksInfo();
    context.commit('setCreditsPackages', creditPackages);
  },
  async buyCredits(context: $TSFixMe, creditsPackages: $TSFixMe) {
    const loggedInOrganization = context.rootGetters['auth/getUserOrganization'];
    const boughtCredits = await buyCreditsPacks(creditsPackages, loggedInOrganization);
    return boughtCredits;
  },
};

const mutations = {
  setOrganizations(state: State, organizations: Organization[]) {
    state.organizations = organizations;
  },
  addOrganization(state: State, organization: Organization) {
    state.organizations = state.organizations.filter((org) => org._id !== organization._id);
    state.organizations.push(organization);
  },
  setRemainingCredits(state: State, remainingCredits: number) {
    state.remainingCredits = remainingCredits;
  },
  setCreditsPackages(state: State, creditsPackages: $TSFixMe[]) {
    state.creditsPackages = creditsPackages;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
