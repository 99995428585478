<template>
  <div class="user-detail-wrapper">
    <AppHeader class="sticky-header">
      <template #options-bar>
        <UserDetailHeader class="max-w-6xl" />
      </template>
    </AppHeader>
    <div class="content-wrapper">
      <div class="title-container">
        <h1 class="title">{{ $t('general.users.userProfile') }}</h1>
      </div>
      <UserDetailTabs :tabs="userComponents" :active-tab="activeTab" @changeTab="changeTab" />
      <component
        :is="userComponents[activeTab].component"
        v-if="!isLoading"
        :roles="roles"
        :organization="currentUserOrg"
        :user="currentUser"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserDetailHeader from '@/components/userDetail/UserDetailHeader.vue';
import AppHeader from '@/components/shared/AppHeader.vue';
import UserGeneral from '@/components/userDetail/profile/UserGeneral.vue';
import UserDetailTabs from '@/components/userDetail/UserDetailTabs.vue';

export default {
  components: {
    UserDetailHeader,
    AppHeader,
    UserGeneral,
    UserDetailTabs,
  },
  data() {
    return {
      userComponents: [
        {
          title: 'General',
          component: 'UserGeneral',
        },
      ],
      activeTab: 0,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      user: 'users/getUserById',
      getOrganizationById: 'organizations/getOrganizationById',
      roles: 'roles/getRoles',
    }),
    currentID() {
      return this.$route.params.id;
    },
    currentUser() {
      return this.user(this.currentID);
    },
    currentUserOrg() {
      return this.getOrganizationById(this.currentUser.organization);
    },
  },
  async mounted() {
    await this.$store.dispatch('users/loadUser', this.currentID);
    if (!this.currentUserOrg) {
      await this.$store.dispatch('organizations/loadOrganization', this.currentUser.organization);
    }
    await this.$store.dispatch('roles/loadRoleName', this.currentUser.role);
    this.isLoading = false;
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.user-detail-wrapper {
  padding-bottom: 5rem;
  .content-wrapper {
    margin: rem(36px auto 24px auto);
    @apply .max-w-6xl;
  }
  .title-container {
    .title {
      @apply .text-4xl .text-blue-lighter .font-sans .font-extrabold;
      margin-top: 2.25rem;
      margin-bottom: 1.5rem;
    }
  }

  .sticky-header {
    position: -webkit-sticky;
    position: sticky;
    top: -4.4rem;
    z-index: 50;
  }
  @media (max-width: 1400px) {
    .sticky-header {
      position: fixed;
      width: 100%;
      top: 0;
    }
    .content-wrapper {
      padding-top: rem(180px - 36px);
    }
  }
}
</style>
