<template>
  <div
    v-click-outside="clickOutsidePopupConfig"
    class="header-popup-bar xl:mx-auto items-center justify-between mt-10 flex-wrap sm:flex-no-wrap"
  >
    <div class="flex items-center mt-9">
      <div id="popup-toggler-date" class="flex text-blue-light items-end cursor-pointer popup-trigger">
        <transition name="fade-down">
          <DateFilterPopup
            :show="objectName === 'createdAt'"
            @dateFilterClicked="onDateFilterClicked"
            @dateFilterCleared="onDateFilterCleared"
          />
        </transition>
        <IconCalendar class="text-blue-light" />
        <div class="ml-3 font-sans font-semibold h-4 hidden lg:block" @click.self="onPopupToggle('createdAt')">{{
          $t('home.general.publishingDate')
        }}</div>
      </div>

      <div
        class="flex text-blue-light items-end cursor-pointer popup-trigger ml-12"
        @click="onPopupToggle('templateName')"
      >
        <transition name="fade-down">
          <RadioFilterPopup
            v-show="objectName === 'templateName'"
            :categories="getUniqueValuesOfKey('templateName')"
            button-type="checkbox"
            category-type="templateName"
            @resetFilterClicked="onResetFilterClicked"
            @exclusiveFilterClicked="onExclusiveFilterClicked"
            >{{ $t('home.general.chooseTemplates') }}</RadioFilterPopup
          >
        </transition>
        <IconVideo class="text-blue-light" />
        <div class="ml-3 font-sans font-semibold h-4 hidden lg:block">{{ $t('home.general.templates') }}</div>
      </div>

      <div
        class="flex text-blue-light items-end cursor-pointer popup-trigger ml-12"
        @click="onPopupToggle('renders.format')"
      >
        <transition name="fade-down">
          <RadioFilterPopup
            v-show="objectName === 'renders.format'"
            button-type="radio"
            category-type="renders.format"
            :categories="getFormatsFromContents"
            @resetFilterClicked="onResetFilterClicked"
            @exclusiveFilterClicked="onExclusiveFilterClicked"
            >{{ $t('home.general.chooseFormats') }}</RadioFilterPopup
          >
        </transition>
        <IconSignage class="text-blue-light" />
        <div class="ml-3 font-sans font-semibold h-4 hidden lg:block">{{ $t('home.general.formats') }}</div>
      </div>
      <div class="flex text-blue-light items-end cursor-pointer popup-trigger ml-12" @click="onPopupToggle('sort')">
        <transition name="fade-down">
          <RadioPopup
            v-show="objectName === 'sort'"
            category-type="contents"
            :radio-options="sortOptions"
            @radioSelected="onSortOptionSelected"
            >{{ $t('home.general.chooseSortOrder') }}</RadioPopup
          >
        </transition>
        <IconOptions class="text-blue-light" />
        <div class="ml-3 font-sans font-semibold h-4 hidden lg:block ignore">{{
          $t('general.sorting.sortingName')
        }}</div>
      </div>
    </div>

    <div class="search-input ml-0 mt-9 sm:ml-auto sm:mt-0 w-54 sm:w-64">
      <AppInput v-model="searchTerm" placeholder="general.inputs.search" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IconCalendar from '@/assets/images/calendar.svg';
import IconVideo from '@/assets/images/video.svg';
import IconSignage from '@/assets/images/signage.svg';
import IconOptions from '@/assets/images/options.svg';
import RadioPopup from '@/components/shared/popup/RadioPopup.vue';
import AppInput from '@/components/shared/AppInput.vue';
import RadioFilterPopup from '@/components/shared/RadioFilterPopup.vue';
import DateFilterPopup from '@/components/shared/DateFilterPopup.vue';

export default {
  components: {
    DateFilterPopup,
    RadioFilterPopup,
    AppInput,
    IconCalendar,
    IconVideo,
    IconSignage,
    IconOptions,
    RadioPopup,
  },
  data() {
    return {
      objectName: null,
      showSideBar: false,
      inputDebounce: null,

      clickOutsidePopupConfig: {
        handler: this.closeAnyPopup,
      },

      sortOptions: [
        {
          name: this.$t('general.sorting.modifiedDESC'),
          sortBy: 'createdAt',
          reverse: true,
          isDefault: true,
        },
        {
          name: this.$t('general.sorting.modifiedASC'),
          sortBy: 'createdAt',
          reverse: false,
          isDefault: false,
        },
        {
          name: this.$t('general.sorting.nameASC'),
          sortBy: (o) => o.name.toLowerCase(),
          reverse: false,
          isDefault: false,
        },
        {
          name: this.$t('general.sorting.nameDESC'),
          sortBy: (o) => o.name.toLowerCase(),
          reverse: true,
          isDefault: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getUniqueValuesOfKey: 'content/getUniqueValuesOfKey',
      getFormatsFromContents: 'content/getFormatsFromContents',
    }),
    searchTerm: {
      get() {
        return this.$store.getters['content/getSearchTerm'];
      },
      set(value) {
        clearTimeout(this.inputDebounce);

        this.inputDebounce = setTimeout(() => {
          this.$store.dispatch('content/addSearchTerm', value);
          this.$store.dispatch('campaigns/addSearchTerm', value);
          this.$store.dispatch('assets/addSearchTerm', value);
          this.$store.dispatch('templates/setSearchTerm', value);
        }, 250);
      },
    },
  },
  async beforeDestroy() {
    await this.resetAllFilters();
  },
  methods: {
    async resetAllFilters() {
      await this.$store.dispatch('content/resetFilters');
    },
    onPopupToggle(category) {
      if (this.objectName === category) {
        this.objectName = null;
      } else {
        this.objectName = category;
      }
    },
    closeAnyPopup() {
      this.objectName = null;
    },
    updateSort(sortBy) {
      this.$store.dispatch('updateSortedBy', sortBy);
    },
    onSortOptionSelected(sortOption) {
      const sortBy = {
        key: sortOption.sortBy,
        reverse: sortOption.reverse,
      };
      this.updateSort(sortBy);
    },
    onSelectionMade() {
      this.showSideBar = false;
    },
    onResetFilterClicked(categoryType) {
      this.$store.dispatch('content/resetFilter', categoryType);
    },
    onExclusiveFilterClicked(filter) {
      this.$store.dispatch('content/addExclusiveFilter', filter);
    },
    onInclusiveFilterClicked(payload) {
      if (!payload.isChecked) {
        this.$store.dispatch('content/addInclusiveFilter', payload.filter);
      } else {
        this.$store.dispatch('content/removeFilter', payload.filter);
      }
    },
    onDateFilterClicked(dateRange) {
      this.$store.dispatch('content/addDateRangeFilter', dateRange);
    },
    onDateFilterCleared() {
      this.$store.dispatch('content/clearDateRangeFilter');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';

.header-popup-bar {
  display: flex;
}

.search-input {
  border-bottom: 1px solid $cch-dark-grey;
}
</style>
