<template>
  <div class="billing-row-wrapper" :class="{ header: isHeader, row: !isHeader }">
    <span>{{ $t(name) }}</span>
    <span :class="{ 'subscription-info': !isHeader }">{{ getPriceString }}</span>
    <span>{{ $t(pricePerCredit) }}</span>
    <span>{{ amountOfCredits }}</span>
    <FilterPopupCheckboxField v-if="!isHeader" class="checkbox" @inclusiveFilterClicked="onCheckboxClicked" />
  </div>
</template>

<script>
import FilterPopupCheckboxField from '@/components/shared/FilterPopupCheckboxField.vue';

export default {
  name: 'CreditsRowBase',
  components: { FilterPopupCheckboxField },
  props: {
    isHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    price: {
      type: [String, Number],
      required: true,
    },
    amountOfCredits: {
      type: [String, Number],
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    isRecommended: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isBuySelected: false,
    };
  },
  computed: {
    pricePerCredit() {
      if (this.isHeader) {
        return 'general.credits.pricePerCredit';
      }
      const pricePerCredit = this.price / this.amountOfCredits;
      if (pricePerCredit % 1 === 0) {
        return `${this.price / this.amountOfCredits}.-`;
      }
      if ((pricePerCredit * 10) % 1 === 0) {
        return `${this.price / this.amountOfCredits}0`;
      }
      return `${this.price / this.amountOfCredits}`;
    },
    getPriceString() {
      if (this.isHeader) {
        return this.price;
      }
      return `${this.price}.-`;
    },
  },
  methods: {
    onCheckboxClicked(value) {
      this.$emit('change', { value: !value.isChecked, index: this.index });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables.module.scss';
.billing-row-wrapper {
  display: grid;
  grid-template-columns: 25rem 13rem 13rem 13rem 2rem;
  grid-gap: 0.625rem;
  font-family: $cch-font-family;
  letter-spacing: 0.5px;

  .subscription-info {
    color: $cch-buttons-blue;
  }

  .paid {
    color: #00bb91;
  }
}

.header {
  font-weight: bold;
  color: $cch-light-steel-blue;
  padding-left: 1.25rem;
  margin-bottom: 0.75rem;
}
.row {
  background-color: white;
  padding: 1.25rem;
  margin-top: 1.25rem;
}
</style>
