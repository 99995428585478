<template>
  <div>
    <ContentSpecificationForm
      ref="contentSpecificationForm"
      @previewRequested="onPreviewRequested"
      @languageChanged="onLanguageChanged"
      @formatChanged="onFormatChanged"
    />
    <Html5ContentPreview
      v-if="isShowingPreviews"
      :previews="activePreviewObjects.previews"
      :formats="formats"
      :language="activeLanguage"
      @previewRemoved="onPreviewRemoved"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as uuid from 'uuid';

import ContentSpecificationForm from '@/components/campaignDetail/contentCreation/contentSpecification/ContentSpecificationForm.vue';
import Html5ContentPreview from '@/components/campaignDetail/contentCreation/contentSpecification/Html5ContentPreview.vue';

export default {
  components: {
    ContentSpecificationForm,
    Html5ContentPreview,
  },
  data() {
    return {
      isShowingPreviews: false,
      activeFormat: '',
      activeLanguage: '',
    };
  },
  computed: {
    ...mapGetters({
      formats: 'contentCreation/getFormats',
      previewObjects: 'contentCreation/getPreviewObjects',
    }),

    activePreviewObjects() {
      return this.previewObjectsForLanguage(this.activeLanguage);
    },
  },
  async created() {
    await this.$store.dispatch('contentCreation/initializePreviewObjects');
  },
  methods: {
    previewObjectsForLanguage(language) {
      if (typeof this.previewObjects !== 'undefined') {
        return this.previewObjects.find((obj) => {
          return obj.language === language;
        });
      }
      return [];
    },
    async onPreviewRequested(payload) {
      const language = payload?.language || this.activeLanguage;
      const format = payload?.format || this.activeFormat;
      this.isShowingPreviews = true;
      const temporaryId = uuid.v1();
      let dataObject;

      try {
        const data = await this.$store.dispatch('contentCreation/startHtmlPreviewRendering', {
          language,
          format,
          tmpId: temporaryId,
        });
        dataObject = data.data;
      } catch (e) {
        if (e.data?.error?.data) {
          dataObject = e.data.error.data;
        } else {
          dataObject = {
            id: new Date().getTime().toString(),
            status: 'FAILED',
            previewURL: null,
            errorMessage: 'Something wrong happen',
          };
        }
      }
      this.$store.commit('contentCreation/updateHtmlPreviewObject', {
        tmpId: temporaryId,
        format,
        language,
        id: dataObject.id,
        status: dataObject.status,
        previewURL: dataObject.previewURL,
        errorMessage: dataObject.errorMessage,
      });
    },
    onLanguageChanged(value) {
      if (this.previewObjectsForLanguage(value)) {
        this.isShowingPreviews = this.previewObjectsForLanguage(value).previews.length > 0;
      }
      this.activeLanguage = value;
    },
    onFormatChanged(value) {
      this.activeFormat = value;
    },
    onPreviewRemoved(id) {
      this.$store.dispatch('contentCreation/removePreviewObject', id);
      this.isShowingPreviews = this.activePreviewObjects.previews.length > 0;
    },
    validate() {
      return this.$refs.contentSpecificationForm.validate();
    },
  },
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
