<template>
  <div>
    <div v-for="setting in settings" :key="setting.format" class="format-detail">
      <span class="format-detail-label">{{ setting.format }}</span>
      <div class="setting-row">
        <div class="input-field min">
          <FormInputField
            v-model="setting.minValue"
            :placeholder="$t('templates.creation.minValue')"
            :disabled="setting.isMinUnlimited"
            type="number"
            style="margin-bottom: 0.5rem"
            @input="updateData"
          />
          <label class="unlimited">
            <input id="min-checkbox" v-model="setting.isMinUnlimited" type="checkbox" @change="updateData" />
            {{ $t('templates.creation.unlimited') }}
          </label>
        </div>
        <div class="input-field">
          <FormInputField
            v-model="setting.maxValue"
            :placeholder="$t('templates.creation.maxValue')"
            :disabled="setting.isMaxUnlimited"
            type="number"
            style="margin-bottom: 0.5rem"
            @input="updateData"
          />
          <label class="unlimited">
            <input id="max-checkbox" v-model="setting.isMaxUnlimited" type="checkbox" @change="updateData" />
            {{ $t('templates.creation.unlimited') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputField from '@/components/shared/formFields/FormInputField.vue';

export default {
  components: {
    FormInputField,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      settings: [],
    };
  },
  computed: {},
  mounted() {
    this.settings = this.data;
  },
  methods: {
    updateData() {
      this.$emit('settingsUpdated', this.settings);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/templateCreation.scss';
@import '~@/styles/variables.module.scss';

.detail-container {
  padding: 0 !important;
}

.unlimited {
  font-weight: normal;
  font-size: 1rem;
  color: $cch-dark-grey;
}
</style>
