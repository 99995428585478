<template>
  <div class="error-wrapper">
    <div class="error-title">{{ $t('contentWizard.preview.renderFailed') }}</div>
    <div class="error-message">
      {{ userErrorMessage }}
      <div class="error">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="escapedErrorWithLineBreaks" />
      </div>
    </div>
  </div>
</template>

<script>
import { escapeHtml } from '@/helpers/stringHelper';

export default {
  name: 'PreviewErrorMessage',
  props: {
    userErrorMessage: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  computed: {
    escapedErrorWithLineBreaks() {
      const escapedError = escapeHtml(this.error);
      return `<div>${escapedError.replaceAll('\n', '</div><div>')}</div>`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';

.error-wrapper {
  @apply .w-2/3 .m-auto .text-center;
  color: $cch-dark-grey;

  .error-title {
    font-size: rem(16px);
  }

  .error-message {
    word-wrap: anywhere;
    font-size: rem(12px);

    .error {
      @apply .mt-4 .bg-red-100 .border .border-red-400 .text-red-700 .text-left .px-4 .py-3 .rounded .relative;
      word-wrap: anywhere;
      font-family: 'Courier New', 'Courier', monospace !important;
      font-weight: bold;

      & > div {
        padding-left: 1rem;
        text-indent: -1rem;
      }
    }
  }
}
</style>
