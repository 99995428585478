import HTTP from '@/services/http';
import getLogger from '@/services/logger';
import { ScalingConfig, UpdateScalingConfigParams } from '@/types/ScalingConfig';

const LOG = getLogger('resource/scaling-config');
const urlPrefix = `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/admin/scaling-config`;

export const getScalingConfig = async (): Promise<ScalingConfig> => {
  LOG.info('Attempting to get scaling config');
  const response = await HTTP.get(urlPrefix);
  return response.data;
};

export const updateScalingConfig = async (params: UpdateScalingConfigParams) => {
  LOG.log('Attempting to update scaling config');
  await HTTP.post(urlPrefix, params);
};
