<template>
  <div v-if="showPreviewForCurrentLanguage" class="preview-wrapper">
    <div class="preview-tabs">
      <div
        v-for="(preview, index) in previews"
        :id="`tab_${index}`"
        :key="index"
        class="preview-tab-item"
        :class="{ active: activeTab === index }"
        @click="setActiveTab(index)"
      >
        <div
          class="progress-bar"
          :style="{ width: `${preview.renderProgress}%` }"
          :class="{
            rendering: preview.status !== 'WAITING',
          }"
        />
        <span v-if="typeof preview !== 'undefined'" :id="`title_${index}`" class="tab-item-title">
          {{ formatName(preview.format, language) }}
        </span>
        <div v-if="activeTab !== index" class="blur-filter" />
        <CloseIcon
          v-if="activeTab === index"
          viewBox="0 0 16 16"
          class="close-icon"
          @click="removePreview(preview.id)"
        />
      </div>
    </div>
    <div class="preview-container">
      <span v-if="activePreview" class="preview-title">{{ formatName(activePreview.format, language) }}</span>
      <div
        v-if="activePreview"
        class="preview-content-container"
        :class="{ 'preview-content-container-padding': previewType !== billboardPreview }"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CloseIcon from '@/assets/images/close.svg';
import { getLanguageCode } from '@/helpers/helpers';

export default {
  components: {
    CloseIcon,
  },
  props: {
    previews: {
      type: Array,
      default: () => [],
    },
    formats: {
      type: Array,
      default: null,
    },
    language: {
      type: String,
      default: '',
    },
    previewType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showPreview: true,
      status: '',
      billboardPreview: 'billboard',
    };
  },
  computed: {
    ...mapGetters({
      activeTab: 'contentCreation/getActiveTab',
    }),

    activePreview() {
      if (this.activeTab < this.previews.length) {
        return this.previews[this.activeTab];
      }

      return this.previews[0];
    },
    activeSpanWidth() {
      return document.getElementById(`title_${this.activeTab}`).offsetWidth + 48;
    },
    showPreviewForCurrentLanguage() {
      return this.previews.length > 0;
    },
  },

  mounted() {
    this.setActiveTab(0);
  },
  methods: {
    setActiveTab(index) {
      if (this.previews && index < this.previews.length) {
        const previousIndex = this.activeTab;
        this.$store.commit('contentCreation/setActiveTab', index);
        this.showPreview = false;
        this.$nextTick(() => {
          if (this.previews && this.activeTab !== previousIndex && previousIndex < this.previews.length) {
            const currentTab = document.getElementById(`tab_${previousIndex}`);
            if (currentTab) {
              currentTab.style.minWidth = '0';
            }
          }
          if (this.previews && this.previews.length > 0) {
            const currentTab = document.getElementById(`tab_${this.activeTab}`);
            if (currentTab) {
              currentTab.style.minWidth = `${this.activeSpanWidth}px`;
            }
            this.showPreview = true;
          }
        });
      }
    },
    formatName(format, language) {
      return `${this.getFormatName(format)}_${getLanguageCode(language)}`;
    },

    getFormatName(name) {
      return this.formats
        .filter((format) => {
          return format.name === name;
        })
        .pop().humanReadableName;
    },

    removePreview(id) {
      let deletedTabIndex = this.activeTab;
      const indexToRemove = this.activeTab;
      if (deletedTabIndex === this.previews.length - 1) {
        deletedTabIndex -= 1;
        if (deletedTabIndex >= 0) {
          this.setActiveTab(deletedTabIndex);
        }
      }
      this.$emit('previewRemoved', { id, indexToRemove });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.preview-wrapper {
  margin-bottom: 3rem;
  .preview-tabs {
    display: flex;
    flex-direction: row;
    color: $cch-light-steel-blue;
    .preview-tab-item {
      position: relative;
      display: flex;
      width: rem(340px);
      padding: (0.75rem 3rem 0.75rem 0.75rem);
      background-color: rgba(197, 205, 226, 0.25);
      /*justify-content: center;
      align-items: center;*/
      flex-grow: 1;
      max-width: rem(340px);
      padding: (0.75rem 0.5rem 0.75rem 0.5rem);
      overflow: hidden;
      &.preview-tab-item:not(:last-child) {
        margin-right: 0.25rem;
      }
      &.preview-tab-item .active {
        width: fit-content;
      }

      .progress-bar {
        position: absolute;
        left: 0;
        top: 0;
        height: rem(2px);
        background-color: #d5d5d5;
      }
      .tab-item-title {
        @apply .mx-auto;
        font-family: $cch-font-family;
        font-weight: bold;
      }
      .close-icon {
        position: absolute;
        width: 0.75rem;
        height: 0.75rem;
        top: 0.85rem;
        right: 0.5rem;
        cursor: pointer;
        z-index: 10;
      }
      .blur-filter {
        position: absolute;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(to right, rgba(229, 233, 248, 0) 1%, #e5e9f8 96%);
        width: rem(40px);
        height: 90%;
      }
    }
  }
  .preview-container {
    background-color: #ffffff;
    min-height: rem(630px);
    display: flex;
    flex-direction: column;
    .preview-title {
      display: block;
      padding-left: 3rem;
      padding-top: 2.5rem;
      font-weight: 800;
      color: $cch-light-steel-blue;
    }
    .preview-content-container {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
    }

    .preview-content-container-padding {
      padding: 5rem;
    }
  }
}
.active {
  background-color: #ffffff !important;
  color: $cch-buttons-blue !important;
}

.waiting {
  background-color: #d5d5d5;
}
.rendering {
  background-color: $cch-buttons-blue !important;
}
</style>
