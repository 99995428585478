import Fuse from 'fuse.js';
// eslint-disable-next-line import/no-cycle
import {
  getAllUsers,
  updateUser,
  createNewUser,
  deactivateUser,
  activateUser,
  deleteUser,
  getUser,
} from '@/services/api/users.resource';
import { isSearchApplied, fillArrayValuesBasedOnFilteredResult } from '@/services/store/shared';

const state = {
  users: [],
  doesUserEmailAlreadyExist: false,
  isErrorReturned: false,
  clientFilters: [],
  roleFilters: [],
  searchTerm: null,
  searchOptions: {
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['lastName', 'firstName', 'email'],
  },
  loading: false,
};

const getters = {
  getUsers(state: $TSFixMe) {
    if (state.users) {
      let users = [];
      if (state.clientFilters.length > 0) {
        const filteredByClientsUsers = state.users.filter((user: $TSFixMe) =>
          state.clientFilters.includes(user.organization.name),
        );
        users = fillArrayValuesBasedOnFilteredResult(filteredByClientsUsers, state.clientFilters, state.users);
      } else {
        users = state.users;
      }

      if (users.length > 0 && state.roleFilters.length > 0) {
        const filteredByRolesUsers = users.filter((user: $TSFixMe) => state.roleFilters.includes(user.role.name));
        users = fillArrayValuesBasedOnFilteredResult(filteredByRolesUsers, state.roleFilters, state.users);
      }

      if (users.length > 0 && isSearchApplied(state.searchTerm)) {
        const fuse = new Fuse(users, state.searchOptions);
        users = fuse.search(state.searchTerm);
      }

      return users.sort((a: $TSFixMe, b: $TSFixMe) => {
        const lastName1 = a.lastName.toLocaleLowerCase();
        const lastName2 = b.lastName.toLocaleLowerCase();

        if (lastName1 === lastName2) {
          return 0;
        }
        return lastName1 > lastName2 ? 1 : -1;
      });
    }
    return [];
  },
  getUserById: (state: $TSFixMe) => (id: $TSFixMe) => state.users.find((user: $TSFixMe) => user._id === id),
  getSearchTerm: (state: $TSFixMe) => state.searchTerm,
  getLoading: (state: $TSFixMe) => state.loading,
};

const actions = {
  async loadAllUsers({ commit, rootGetters }: $TSFixMe) {
    try {
      commit('setLoading', true);
      const users = await getAllUsers();
      const roles = rootGetters['roles/getRoles'];
      const organizations = rootGetters['organizations/getOrganizations'];
      /*eslint-disable */
      users.forEach((user: $TSFixMe) => {
        user.role = roles.find((role: $TSFixMe) => role._id === user.role);
        user.organization = organizations.find((organization: $TSFixMe) => organization._id === user.organization);
      });
      /* eslint-enable */
      commit('setUsers', users);
    } finally {
      commit('setLoading', false);
    }
  },
  async loadUser({ commit }: $TSFixMe, id: $TSFixMe) {
    const user = await getUser(id);
    if (user) {
      commit('addUser', user);
    }
  },
  async createUser({ commit, dispatch }: $TSFixMe, user: $TSFixMe) {
    commit('setDoesUserEmailAlreadyExist', false);
    commit('setIsErrorReturned', false);
    try {
      await createNewUser(user);
      dispatch('loadAllUsers');
    } catch (error: $TSFixMe) {
      commit('setIsErrorReturned', true);
      if (error.status === 500) {
        commit('setDoesUserEmailAlreadyExist', true);
      }
    }
  },
  async editUser(context: $TSFixMe, userObject: $TSFixMe) {
    await updateUser(userObject.id, userObject.user);
  },
  async deactivateUser(context: $TSFixMe, user: $TSFixMe) {
    await deactivateUser(user);
    context.dispatch('loadAllUsers');
  },
  async activateUser(context: $TSFixMe, user: $TSFixMe) {
    await activateUser(user);
    context.dispatch('loadAllUsers');
  },
  async deleteUser(context: $TSFixMe, user: $TSFixMe) {
    await deleteUser(user);
    context.dispatch('loadAllUsers');
  },
  async addClientFilter({ commit }: $TSFixMe, filter: $TSFixMe) {
    commit('addClientFilter', filter);
  },
  async addRoleFilter({ commit }: $TSFixMe, filter: $TSFixMe) {
    commit('addRoleFilter', filter);
  },
  async removeClientFilter({ commit }: $TSFixMe, filter: $TSFixMe) {
    commit('removeClientFilter', filter);
  },
  async removeRoleFilter({ commit }: $TSFixMe, filter: $TSFixMe) {
    commit('removeRoleFilter', filter);
  },
  async setSearchTerm({ commit }: $TSFixMe, searchTerm: $TSFixMe) {
    commit('setSearchTerm', searchTerm);
  },
};

const mutations = {
  setUsers(state: $TSFixMe, users: $TSFixMe) {
    state.users = users;
  },
  addUser(state: $TSFixMe, user: $TSFixMe) {
    state.users = state.users.filter((u: $TSFixMe) => u._id !== user._id);
    state.users.push(user);
  },
  setIsErrorReturned(state: $TSFixMe, isErrorReturned: boolean) {
    state.isErrorReturned = isErrorReturned;
  },
  setDoesUserEmailAlreadyExist(state: $TSFixMe, doesUserEmailAlreadyExist: $TSFixMe) {
    state.doesUserEmailAlreadyExist = doesUserEmailAlreadyExist;
  },
  addClientFilter(state: $TSFixMe, filter: $TSFixMe) {
    state.clientFilters.push(filter);
  },
  addRoleFilter(state: $TSFixMe, filter: $TSFixMe) {
    state.roleFilters.push(filter);
  },
  removeClientFilter(state: $TSFixMe, filter: $TSFixMe) {
    if (state.clientFilters.includes(filter)) {
      const index = state.clientFilters.indexOf(filter);
      state.clientFilters.splice(index, 1);
    }
  },
  removeRoleFilter(state: $TSFixMe, filter: $TSFixMe) {
    if (state.roleFilters.includes(filter)) {
      const index = state.roleFilters.indexOf(filter);
      state.roleFilters.splice(index, 1);
    }
  },
  setSearchTerm(state: $TSFixMe, searchTerm: $TSFixMe) {
    state.searchTerm = searchTerm;
  },
  setLoading(state: $TSFixMe, value: boolean) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
