
import { defineComponent } from 'vue';
import { CmsConfigEntity, CreateCmsConfigEntity, UpdateCmsConfigEntity } from '@/types';
import AppText from '@/components/shared/AppText.vue';
import AppButton from '../shared/buttons/AppButton.vue';
import AppHeader from '@/components/shared/AppHeader.vue';
import AppLoading from '@/components/shared/AppLoading.vue';
import DiscardModal from '@/components/shared/DiscardModal.vue';
import CmsConfigProfileModal, { CmsConfigProfileSubmitParams } from '@/components/cmsConfig/CmsConfigProfileModal.vue';
import EditIcon from '@/assets/images/edit.svg';
import TrashIcon from '@/assets/images/trash.svg';
import { ITEMS_PER_PAGE_OPTIONS, DEFAULT_ITEMS_PER_PAGE } from '@/constants/pagination';

export default defineComponent({
  components: {
    AppText,
    AppButton,
    AppHeader,
    AppLoading,
    DiscardModal,
    EditIcon,
    TrashIcon,
    CmsConfigProfileModal,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('config.cms.organization'),
          value: 'organizationName',
          align: 'start',
        },
        {
          text: this.$t('config.cms.cmsPathId'),
          value: 'cmsPathId',
          align: 'start',
        },
        {
          text: this.$t('config.cms.cmsPathName'),
          value: 'cmsPathName',
          align: 'start',
        },
        {
          text: this.$t('config.cms.cmsUser'),
          value: 'cmsUsername',
          align: 'start',
        },
        {
          text: this.$t('config.cms.actions'),
          value: 'actions',
          sortable: false,
          align: 'end',
        },
      ],
      activeItem: null as CmsConfigEntity | null,
      isDeleteModalShown: false,
      inputDebounce: undefined as number | undefined,
      isSaveModalShown: false,
      isChangePasswordModalShown: false,
      ITEMS_PER_PAGE_OPTIONS,
      DEFAULT_ITEMS_PER_PAGE,
    };
  },
  computed: {
    entities() {
      return this.$store.getters['cms/getEntities'] as CmsConfigEntity[];
    },
    isLoading() {
      return this.$store.getters['cms/getLoading'] as boolean;
    },
    isSaving() {
      return this.$store.getters['cms/getSaving'] as boolean;
    },
    searchTerm: {
      get() {
        return this.$store.getters['cms/getSearchTerm'];
      },
      set(value: string) {
        clearTimeout(this.inputDebounce);
        this.inputDebounce = setTimeout(() => {
          this.$store.dispatch('cms/setSearchTerm', value);
        }, 250);
      },
    },
  },
  async mounted() {
    await this.$store.dispatch('cms/loadCmsConfigEntities');
  },
  methods: {
    handleClickNew() {
      this.activeItem = null;
      this.isSaveModalShown = true;
    },
    handleClickEdit(item: CmsConfigEntity) {
      this.activeItem = item;
      this.isSaveModalShown = true;
    },
    handleClickDelete(item: CmsConfigEntity) {
      this.activeItem = item;
      this.isDeleteModalShown = true;
    },
    async handleDelete() {
      if (this.activeItem) {
        await this.$store.dispatch('cms/deleteCmsConfigEntity', this.activeItem.id);
      }
      this.activeItem = null;
      this.isDeleteModalShown = false;
    },
    async handleSave(params: CmsConfigProfileSubmitParams) {
      if (params.id) {
        const payload: UpdateCmsConfigEntity = {
          cmsPathId: Number(params.cmsPathId),
          cmsUsername: params.cmsUsername,
          newPassword: params.newPassword ?? undefined,
        };
        await this.$store.dispatch('cms/updateCmsConfigEntity', {
          id: params.id,
          params: payload,
        });
      } else {
        const payload: CreateCmsConfigEntity = {
          cmsPathId: Number(params.cmsPathId),
          cmsUsername: params.cmsUsername,
          organizationId: params.organizationId,
          cmsPassword: params.newPassword,
        };
        await this.$store.dispatch('cms/createCmsConfigEntity', payload);
      }
      this.activeItem = null;
      this.isSaveModalShown = false;
    },
    closeDeleteModal() {
      this.activeItem = null;
      this.isDeleteModalShown = false;
    },
    closeSaveModal() {
      this.activeItem = null;
      this.isSaveModalShown = false;
    },
  },
});
