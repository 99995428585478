
import { format } from 'date-fns';
import TileTitle from '@/components/shared/tiles/TileTitle.vue';
import OptionsBox from '@/components/campaigns/OptionsBox.vue';
import CampaignUpdateInfo from '@/components/campaigns/CampaignUpdateInfo.vue';
import ContentCount from '@/components/campaigns/ContentCount.vue';
import OptionsIcon from '@/assets/images/options.svg';
import EditIcon from '@/assets/images/edit_FILL0_wght200_GRAD0_opsz24.svg';
import DeleteIcon from '@/assets/images/delete_FILL0_wght200_GRAD0_opsz24.svg';
import DownloadIcon from '@/assets/images/file_download_FILL0_wght200_GRAD0_opsz24.svg';
import ShareIcon from '@/assets/images/share_FILL0_wght200_GRAD0_opsz24.svg';
import { defineComponent, PropType } from 'vue';
import { Campaign } from '@/types';

export default defineComponent({
  components: {
    TileTitle,
    CampaignUpdateInfo,
    ContentCount,
    OptionsIcon,
    OptionsBox,
    EditIcon,
    DeleteIcon,
    DownloadIcon,
    ShareIcon,
  },
  props: {
    campaign: {
      type: Object as PropType<Campaign>,
      default: null,
    },
  },
  data() {
    return {
      isOptionsBoxOpen: false,
      vClickConfig: {},
    };
  },
  computed: {
    updateYear() {
      return format(new Date(this.campaign.updatedAt), 'yyyy');
    },
    updateDate() {
      return format(new Date(this.campaign.updatedAt), 'dd. MMM');
    },
    contentCount() {
      if (this.campaign.contentIds) {
        return this.campaign.contentIds.length;
      }
      return 0;
    },
    campaignName() {
      if (this.campaign.name.length < 24) {
        return this.campaign.name;
      }
      return `${this.campaign.name.slice(0, 24)}...`;
    },
    hasContents() {
      return this.campaign.contentIds && this.campaign.contentIds.length > 0;
    },
  },
  mounted() {
    this.vClickConfig = {
      handler: this.closeOptionsBox,
      events: ['contextmenu', 'click'],
    };
  },
  methods: {
    showCampaignDetail() {
      this.$router.push({ name: 'campaign', params: { id: this.campaign._id } });
    },
    toggleOptionsBox() {
      this.isOptionsBoxOpen = !this.isOptionsBoxOpen;
    },
    closeOptionsBox() {
      this.isOptionsBoxOpen = false;
    },
    onClickEdit() {
      this.$emit('edit', this.campaign);
    },
    onClickDownload() {
      this.$emit('download', this.campaign);
    },
    onClickShare() {
      this.$emit('share', this.campaign);
    },
    onClickDelete() {
      this.$emit('delete', this.campaign);
    },
  },
});
