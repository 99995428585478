<template>
  <ModalBase
    :title="$t('assets.general.addAsset')"
    :show="show"
    :override-style="{
      width: scssVariables.modalSideOffset,
      height: scssVariables.modalSideOffset,
      'margin-bottom': '0 rem',
      position: 'absolute',
      'z-index': 1000,
    }"
    :override-header-style="{ 'margin-bottom': '1rem' }"
    :override-header-title-style="{ 'margin-left': '0', 'font-size': '1.5rem' }"
    @closeClicked="handleClose"
  >
    <template #content>
      <div class="content-container">
        <span class="content-container-title">{{ assetName }}</span>
        <div class="preview-box">
          <video v-if="isVideo && show" ref="videoPlayer" class="asset-video" controls>
            <source :src="getMediaSource()" />
          </video>
          <audio v-if="isAudio && show" ref="audioPlayer" class="asset-audio" controls>
            <source :src="getMediaSource()" />
          </audio>
          <div v-if="getMarkerContainerWidth()" class="markers" :style="{ width: `${getMarkerContainerWidth()}px` }">
            <div class="start-marker" :style="{ left: `${getStartMarkerPosition()}px` }" />
            <div class="end-marker" :style="{ left: `${getEndMarkerPosition()}px` }" />
          </div>
        </div>
        <MediaEditor v-if="duration" :duration="duration" :disabled="loading" @change="handleChangeSelection" />
      </div>
      <div v-if="isShowingOverlay" class="overlay-loader">
        <div class="spinner" />
      </div>
    </template>
    <template #button-row>
      <div class="actions-row">
        <AppButton size="small-auto" type="outlined" @click="backToAssets()">
          {{ $t('contentWizard.assets.backToAssets') }}
        </AppButton>
        <AppButton :loading="loading" @click="handleSubmit()">
          <template #loader>
            {{ $t('general.buttons.saving') }}
            <SmallSpinner v-if="loading" class="spinner" />
          </template>
          {{ $t('contentWizard.assets.selectSection') }}
        </AppButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { AssetType } from '@/types';
import scssVariables from '@/styles/variables.module.scss';
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import MediaEditor from '@/components/shared/MediaEditor.vue';
import SmallSpinner from '@/components/shared/SmallSpinner.vue';

export default {
  components: {
    ModalBase,
    AppButton,
    MediaEditor,
    SmallSpinner,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    asset: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      scssVariables,
      duration: 0,
      startSeconds: 0,
      endSeconds: 0,
      startTimestamp: null,
      endTimestamp: null,
    };
  },
  computed: {
    isVideo() {
      return this.asset.type === AssetType.VIDEO;
    },
    isAudio() {
      return this.asset.type === AssetType.AUDIO;
    },
    assetName() {
      const assetName = this.asset.name;
      const { width } = this.asset.metadata;
      const { height } = this.asset.metadata;

      return `${assetName} (${width} × ${height})`;
    },
    isShowingOverlay() {
      return false;
    },
  },
  mounted() {
    if (this.isVideo) {
      this.$refs.videoPlayer.onloadedmetadata = () => {
        if (this.$refs.videoPlayer) {
          this.duration = this.$refs.videoPlayer.duration;
        }
      };
    } else if (this.isAudio) {
      this.$refs.audioPlayer.onloadedmetadata = () => {
        this.duration = this.$refs.audioPlayer.duration;
      };
    }
  },
  methods: {
    getMediaSource() {
      return this.asset.signedUrl;
    },
    backToAssets() {
      this.$emit('backToAssets');
    },
    handleClose() {
      this.$emit('cancel');
    },
    handleSubmit() {
      this.$emit('submit', {
        asset: this.asset,
        startTimestamp: this.startTimestamp,
        endTimestamp: this.endTimestamp,
      });
    },
    handleChangeSelection(params) {
      this.startTimestamp = params.startTimestamp;
      this.endTimestamp = params.endTimestamp;
      this.startSeconds = params.startSeconds;
      this.endSeconds = params.endSeconds;
    },
    getMarkerContainerWidth() {
      return this.$refs.videoPlayer ? this.$refs.videoPlayer.offsetWidth - 32 : null;
    },
    getStartMarkerPosition() {
      return (this.startSeconds ? (this.startSeconds / this.duration) * this.getMarkerContainerWidth() : 0) - 2;
    },
    getEndMarkerPosition() {
      return (
        (this.endSeconds
          ? (this.endSeconds / this.duration) * this.getMarkerContainerWidth()
          : this.getMarkerContainerWidth()) - 2
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.content-container {
  height: calc(100% - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
  .error-box {
    width: 100%;
    margin: rem(16px 0);
  }
  .content-container-title {
    font-family: $cch-font-family;
    color: $cch-dark-grey;
  }
  .overlay-loader {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner,
    .spinner:after {
      border-radius: 50%;
      width: rem(150px);
      height: rem(150px);
    }
    .spinner {
      @apply .spin;
      position: relative;
      border-top: rem(4px) solid $cch-light-gray;
      border-right: rem(4px) solid $cch-light-blue;
      border-bottom: rem(4px) solid $cch-light-blue;
      border-left: rem(4px) solid $cch-light-blue;
      transform: translateZ(0);
    }
  }

  .preview-box {
    position: relative;
    height: calc(100% - 150px);
    display: flex;
    justify-content: center;
    video {
      max-height: 100%;
      box-shadow: 0 0 5px 0 rgba(71, 67, 102, 0.19);
    }
  }
}

.actions-row {
  height: rem(88px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  background-color: $cch-light-gray;

  .spinner {
    @apply ml-4;
    width: rem(22px);
    height: rem(22px);
    flex: 0 0 rem(22px);
  }
}

.markers {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 22px;
  margin: 0 auto;
}
.start-marker,
.end-marker {
  width: 2px;
  height: 16px;
  background: red;
  position: absolute;
}
</style>
