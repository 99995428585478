import HTTP from '@/services/http';
import getLogger from '@/services/logger';
import { OrganizationGroup } from '@/types';

const LOG = getLogger('resource/organization-group');

export const getOrganizationGroups = async (): Promise<Array<OrganizationGroup>> => {
  LOG.debug('Attempting to get list of organization groups for current user');
  const response = await HTTP.get<Array<OrganizationGroup>>(`${process.env.VUE_APP_AUTH_URL}/organization-group`);
  return response.data;
};

export const createOrganizationGroup = async (params: { name: string }): Promise<OrganizationGroup> => {
  LOG.debug(`Attempting to create a new organization group with name ${params.name}`);
  const response = await HTTP.post<OrganizationGroup>(`${process.env.VUE_APP_AUTH_URL}/organization-group`, params);
  return response.data;
};

export const updateOrganizationGroup = async (id: string, params: { name: string }): Promise<OrganizationGroup> => {
  LOG.debug(`Attempting to update the organization group with id ${id}`);
  const response = await HTTP.put<OrganizationGroup>(`${process.env.VUE_APP_AUTH_URL}/organization-group/${id}`, {
    name: params.name,
  });
  return response.data;
};
