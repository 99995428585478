<template>
  <div class="modal-header" :style="overrideHeaderStyle">
    <h1 :style="overrideHeaderTitleStyle">
      <slot />
    </h1>
    <CloseButton class="close-button" @clicked="onCloseClicked" />
  </div>
</template>

<script>
import CloseButton from '@/components/shared/buttons/CloseButton.vue';

export default {
  name: 'ModalHeader',
  components: { CloseButton },
  props: {
    overrideHeaderStyle: {
      type: Object,
      required: false,
      default: null,
    },
    overrideHeaderTitleStyle: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    onCloseClicked() {
      this.$emit('closeClicked');
    },
  },
};
</script>

<style scoped>
.modal-header {
  @apply .mb-24 .flex .flex-row;
  position: relative;
}
.close-button {
  position: absolute;
  right: 0;
  top: 0;
}

h1 {
  @apply .font-bold .text-xl .text-center .font-sans;
}
</style>
