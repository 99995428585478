import { getScalingConfig, updateScalingConfig } from '@/services/api/scaling-config.resource';
import { ScalingConfig, UpdateScalingConfigParams } from '@/types/ScalingConfig';
import { isEqual } from 'lodash-es';
import { ActionContext } from 'vuex';

interface State {
  initialConfig: ScalingConfig;
  currentConfig: ScalingConfig;
  loading: boolean;
  dirty: boolean;
}

type Context = ActionContext<State, any>;

const state: State = {
  initialConfig: {
    DAY_END: '',
    DAY_START: '',
    MIN_PREVIEW_INSTANCES_DAY: 0,
    MAX_PREVIEW_INSTANCES_DAY: 0,
    MIN_PREVIEW_INSTANCES_NIGHT: 0,
    MAX_PREVIEW_INSTANCES_NIGHT: 0,
    MIN_RENDER_INSTANCES_DAY: 0,
    MAX_RENDER_INSTANCES_DAY: 0,
    MIN_RENDER_INSTANCES_NIGHT: 0,
    MAX_RENDER_INSTANCES_NIGHT: 0,
    MAX_INSTANCES: 0,
  },
  currentConfig: {
    DAY_END: '',
    DAY_START: '',
    MIN_PREVIEW_INSTANCES_DAY: 0,
    MAX_PREVIEW_INSTANCES_DAY: 0,
    MIN_PREVIEW_INSTANCES_NIGHT: 0,
    MAX_PREVIEW_INSTANCES_NIGHT: 0,
    MIN_RENDER_INSTANCES_DAY: 0,
    MAX_RENDER_INSTANCES_DAY: 0,
    MIN_RENDER_INSTANCES_NIGHT: 0,
    MAX_RENDER_INSTANCES_NIGHT: 0,
    MAX_INSTANCES: 0,
  },
  loading: false,
  dirty: false,
};

const getters = {
  getInitialConfig: (state: State) => state.initialConfig,
  getCurrentConfig: (state: State) => state.currentConfig,
  isLoading: (state: State) => state.loading,
  isDirty: (state: State) => state.dirty,
};

const actions = {
  async fetchConfig(context: Context) {
    try {
      context.commit('setLoading', true);
      const config = await getScalingConfig();
      context.commit('setInitialConfig', config);
      context.commit('setCurrentConfig', config);
    } finally {
      context.commit('setLoading', false);
    }
  },
  async saveConfig(context: Context) {
    try {
      context.commit('setLoading', true);
      if (context.state.currentConfig) {
        await updateScalingConfig({
          DAY_START: context.state.currentConfig.DAY_START,
          DAY_END: context.state.currentConfig.DAY_END,
          MIN_PREVIEW_INSTANCES_DAY: Number(context.state.currentConfig.MIN_PREVIEW_INSTANCES_DAY),
          MAX_PREVIEW_INSTANCES_DAY: Number(context.state.currentConfig.MAX_PREVIEW_INSTANCES_DAY),
          MIN_PREVIEW_INSTANCES_NIGHT: Number(context.state.currentConfig.MIN_PREVIEW_INSTANCES_NIGHT),
          MAX_PREVIEW_INSTANCES_NIGHT: Number(context.state.currentConfig.MAX_PREVIEW_INSTANCES_NIGHT),
          MIN_RENDER_INSTANCES_DAY: Number(context.state.currentConfig.MIN_RENDER_INSTANCES_DAY),
          MAX_RENDER_INSTANCES_DAY: Number(context.state.currentConfig.MAX_RENDER_INSTANCES_DAY),
          MIN_RENDER_INSTANCES_NIGHT: Number(context.state.currentConfig.MIN_RENDER_INSTANCES_NIGHT),
          MAX_RENDER_INSTANCES_NIGHT: Number(context.state.currentConfig.MAX_RENDER_INSTANCES_NIGHT),
        });
        const config = await getScalingConfig();
        context.commit('setInitialConfig', config);
        context.commit('setCurrentConfig', config);
        context.commit('setDirty', false);
      }
    } finally {
      context.commit('setLoading', false);
    }
  },
  setCurrentConfig(context: Context, config: ScalingConfig) {
    context.commit('setCurrentConfig', config);
    if (isEqual(context.state.initialConfig, config)) {
      context.commit('setDirty', false);
    } else {
      context.commit('setDirty', true);
    }
  },
  resetCurrentConfig(context: Context) {
    context.commit('setCurrentConfig', context.state.initialConfig);
    context.commit('setDirty', false);
  },
};

const mutations = {
  setInitialConfig(state: State, config: ScalingConfig) {
    state.initialConfig = { ...config };
  },
  setCurrentConfig(state: State, config: ScalingConfig) {
    state.currentConfig = { ...config };
  },
  setLoading(state: State, value: boolean) {
    state.loading = value;
  },
  setDirty(state: State, value: boolean) {
    state.dirty = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
