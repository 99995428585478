<template>
  <div class="wizard-wrapper">
    <TabComponent
      :tabs="tabs"
      :show="show"
      @closeRequested="startCancelFlow"
      @closeTab="closeWizard"
      @lastItem="finished"
    />
    <DiscardModal
      v-if="isCancelModalOpen"
      message="contentWizard.general.cancelMessage"
      left-button-text="contentWizard.buttons.discardChanges"
      right-button-text="contentWizard.buttons.keepEditing"
      :is-discard-modal-open="isCancelModalOpen"
      :header-hidden="true"
      @clickLeftButton="closeWizard"
      @clickRightButton="closeCancelModal"
      @closeModal="closeCancelModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FormatSelection from '@/components/campaignDetail/contentCreation/formatSelection/FormatSelection.vue';
import BaseDataCreation from '@/components/campaignDetail/contentCreation/baseDataCreation/BaseDataCreation.vue';
import TemplateSelection from '@/components/campaignDetail/contentCreation/templateSelection/TemplateSelection.vue';
import Html5ContentSpecification from '@/components/campaignDetail/contentCreation/contentSpecification/Html5ContentSpecification.vue';
import VideoContentSpecification from '@/components/campaignDetail/contentCreation/contentSpecification/VideoContentSpecification.vue';
import TabComponent from '@/components/shared/tabComponent/TabComponent.vue';
import DiscardModal from '@/components/shared/DiscardModal.vue';

export default {
  components: {
    TabComponent,
    DiscardModal,
  },
  beforeRouteLeave(to, from, next) {
    if (this.shouldCheckRouteLeave && this.hasUnsavedData) {
      this.nextObj = next;
      this.openCancelModal();
    } else {
      next();
    }
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tabs: [
        {
          component: () => {
            return BaseDataCreation;
          },
          tabName: 'contentWizard.header.basedata',
          tabIcon: 'basedata',
          previousButtonText: 'contentWizard.footer.previous',
          nextButtonText: 'contentWizard.footer.next',
          beforeNextFunction: 'save',
        },
        {
          component: () => {
            return TemplateSelection;
          },
          tabName: 'contentWizard.header.templates',
          tabIcon: 'analytics',
          previousButtonText: 'contentWizard.footer.previous',
          nextButtonText: 'contentWizard.footer.next',
          beforeNextFunction: 'save',
        },
        {
          component: () => {
            return FormatSelection;
          },
          tabName: 'contentWizard.header.formats',
          tabIcon: 'formats',
          previousButtonText: 'contentWizard.footer.previous',
          nextButtonText: 'contentWizard.footer.next',
          beforeNextFunction: 'save',
          beforePreviousFunction: 'save',
        },
        {
          component: () => {
            if (this.templateType === 'VIDEO') {
              return VideoContentSpecification;
            }
            if (this.templateType === 'HTML5') {
              return Html5ContentSpecification;
            }
            throw new Error(`Unsupported template type provided: ${this.templateType}`);
          },
          tabName: 'contentWizard.header.content',
          tabIcon: 'content',
          previousButtonText: 'contentWizard.footer.previous',
          nextButtonText: 'contentWizard.footer.renderNow',
          beforeNextConfirmRequired: true,
          confirmTitle: this.$t('content.general.confirmRenderTitle'),
          confirmMessage: this.$t('content.general.confirmRenderMessage'),
        },
      ],
      nextObj: null,
      isCancelModalOpen: false,
      shouldCheckRouteLeave: true,
    };
  },
  computed: {
    ...mapGetters({
      templateType: 'contentCreation/getTemplateType',
      template: 'contentCreation/getTemplate',
      hasUnsavedData: 'contentCreation/getHasUnsavedData',
    }),
  },
  async mounted() {
    window.addEventListener('beforeunload', this.leavingPageConfirmationCallback);
    this.$store.dispatch('campaigns/loadCampaigns');
    await this.$store.dispatch('templates/loadTemplateList');
  },
  beforeDestroy() {
    this.$store.dispatch('contentCreation/resetContent');
    this.$store.dispatch('contentCreation/resetCopiedContent');
    window.removeEventListener('beforeunload', this.leavingPageConfirmationCallback);
  },
  methods: {
    leavingPageConfirmationCallback(event) {
      if (!this.hasUnsavedData) {
        return;
      }
      // eslint-disable-next-line no-alert
      if (!window.confirm()) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    },
    startCancelFlow() {
      if (this.shouldCheckRouteLeave && this.hasUnsavedData) {
        this.openCancelModal();
      } else {
        this.closeWizard();
      }
    },
    closeWizard(campaignId = null) {
      const campaignIdFromWizardOrRoute = campaignId || this.$route.params.id;

      this.$store.dispatch('campaigns/getCampaignDetail', campaignIdFromWizardOrRoute);
      if (this.nextObj) {
        this.nextObj();
      } else {
        this.shouldCheckRouteLeave = false;
        this.$router.push({ name: 'campaign', params: { id: campaignIdFromWizardOrRoute } });
      }
    },
    openCancelModal() {
      this.isCancelModalOpen = true;
    },
    closeCancelModal() {
      this.isCancelModalOpen = false;
      if (this.nextObj) {
        this.nextObj(false);
        this.nextObj = null;
      }
    },
    async finished() {
      await this.sendContent();
    },
    async sendContent() {
      const content = this.$store.getters['contentCreation/getBaseData'];
      const campaignId = content.campaignId.valueOf();

      await this.$store.dispatch('contentCreation/startRendering');
      await this.$store.dispatch('content/setMediaRenderStatus', true);

      this.closeWizard(campaignId);
    },
  },
};
</script>

<style scoped lang="scss">
.wizard-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
