<template>
  <div class="user-general-wrapper">
    <div class="title-row">
      <span class="title-row-title">{{ $t('general.users.profile') }}</span>
      <AppButton size="x-small" @click="openEditUserModal">{{ $t('general.users.editProfile') }}</AppButton>
    </div>
    <FormInputField v-model="firstName" disabled class="form-field" :placeholder="$t('general.users.firstName')" />
    <FormInputField v-model="lastName" disabled class="form-field" :placeholder="$t('general.users.lastName')" />
    <FormInputField v-model="email" disabled class="form-field" :placeholder="$t('general.users.eMail')" />
    <FormInputField v-model="client" disabled class="form-field" :placeholder="$t('general.users.client')" />
    <FormInputField v-model="role" disabled class="form-field" :placeholder="$t('general.users.role')" />
    <ChangePasswordModal
      v-if="isPasswordModalOpen"
      :show="isPasswordModalOpen"
      :user="user"
      @close="closePasswordModal"
      @changePassword="changePassword"
    />
    <EditUserModal
      v-if="isEditUserModalOpen"
      :show="isEditUserModalOpen"
      :user="user"
      @openPasswordModal="openPasswordModal"
      @close="closeEditUserModal"
      @edit="editUser"
    />
  </div>
</template>

<script>
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import ChangePasswordModal from '@/components/userDetail/profile/ChangePasswordModal.vue';
import EditUserModal from '@/components/userDetail/profile/EditUserModal.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';

export default {
  components: {
    FormInputField,
    ChangePasswordModal,
    EditUserModal,
    AppButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: Array,
      default: null,
    },
    organization: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      client: '',
      role: '',
      isPasswordModalOpen: false,
      isEditUserModalOpen: false,
    };
  },
  computed: {
    roleNames() {
      return this.roles.map((role) => {
        return role.name;
      });
    },
    organizationNames() {
      return this.organization.map((organization) => {
        return organization.name;
      });
    },
  },
  watch: {
    user(newUserObject) {
      if (newUserObject !== null) {
        this.setUserFormData();
      }
    },
  },
  mounted() {
    if (this.user !== null) {
      this.setUserFormData();
    }
  },
  methods: {
    setUserFormData() {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.email = this.user.email;
      this.password = this.user.password;
      this.role = this.findRoleName(this.user.role);
      this.client = this.organization.name;
    },
    openEditUserModal() {
      this.isEditUserModalOpen = true;
    },
    closeEditUserModal() {
      this.isEditUserModalOpen = false;
    },
    changePassword(user) {
      this.$store.dispatch('users/editUser', user).then(() => {
        this.closePasswordModal();
      });
    },
    openPasswordModal() {
      this.isPasswordModalOpen = true;
      this.closeEditUserModal();
    },
    closePasswordModal() {
      this.isPasswordModalOpen = false;
      this.openEditUserModal();
    },
    editUser(userObject) {
      this.$store.dispatch('users/editUser', userObject).then(() => {
        this.closeEditUserModal();
        this.$store.dispatch('users/loadUser', this.user._id);
      });
    },
    findRoleName() {
      if (this.user !== null) {
        const roleName = this.roles
          .filter((role) => {
            return role._id === this.user.role;
          })
          .pop();
        if (typeof roleName !== 'undefined' && roleName) {
          return roleName.name;
        }
      }
      return undefined;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.user-general-wrapper {
  max-width: rem(620px);
  background-color: #ffffff;
  padding: 1rem;
  box-sizing: border-box;
  .title-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    .title-row-title {
      font-family: $cch-font-family;
      font-size: 1.5rem;
      font-weight: 800;
      color: $cch-dark-grey;
    }
    .confirmation-button {
      background-color: $cch-buttons-blue;
      font-family: $cch-font-family;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      padding: 0.5rem 1rem;
    }
  }
  .form-field {
    box-sizing: border-box;
    &.form-field:not(:nth-child(2)) {
      border-top: rem(2px) solid $cch-light-gray;
    }
  }
}
</style>
