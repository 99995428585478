<template>
  <span :class="`app-text app-text--${type}`">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'AppText',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style lang="scss" scoped>
.app-text {
  @apply .font-sans;
  &.app-text--primary {
    @apply .text-blue-light .text-base;
  }
  &.app-text--date {
    @apply .text-lg .text-black .font-bold;
  }
  &.app-text--year {
    @apply .text-sm .text-black;
  }
  &.app-text--contentcount {
    @apply .text-32 .text-black .font-bold;
  }
  &.app-text--title {
    @apply .text-4xl .text-blue-lighter .font-extrabold;
  }
  &.app-text--pane {
    @apply .text-2xl .text-black .font-extrabold;
  }
  &.app-text--navigation-item {
    @apply .text-base;
    font-weight: 600;
  }
}
</style>
