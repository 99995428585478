
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import SmallSpinner from '@/components/shared/SmallSpinner.vue';
import scssVariables from '@/styles/variables.module.scss';
import { defineComponent, PropType } from 'vue';
import { OrganizationGroup } from '@/types';

const DEFAULT_NAME = '';

export default defineComponent({
  components: {
    ModalBase,
    FormInputField,
    AppButton,
    SmallSpinner,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    organizationGroup: {
      type: Object as PropType<OrganizationGroup | null>,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'cancel', 'submit'],
  data() {
    return {
      scssVariables,
      name: DEFAULT_NAME,
    };
  },
  computed: {
    title(): string {
      return this.organizationGroup
        ? this.$t('organizations.general.editOrganizationGroup')
        : this.$t('organizations.general.createOrganizationGroup');
    },
    submitText(): string {
      return this.organizationGroup ? this.$t('general.buttons.edit') : this.$t('general.buttons.create');
    },
    isFormValid(): boolean {
      if (!this.name || this.name.length === 0) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    this.initializeForm();
    this.focusFirstField();
  },
  methods: {
    initializeForm() {
      if (this.organizationGroup) {
        this.name = this.organizationGroup.name;
      } else {
        this.name = DEFAULT_NAME;
      }
    },
    focusFirstField() {
      if (this.$refs.firstInput) {
        (this.$refs.firstInput as HTMLElement).focus();
      }
    },
    onClose() {
      this.$emit('close');
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSubmit() {
      if (this.isFormValid) {
        this.$emit('submit', {
          name: this.name,
        });
      }
    },
  },
});
