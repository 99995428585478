
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import GroupIcon from '@/assets/images/group.svg';
import scssVariables from '@/styles/variables.module.scss';
import { toast } from '@/services/toast';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    ModalBase,
    GroupIcon,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    getLinkFunc: {
      type: Function as PropType<(() => Promise<string>) | null>,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      fullLink: '',
      scssVariables,
    };
  },
  mounted() {
    (async () => {
      if (this.getLinkFunc) {
        try {
          this.loading = true;
          const linkId = await this.getLinkFunc();
          this.fullLink = `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/download/${linkId}`;
        } finally {
          this.loading = false;
        }
      }
    })();
  },
  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    onSuccessfullyCopied() {
      toast.$emit('showToast', {
        type: 'success',
        message: this.$t('content.general.copiedLink'),
      });
    },
    async copyLink() {
      if (!this.loading && this.fullLink) {
        await this.$copyText(this.fullLink);
        this.onSuccessfullyCopied();
      }
    },
  },
});
