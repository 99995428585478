var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModalBase',{attrs:{"title":"contentWizard.contentSpecification.copyFrom","show":_vm.show,"override-style":{
    width: '35%',
    height: '40%',
    'margin-bottom': '0 rem',
    position: 'absolute',
    'max-width': '80%',
    overflow: 'hidden',
    'z-index': 1000,
    'background-color': _vm.scssVariables.cchLightGray,
  },"override-header-style":{ 'margin-bottom': '1rem' }},on:{"closeClicked":_vm.onCloseClicked},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"content-wrapper"},[_c('FormSelectionField',{attrs:{"placeholder":_vm.placeholder,"list":_vm.getOptions()},on:{"input":_vm.selectTarget}})],1)]},proxy:true},{key:"button-row",fn:function(){return [_c('div',{staticClass:"actions-row"},[_c('button',{staticClass:"copy-button",on:{"click":_vm.copyContent}},[_vm._v(" "+_vm._s(_vm.$t('contentWizard.contentSpecification.copyContent'))+" ")])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }