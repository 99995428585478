
import AssetHeader from '@/components/assets/AssetHeader.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import CreateAssetModal from '@/components/assets/CreateAssetModal.vue';
import AssetsGrid from '@/components/assets/AssetsGrid.vue';
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import scssVariables from '@/styles/variables.module.scss';
import { defineComponent } from 'vue';
import { Asset, AssetType } from '@/types';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'AssetSelectionModal',
  components: {
    AssetHeader,
    CreateAssetModal,
    AssetsGrid,
    ModalBase,
    AppButton,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    assetRestrictions: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isCreateModalShown: false,
      selectedAsset: null as Asset | null,
      modalPage: 1,
      scssVariables,
    };
  },
  computed: {
    ...mapGetters({
      getAssetById: 'assets/getAssetById',
      path: 'assets/getPath',
      getAssets: 'assets/getAssets',
      isShared: 'assets/getIsShared',
      isLoading: 'assets/getLoading',
    }),
    showMediaTypes() {
      return this.type === '';
    },
    assets(): Asset[] {
      if (!this.selectedAsset) {
        return this.getAssets;
      }
      const assets: Asset[] = [];
      this.getAssets.forEach((asset: Asset) => {
        assets.push({ ...asset, selected: asset._id === this.selectedAsset!._id });
      });
      return assets;
    },
  },
  beforeDestroy() {
    this.$store.dispatch('assets/removeSearchTerm');
  },
  methods: {
    assetSelected(asset: Asset) {
      const id = asset._id;
      this.selectedAsset = this.getAssetById(id);
      if (this.selectedAsset) {
        if (this.selectedAsset.type === AssetType.FOLDER || this.selectedAsset.type === AssetType.SHARED_FOLDER) {
          this.$store.dispatch('assets/removeSearchTerm');
          this.$store.dispatch('assets/setPath', [...this.path, id]);
          this.selectedAsset = null;
        }
      }
    },
    assetUnselected() {
      this.selectedAsset = null;
    },
    createAsset(assetData: { name: string; file: any }) {
      this.$store
        .dispatch('assets/createAsset', { fileName: assetData.name, file: assetData.file, path: this.path })
        .then(() => {
          this.closeCreateModal();
          this.$store.dispatch('assets/getAllAssets');
        });
    },
    openCreateModal() {
      this.isCreateModalShown = true;
    },
    closeCreateModal() {
      this.isCreateModalShown = false;
    },
    onCloseClicked() {
      this.$emit('closeClick');
    },
    confirmAssetSelection() {
      this.$emit('selectedAsset', this.selectedAsset);
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }: any) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.modalPage += 1;
      }
    },
    resetPageContentState() {
      (this.$refs.assetGridContainer as any).scrollTo(0, 0);
      this.modalPage = 1;
    },
    breadcrumbClicked(path: string[]) {
      this.$store.dispatch('assets/setPath', path);
    },
  },
});
