<template>
  <div class="app-selection">
    <select :disabled="disabled" :class="{ disabled: disabled }" @input="$emit('input', $event.target.value)">
      <option disabled :selected="!selectedItem">{{ $t(placeholder) }}</option>
      <option
        v-for="(item, index) in list"
        :key="index"
        :value="getItemValue(item)"
        :selected="getItemValue(item) === selectedItem"
        >{{ getItemName(item) }}</option
      >
    </select>
    <label class="app-selection-label">{{ $t(placeholder) }}</label>
    <ArrowDownIcon class="selection-icon" />
  </div>
</template>

<script>
import ArrowDownIcon from '@/assets/images/arrow-down.svg';

export default {
  components: {
    ArrowDownIcon,
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: false,
      default: null,
    },
    selectedItem: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    getItemName(item) {
      if (item.name) {
        return item.name;
      }
      if (item.key && typeof item.key === 'string') {
        return item.key;
      }
      if (typeof item.value !== 'undefined' && item.value) {
        return item.value;
      }
      return item;
    },
    getItemValue(item) {
      if (typeof item.value !== 'undefined' && item.value) {
        return item.value;
      }
      if (typeof item.id !== 'undefined' && item.id) {
        return item.id;
      }
      if (typeof item._id !== 'undefined' && item._id) {
        return item._id;
      }
      return item;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.app-selection {
  @apply .font-sans .font-semibold .text-blue-light .w-full .relative .bg-white;
  height: rem(51px);
  overflow: hidden;
  outline: none;

  .app-selection-label {
    @apply .absolute;
    color: rgba(51, 51, 51, 0.5);
    top: 0.5rem;
    left: 1rem;
    z-index: 0;
    pointer-events: none;
    font-size: 0.75rem;
  }
  .disabled {
    cursor: not-allowed;
  }
  select:focus {
    outline: none;
  }
  select {
    @apply .absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    background-color: #ffffff;
    color: $cch-dark-grey;
    appearance: none;
  }

  .selection-icon {
    float: right;
    pointer-events: none;
    position: relative;
    right: rem(15px);
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
  }
}
</style>
