<template>
  <div class="item-wrapper">
    <div class="item-container" :class="{ 'blue-color': active }">
      <component :is="iconComponent" />
      <span class="header-item-label">{{ $t(name) }}</span>
    </div>
    <div v-if="!isLast" class="connection-line" />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconComponent: '',
    };
  },
  computed: {
    loader() {
      return () => import(`@/assets/images/${this.icon}.svg`);
    },
  },
  mounted() {
    this.loader().then(() => {
      this.iconComponent = () => this.loader();
    });
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.item-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /*align-items: center;*/
  .item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: rem(0 24px);
    color: $cch-light-steel-blue;
    .header-item-label {
      margin-top: 0.75rem;
      font-family: $cch-font-family;
      font-weight: 800;
      font-size: 1rem;
    }
  }
  .connection-line {
    margin-top: 1rem;
    height: rem(1px);
    width: rem(30px);
    background-color: rgba(140, 153, 173, 0.5);
  }
}
.blue-color {
  color: $cch-buttons-blue !important;
}
</style>
