const state = {
  sortedBy: {
    key: null,
    reverse: null,
  },
  assetView: null,
  templateView: 'list',
  i18nextLng: null,
};

const getters = {
  getSortedBy: (state: $TSFixMe) => {
    return state.sortedBy;
  },
  getAssetView: (state: $TSFixMe) => {
    return state.assetView || 'list';
  },
  getTemplateView: (state: $TSFixMe) => {
    return state.templateView || 'list';
  },
  getLanguage: (state: $TSFixMe) => {
    return state.i18nextLng;
  },
};

const actions = {
  setTemplateView({ commit }: $TSFixMe, templateView: $TSFixMe) {
    commit('setTemplateView', templateView);
  },
  setLanguage({ commit }: $TSFixMe, language: $TSFixMe) {
    commit('setLanguage', language);
  },
};

const mutations = {
  updateSortedBy(state: $TSFixMe, payload: $TSFixMe) {
    state.sortedBy = payload;
  },
  setAssetView(state: $TSFixMe, string: $TSFixMe) {
    state.assetView = string;
  },
  setTemplateView(state: $TSFixMe, templateView: $TSFixMe) {
    state.templateView = templateView;
  },
  setLanguage(state: $TSFixMe, language: $TSFixMe) {
    state.i18nextLng = language;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
