
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    type: {
      type: String as PropType<'normal' | 'outlined' | 'text'>,
      required: false,
      default: 'normal',
    },
    color: {
      type: String as PropType<'primary' | 'warning'>,
      required: false,
      default: 'primary',
    },
    size: {
      type: String as PropType<'normal' | 'small' | 'normal-auto' | 'small-auto' | 'x-small'>,
      required: false,
      default: 'normal',
    },
    uppercase: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    click(e: Event) {
      this.$emit('click', e);
    },
  },
});
