import { render, staticRenderFns } from "./AssetHeader.vue?vue&type=template&id=963c1cda&scoped=true&"
import script from "./AssetHeader.vue?vue&type=script&lang=js&"
export * from "./AssetHeader.vue?vue&type=script&lang=js&"
import style0 from "./AssetHeader.vue?vue&type=style&index=0&id=963c1cda&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "963c1cda",
  null
  
)

export default component.exports