<template>
  <div class="form-input-container">
    <input
      class="form-input"
      :placeholder="$t(placeholder)"
      :value="value"
      :type="type"
      :name="name"
      :autocomplete="autocomplete"
      @input="$emit('input', $event.target.value)"
      @keydown.enter="onEnterPressed"
    />
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'off',
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    onEnterPressed() {
      this.$emit('enterPressed');
    },
  },
};
</script>

<style scoped>
.form-input {
  @apply .bg-blue-lightest .h-12 .w-full .pl-2;
}
</style>
