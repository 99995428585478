// eslint-disable-next-line import/no-cycle
import HTTP from '@/services/http';
import getLogger from '@/services/logger';

const LOG = getLogger('resource/credit');

const urlPrefixOrganization = `${process.env.VUE_APP_AUTH_URL}/organization`;
const urlPrefix = `${process.env.VUE_APP_AUTH_URL}`;

export const fetchCredits = async (organizationId: $TSFixMe) => {
  try {
    const creditsResponse = await HTTP.get(`${urlPrefixOrganization}/${organizationId}/credit`);
    return creditsResponse.data;
  } catch (e) {
    LOG.error('Error while fetching credits left for organization');
    return {};
  }
};

export async function getCreditsPackInfo() {
  try {
    const response = await HTTP.get(`${urlPrefix}/creditPackages`);
    return response.data;
  } catch (e) {
    LOG.error(`Error while fetching credit packages Info`);
    return {};
  }
}

export async function buyCreditsPacks(creditsPacks: $TSFixMe, organizationId: $TSFixMe) {
  try {
    const response = await HTTP.post(`${urlPrefixOrganization}/${organizationId}/creditPackage`, creditsPacks);
    return response.data;
  } catch (e) {
    LOG.error(`Error while buying creditPacks ${creditsPacks}`);
    return {};
  }
}
