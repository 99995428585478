export const TYPES = {
  VIDEO: 'VIDEO',
  HTML5: 'HTML5',
};

export default {
  TYPES,
};

export const DEFAULT_CONTENT_TAKE_SIZE = 20;
