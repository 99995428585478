
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import scssVariables from '@/styles/variables.module.scss';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    ModalBase,
    AppButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    assetName: {
      type: String,
      required: true,
      default: '',
    },
    recipients: {
      type: Array as PropType<Array<{ id: string; name: string }>>,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      scssVariables,
    };
  },
  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    onUndoClicked() {
      this.$emit('undo');
    },
    onSubmitClick() {
      this.$emit('submit');
    },
  },
});
