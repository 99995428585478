<template>
  <button @click="onClicked">
    <Close class="icon" />
  </button>
</template>

<script>
import Close from '@/assets/images/close.svg';

export default {
  name: 'CloseButton',
  components: {
    Close,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  methods: {
    onClicked() {
      this.$emit('clicked', this.id);
    },
  },
};
</script>

<style scoped lang="scss">
button {
  @apply .inline-flex .h-4 .w-4 .ml-4 .outline-none;
}
.icon {
  fill: black;
  stroke: black;
}
</style>
