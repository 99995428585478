<template>
  <div class="app-input-wrapper">
    <div class="app-input" :class="{ 'invalid-input': invalid }">
      <input
        :disabled="disabled"
        :value="value"
        :placeholder="placeholder"
        type="password"
        @input="$emit('input', $event.target.value)"
      />
      <label class="app-input-label">{{ $t(placeholder) }}</label>
      <div v-if="layer !== null && layer.maxValue" class="char-counter-container">
        <CharCounter
          :current-input="value"
          :max-count="parseMaxCount(layer)"
          @maxValueExceeded="exceededMaxValue()"
          @maxValueObeyed="obeyedMaxValue()"
        />
      </div>
      <div v-if="resetPossible" class="reset-password" @click="changePassword">
        <span class="reset-password-text">{{ $t('general.users.changePassword') }}</span>
      </div>
    </div>
    <div v-if="invalid" class="error-wrapper">
      <span class="error-message">{{ $t('contentWizard.contentSpecification.rowError') }} 1"</span>
    </div>
  </div>
</template>

<script>
import CharCounter from '@/components/shared/formFields/CharCounter.vue';

export default {
  components: {
    CharCounter,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: true,
    },
    layer: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    resetPossible: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      invalid: false,
    };
  },
  methods: {
    changePassword() {
      this.$emit('openPasswordModal');
    },
    parseMaxCount(layer) {
      return parseInt(layer.maxValue, 10);
    },
    exceededMaxValue() {
      this.invalid = true;
    },
    obeyedMaxValue() {
      this.invalid = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.app-input-wrapper {
  position: relative;
  height: fit-content;
  .app-input {
    @apply .font-sans .font-semibold .text-blue-light .w-full .relative .bg-white;
    height: rem(51px);
    overflow: hidden;
    .app-input-label {
      @apply .absolute;
      top: 0.5rem;
      left: 1rem;
      font-size: 0.75rem;
      color: rgba(51, 51, 51, 0.5);
      z-index: 0;
      pointer-events: none;
      transition: transform 0.3s ease;
    }
    input:focus {
      outline: none;
      @apply .border-blue-lighter;
    }
    input {
      @apply .block .absolute;
      background-color: #ffffff;
      bottom: 0;
      color: $cch-dark-grey;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      width: calc(100% - 60px);
    }
    input::placeholder {
      opacity: 0;
    }
    .char-counter-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: absolute;
      right: 0.25rem;
      top: rem(25px);
    }
    .reset-password {
      position: absolute;
      right: 0.75rem;
      bottom: 0.5rem;
      cursor: pointer;
      .reset-password-text {
        font-family: $cch-font-family;
        font-size: 1rem;
        font-weight: 600;
        text-align: right;
        color: $cch-buttons-blue !important;
      }
    }
  }

  .error-wrapper {
    position: absolute;
    bottom: -1.25rem;
    .error-message {
      font-family: $cch-font-family;
      font-size: 0.75rem;
      font-weight: 500;
      color: #ef3c4c;
    }
  }
  .invalid-input {
    outline: solid rem(1px) #ef3c4c;
  }
}
</style>
