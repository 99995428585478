
import { defineComponent } from 'vue';
import { OrganizationGroup } from '@/types';
import {
  getOrganizationGroups,
  createOrganizationGroup,
  updateOrganizationGroup,
} from '@/services/api/organization-groups.resource';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import OrganizationGroupSaveModal from '@/components/organizations/OrganizationGroupSaveModal.vue';
import EditIcon from '@/assets/images/edit.svg';
import { ITEMS_PER_PAGE_OPTIONS, DEFAULT_ITEMS_PER_PAGE } from '@/constants/pagination';

export default defineComponent({
  components: {
    EditIcon,
    AppButton,
    OrganizationGroupSaveModal,
  },
  data() {
    return {
      organizationGroups: [] as Array<OrganizationGroup>,
      headers: [
        {
          text: this.$t('organizations.general.name'),
          value: 'name',
          align: 'start',
        },
        {
          text: this.$t('organizations.general.actions'),
          value: 'actions',
          sortable: false,
          align: 'end',
        },
      ],
      searchTerm: '',
      isSaveModalShown: false,
      editingOrganizationGroup: null as OrganizationGroup | null,
      isSaving: false,
      ITEMS_PER_PAGE_OPTIONS,
      DEFAULT_ITEMS_PER_PAGE,
    };
  },
  computed: {
    visibleOrganizations() {
      if (this.searchTerm) {
        return this.organizationGroups.filter((item) =>
          item.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
        );
      }
      return this.organizationGroups;
    },
  },
  async mounted() {
    this.organizationGroups = await getOrganizationGroups();
  },
  methods: {
    onClickEdit(item: OrganizationGroup) {
      this.editingOrganizationGroup = item;
      this.isSaveModalShown = true;
    },
    onClickNew() {
      this.isSaveModalShown = true;
    },
    onCloseSaveModal() {
      this.editingOrganizationGroup = null;
      this.isSaveModalShown = false;
    },
    async onSubmitSaveModal(payload: { name: string }) {
      try {
        this.isSaving = true;
        if (this.editingOrganizationGroup) {
          await updateOrganizationGroup(this.editingOrganizationGroup.id, {
            name: payload.name,
          });
        } else {
          await createOrganizationGroup({
            name: payload.name,
          });
        }
        this.editingOrganizationGroup = null;
        this.isSaveModalShown = false;
        this.organizationGroups = await getOrganizationGroups();
      } finally {
        this.isSaving = false;
      }
    },
  },
});
