
import { defineComponent } from 'vue';
import AppHeader from '@/components/shared/AppHeader.vue';
import OrganizationManagement from './OrganizationManagement.vue';
import OrganizationGroupManagement from './OrganizationGroupManagement.vue';

export default defineComponent({
  components: {
    AppHeader,
    OrganizationManagement,
    OrganizationGroupManagement,
  },
  data() {
    return {
      tab: 0,
    };
  },
});
