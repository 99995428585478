
import { defineComponent, PropType } from 'vue';
import { Notification, Render, RenderNotificationData, RenderNotificationDataRenderItem } from '@/types';
import { mapGetters } from 'vuex';
import { FINAL_STATUSES } from '@/constants/content/contentCreation';
import { getLanguageCode } from '@/helpers/helpers';
import CloseIcon from '@/assets/images/close.svg';
import CheckIcon from '@/assets/images/check.svg';

export default defineComponent({
  name: 'NotificationItemRender',
  components: {
    CloseIcon,
    CheckIcon,
  },
  props: {
    item: {
      type: Object as PropType<Notification>,
      required: true,
      default: null,
    },
  },
  emits: ['clickView'],
  data() {
    return {
      FINAL_STATUSES,
      progresses: {} as Record<string, number>,
    };
  },
  computed: {
    ...mapGetters({
      renders: 'renders/getRenders',
    }),
    data() {
      return this.item.data as RenderNotificationData;
    },
  },
  methods: {
    onClickHide(notification: Notification) {
      if (!notification.seen) {
        this.$store.dispatch('notifications/markNotificationSeen', {
          notificationId: notification.id,
        });
      }
    },
    onClickView(notification: Notification) {
      if (!this.data.renders.some((render) => !this.isRenderDone(render))) {
        if (!notification.seen) {
          this.$store.dispatch('notifications/markNotificationSeen', {
            notificationId: notification.id,
          });
        }
      }
      const url = `/campaigns/${(notification.data as RenderNotificationData).campaign.id}`;
      const contentId = (notification.data as RenderNotificationData).content.id;
      if (this.$route.path !== url || this.$route.query.contentId !== contentId) {
        this.$router.push({
          path: `${url}?contentId=${contentId}`,
        });
      }
      this.$emit('clickView');
    },
    getRenderStatus(render: RenderNotificationDataRenderItem): number {
      if (render.status === FINAL_STATUSES.CREATED) {
        return 100;
      }
      const wsRender = this.renders.find((item: Render) => item.id === render.id);
      if (wsRender) {
        this.progresses[render.id] = wsRender.progress;
        return wsRender.progress;
      }
      return this.progresses[render.id] || 0;
    },
    formatName(format: string, language: string) {
      return `${format}_${getLanguageCode(language)}`;
    },
    isRenderDone(render: RenderNotificationDataRenderItem): boolean {
      return render.status === FINAL_STATUSES.CREATED || render.status === FINAL_STATUSES.FAILED;
    },
  },
});
