
import { defineComponent } from 'vue';
import AppHeader from '@/components/shared/AppHeader.vue';
import AppLoading from '@/components/shared/AppLoading.vue';
import DiscardModal from '@/components/shared/DiscardModal.vue';
import RenderConfigTitleRow from './RenderConfigTitleRow.vue';
import { ScalingConfig } from '@/types/ScalingConfig';
import { toast } from '@/services/toast';

const DAY_TEST_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export default defineComponent({
  components: {
    AppHeader,
    AppLoading,
    DiscardModal,
    RenderConfigTitleRow,
  },
  data() {
    return {
      isConfirmSaveModalShown: false,
      changesText: '',
    };
  },
  computed: {
    isDirty() {
      return this.$store.getters['renderConfig/isDirty'] as boolean;
    },
    isLoading() {
      return this.$store.getters['renderConfig/isLoading'] as boolean;
    },
    initialConfig() {
      return this.$store.getters['renderConfig/getInitialConfig'] as ScalingConfig;
    },
    currentConfig() {
      return this.$store.getters['renderConfig/getCurrentConfig'] as ScalingConfig;
    },
    dayAndNightPhase() {
      return {
        start: this.currentConfig?.DAY_START || '',
        end: this.currentConfig?.DAY_END || '',
      };
    },
    isTimeRangeValid() {
      if (!this.currentConfig.DAY_START || !this.currentConfig.DAY_END) {
        return false;
      }
      if (!DAY_TEST_REGEX.test(this.currentConfig.DAY_START) || !DAY_TEST_REGEX.test(this.currentConfig.DAY_END)) {
        return false;
      }
      if (this.currentConfig.DAY_END <= this.currentConfig.DAY_START) {
        return false;
      }
      return true;
    },
    isPreviewInstancesDayValid() {
      return (
        Number(this.currentConfig.MIN_PREVIEW_INSTANCES_DAY) < Number(this.currentConfig.MAX_PREVIEW_INSTANCES_DAY) &&
        Number(this.currentConfig.MAX_PREVIEW_INSTANCES_DAY) <= Number(this.currentConfig.MAX_INSTANCES)
      );
    },
    isPreviewInstancesNightValid() {
      return (
        Number(this.currentConfig.MIN_PREVIEW_INSTANCES_NIGHT) <
          Number(this.currentConfig.MAX_PREVIEW_INSTANCES_NIGHT) &&
        Number(this.currentConfig.MAX_PREVIEW_INSTANCES_NIGHT) <= Number(this.currentConfig.MAX_INSTANCES)
      );
    },
    isRenderInstancesDayValid() {
      return (
        Number(this.currentConfig.MIN_RENDER_INSTANCES_DAY) < Number(this.currentConfig.MAX_RENDER_INSTANCES_DAY) &&
        Number(this.currentConfig.MAX_RENDER_INSTANCES_DAY) <= Number(this.currentConfig.MAX_INSTANCES)
      );
    },
    isRenderInstancesNightValid() {
      return (
        Number(this.currentConfig.MIN_RENDER_INSTANCES_NIGHT) < Number(this.currentConfig.MAX_RENDER_INSTANCES_NIGHT) &&
        Number(this.currentConfig.MAX_RENDER_INSTANCES_NIGHT) <= Number(this.currentConfig.MAX_INSTANCES)
      );
    },
  },
  async mounted() {
    await this.$store.dispatch('renderConfig/fetchConfig');
  },
  methods: {
    async handleClickReset() {
      await this.$store.dispatch('renderConfig/resetCurrentConfig');
    },
    async handleChangeCurrentConfig(updatedConfig: ScalingConfig) {
      await this.$store.dispatch('renderConfig/setCurrentConfig', updatedConfig);
    },
    closeConfirmSaveModal() {
      this.isConfirmSaveModalShown = false;
      this.changesText = '';
    },
    getChangeText({
      label,
      currentValue,
      initialValue,
    }: {
      label: string;
      currentValue: string | number;
      initialValue: string | number;
    }): string {
      if (currentValue !== initialValue) {
        return `${label}: ${initialValue} &#8680; ${currentValue}`;
      }
      return '';
    },
    updateChangesText() {
      const changes = [
        {
          label: this.$t('config.general.dayStart'),
          currentValue: this.currentConfig.DAY_START,
          initialValue: this.initialConfig.DAY_START,
        },
        {
          label: this.$t('config.general.dayEnd'),
          currentValue: this.currentConfig.DAY_END,
          initialValue: this.initialConfig.DAY_END,
        },
        {
          label: this.$t('config.general.minPreviewInstancesDay'),
          currentValue: this.currentConfig.MIN_PREVIEW_INSTANCES_DAY,
          initialValue: this.initialConfig.MIN_PREVIEW_INSTANCES_DAY,
        },
        {
          label: this.$t('config.general.maxPreviewInstancesDay'),
          currentValue: this.currentConfig.MAX_PREVIEW_INSTANCES_DAY,
          initialValue: this.initialConfig.MAX_PREVIEW_INSTANCES_DAY,
        },
        {
          label: this.$t('config.general.minPreviewInstancesNight'),
          currentValue: this.currentConfig.MIN_PREVIEW_INSTANCES_NIGHT,
          initialValue: this.initialConfig.MIN_PREVIEW_INSTANCES_NIGHT,
        },
        {
          label: this.$t('config.general.maxPreviewInstancesNight'),
          currentValue: this.currentConfig.MAX_PREVIEW_INSTANCES_NIGHT,
          initialValue: this.initialConfig.MAX_PREVIEW_INSTANCES_NIGHT,
        },
        {
          label: this.$t('config.general.minRenderInstancesDay'),
          currentValue: this.currentConfig.MIN_RENDER_INSTANCES_DAY,
          initialValue: this.initialConfig.MIN_RENDER_INSTANCES_DAY,
        },
        {
          label: this.$t('config.general.maxRenderInstancesDay'),
          currentValue: this.currentConfig.MAX_RENDER_INSTANCES_DAY,
          initialValue: this.initialConfig.MAX_RENDER_INSTANCES_DAY,
        },
        {
          label: this.$t('config.general.minRenderInstancesNight'),
          currentValue: this.currentConfig.MIN_RENDER_INSTANCES_NIGHT,
          initialValue: this.initialConfig.MIN_RENDER_INSTANCES_NIGHT,
        },
        {
          label: this.$t('config.general.maxRenderInstancesNight'),
          currentValue: this.currentConfig.MAX_RENDER_INSTANCES_NIGHT,
          initialValue: this.initialConfig.MAX_RENDER_INSTANCES_NIGHT,
        },
      ];
      const lines: Array<string> = [];
      for (const change of changes) {
        const line = this.getChangeText(change);
        if (line) {
          lines.push(line);
        }
      }
      this.changesText = lines.join('</br>');
    },
    handleClickSave() {
      this.updateChangesText();
      this.isConfirmSaveModalShown = true;
    },
    async saveConfig() {
      this.closeConfirmSaveModal();
      await this.$store.dispatch('renderConfig/saveConfig');
      toast.$emit('showToast', {
        type: 'success',
        message: this.$t('config.general.saveSuccessfully'),
      });
    },
  },
});
