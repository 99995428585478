<template>
  <v-lazy :min-height="200">
    <div class="tile-wrapper" :class="{ selected: selected }">
      <div class="tile-container" @contextmenu.prevent="openOptionsBox">
        <button class="template-button" @mouseover="onHover" @mouseleave="offHover" @click="onClickTemplateImage(data)">
          <img
            :src="data.thumbSignedUrl"
            :class="{ onhover: hover, offhover: !hover }"
            class="thumbnail"
            alt="Preview"
          />
        </button>

        <div class="info-container">
          <TileInfo :data="data" :type="type" />
          <EyeIcon
            v-if="view === 'wizard' && type === 'templates'"
            class="bottom-icon cursor-pointer"
            @click="eyeClicked(data)"
          />
        </div>
        <div v-if="view === 'overview' && type === 'templates'" class="options-container" @click="toggleOptionsBox">
          <div class="options-icon-container">
            <OptionsIcon class="options-icon" />
          </div>
        </div>
      </div>
      <Transition>
        <OptionsBox v-if="isOptionsBoxOpen" v-click-outside="vClickConfig">
          <div @click="onEditClicked">
            <EditIcon />
            <div>{{ $t('general.buttons.edit') }}</div>
          </div>
          <div @click="onImportLayersClicked">
            <LayersIcon />
            <div>{{ $t('contentWizard.layers.importShort') }}</div>
          </div>
          <div @click="onExportLayersClicked">
            <ShareIcon />
            <div>{{ $t('contentWizard.layers.exportShort') }}</div>
          </div>
          <div @click="onTrashClicked">
            <DeleteIcon />
            <div>{{ $t('general.buttons.delete') }}</div>
          </div>
        </OptionsBox>
      </Transition>
    </div>
  </v-lazy>
</template>

<script>
import EyeIcon from '@/assets/images/eye.svg';
import OptionsIcon from '@/assets/images/options.svg';
import TileInfo from '@/components/shared/tiles/TileInfo.vue';
import OptionsBox from '@/components/campaigns/OptionsBox.vue';
import EditIcon from '@/assets/images/edit_FILL0_wght200_GRAD0_opsz24.svg';
import LayersIcon from '@/assets/images/layers_FILL0_wght200_GRAD0_opsz24.svg';
import ShareIcon from '@/assets/images/ios_share_FILL0_wght200_GRAD0_opsz24.svg';
import DeleteIcon from '@/assets/images/delete_FILL0_wght200_GRAD0_opsz24.svg';
import { helperMixin } from '@/helpers/helpers';

export default {
  name: 'GridTile',
  components: {
    OptionsBox,
    TileInfo,
    EyeIcon,
    OptionsIcon,
    EditIcon,
    ShareIcon,
    DeleteIcon,
    LayersIcon,
  },
  mixins: [helperMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    view: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
      isOptionsBoxOpen: false,
      vClickConfig: {
        handler: this.closeOptionsBox,
        events: ['contextmenu', 'click'],
      },
    };
  },
  methods: {
    onHover() {
      this.hover = true;
    },
    offHover() {
      this.hover = false;
    },
    toggleOptionsBox() {
      this.isOptionsBoxOpen = !this.isOptionsBoxOpen;
    },
    openOptionsBox() {
      this.isOptionsBoxOpen = true;
    },
    closeOptionsBox() {
      this.isOptionsBoxOpen = false;
    },
    onTrashClicked() {
      this.$emit('trashClicked', this.data._id);
    },
    onClickTemplateImage(template) {
      if (this.view === 'overview') {
        this.$emit('editClicked', this.data._id);
      } else {
        this.$emit('templateSelected', template);
      }
    },
    eyeClicked(template) {
      this.$emit('previewClick', template);
    },
    onEditClicked() {
      this.$emit('editClicked', this.data._id);
    },
    onImportLayersClicked() {
      this.$emit('importLayersClicked', this.data._id);
    },
    onExportLayersClicked() {
      this.$emit('exportLayersClicked', this.data._id);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.tile-wrapper {
  position: relative;
  &.selected {
    outline: rem(2px) solid $cch-buttons-blue;
  }
}
.tile-container {
  @apply .text-left flex flex-col;
}
.template-button {
  @apply .h-48;
  flex-shrink: 0;
  width: 100%;
}
.options-container {
  position: absolute;
  right: 0.5rem;
  float: right;
  width: 20px;
  height: 20px;
  bottom: 0.5rem;
  justify-content: center;
  align-content: center;
  display: flex;
  .options-icon-container {
    cursor: pointer;
    border-radius: 10px;
    width: 1rem;
    height: 1rem;
    background-color: #ffffff;
    .options-icon {
      position: relative;
      height: 15px;
      width: 3px;
      object-fit: contain;
      border-radius: 10px;
      left: 0.4rem;
    }
  }
}
.thumbnail {
  @apply .w-full .object-cover .bg-white;
  height: 100%;
}

.offhover {
  @apply .opacity-100;
}

.onhover {
  @apply .opacity-50;
}

.info-container {
  height: 100%;
  @apply .bg-white .w-full .relative;
  .bottom-icon {
    position: absolute;
    right: 1rem;
    bottom: 1.81rem;
  }
}

button:focus {
  outline: none;
}

.options-box {
  @apply absolute right-0;
}
</style>
