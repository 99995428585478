<template>
  <div>
    <TabComponent
      :tabs="tabs"
      :show="true"
      @closeRequested="startCancelFlow"
      @closeTab="closeWizard"
      @lastItem="finished"
    />
    <DiscardModal
      v-if="isCancelModalOpen"
      message="contentWizard.general.cancelMessage"
      left-button-text="contentWizard.buttons.discardChanges"
      right-button-text="contentWizard.buttons.keepEditing"
      :is-discard-modal-open="isCancelModalOpen"
      :header-hidden="true"
      @clickLeftButton="closeWizard"
      @clickRightButton="closeCancelModal"
      @closeModal="closeCancelModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TabComponent from '@/components/shared/tabComponent/TabComponent.vue';
import TemplateBaseDataCreation from '@/components/templates/templateCreation/baseData/TemplateBaseDataCreation.vue';
import TemplateFormatsCreation from '@/components/templates/templateCreation/formats/TemplateFormatsCreation.vue';
import TemplateLayersCreation from '@/components/templates/templateCreation/layers/TemplateLayersCreation.vue';
import TemplateAssetsCreation from '@/components/templates/templateCreation/assets/TemplateAssetsCreation.vue';
import DiscardModal from '@/components/shared/DiscardModal.vue';
import { toast } from '@/services/toast';

export default {
  components: {
    TabComponent,
    DiscardModal,
  },
  beforeRouteLeave(to, from, next) {
    if (this.shouldCheckRouteLeave && this.hasUnsavedData) {
      this.nextObj = next;
      this.openCancelModal();
    } else {
      next();
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nextObj: null,
      isCancelModalOpen: false,
      shouldCheckRouteLeave: true,
    };
  },

  computed: {
    ...mapGetters('templateCreation', {
      isLoading: 'getIsLoading',
      isEditing: 'getIsEditing',
      hasUnsavedData: 'getHasUnsavedData',
    }),
    ...mapState('templateCreation', ['isSaving', 'templateUploadProgress']),
    tabs() {
      return [
        {
          component: () => {
            return TemplateBaseDataCreation;
          },
          tabName: 'templates.creation.basedata',
          tabIcon: 'basedata',
          previousButtonText: 'contentWizard.footer.previous',
          nextButtonText: 'contentWizard.footer.next',
          beforeNextFunction: 'save',
        },
        {
          component: () => {
            return TemplateFormatsCreation;
          },
          tabName: 'templates.creation.formats',
          tabIcon: 'analytics',
          previousButtonText: 'contentWizard.footer.previous',
          nextButtonText: 'contentWizard.footer.next',
          beforeNextFunction: 'save',
          beforePreviousFunction: 'save',
        },
        {
          component: () => {
            return TemplateLayersCreation;
          },
          tabName: 'templates.creation.layers',
          tabIcon: 'formats',
          previousButtonText: 'contentWizard.footer.previous',
          nextButtonText: 'contentWizard.footer.next',
          beforeNextFunction: 'save',
        },
        {
          component: () => {
            return TemplateAssetsCreation;
          },
          tabName: 'templates.creation.assets',
          tabIcon: 'content',
          previousButtonText: 'contentWizard.footer.previous',
          nextButtonText: this.finalButtonText,
          nextIsSaving: this.isSaving,
          uploadProgress: this.templateUploadProgress,
          beforeNextFunction: 'save',
        },
      ];
    },
    finalButtonText() {
      return this.isEditing ? 'templates.creation.updateTemplate' : 'templates.creation.createTemplate';
    },
  },
  mounted() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  methods: {
    preventNav(event) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(this.$t('contentWizard.general.cancelMessage'))) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    },
    startCancelFlow() {
      if (this.shouldCheckRouteLeave && this.hasUnsavedData) {
        this.openCancelModal();
      } else {
        this.closeWizard();
      }
    },
    closeWizard() {
      this.$store.dispatch('templateCreation/reset');
      if (this.nextObj) {
        this.nextObj();
      } else {
        this.shouldCheckRouteLeave = false;
        this.$router.push({ name: 'templates' });
      }
    },
    async finished() {
      try {
        this.shouldCheckRouteLeave = true;

        if (this.isEditing) {
          await this.$store.dispatch('templateCreation/update');
        } else {
          await this.$store.dispatch('templateCreation/create');
        }

        toast.$emit('showToast', {
          type: 'success',
          groupName: 'template-creation',
          message: this.$t('templates.creation.templateSaved'),
        });

        this.shouldCheckRouteLeave = false;
        await this.$router.push({ name: 'templates' });
        await this.$store.dispatch('templateCreation/reset');
      } catch (e) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'template-creation',
          message: e.message || e.data.message.message || e.data.message,
        });
      }
    },
    openCancelModal() {
      this.isCancelModalOpen = true;
    },
    closeCancelModal() {
      this.isCancelModalOpen = false;
      if (this.nextObj) {
        this.nextObj(false);
        this.nextObj = null;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
