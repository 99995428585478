<template>
  <v-app>
    <div id="app" class="bg-blue-lightest" :class="{ modal: isModalOpen }" data-app>
      <component :is="getAppHeader" v-if="isHeaderVisible" class="sticky-header" />
      <div class="header-container">
        <div class="header-group">
          <BurgerIcon v-if="isNavigationVisible" class="hamburger-menu" @click="onHamburgerClicked" />
          <JlsIcon class="jls-icon" @click="goToHome" />
        </div>
        <NotificationButton
          v-if="isLoggedIn"
          :unread-count="unreadNotificationCount"
          @click.native="showNotificationPanel"
        />
      </div>
      <AppMenu v-if="isLoggedIn" ref="appMenu" />
      <router-view @modalOpen="modalOpen" />
      <ToastViewer />
      <ModalMessageViewer />
      <NotificationPanel
        v-if="isLoggedIn"
        :visible="isNotificationPanelOpen"
        :unread-items="unreadNotifications"
        :read-items="readNotifications"
        @close="hideNotificationPanel"
        @changeTab="handleChangeTab"
      />
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import AppHeader from '@/components/shared/AppHeader.vue';
import ModalMessageViewer from '@/components/shared/fixed/ModalMessageViewer.vue';
import ToastViewer from '@/components/shared/fixed/ToastViewer.vue';
import AppMenu from '@/components/shared/AppMenu.vue';
import NotificationButton from '@/components/shared/notifications/NotificationButton.vue';
import NotificationPanel from '@/components/shared/notifications/NotificationPanel.vue';
import BurgerIcon from '@/assets/images/burger.svg';
import JlsIcon from '@/assets/images/jls_logo_black.svg';

export default {
  components: {
    AppMenu,
    ModalMessageViewer,
    ToastViewer,
    AppHeader,
    BurgerIcon,
    JlsIcon,
    NotificationButton,
    NotificationPanel,
  },
  data() {
    return {
      isModalOpen: false,
      isNotificationPanelOpen: false,
      loadNotificationsInterval: null,
    };
  },
  computed: {
    isHeaderVisible() {
      return !(this.$route.meta.header && this.$route.meta.header.hidden) && this.isLoggedIn;
    },
    getAppHeader() {
      return this.$route.meta.header && this.$route.meta.header.component
        ? this.$route.meta.header.component
        : 'app-header';
    },
    isNavigationVisible() {
      return !(this.$route.meta.navigation && this.$route.meta.navigation.hidden) && this.isLoggedIn;
    },
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      unreadNotifications: 'notifications/getUnreadNotifications',
      readNotifications: 'notifications/getReadNotifications',
      previewObjects: 'contentCreation/getPreviewObjects',
    }),
    previews() {
      if (!this.previewObjects || this.previewObjects.length === 0) {
        return [];
      }
      const previewObject = this.previewObjects.find((object) => object.language === this.activeLanguage);
      if (!previewObject) {
        return [];
      }
      return previewObject.previews;
    },
    unreadNotificationCount() {
      return this.unreadNotifications.length;
    },
  },
  watch: {
    async isLoggedIn() {
      if (this.isLoggedIn) {
        await this.loadNotificationsIntervally();
      } else {
        this.stopLoadingNotifications();
      }
    },
  },
  async mounted() {
    if (this.isLoggedIn) {
      await this.$store.dispatch('contentCreation/initializePreviewObjects');
      await this.loadNotificationsIntervally();
    }
  },
  methods: {
    async loadNotificationsIntervally() {
      await this.$store.dispatch('notifications/loadUnreadNotifications');
      await this.$store.dispatch('notifications/loadReadNotifications');
      if (!this.loadNotificationsInterval) {
        this.loadNotificationsInterval = setInterval(async () => {
          await this.$store.dispatch('notifications/loadUnreadNotifications');
        }, 10000);
      }
    },
    stopLoadingNotifications() {
      if (this.loadNotificationsInterval) {
        clearInterval(this.loadNotificationsInterval);
        this.loadNotificationsInterval = null;
      }
    },
    modalOpen(bool) {
      this.isModalOpen = bool;
    },
    onHamburgerClicked() {
      this.$refs.appMenu.openMenu();
    },
    goToHome() {
      this.$router.push('/');
    },
    showNotificationPanel() {
      this.isNotificationPanelOpen = true;
    },
    hideNotificationPanel() {
      this.isNotificationPanelOpen = false;
    },
    handleChangeTab(tab) {
      if (this.isLoggedIn) {
        if (tab === 0) {
          this.$store.dispatch('notifications/loadUnreadNotifications');
        }
        if (tab === 1) {
          this.$store.dispatch('notifications/loadReadNotifications');
        }
      }
    },
  },
};
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>

<style lang="scss">
@import '~@/styles/global.scss';
#app {
  min-height: 100vh;
}
.modal {
  overflow-y: hidden;
  height: 100vh;
}
</style>
<style scoped lang="scss">
@import '@/styles/global.scss';
.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: -4.4rem;
  z-index: 50;
}
.header-container {
  @apply .fixed;
  top: 2.75rem;
  padding: 0 2rem;
  z-index: 100;
  .header-group {
    display: flex;
    align-items: center;
  }
  .hamburger-menu {
    cursor: pointer;
    margin-right: 1rem;
  }
  .jls-icon {
    cursor: pointer;
    width: rem(36px);
    height: rem(36px);
  }
}
</style>
