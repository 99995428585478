<template>
  <div class="form-url-input-field">
    <div class="form-url-input-field__container" :class="{ 'form-url-input-field__container--invalid': invalid }">
      <input
        :disabled="disabled"
        :value="value"
        placeholder="https://..."
        :type="type"
        :accept="accept"
        :class="{ disabled: disabled }"
        @input="$emit('input', $event.target.value)"
        @blur="testURL"
      />
      <label class="form-url-input-field__label">{{ $t(placeholder) }}</label>
      <div v-if="layer !== null && max" class="form-url-input-field__info">
        <SmallSpinner v-if="isCheckingURL" class="form-url-input-field__spinner" />
        <CharCounter
          :current-input="value"
          :max-count="max"
          @maxValueExceeded="exceededMaxValue()"
          @maxValueObeyed="obeyedMaxValue()"
        />
      </div>
    </div>
    <div v-if="invalid" class="form-url-input-field__error-wrapper">
      <span class="form-url-input-field__error-message">{{ $t('contentWizard.contentSpecification.' + error) }}</span>
    </div>
  </div>
</template>

<script>
import CharCounter from '@/components/shared/formFields/CharCounter.vue';
import SmallSpinner from '@/components/shared/SmallSpinner.vue';

export default {
  components: {
    CharCounter,
    SmallSpinner,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    accept: {
      type: Array,
      required: false,
      default: () => [],
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: true,
    },
    layer: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      invalid: false,
      isCheckingURL: false,
      error: 'maxCharError',
    };
  },
  methods: {
    parseMaxCount(layer) {
      return parseInt(layer.maxValue, 10);
    },
    exceededMaxValue() {
      this.invalid = true;
      this.error = 'maxCharError';
    },
    obeyedMaxValue() {
      this.invalid = false;
    },
    testURL() {
      if (!this.value) {
        return;
      }
      this.isCheckingURL = true;
      this.$store
        .dispatch('contentCreation/checkURL', this.value)
        .then(() => {
          this.invalid = false;
        })
        .catch(() => {
          this.invalid = true;
          this.error = 'invalidURL';
        })
        .finally(() => {
          this.isCheckingURL = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.form-url-input-field {
  position: relative;
  height: fit-content;

  &__container {
    @apply .font-sans .font-semibold .text-blue-light .w-full .relative .bg-white;
    height: rem(51px);
    overflow: hidden;

    input:focus {
      outline: none;
      @apply .border-blue-lighter;
    }
    input {
      @apply .block .absolute;
      bottom: 0;
      background-color: #ffffff;
      color: $cch-dark-grey;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      width: calc(100% - 60px);
    }
    input::placeholder {
      opacity: 0.5;
    }

    .disabled {
      cursor: not-allowed;
    }

    &--invalid {
      outline: #ef3c4c rem(1px) solid;
    }
  }

  &__label {
    @apply .absolute;
    top: 0.5rem;
    left: 1rem;
    font-size: 0.75rem;
    color: rgba(51, 51, 51, 0.5);
    z-index: 0;
    pointer-events: none;
    transition: transform 0.3s ease;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 0.25rem;
    top: rem(25px);
  }

  &__spinner {
    position: relative;
    bottom: 12px;
    width: 25px;
    height: 25px;
    right: 5px;
  }

  &__error-wrapper & {
    position: absolute;
    bottom: -1.25rem;

    &__error-message {
      font-family: $cch-font-family;
      font-size: 0.75rem;
      font-weight: 500;
      color: #ef3c4c;
    }
  }
}
</style>
