<template>
  <div class="container-asset">
    <FormInputField
      v-model="layerName"
      :placeholder="$t('templates.creation.layerName')"
      :disabled="!asset.isNew"
      :title="asset.isNew ? '' : $t('templates.creation.noChangeAllowed')"
      class="input-field"
      @input="updateData"
    />
    <FormSelectionField
      v-model="type"
      :placeholder="$t('templates.creation.layerType')"
      class="input-field file-type-selection"
      :list="types"
      :selected-item="type"
      @input="updateData"
    />
    <FileInputFormField
      class="input-field"
      style="width: 10%"
      :value="file"
      :type-description="$t('templates.creation.file')"
      :accept="fileAcceptTypes"
      :placeholder="assetFilePlaceholder"
      @input="handleFileUpload($event)"
    />
    <v-tooltip bottom open-delay="500">
      <template #activator="{ on, attrs }">
        <div class="remove-button">
          <TrashIcon class="trash-icon" viewBox="0 0 16 16" v-bind="attrs" @click="removeItem()" v-on="on" />
        </div>
      </template>
      <span>{{ $t('content.tooltip.delete') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import FormSelectionField from '@/components/shared/formFields/FormSelectionField.vue';
import TrashIcon from '@/assets/images/trash.svg';
import FileInputFormField from '@/components/shared/formFields/FileInputFormField.vue';

export default {
  components: {
    FileInputFormField,
    FormInputField,
    FormSelectionField,
    TrashIcon,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      type: null,
      layerName: null,
      file: null,
    };
  },
  computed: {
    types() {
      return ['image', 'video', 'audio'];
    },
    fileAcceptTypes() {
      return ['video/*', 'image/*', 'audio/*', 'application/postscript'];
    },
    assetFilePlaceholder() {
      if (!this.asset.isNew) {
        return this.$t('templates.creation.noChanges');
      }

      return this.$t('templates.creation.noFileSelected');
    },
  },
  mounted() {
    this.type = this.asset.type;
    this.layerName = this.asset.layerName;
    this.file = this.asset.file;
  },
  methods: {
    removeItem() {
      this.$emit('removeClick');
    },
    updateData() {
      this.$emit('input', {
        id: this.asset.id,
        type: this.type,
        layerName: this.layerName,
        isNew: this.asset.isNew,
        file: this.file,
      });
    },
    handleFileUpload(file) {
      this.file = file;
      this.updateData();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';

.container-asset {
  display: flex;
  width: 100%;
  margin-bottom: rem(30px);
  font-family: $cch-font-family;
  font-size: rem(36px);
  font-weight: 800;
  color: #c5cde2;
  justify-content: center;
  align-content: stretch;
}
.remove-button {
  background-color: white;
  height: rem(51px);
  flex-grow: 1;
  position: relative;
  @apply cursor-pointer;
  .trash-icon {
    position: absolute;
    width: rem(26px);
    height: rem(25px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.input-field {
  margin-bottom: rem(30px);
  flex-grow: 3.5;
  border-right: 2px solid $cch-light-gray;
  font-size: 16px;
}
.file-type-selection {
  width: 1px;
}
</style>
