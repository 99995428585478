import Vue from 'vue';
import VueSocketIO from 'vue-socket.io';
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import Fetch from 'i18next-fetch-backend';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import VueClipboard from 'vue-clipboard2';
import getLogger from '@/services/logger';
import store from '@/store';
import router from '@/router';
import { i18nextOptions } from './constants/i18next/i18nextOptions';
import App from './App.vue';
import vuetify from './plugins/vuetify';

Vue.use(VueClipboard);

Vue.use(
  new VueSocketIO({
    connection: process.env.VUE_APP_RENDER_WS,
    debug: true,
  }),
);

Vue.use(VueI18Next);

Vue.prototype.$toast = new Vue();
Vue.prototype.$modal = new Vue();

const translationPromise = i18next.use(I18nextBrowserLanguageDetector).use(Fetch).init(i18nextOptions);

const i18n = new VueI18Next(i18next);

const LOG = getLogger('main');

// --- Dev Tools
Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV !== 'production';
Vue.config.devtools = process.env.NODE_ENV !== 'production';

// for testing
if ((window as $TSFixMe).Cypress) {
  // eslint-disable-next-line no-underscore-dangle
  (window as $TSFixMe).__store__ = store;
}

const init = () => {
  LOG.info('Initializing dependencies');
  const promises = [];
  promises.push(translationPromise);

  Promise.all(promises).finally(() => {
    Vue.component('DatePicker', DatePicker);
    initVue();
  });
};

function initVue() {
  LOG.info('Bootstrapping App');
  store.dispatch('auth/tryLoginWithToken').then(() => {
    new Vue({
      router,
      store,
      i18n,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  });
}

init();
