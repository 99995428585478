import { render, staticRenderFns } from "./FormatRow.vue?vue&type=template&id=c21e9b9c&scoped=true&"
import script from "./FormatRow.vue?vue&type=script&lang=js&"
export * from "./FormatRow.vue?vue&type=script&lang=js&"
import style0 from "./FormatRow.vue?vue&type=style&index=0&id=c21e9b9c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c21e9b9c",
  null
  
)

export default component.exports