// eslint-disable-next-line import/no-cycle
import HTTP from '@/services/http';
import getLogger from '@/services/logger';

const LOG = getLogger('resource/data');
const urlPrefix = `api/v${process.env.VUE_APP_API_VERSION}/data`;

export async function getProductData(organizationId: $TSFixMe, productId: $TSFixMe) {
  LOG.log(`Attempting to get product data for product id: ${productId}`);
  return HTTP.get(`${urlPrefix}/${organizationId}/product/${productId}`)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function getColumnNames(organizationId: $TSFixMe) {
  LOG.log(`Attempting to get product schema via url: ${organizationId}`);
  return HTTP.get(`${urlPrefix}/${organizationId}/schema`)
    .then((res) => res)
    .catch((err) => {
      LOG.error('Failed to get columnNames', err);
      return false;
    });
}
