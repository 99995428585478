import { orderBy as _orderBy } from 'lodash-es';
import Fuse from 'fuse.js';
import getLogger from '@/services/logger';

// eslint-disable-next-line import/no-cycle
import {
  getContent,
  deleteContent,
  deleteRender,
  getHtml5PreviewUrl,
  getContentsByCampaignId,
} from '@/services/api/content.resource';
import {
  areFiltersApplied,
  filterObjectArrayByDateRange,
  getIndexOfKeyValueObjectInArray,
  getUniqueValues,
  isDateRangeFilterApplied,
  isSearchApplied,
  isSortApplied,
} from '@/services/store/shared';
import { DEFAULT_CONTENT_TAKE_SIZE } from '@/constants/content/content';
import { uploadCms } from '@/services/api/cms.resource';

const LOG = getLogger('store/content');

const state = {
  /**
   * This content is for preview purpose only. The actual one will have a different format.
   * (and thus require changes in ContentListItem component)
   */
  content: [],
  contents: [],
  searchTerm: null,
  languages: ['german', 'french', 'italian', 'english'],
  keyValuePairFilterArray: [],
  dateRangeFilter: null,
  searchOptions: {
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name', 'templateName', 'renders.type', 'renders.format', 'renders.language', 'createdAt'],
  },
  isMediaRendering: false,
  isLoading: false,
  contentsTake: DEFAULT_CONTENT_TAKE_SIZE,
  hasCmsUpload: false,
  isUploading: false,
};

const getters = {
  getFilteredContents: (state: $TSFixMe, getters: $TSFixMe, rootState: $TSFixMe, rootGetters: $TSFixMe) => {
    let result = _orderBy(state.contents, ['updatedAt'], ['desc']);
    if (areFiltersApplied(state.keyValuePairFilterArray)) {
      state.keyValuePairFilterArray.forEach((filter: $TSFixMe) => {
        let threshold = 0.2;
        if (filter.key === 'renders.format' || filter.key === 'templateName') {
          threshold = -1;
        }
        const fuse = new Fuse(result, {
          keys: [filter.key],
          threshold,
        });
        result = [...fuse.search(filter.value)];
      });
    }

    if (isDateRangeFilterApplied(state.dateRangeFilter)) {
      LOG.log('Applying date range filter');
      result = filterObjectArrayByDateRange(result, state.dateRangeFilter, 'createdAt');
    }

    if (isSortApplied(rootGetters.getSortedBy.key)) {
      LOG.log('Applying sort');
      result = _orderBy(result, [rootGetters.getSortedBy.key], rootGetters.getSortedBy.reverse ? ['desc'] : ['asc']);
    }

    if (isSearchApplied(state.searchTerm)) {
      LOG.log('Applying search filter');
      const fuse = new Fuse(result, state.searchOptions);
      result = fuse.search(state.searchTerm);
    }

    return result;
  },

  getSearchTerm: (state: $TSFixMe) => state.searchTerm,

  getLanguages: (state: $TSFixMe) => state.languages,

  getUniqueValuesOfKey: (state: $TSFixMe) => (key: $TSFixMe) => {
    if (state.contents) {
      return getUniqueValues(state.contents, key).sort();
    }

    return [];
  },

  getIsMediaRendering: (state: $TSFixMe) => state.isMediaRendering,

  getFormatsFromContents: (state: $TSFixMe) => {
    if (state.contents) {
      const formats = new Set<string>();
      state.contents.map((content: $TSFixMe) => {
        for (const render of content.renders) {
          formats.add(render.format);
        }
      });
      return Array.from(formats.values());
    }
    return [];
  },

  getContents: (state: $TSFixMe) => state.contents,

  getIsLoading: (state: $TSFixMe) => state.isLoading,

  getContentsTake: (state: $TSFixMe) => state.contentsTake,

  getHasCmsUpload: (state: $TSFixMe) => state.hasCmsUpload,

  getIsUploading: (state: $TSFixMe) => state.isUploading,
};

const actions = {
  async getContentItemHtml5PreviewUrl(_: $TSFixMe, id: $TSFixMe) {
    return getHtml5PreviewUrl(id);
  },
  async loadContent({ commit }: $TSFixMe) {
    commit('setContent', await getContent());
  },
  async deleteContent(_: $TSFixMe, contentID: $TSFixMe) {
    await deleteContent(contentID);
  },
  async deleteRender(_: $TSFixMe, renderID: $TSFixMe) {
    await deleteRender(renderID);
  },
  addExclusiveFilter({ commit }: $TSFixMe, payload: $TSFixMe) {
    commit('removeFiltersFromSameCategory', payload);
    commit('addExclusiveFilter', payload);
  },

  addDateRangeFilter({ commit }: $TSFixMe, payload: $TSFixMe) {
    commit('addDateRangeFilter', payload);
  },

  clearDateRangeFilter({ commit }: $TSFixMe) {
    commit('addDateRangeFilter', null);
  },

  addInclusiveFilter({ commit }: $TSFixMe, payload: $TSFixMe) {
    commit('addInclusiveFilter', payload);
  },

  removeFilter({ commit }: $TSFixMe, payload: $TSFixMe) {
    commit('removeFilter', payload);
  },

  resetFilter({ commit }: $TSFixMe, category: $TSFixMe) {
    commit('resetFilter', category);
  },

  resetFilters({ commit }: $TSFixMe) {
    commit('resetFilters');
  },

  addSearchTerm({ commit }: $TSFixMe, term: $TSFixMe) {
    commit('addSearchTerm', term);
  },

  removeSearchTerm({ commit }: $TSFixMe) {
    commit('removeSearchTerm');
  },
  async loadContents(
    { commit, state }: $TSFixMe,
    { campaignId, appended, take }: { campaignId: string; appended: boolean; take?: number },
  ) {
    try {
      commit('setIsLoading', true);
      const currentTakeSize = take ? take : state.contentsTake;
      if (appended) {
        const result = await getContentsByCampaignId(campaignId, DEFAULT_CONTENT_TAKE_SIZE, currentTakeSize);
        const newContents = [...state.contents, ...result.contents];
        const newContentsTake = currentTakeSize + DEFAULT_CONTENT_TAKE_SIZE;
        commit('setContents', newContents);
        commit('setContentsTake', newContentsTake);
        commit('setHasCmsUpload', result.hasCmsUpload);
      } else {
        const result = await getContentsByCampaignId(campaignId, currentTakeSize, 0);
        commit('setContents', result.contents);
        commit('setContentsTake', currentTakeSize);
        commit('setHasCmsUpload', result.hasCmsUpload);
      }
    } finally {
      commit('setIsLoading', false);
    }
  },
  removeContents({ commit }: $TSFixMe) {
    commit('setContents', []);
  },
  setMediaRenderStatus({ commit }: $TSFixMe, status: $TSFixMe) {
    commit('setMediaRenderStatus', status);
  },
  setIsLoading({ commit }: $TSFixMe, value: boolean) {
    commit('setIsLoading', value);
  },
  async uploadContents({ commit }: $TSFixMe, renderIds: string[]) {
    try {
      commit('setIsUploading', true);
      await uploadCms(renderIds);
    } finally {
      commit('setIsUploading', false);
    }
  },
};

const mutations = {
  setContents(state: $TSFixMe, contents: $TSFixMe) {
    state.contents = contents;
  },
  addContent(state: $TSFixMe, payload: $TSFixMe) {
    state.content.push(payload);
  },
  setContent(state: $TSFixMe, content: $TSFixMe) {
    state.content = content;
  },
  removeFiltersFromSameCategory(state: $TSFixMe, payload: $TSFixMe) {
    state.keyValuePairFilterArray = state.keyValuePairFilterArray.filter(
      (filter: $TSFixMe) => filter.key !== payload.key,
    );
  },

  addExclusiveFilter(state: $TSFixMe, payload: $TSFixMe) {
    state.keyValuePairFilterArray.push(payload);
  },

  addDateRangeFilter(state: $TSFixMe, payload: $TSFixMe) {
    state.dateRangeFilter = payload;
  },

  addInclusiveFilter(state: $TSFixMe, payload: $TSFixMe) {
    state.keyValuePairFilterArray.push(payload);
  },

  removeFilter(state: $TSFixMe, payload: $TSFixMe) {
    const index = getIndexOfKeyValueObjectInArray(payload, state.keyValuePairFilterArray);
    if (index !== -1) {
      state.keyValuePairFilterArray.splice(index, 1);
    }
  },

  resetFilter(state: $TSFixMe, category: $TSFixMe) {
    state.keyValuePairFilterArray = state.keyValuePairFilterArray.filter((filter: $TSFixMe) => filter.key !== category);
  },

  resetFilters(state: $TSFixMe) {
    state.keyValuePairFilterArray = [];
    state.dateRangeFilter = null;
    state.searchTerm = null;
  },

  addSearchTerm(state: $TSFixMe, term: $TSFixMe) {
    state.searchTerm = term;
  },

  removeSearchTerm(state: $TSFixMe) {
    state.searchTerm = null;
  },
  setMediaRenderStatus(state: $TSFixMe, status: $TSFixMe) {
    state.isMediaRendering = status;
  },
  setIsLoading(state: $TSFixMe, value: boolean) {
    state.isLoading = value;
  },
  setContentsTake(state: $TSFixMe, take: number) {
    state.contentsTake = take;
  },
  setHasCmsUpload(state: $TSFixMe, value: boolean) {
    state.hasCmsUpload = value;
  },

  setIsUploading(state: $TSFixMe, value: boolean) {
    state.isUploading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
