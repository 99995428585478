<template>
  <div id="datepicker-trigger-popup" class="datepicker-trigger">
    <DatePicker
      v-if="show"
      v-model="range"
      class="date-picker"
      is-range
      :model-config="modelConfig"
      @input="onDateRangeChange"
    >
      <template #footer>
        <AppButton type="text" size="small-auto" @click="onReset">{{ $t('general.buttons.reset') }}</AppButton>
      </template>
    </DatePicker>
  </div>
</template>

<script>
import AppButton from './buttons/AppButton.vue';

export default {
  name: 'DateFilterPopup',
  components: { AppButton },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      range: {
        start: undefined,
        end: undefined,
      },
      modelConfig: {
        start: {
          timeAdjust: '00:00:00',
        },
        end: {
          timeAdjust: '23:59:59',
        },
      },
    };
  },
  methods: {
    onReset() {
      this.range.start = undefined;
      this.range.end = undefined;
      this.$forceUpdate();
      this.$emit('dateFilterCleared');
    },
    onDateRangeChange(range) {
      this.$emit('dateFilterClicked', {
        startDate: range.start,
        endDate: range.end,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.module.scss';

.date-picker {
  @apply .absolute;
  border: none;
  box-shadow: 0 0 5px 0 rgba(71, 67, 102, 0.19);
  filter: drop-shadow(0 0 5px rgba(71, 67, 102, 0.19));
  top: 11rem !important;
  border-radius: 0;
  padding: 16px;
}

.date-picker::after {
  content: '';
  position: absolute;
  left: 20%;
  bottom: 99%;
  border-style: solid;
  margin-left: -5px;
  border-width: 1.5rem;
  border-color: transparent transparent white transparent;
  pointer-events: none;
  z-index: 1000;
}
.datepicker-reset {
  padding-left: 1rem;
  padding-bottom: 1rem;
  color: $cch-dark-grey;
}
</style>
