import { render, staticRenderFns } from "./FormURLInputField.vue?vue&type=template&id=080f8fc0&scoped=true&"
import script from "./FormURLInputField.vue?vue&type=script&lang=js&"
export * from "./FormURLInputField.vue?vue&type=script&lang=js&"
import style0 from "./FormURLInputField.vue?vue&type=style&index=0&id=080f8fc0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "080f8fc0",
  null
  
)

export default component.exports