<template>
  <header class="header bg-white pt-11 pb-8 px-8">
    <div class="fixed flex items-center left-section" />

    <!-- has to be v-if and not v-isShown for the v-click-outside directive to properly work-->
    <transition name="slide">
      <SideBar v-if="showSideBar" v-click-outside="onSelectionMade" @selectionMade="onSelectionMade" />
    </transition>

    <div class="max-w-6xl ml-24 flex items-center h-8 cch-title">
      <h1 class="text-lg font-extrabold font-sans">content creation hub</h1>
    </div>

    <slot name="options-bar" />
  </header>
</template>

<script>
import SideBar from '@/components/shared/SideBar.vue';

export default {
  components: {
    SideBar,
  },
  data() {
    return {
      showSideBar: false,
      inputDebounce: null,

      clickOutsidePopupConfig: {
        handler: this.closeAnyPopup,
        middleware: this.middleware,
        events: ['click'],
        isActive: true,
      },
    };
  },
  methods: {
    onPopupToggle(category) {
      if (this.showPopup === category) {
        this.showPopup = null;
      } else {
        this.showPopup = category;
      }
    },
    closeAnyPopup() {
      this.showPopup = null;
    },
    middleware(event) {
      try {
        // add class ignore to any elements you want to exclude from closing popups
        return !event.target.className.includes('ignore');
      } catch (e) {
        return false;
      }
    },
    onBurgerClicked() {
      this.showSideBar = true;
    },
    onSelectionMade() {
      this.showSideBar = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.left-section {
  top: 3rem;
}
.cch-title {
  position: fixed;
  padding-top: 3.8rem;
  top: 0;
}
</style>
