<template>
  <div>
    <AppHeader class="sticky-header">
      <template #options-bar>
        <CampaignDetailHeader class="max-w-6xl" />
      </template>
    </AppHeader>
    <div class="overview">
      <router-view :key="$route.params.id" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CampaignDetailHeader from '@/components/campaignDetail/CampaignDetailHeader.vue';
import AppHeader from '@/components/shared/AppHeader.vue';
import { DURATION } from '@/constants/duration';
import { toast } from '@/services/toast';

export default {
  components: {
    CampaignDetailHeader,
    AppHeader,
  },
  computed: {
    ...mapGetters({
      mediaIsRendering: 'content/getIsMediaRendering',
      isUploading: 'content/getIsUploading',
    }),
  },
  watch: {
    mediaIsRendering(newValue) {
      if (newValue === true) {
        toast.$emit('showToast', {
          type: 'success',
          groupName: 'render-start-validation',
          message: this.$t(`content.general.contentRendering`),
          duration: DURATION.MEDIUM,
        });
      }
    },
    isUploading(newValue) {
      if (newValue === true) {
        toast.$emit('showToast', {
          type: 'success',
          groupName: 'content-upload',
          message: this.$t(`content.general.contentUploading`),
          duration: DURATION.MEDIUM,
        });
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch('content/setMediaRenderStatus', false);
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: -4.4rem;
  z-index: 50;
}
@media (max-width: 1800px) {
  .sticky-header {
    position: fixed;
    width: 100%;
    top: 0;
  }
  .overview {
    padding-top: rem(180px);
  }
}
</style>
