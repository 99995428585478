<template>
  <div class="header-wrapper">
    <div class="title-container">
      <BackIcon class="back-icon" @click="goBack" />
      <AppText type="title">{{ title }}</AppText>
    </div>
    <v-tooltip bottom :disabled="hasCreditsLeft" open-delay="500">
      <template #activator="{ on, attrs }">
        <AppButton v-bind="attrs" :disabled="!hasCreditsLeft" @click="openContentCreation" v-on="on"
          >+ New Content</AppButton
        >
      </template>
      <span>{{ $t('campaigns.general.noRenderLackCredits') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BackIcon from '@/assets/images/back.svg';
import AppText from '@/components/shared/AppText.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';

export default {
  components: {
    BackIcon,
    AppText,
    AppButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      hasCreditsLeft: 'organizations/getHasCreditsLeft',
    }),
  },
  methods: {
    goBack() {
      this.$emit('goBack');
      this.$router.push({ name: 'campaignOverview' });
    },
    openContentCreation() {
      this.$emit('open');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: rem(36px 0 24px 0);
  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .back-icon {
      margin-right: rem(24px);
      cursor: pointer;
    }
  }
}
</style>
