<template>
  <div ref="inputWrapper" class="app-file-input-wrapper">
    <div class="file-info">
      <label class="type-label">{{ typeDescription }}</label>
      <div v-if="value" class="file-display">{{ value.name }}</div>
      <div v-else class="file-display">{{ placeholder }}</div>
    </div>

    <v-tooltip bottom open-delay="500">
      <template #activator="{ on, attrs }">
        <label class="file-select" v-bind="attrs" v-on="on">
          <div class="select-button" :class="{ 'select-button-disabled': disabled }" :disabled="disabled">
            <SharingIcon />
            <input type="file" :accept="accept" :disabled="disabled" @change="onFileSelected" />
          </div>
        </label>
      </template>
      <span>{{ $t('content.tooltip.upload') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import SharingIcon from '@/assets/images/sharing.svg';
import { toast } from '@/services/toast';

export default {
  name: 'FileInputFormField',
  components: {
    SharingIcon,
  },
  props: {
    value: {
      type: File,
      required: false,
      default: null,
    },
    typeDescription: {
      type: String,
      required: true,
    },
    accept: {
      type: Array,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    disabled(newValue) {
      if (newValue) {
        this.clearEventListeners();
      } else {
        this.createEventListeners();
      }
    },
  },
  mounted() {
    if (!this.disabled) {
      this.createEventListeners();
    }
  },
  methods: {
    createEventListeners() {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        this.$refs.inputWrapper.addEventListener(eventName, (e) => {
          e.preventDefault();
          e.stopPropagation();
        });
      });
      ['dragenter', 'dragover'].forEach((eventName) => {
        this.$refs.inputWrapper.addEventListener(eventName, this.onDragEnter);
      });
      ['dragleave', 'drop'].forEach((eventName) => {
        this.$refs.inputWrapper.addEventListener(eventName, this.onDragLeave);
      });
      this.$refs.inputWrapper.addEventListener('drop', this.handleDrop);
    },
    clearEventListeners() {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        this.$refs.inputWrapper.removeEventListener(eventName, (e) => {
          e.preventDefault();
          e.stopPropagation();
        });
      });

      ['dragenter', 'dragover'].forEach((eventName) => {
        this.$refs.inputWrapper.removeEventListener(eventName, this.onDragEnter);
      });
      ['dragleave', 'drop'].forEach((eventName) => {
        this.$refs.inputWrapper.removeEventListener(eventName, this.onDragLeave);
      });
      this.$refs.inputWrapper.removeEventListener('drop', this.handleDrop);
    },
    onFileSelected(event) {
      this.$emit('input', event.target.files[0]);
    },
    handleDrop(event) {
      const files = [...event.dataTransfer.files];
      if (files.length !== 1) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'file-drag-and-drop',
          message: this.$t(`general.inputs.oneFileAllowed`),
        });
      } else {
        this.$emit('input', files[0]);
      }
    },
    onDragEnter() {
      this.$refs.inputWrapper.classList.add('highlight');
    },
    onDragLeave() {
      this.$refs.inputWrapper.classList.remove('highlight');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.app-file-input-wrapper {
  @apply .font-sans .font-semibold .text-blue-light .w-full .relative .bg-white;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  .file-info {
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    margin-right: 10px;
    .type-label {
      color: rgba(51, 51, 51, 0.5);
      font-size: 0.75rem;
    }
    .file-display {
      color: rgb(51, 51, 51);
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .file-select {
    text-align: center;
    color: black;
    width: 20px;
  }
}
.highlight {
  background-color: rgba($cch-dark-grey, 0.1);
}
.select-button {
  cursor: pointer;
}
.select-button-disabled {
  cursor: default;
  opacity: 50%;
}
.select-button > input[type='file'] {
  display: none;
}
</style>
