<template>
  <div v-if="getFilteredContents" class="campaign-detail-wrapper max-w-6xl xl:mx-auto">
    <AppLoading :loading="isLoading" />
    <template v-if="!isLoading">
      <CampaignDetailHeaderRow :title="campaignName" @open="openContentCreationWizard" @goBack="resetCampaign" />
      <div v-if="hasWaitingRender && jobsInQueue > 1" class="queue-status-message">
        {{ $t('campaigns.general.renderQueueStatusMessage', [jobsInQueue]) }}
        <v-tooltip bottom open-delay="500">
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" class="info-icon mx-1" v-on="on">?</span>
          </template>
          <span>{{ $t('campaigns.general.renderQueueStatusInfo') }}</span>
        </v-tooltip>
      </div>
      <div v-if="getFilteredContents.length > 0 && !isLoading">
        <ContentAccordion
          v-for="content in getFilteredContents"
          :key="content.id"
          :content="content"
          :list-item-size-has-increased="listItemSizeHasIncreased"
          @contentDeleted="loadContents(false)"
          @contentMoved="changeCampaign"
          @copyContent="copyContent"
        />
        <div v-if="!isLoading && hasMoreContents" class="flex justify-center gap-3">
          <AppButton size="small-auto" type="outlined" :loading="isLoadingContents" @click="handleClickLoadMore">
            {{ $t('campaigns.general.loadMore') }}
          </AppButton>
        </div>
      </div>
      <div v-if="getFilteredContents.length === 0 && !isLoading" class="empty-campaign-wrapper">
        <div class="empty-campaign-container">
          <EmptyContentIcon class="empty-content-icon" viewBox="0 0 45 45" />
          <div class="text-wrapper">{{ $t('content.general.emptyContent') }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentAccordion from '@/components/campaignDetail/contentDetail/ContentAccordion.vue';
import CampaignDetailHeaderRow from '@/components/campaignDetail/CampaignDetailHeaderRow.vue';
import AppLoading from '@/components/shared/AppLoading.vue';
import EmptyContentIcon from '@/assets/images/empty_video.svg';
import { CONTENT_FETCH_COUNTERS, CONTENT_FETCH_DELAYS } from '@/constants/content/contentFetch';
import { QUEUE_STATUS_FETCH_INTERVAL } from '@/constants/queueStatus';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import { DEFAULT_CONTENT_TAKE_SIZE } from '@/constants/content/content';

export default {
  name: 'CampaignDetailMainView',
  components: {
    AppButton,
    ContentAccordion,
    CampaignDetailHeaderRow,
    EmptyContentIcon,
    AppLoading,
  },
  data() {
    return {
      contentFetchTimeout: null,
      queueStatusFetchInterval: null,
      CONTENT_FETCH_DELAYS,
      CONTENT_FETCH_COUNTERS,
      shortDelayContentFetchesLeft: CONTENT_FETCH_COUNTERS.SHORT_DELAY_FETCHES_ALLOCATED,
      copiedContent: null,
      campaignName: '',
      isLoading: false,
      listItemSizeHasIncreased: false,
      filteredContentSize: undefined,
      contentsTotal: 0,
    };
  },
  computed: {
    ...mapGetters({
      getCampaignById: 'campaigns/getCampaignById',
      getFilteredContents: 'content/getFilteredContents',
      getRequestObject: 'contentCreation/getContent',
      queueStatus: 'contentCreation/getVideoJobQueueStatus',
      contents: 'content/getContents',
      isLoadingContents: 'content/getIsLoading',
    }),
    jobsInQueue() {
      return this.queueStatus.render.enqueued + this.queueStatus.render.waiting + this.queueStatus.render.active;
    },
    hasWaitingRender() {
      // Workaround: It should only be necessary to listen to the state of the contents,
      // but for some reason the contents' status can sometimes be failed, while the render is still active
      const hasWaitingContents = this.getFilteredContents.some((content) => content.status === 'WAITING');
      const hasWaitingRenders = this.getFilteredContents.some((content) =>
        content.renders.some((render) => render.status === 'WAITING' || render.status === 'RENDERING'),
      );
      return hasWaitingContents || hasWaitingRenders;
    },
    hasMoreContents() {
      return this.contents.length < this.contentsTotal;
    },
  },
  watch: {
    hasWaitingRender: {
      handler(hasWaitingRender) {
        if (hasWaitingRender && !this.queueStatusFetchInterval) {
          this.queueStatusFetchInterval = setInterval(this.updateVideoJobQueueStatus, QUEUE_STATUS_FETCH_INTERVAL);
        } else {
          this.clearQueueStatusFetchInterval();
        }
      },
      deep: true,
    },
    getFilteredContents: {
      handler(getFilteredContents) {
        if (this.filteredContentSize && this.filteredContentSize < getFilteredContents.length) {
          this.listItemSizeHasIncreased = true;
        }
        this.filteredContentSize = getFilteredContents.length;
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      this.loadCampaigns(),
      this.loadContents(false, DEFAULT_CONTENT_TAKE_SIZE),
      this.triggerDelayedContentFetch(),
      this.$store.dispatch('organizations/loadCreditsForLoggedInOrganization'),
    ]);
    this.isLoading = false;
  },
  beforeDestroy() {
    this.$store.dispatch('content/removeContents');
    this.destroyContentFetch();
    this.clearQueueStatusFetchInterval();
  },
  methods: {
    async loadCampaigns() {
      let campaign = this.getCampaignById(this.$route.params.id);
      if (!campaign) {
        await this.$store.dispatch('campaigns/loadCampaigns');
        campaign = this.getCampaignById(this.$route.params.id);
      }
      if (!campaign) {
        this.$router.replace({ name: 'campaignOverview' });
      }
      this.campaignName = campaign.name;
      this.contentsTotal = campaign.contentIds?.length;
    },
    async loadContents(appended, take) {
      await this.$store.dispatch('content/loadContents', {
        campaignId: this.$route.params.id,
        appended,
        take,
      });
    },
    changeCampaign(newCampaignId) {
      this.$router.push({ name: 'campaign', params: { id: newCampaignId } });
    },
    copyContent(content) {
      this.copiedContent = content;
      this.$store.dispatch('contentCreation/setCopiedContent', this.copiedContent);
      this.openContentCreationWizard();
    },
    resetCampaign() {
      this.$store.dispatch('campaigns/resetCampaign');
    },
    async triggerDelayedContentFetch() {
      this.contentFetchTimeout = window.setInterval(async () => {
        await this.loadContents(false);
      }, CONTENT_FETCH_DELAYS.MAX);
    },
    destroyContentFetch() {
      window.clearInterval(this.contentFetchTimeout);
      this.contentFetchTimeout = null;
    },
    clearQueueStatusFetchInterval() {
      clearInterval(this.queueStatusFetchInterval);
      this.queueStatusFetchInterval = null;
    },
    updateVideoJobQueueStatus() {
      this.$store.dispatch('contentCreation/fetchVideoJobQueueStatus');
    },
    openContentCreationWizard() {
      this.destroyContentFetch();
      this.$router.push({
        name: 'contentCreation',
        params: { id: this.$route.params.id },
      });
    },
    async handleClickLoadMore() {
      this.loadContents(true);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.campaign-detail-wrapper {
  padding-bottom: 6rem;
  margin: rem(36px auto 24px auto);
  .empty-campaign-wrapper {
    @apply bg-white flex justify-center items-center max-w-6xl;
    height: rem(522px);
    .empty-campaign-container {
      @apply flex flex-col items-center;
      width: rem(210px);
      .empty-content-icon {
        margin-bottom: 1rem;
        width: rem(45px);
      }
      .text-wrapper {
        font-family: $cch-font-family;
        font-size: rem(20px);
        font-weight: bold;
        text-align: center;
        color: #c5cde2;
      }
    }
  }
  .queue-status-message {
    @apply bg-white mb-8 flex;
    border-left: $cch-status-color-info 3px solid;
    padding: 1rem 1.25rem;

    .info-icon {
      @apply inline-block rounded-lg w-4 h-4 text-center text-sm font-bold align-middle cursor-pointer;
      border: 1px solid $cch-light-steel-blue;
      color: $cch-light-steel-blue;
    }
  }
}
</style>
