<template>
  <div class="app-input">
    <input :value="value" :placeholder="placeholder" :type="type" @input="$emit('input', $event.target.value)" />
    <label class="app-input-label">
      {{ $t(placeholder) }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.app-input {
  @apply .font-sans .font-semibold .text-blue-light .w-full .relative;
  .app-input-label {
    @apply .absolute .text-blue-lighter;
    top: 0;
    z-index: 0;
    pointer-events: none;
    transition: transform 0.3s ease;
  }
  input:focus {
    outline: none;
    @apply .border-blue-lighter;
  }
  input:focus + .app-input-label,
  input:not(:placeholder-shown) + .app-input-label {
    transform: scale(0.8) translateY(-150%) translateX(-10px);
  }
  input {
    @apply .block .border-blue-light .border-b .pb-1 .w-full;
  }
  input::placeholder {
    opacity: 0;
  }
}
</style>
