<template>
  <div class="footer-wrapper">
    <div class="button-container xl:mx-auto">
      <AppButton type="outlined" :style="{ visibility: hidePreviousButton }" @click="previousTab">
        {{ $t(previousText) }}
      </AppButton>

      <AppButton :loading="nextIsSaving" @click="nextTab">
        {{ $t(nextText) }}
        <template #loader>
          <template v-if="uploadProgress < 100">
            {{ $t('general.buttons.uploading') }}&nbsp;<span class="text-sm">{{ uploadProgress }}%</span>
          </template>
          <template v-else>
            {{ $t('general.buttons.saving') }}
          </template>
          <SmallSpinner v-if="uploadProgress === 100" class="spinner" />
        </template>
      </AppButton>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/shared/buttons/AppButton.vue';
import SmallSpinner from '@/components/shared/SmallSpinner.vue';

export default {
  components: {
    SmallSpinner,
    AppButton,
  },
  props: {
    previousText: {
      type: String,
      default: '',
    },
    nextText: {
      type: String,
      default: '',
    },
    nextIsSaving: {
      type: Boolean,
      default: false,
    },
    uploadProgress: {
      type: Number,
      default: 0,
    },
    currentTabIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    hidePreviousButton() {
      if (this.currentTabIndex === 0) {
        return 'hidden';
      }
      return 'visible';
    },
  },
  methods: {
    previousTab() {
      this.$emit('previous');
    },
    nextTab() {
      this.$emit('next');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.footer-wrapper {
  @apply z-10 flex flex-row justify-center;
  background-color: $cch-light-gray;
  height: rem(80px);
  border-top: solid rem(1px) $cch-light-steel-blue;

  .button-container {
    @apply w-3/4 h-full flex flex-row justify-between items-center;
  }

  .previous-button {
    @apply text-base font-bold;
    color: $cch-dark-grey;
    background-color: $cch-light-gray;
    font-family: $cch-font-family;
  }

  .spinner {
    @apply ml-4;
    width: rem(22px);
    height: rem(22px);
  }
}
</style>
