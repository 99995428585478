<template>
  <ModalBase
    :title="format.humanReadableName"
    :show="show"
    :override-style="{
      'max-height': scssVariables.modalSideOffset,
      'margin-bottom': '0 rem',
      position: 'relative',
    }"
    :override-header-style="{ 'margin-bottom': '1rem' }"
    @closeClicked="onCloseClicked"
  >
    <template #content>
      <div class="preview">
        <video v-if="show" autoplay class="preview-video" controls preload>
          <source :src="format.videoSignedUrl" />
        </video>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import scssVariables from '@/styles/variables.module.scss';

export default {
  components: {
    ModalBase,
  },

  props: {
    format: {
      type: Object,
      default: null,
      required: true,
    },
    templateId: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scssVariables,
    };
  },
  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.preview {
  margin-bottom: rem(32px);
  height: 80%;
  .preview-video {
    position: relative;

    max-width: 100%;
    max-height: rem(535px);
    margin: 0 auto;
    box-shadow: 0 0 5px 0 rgba(71, 67, 102, 0.19);
  }
}
</style>
