<template>
  <label class="checkbox-container">
    <slot />
    <input v-model="isChecked" class="checkbox-input" type="checkbox" @click="onFilterClicked" />
    <span :class="[rounded ? 'custom-rounded-checkbox' : 'custom-checkbox']" />
  </label>
</template>

<script>
export default {
  name: 'FilterPopupCheckboxField',
  props: {
    category: {
      type: String,
      required: false,
      default: null,
    },
    categoryType: {
      type: String,
      required: false,
      default: null,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  methods: {
    onFilterClicked() {
      const filter = {
        key: this.categoryType,
        value: this.category,
      };
      this.$emit('inclusiveFilterClicked', {
        filter,
        isChecked: this.isChecked,
      });
    },
  },
};
</script>

<style scoped>
.checkbox-container {
  @apply .text-blue-light .font-sans .font-semibold .text-base .mb-6 .relative .text-left .pl-10 .block .cursor-pointer;
}

.checkbox-input {
  @apply .opacity-0 .w-0 .h-0 .absolute;
}

.custom-checkbox,
.custom-rounded-checkbox {
  @apply .absolute .w-6 .h-6 .cursor-pointer;
  top: -0.1rem;
  left: 0.1rem;
  border: solid 1.5px #909cb0;
}

.custom-rounded-checkbox {
  @apply .rounded-full;
}

.checkbox-input:checked + .custom-rounded-checkbox,
.checkbox-input:checked + .custom-checkbox {
  border: solid 1.5px black;
}

.checkbox-input:checked + .custom-rounded-checkbox::after {
  @apply .rounded-full;
}

.checkbox-input:checked + .custom-checkbox::after,
.checkbox-input:checked + .custom-rounded-checkbox::after {
  @apply .absolute .bg-black;
  content: '';
  top: 0.2rem;
  left: 0.2rem;
  width: 0.9rem;
  height: 0.9rem;
  border: solid 1.5px black;
}
</style>
