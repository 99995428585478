// initial state
const state = {
  sortedBy: {
    key: null,
    reverse: null,
  },
};

// getters
const getters = {
  getSortedBy: (state: $TSFixMe) => {
    return state.sortedBy;
  },
};

// actions
const actions = {
  updateSortedBy({ commit }: $TSFixMe, payload: $TSFixMe) {
    commit('updateSortedBy', payload);
  },
};

// mutations
const mutations = {
  updateSortedBy(state: $TSFixMe, payload: $TSFixMe) {
    state.sortedBy = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
