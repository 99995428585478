export const FORMAT = {
  LANDSCAPE: 'Landscape',
  PORTRAIT: 'Portrait',
  SOCIAL_MEDIA_SQUARE: 'Square',
  HEADER_STATIC: 'Header Static',
  HEADER_MOBILE: 'Header Mobile',
  WIDEBOARD: 'Wideboard',
  MOBILE_RECTANGLE: 'MobileRectangle',
  LEADERBOARD: 'LeaderBoard',
};

export default FORMAT;
