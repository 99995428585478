<template>
  <h2 :class="`grid-title grid-title--${type}`">
    <slot />
  </h2>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style scoped lang="scss">
.grid-title {
  @apply .text-black .font-sans;
  &.grid-title--primary {
    @apply .p-2 .font-bold .text-xl;
  }
  &.grid-title--campaign {
    @apply .font-extrabold .text-2xl;
  }
}
</style>
