import { cloneDeep } from 'lodash-es';
// eslint-disable-next-line import/no-cycle
import HTTP from '@/services/http';
// eslint-disable-next-line import/no-cycle
import getLogger from '@/services/logger';

const LOG = getLogger('resource/render');
const urlPrefix = `api/v${process.env.VUE_APP_API_VERSION}/render`;

export async function enqueueRenderJob(
  templateId: $TSFixMe,
  campaignId: $TSFixMe,
  organizationId: $TSFixMe,
  data: $TSFixMe,
  contentName: $TSFixMe,
  preview = false,
) {
  LOG.log(`Attempting to enqueue new render job`);

  const renderData = cloneDeep(data);
  const mappedRenderData = renderData.map(({ format, ...otherRenderProps }: $TSFixMe) => {
    return {
      composition: format,
      ...otherRenderProps,
    };
  });

  const job = {
    templateID: templateId,
    campaignID: campaignId,
    organizationID: organizationId,
    contentName,
    data: mappedRenderData,
  };
  return HTTP.post(`${urlPrefix}/${preview ? 'preview' : 'enqueue'}`, job);
}

export async function startHtmlRenderJob(
  templateId: $TSFixMe,
  campaignId: $TSFixMe,
  organizationId: $TSFixMe,
  data: $TSFixMe,
  contentName: $TSFixMe,
  preview = false,
) {
  LOG.log(`Attempting to enqueue new render job`);
  const job = {
    templateID: templateId,
    campaignID: campaignId,
    organizationID: organizationId,
    contentName,
    data,
  };
  return HTTP.post(`${urlPrefix}/${preview ? 'previewhtml' : 'renderhtml'}`, job);
}

export async function getJobQueueStatus() {
  return HTTP.get(`${urlPrefix}/queue-status`);
}
