<template>
  <ContentRowBase
    :id="content.id"
    :name="content.name"
    :language="languageString"
    :creation-date="creationDate"
    :template-name="templateName"
    :format-info="formatInfo"
    :status="status"
    :campaign="content.campaignId"
    :is-header="true"
    :is-uploadable="isUploadable"
    @titleClicked="$emit('titleClicked')"
    @downloadClicked="$emit('downloadClicked')"
    @copyContentClicked="$emit('copyContentClicked')"
    @deleteContentClicked="$emit('deleteContentClicked')"
    @changeCampaignTriggered="onChangeCampaignTriggered"
    @uploadContent="$emit('uploadContent')"
  />
</template>

<script>
import { format } from 'date-fns';
import ContentRowBase from '@/components/campaignDetail/contentDetail/ContentRowBase.vue';
import { getLanguageCodeFromLanguage } from '@/helpers/helpers';
import { TYPES } from '@/constants/content/content';

export default {
  name: 'ContentAccordionTitleRow',
  components: { ContentRowBase },
  props: {
    content: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    hasCmsUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    languageString() {
      const uniqueLanguages = new Set(
        this.content.renders.map((render) => getLanguageCodeFromLanguage(render.language)),
      );
      return [...uniqueLanguages].join(', ');
    },
    creationDate() {
      return format(new Date(this.content.createdAt), 'dd. MMM yyyy HH:mm');
    },
    templateName() {
      return this.content.templateName;
    },
    formatInfo() {
      const uniqueFormats = new Set(this.content.renders.map((render) => render.format));
      const formatTextKey = uniqueFormats.size === 1 ? 'general.languages.format' : 'general.languages.formats';
      return `${uniqueFormats.size} ${this.$t(formatTextKey)}`;
    },
    isUploadable() {
      return this.hasCmsUpload && this.content.renders.some((render) => render.type === TYPES.VIDEO);
    },
  },
  methods: {
    onChangeCampaignTriggered(newCampaignId) {
      this.$emit('changeCampaignTriggered', newCampaignId);
    },
  },
};
</script>

<style scoped lang="scss"></style>
