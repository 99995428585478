<template>
  <ModalBase
    :title="$t('content.general.moveContent')"
    :show="show"
    :override-style="{
      width: 'auto',
      'min-width': scssVariables.cchMedium,
      height: 'auto',
      'margin-bottom': '0 rem',
    }"
    :override-header-style="{ 'margin-bottom': '1rem' }"
    @closeClicked="onCloseClicked"
  >
    <template #content>
      <label>
        <FormSelectionField
          v-model="selectedCampaign"
          :list="campaigns"
          :selected-item="currentCampaign"
          :placeholder="$t('contentWizard.contentDescription.belongingCampaign')"
        />
      </label>
    </template>
    <template #button-row>
      <div class="actions-row">
        <div
          v-if="!isMoving"
          class="actions-row-item"
          :class="{ disabled: selectedCampaign === currentCampaign }"
          @click="moveContent"
        >
          <div class="actions-row-item-label">{{ $t('content.general.moveContent') }}</div>
          <MoveIcon class="actions-row-item-icon" />
        </div>
        <div v-else class="actions-row-item">
          <SmallSpinner />
        </div>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import MoveIcon from '@/assets/images/move.svg';
import FormSelectionField from '@/components/shared/formFields/FormSelectionField.vue';
import SmallSpinner from '@/components/shared/SmallSpinner.vue';
import scssVariables from '@/styles/variables.module.scss';

export default {
  name: 'ContentMoveModal',
  components: {
    FormSelectionField,
    ModalBase,
    MoveIcon,
    SmallSpinner,
  },
  props: {
    currentCampaign: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCampaign: this.currentCampaign,
      isMoving: false,
      scssVariables,
    };
  },
  computed: {
    ...mapGetters({
      campaigns: 'campaigns/getAllCampaigns',
    }),
  },
  watch: {
    link: {
      handler() {
        if (this.link) {
          this.copyLink();
        }
      },
    },
  },
  methods: {
    onCloseClicked() {
      if (!this.copying) {
        this.$emit('close');
      }
    },
    async moveContent() {
      if (this.selectedCampaign !== this.currentCampaign) {
        this.isMoving = true;
        this.$emit('changeCampaign', this.selectedCampaign);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.content {
  padding-left: rem(100px);
  padding-right: rem(100px);
  padding-top: rem(50px);
}

#link-box {
  width: 100%;
  background-color: white;
}

.actions-row {
  height: rem(70px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $cch-light-gray;
  margin: rem(31px) rem(-31px) rem(-31px);
  padding: rem(31px);
  width: auto;
  .actions-row-item:first-child {
    margin-right: 4.5rem;
  }
  .actions-row-item:last-child {
    margin-left: 4.5rem;
  }
  .actions-row-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    max-width: 150px;
    overflow: hidden;

    .actions-row-item-label {
      padding-right: rem(10px);
      font-size: rem(16px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: rem(0.53px);
      text-transform: capitalize;
      color: #333333;
    }
    .actions-row-item-icon {
      object-fit: contain;
      align-self: center;
      position: relative;
    }
  }

  .actions-row-item.disabled {
    cursor: auto;

    .actions-row-item-label {
      color: #ccc;
    }
    .actions-row-item-icon {
      path {
        stroke: #ccc;
      }
    }
  }
}
</style>
