<template>
  <div class="media-editor">
    <label>Select your media section</label>
    <div class="media-controls">
      <input type="text" class="media-timestamp-input" :value="startTimestamp || minTimestamp" readonly />
      <div class="media-slider">
        <VueSlider
          v-model="value"
          :min="0"
          :max="100"
          :tooltip-formatter="formatValue"
          :disabled="disabled"
          @change="handleChange"
        />
      </div>
      <input type="text" class="media-timestamp-input" :value="endTimestamp || maxTimestamp" readonly />
    </div>
    <div class="total-duration">
      <span>{{ $t('assets.general.totalDuration') }}: {{ maxTimestamp }}</span>
      <span>{{ $t('assets.general.sectionDuration') }}: {{ sectionTimestamp }}</span>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

export default {
  name: 'MediaEditor',
  components: {
    VueSlider,
  },
  props: {
    duration: {
      type: Number,
      required: false,
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      value: [0, 100],
      startSeconds: 0,
      endSeconds: this.duration,
      startTimestamp: '',
      endTimestamp: '',
    };
  },
  computed: {
    minTimestamp() {
      return this.convertSecondsToTimestamp(0);
    },
    maxTimestamp() {
      return this.convertSecondsToTimestamp(this.duration);
    },
    sectionTimestamp() {
      return this.convertSecondsToTimestamp(this.endSeconds - this.startSeconds);
    },
  },
  methods: {
    handleChange(event) {
      this.startSeconds = this.convertValueToSeconds(event[0]);
      this.endSeconds = this.convertValueToSeconds(event[1]);
      this.startTimestamp = this.convertSecondsToTimestamp(this.startSeconds);
      this.endTimestamp = this.convertSecondsToTimestamp(this.endSeconds);
      this.$emit('change', {
        startSeconds: this.startSeconds,
        endSeconds: this.endSeconds,
        startTimestamp: this.startTimestamp,
        endTimestamp: this.endTimestamp,
      });
    },
    formatValue(value) {
      const seconds = (value * this.duration) / 100;
      return this.convertSecondsToTimestamp(seconds);
    },
    convertValueToSeconds(value) {
      return (value * this.duration) / 100;
    },
    convertSecondsToTimestamp(seconds) {
      const time = parseFloat(seconds).toFixed(3);
      const min = String(Math.floor(time / 60) % 60).padStart(2, '0');
      const sec = String(Math.floor(time - min * 60)).padStart(2, '0');
      const mil = time.slice(-3);
      return `${min}:${sec}:${mil}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.module.scss';
@import '~@/styles/animations.scss';

.media-editor {
  background: #fff;
  label {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 1rem;
    display: block;
  }
}
.media-controls {
  display: flex;
  align-items: center;
  height: 40px;
}
.media-slider {
  width: 100%;
}
.media-timestamp-input {
  width: 100px;
  height: 100%;
}
.media-timestamp-input:first-of-type {
  text-align: left;
}
.media-timestamp-input:last-of-type {
  text-align: right;
}
.total-duration {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
