<template>
  <div class="html-preview-wrapper">
    <ContentPreviewBase
      :previews="previews"
      :formats="formats"
      :language="language"
      :preview-type="previewWidthPixels === billboardWith ? billboardPreview : ''"
      @previewRemoved="onPreviewRemoved"
    >
      <iframe
        v-if="isPreviewRenderCreated && !isImageTemplatePreview"
        class="preview-html"
        :src="activePreviewUrl"
        :height="previewHeight"
        :width="previewWidth"
      />
      <img v-if="isPreviewRenderCreated && isImageTemplatePreview" :src="activePreviewUrl" alt="Preview" />
      <HtmlLoadingPreview
        v-if="isPreviewRenderLoading"
        :class="{ 'preview-content-container-padding': previewWidthPixels === billboardWith }"
      />
      <PreviewErrorMessage
        v-if="hasPreviewRenderFailed"
        :error="activePreview.errorMessage"
        :class="{ 'preview-content-container-padding': previewWidthPixels === billboardWith }"
      />
    </ContentPreviewBase>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HtmlLoadingPreview from '@/components/campaignDetail/contentCreation/contentSpecification/HtmlLoadingPreview.vue';
import ContentPreviewBase from '@/components/campaignDetail/contentCreation/contentSpecification/ContentPreviewBase.vue';
import { getHTML5PreviewSizeFromFormat } from '@/helpers/helpers';
import { RENDER_HTML5_PREVIEW_STATUS } from '@/constants/content/contentCreation';
import PreviewErrorMessage from '@/components/campaignDetail/contentCreation/contentSpecification/PreviewErrorMessage.vue';

export default {
  components: {
    PreviewErrorMessage,
    HtmlLoadingPreview,
    ContentPreviewBase,
  },
  props: {
    previews: {
      type: Array,
      default: () => [],
    },
    formats: {
      type: Array,
      default: null,
    },
    language: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      billboardPreview: 'billboard',
      billboardWith: 970,
    };
  },
  computed: {
    ...mapGetters({
      activeTab: 'contentCreation/getActiveTab',
    }),
    previewWidthPixels() {
      return getHTML5PreviewSizeFromFormat(this.activePreview.format).widthPixels;
    },
    previewHeight() {
      return getHTML5PreviewSizeFromFormat(this.activePreview.format).height;
    },
    previewWidth() {
      return getHTML5PreviewSizeFromFormat(this.activePreview.format).width;
    },
    isPreviewRenderLoading() {
      return this.activePreview.status === RENDER_HTML5_PREVIEW_STATUS.RENDERING;
    },
    isPreviewRenderCreated() {
      return this.activePreview.status === RENDER_HTML5_PREVIEW_STATUS.CREATED;
    },
    isImageTemplatePreview() {
      return !!this.activePreview.previewURL.image;
    },
    hasPreviewRenderFailed() {
      return this.activePreview.status === RENDER_HTML5_PREVIEW_STATUS.FAILED;
    },
    activePreview() {
      if (this.activeTab < this.previews.length) {
        return this.previews[this.activeTab];
      }
      return this.previews[0];
    },
    activePreviewUrl() {
      if (this.isImageTemplatePreview) {
        return `data:image/jpeg;base64,${this.activePreview.previewURL.image}`;
      }
      return this.activePreview.previewURL;
    },
  },
  watch: {
    previews: {
      handler() {
        this.setPreviewStatus();
      },
      deep: true,
    },
  },
  mounted() {
    this.setPreviewStatus();
  },
  methods: {
    onPreviewRemoved(preview) {
      this.$emit('previewRemoved', preview);
    },
    setPreviewStatus() {
      this.renderPreviews = this.previews;
      this.renderPreviews.forEach((preview) => {
        this.showPreview = false;
        if (preview.status === 'FINISHED') {
          this.$nextTick(() => {
            this.showPreview = true;
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.beforeContentShown {
  width: 100%;
}
.preview-content-container-padding {
  padding: 5rem;
}
</style>
