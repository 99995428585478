import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import i18next from 'i18next';
import { toast } from '@/services/toast';
/*
 * Pass 'forceUpdate: true' with the options when making a request to invalid a specific entry.
 * You can skip the cache for requests by passing 'cache: false' with the options.
 */

const EXCLUDED_TOAST_ERROR_ENDPOINTS = ['auth/login'];

enum MessageType {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
}

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: process.env.AXIOS_TIMEOUT,
});

const emitToast = (type: $TSFixMe, code: $TSFixMe) => {
  toast.$emit('showToast', {
    type,
    message: i18next.t(`ERRORS.codes.${code}`),
  });
};

const createErrorToast = (message: string) => {
  toast.$emit('showToast', {
    type: MessageType.ERROR,
    message,
  });
};

const createToasts = (messages: $TSFixMe) => {
  let type: $TSFixMe;
  if (messages.error?.length > 0) {
    type = MessageType.ERROR;
    messages.error.forEach((message: $TSFixMe) => {
      emitToast(type, message.code);
    });
  }
  if (messages.success?.length > 0) {
    type = MessageType.SUCCESS;
    messages.success.forEach((message: $TSFixMe) => {
      emitToast(type, message.code);
    });
  }
  if (messages.info?.length > 0) {
    type = MessageType.INFO;
    messages.info.forEach((message: $TSFixMe) => {
      emitToast(type, message.code);
    });
  }
};

HTTP.interceptors.request.use((config) => {
  const newConfig = config;
  const token = store.getters['auth/getToken'];
  if (token && !newConfig.url?.endsWith('resetPassword')) {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    newConfig.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  return newConfig;
});

HTTP.interceptors.response.use(
  ({ data }) => {
    if (data.error) {
      createErrorToast(`Error code: ${data.error.code}, text: ${data.error.text}`);
      return Promise.reject(data.error.text);
    }
    if (data.messages) {
      createToasts(data.messages);
    }
    return {
      data: data?.result,
      messages: data?.messages,
    };
  },
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch('auth/wipe');
      router.push({ path: '/login' });
      store.commit('auth/setErrorMessage', 'session_expired');
      return Promise.reject(error.response);
    }
    if (!EXCLUDED_TOAST_ERROR_ENDPOINTS.some((url) => error.request?.responseURL?.includes(url))) {
      const code = error.response?.data?.error?.code || error.response?.status;
      const text = error.response?.data?.error?.text || error.response?.statusText;
      createErrorToast(`Error code: ${code}, text: ${text}`);
    }
    return Promise.reject(error.response);
  },
);

export default HTTP;
