
import { defineComponent, PropType } from 'vue';
import { Template } from '@/types';
import { mapGetters } from 'vuex';
import { formatDate } from '@/helpers/helpers';
import EditIcon from '@/assets/images/edit_FILL0_wght200_GRAD0_opsz24.svg';
import LayersIcon from '@/assets/images/layers_FILL0_wght200_GRAD0_opsz24.svg';
import ShareIcon from '@/assets/images/ios_share_FILL0_wght200_GRAD0_opsz24.svg';
import DeleteIcon from '@/assets/images/delete_FILL0_wght200_GRAD0_opsz24.svg';
import { ITEMS_PER_PAGE_OPTIONS, DEFAULT_ITEMS_PER_PAGE } from '@/constants/pagination';

export default defineComponent({
  components: {
    EditIcon,
    LayersIcon,
    ShareIcon,
    DeleteIcon,
  },
  props: {
    items: {
      type: Array as PropType<Array<Template>>,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('templates.general.templateName'),
          value: 'name',
          align: 'start',
        },
        {
          text: this.$t('templates.general.type'),
          value: 'type',
          align: 'start',
        },
        {
          text: this.$t('templates.general.formats'),
          value: 'formats.length',
          align: 'end',
        },
        {
          text: this.$t('templates.general.lastEdited'),
          value: 'updatedAt',
          align: 'end',
        },
        {
          text: this.$t('general.users.actions'),
          value: 'actions',
          sortable: false,
          align: 'end',
        },
      ],
      formatDate,
      inputDebounce: null as number | null,
      ITEMS_PER_PAGE_OPTIONS,
      DEFAULT_ITEMS_PER_PAGE,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'templates/getLoading',
    }),
    searchTerm: {
      get() {
        return this.$store.getters['templates/getSearchTerm'];
      },
      set(value: string) {
        if (this.inputDebounce) {
          clearTimeout(this.inputDebounce);
        }
        this.inputDebounce = setTimeout(() => {
          this.$store.dispatch('templates/setSearchTerm', value);
        }, 250);
      },
    },
  },
  methods: {
    handleClickEdit(item: Template) {
      this.$emit('editClicked', item._id);
    },
    handleClickImportLayers(item: Template) {
      this.$emit('importLayersClicked', item._id);
    },
    handleClickExportLayers(item: Template) {
      this.$emit('exportLayersClicked', item._id);
    },
    handleClickTrash(item: Template) {
      this.$emit('trashClicked', item._id);
    },
    handleClickRow(item: Template) {
      this.$emit('editClicked', item._id);
    },
    handleClickCellActions(e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();
    },
  },
});
