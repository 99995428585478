<template>
  <div>
    <div class="base-data-wrapper">
      <div class="title-container">
        <span>{{ $t('templates.creation.layers') }}</span>
        <div class="button-container">
          <AppButton class="button" @click="importLayers">{{ $t('contentWizard.layers.import') }}</AppButton>
          <AppButton class="button" @click="exportLayers">{{ $t('contentWizard.layers.export') }}</AppButton>
          <AppButton class="button" @click="addLayer">+ {{ $t('templates.creation.newLayer') }}</AppButton>
        </div>
      </div>
      <LayerRow
        v-for="(layer, index) in displayedLayers"
        :key="layer.id"
        class="layer-row"
        :class="index"
        :allowed-types="allowedLayerTypes"
        :layer="layer"
        :formats="formats"
        :column-names="apiColumnNames"
        :has-api-data="baseData.hasApiData"
        @removeClick="removeLayer(index)"
        @input="updateLayer(index, $event)"
        @updatedColumnName="onUpdatedColumnName(index, $event)"
        @copyClick="copyLayer(index)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as uuid from 'uuid';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import LayerRow from '@/components/templates/templateCreation/layers/LayerRow.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import { TEMPLATE_TYPE, TEMPLATE_LAYER_TYPE } from '@/constants/template/template';
import { prepareCopiedLayer, importLayers, exportLayers, validateLayers } from '@/services/content-creation/layers';
import { toast } from '@/services/toast';

export default {
  components: {
    LayerRow,
    AppButton,
  },
  computed: {
    ...mapGetters({
      layers: 'templateCreation/getLayers',
      formats: 'templateCreation/getFormats',
      baseData: 'templateCreation/getBaseData',
      columnNames: 'templateCreation/getApiLayers',
      userOrganization: 'auth/getUserOrganization',
    }),
    allowedLayerTypes() {
      if (this.baseData.type === TEMPLATE_TYPE.HTML5) {
        return ['data', 'image'];
      }
      return Object.values(TEMPLATE_LAYER_TYPE);
    },
    displayedLayers() {
      if (this.layers && this.layers.length === 0) {
        this.addLayer();
      }
      return this.layers;
    },
    apiColumnNames() {
      if (this.columnNames) {
        const columnOptions = [...this.columnNames];
        columnOptions.unshift('');
        return columnOptions;
      }

      return [];
    },
  },
  async mounted() {
    if (this.baseData.hasApiData) {
      await this.$store.dispatch('templateCreation/loadColumnNames', this.userOrganization);
    }
  },
  methods: {
    save() {
      this.$store.commit('templateCreation/setLayers', this.layers);
    },
    validate() {
      const collectedErrors = validateLayers(this.layers);
      this.showValidationErrors(collectedErrors);
      return collectedErrors.length === 0;
    },
    showValidationErrors(errorMessages) {
      if (errorMessages.length > 0) {
        errorMessages.forEach((errorMessage) => {
          toast.$emit('showToast', {
            type: 'error',
            groupName: 'template-creation-validation',
            message: this.$t(`templates.errors.${errorMessage}`),
          });
        });
      }
    },
    onUpdatedColumnName(index, columnName) {
      this.$store.commit('templateCreation/updateColumnName', {
        updateAt: index,
        columnName,
      });
    },
    addLayer() {
      this.$store.commit('templateCreation/addLayer', {
        id: uuid.v1(),
        name: null,
        property: null,
        layerName: null,
        type: null,
        settings: [],
        rawType: null,
        options: [],
      });
    },
    updateLayer(index, newLayer) {
      this.$store.commit('templateCreation/updateLayer', {
        updateAt: index,
        newLayer,
      });
    },
    removeLayer(index) {
      this.$store.commit('templateCreation/removeLayer', index);
    },
    copyLayer(index) {
      let newLayer = _cloneDeep(this.layers[index]);
      newLayer = prepareCopiedLayer(newLayer);
      this.layers.push(newLayer);
    },
    async importLayers() {
      const importedLayers = await importLayers();
      const collectedErrors = validateLayers(importedLayers);
      this.showValidationErrors(collectedErrors);
      if (collectedErrors.length === 0) {
        this.$store.commit('templateCreation/setLayers', this.layers.concat(importedLayers));

        toast.$emit('showToast', {
          type: 'success',
          groupName: 'template-creation',
          message: this.$t('contentWizard.layers.importSuccess'),
        });
      }
    },
    exportLayers() {
      exportLayers(this.layers);

      toast.$emit('showToast', {
        type: 'success',
        groupName: 'template-creation',
        message: this.$t('contentWizard.layers.exportSuccess'),
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.base-data-wrapper {
  .title-container {
    display: flex;
    width: 100%;
    margin-bottom: rem(30px);
    font-family: $cch-font-family;
    font-size: rem(36px);
    font-weight: 800;
    color: #c5cde2;
    justify-content: space-between;
    align-items: baseline;

    .button-container {
      display: flex;

      .button {
        width: rem(160px);
        height: rem(50px);
        margin-left: rem(16px);
      }
    }
  }
  .selection-field {
    margin-bottom: rem(30px);
  }
}
</style>
