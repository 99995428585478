import jwtDecode from 'jwt-decode';
import getLogger from '@/services/logger';
import {
  TOKEN_KEY,
  TOKEN_TIME_KEY,
  TOKEN_EXPIRATION_KEY,
  USER_DATA_KEY,
  PREFERENCE_SHOW_INACTIVE_USERS,
} from '@/constants/store/auth';
import { removeLocalstorageItem, setLocalstorageItem, getLocalstorageItem } from '@/services/localstorage';
// eslint-disable-next-line import/no-cycle
import { authenticate, resetPassword, sendPasswordResetLink } from '@/services/api/auth.resource';
import { CAMPAIGNS_VIEW_MODE, TEMPLATES_VIEW_MODE } from '@/constants/store/references';

const LOG = getLogger('store/auth');

// initial state
const state = {
  token: undefined,
  tokenTime: undefined,
  expiresIn: undefined,
  isLoggedIn: false,
  id: undefined,
  role: undefined,
  organization: undefined,
  errorMessage: undefined,
  permissions: undefined,
  isPasswordChangeRequired: undefined,
  loading: false,
};

// getters
const getters = {
  getToken: (state: $TSFixMe) => state.token,
  getTokenTime: (state: $TSFixMe) => state.tokenTime,
  getExpiresIn: (state: $TSFixMe) => state.expiresIn,
  getExpirationTime: (state: $TSFixMe) => parseInt(state.tokenTime, 10) + parseInt(state.expiresIn, 10),
  isLoggedIn: (state: $TSFixMe) => state.isLoggedIn,
  getUserId: (state: $TSFixMe) => state.id,
  getUserOrganization: (state: $TSFixMe) => state.organization,
  getUserRole: (state: $TSFixMe) => state.role,
  getUserPermissions: (state: $TSFixMe) => state.permissions,
  isPasswordChangeRequired: (state: $TSFixMe) => state.isPasswordChangeRequired,
  getErrorMessage: (state: $TSFixMe) => state.errorMessage,
  isLoading: (state: $TSFixMe) => state.loading,
};

// actions
const actions = {
  wipe({ commit }: $TSFixMe) {
    LOG.debug('Wiping auth');
    removeLocalstorageItem(TOKEN_KEY);
    removeLocalstorageItem(TOKEN_TIME_KEY);
    removeLocalstorageItem(TOKEN_EXPIRATION_KEY);
    removeLocalstorageItem(USER_DATA_KEY);
    removeLocalstorageItem(TEMPLATES_VIEW_MODE);
    removeLocalstorageItem(CAMPAIGNS_VIEW_MODE);
    removeLocalstorageItem(PREFERENCE_SHOW_INACTIVE_USERS);
    commit('setToken', undefined);
    commit('setTokenTime', undefined);
    commit('setExpiresIn', undefined);
    commit('setUserOrganization', undefined);
    commit('setUserRole', undefined);
    commit('setUserId', undefined);
    commit('setUserPermissions', undefined);
    commit('setIsPasswordChangeRequired', undefined);
  },
  async login({ commit }: $TSFixMe, credentials: $TSFixMe) {
    return authenticate(credentials.username, credentials.password).then((response) => {
      if (response.data.error === 'PASSWORD_CHANGE_REQUIRED') {
        commit('setIsPasswordChangeRequired', true);
        return;
      }
      const tokenTime = new Date().getTime();
      const expiresIn = response.data.expiresIn * 1000;
      setLocalstorageItem(TOKEN_KEY, response.data.token);
      setLocalstorageItem(TOKEN_TIME_KEY, tokenTime);
      setLocalstorageItem(TOKEN_EXPIRATION_KEY, expiresIn);
      const decodedJwt = jwtDecode(response.data.token);

      const userData = {
        organization: (decodedJwt as $TSFixMe).organization,
        role: (decodedJwt as $TSFixMe).role,
        permissions: (decodedJwt as $TSFixMe).permissions,
        id: (decodedJwt as $TSFixMe)._id,
      };

      setLocalstorageItem(USER_DATA_KEY, JSON.stringify(userData));

      commit('setUserOrganization', userData.organization);
      commit('setUserRole', userData.role);
      commit('setUserPermissions', userData.permissions);

      commit('setUserId', userData.id);

      commit('setToken', response.data.token);
      commit('setTokenTime', tokenTime);
      commit('setExpiresIn', expiresIn);

      commit('setErrorMessage', undefined);
    });
  },
  tryLoginWithToken({ commit, dispatch }: $TSFixMe) {
    LOG.info('Trying to log in with token');
    return getLocalstorageItem(TOKEN_KEY)
      .then((token) => {
        commit('setToken', token);
        return getLocalstorageItem(TOKEN_TIME_KEY);
      })
      .then((tokenTime) => {
        commit('setTokenTime', tokenTime);
        return getLocalstorageItem(TOKEN_EXPIRATION_KEY);
      })
      .then((expiration) => {
        commit('setExpiresIn', expiration);
        return getLocalstorageItem(USER_DATA_KEY);
      })
      .then((userDataJson) => {
        const userData = JSON.parse(userDataJson);
        commit('setUserOrganization', userData.organization);
        commit('setUserRole', userData.role);
        commit('setUserPermissions', userData.permissions);
        commit('setUserId', userData.id);
      })
      .catch(() => {
        LOG.info('tryLoginWithToken failed');
        dispatch('wipe');
      });
  },
  checkCredentials({ state, dispatch }: $TSFixMe) {
    if (state.token === undefined) {
      return dispatch('tryLoginWithToken').then(() => {
        LOG.info('tryLoginWithToken succeeded');
        return dispatch('checkLoginStatus');
      });
    }
    return dispatch('checkLoginStatus');
  },
  checkLoginStatus({ state, getters, commit }: $TSFixMe) {
    const isLoggedIn = state.token !== undefined && new Date().getTime() < getters.getExpirationTime;
    commit('setLoggedIn', isLoggedIn);
    if (isLoggedIn) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  updateOrganizationId({ commit }: $TSFixMe, organizationId: $TSFixMe) {
    getLocalstorageItem(USER_DATA_KEY)
      .then((userDataJson) => {
        const userData = JSON.parse(userDataJson);
        userData.organization = organizationId;
        commit('setUserOrganization', userData.organization);
        setLocalstorageItem(USER_DATA_KEY, JSON.stringify(userData));
      })
      .catch(() => {
        LOG.info('Getting user data from localStorage failed');
      });
  },
  async sendPasswordResetLink({ commit }: $TSFixMe, username: string) {
    try {
      commit('setLoading', true);
      await sendPasswordResetLink(username);
    } finally {
      commit('setLoading', false);
    }
  },
  async resetPassword({ commit }: $TSFixMe, params: { resetPasswordJwt: string; newPassword: string }) {
    try {
      commit('setLoading', true);
      await resetPassword(params.resetPasswordJwt, params.newPassword);
    } finally {
      commit('setLoading', false);
    }
  },
};

// mutations
const mutations = {
  setToken(state: $TSFixMe, token: $TSFixMe) {
    state.token = token;
  },
  setTokenTime(state: $TSFixMe, time: $TSFixMe) {
    state.tokenTime = time;
  },
  setExpiresIn(state: $TSFixMe, expiration: $TSFixMe) {
    state.expiresIn = expiration;
  },
  setLoggedIn(state: $TSFixMe, status: $TSFixMe) {
    state.isLoggedIn = status;
  },
  setUserOrganization(state: $TSFixMe, id: $TSFixMe) {
    state.organization = id;
  },
  setUserRole(state: $TSFixMe, role: $TSFixMe) {
    state.role = role;
  },
  setUserPermissions(state: $TSFixMe, permissions: $TSFixMe) {
    state.permissions = permissions;
  },
  setIsPasswordChangeRequired(state: $TSFixMe, isPasswordChangeRequired: $TSFixMe) {
    state.isPasswordChangeRequired = isPasswordChangeRequired;
  },
  setUserId(state: $TSFixMe, id: $TSFixMe) {
    state.id = id;
  },
  setErrorMessage(state: $TSFixMe, message: $TSFixMe) {
    state.errorMessage = message;
  },
  setLoading(state: $TSFixMe, value: boolean) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
