<template>
  <div class="accordion-wrapper" :class="{ 'accordion-open': open, 'accordion-highlighted': highlighted }">
    <div class="flex items-center accordion-row">
      <ContentAccordionTitleRow
        :content="content"
        :status="overallStatus"
        :has-cms-upload="hasCmsUpload"
        @titleClicked="toggleOpen"
        @downloadClicked="downloadAll"
        @copyContentClicked="copyContent"
        @deleteContentClicked="deleteContent"
        @changeCampaignTriggered="updateCampaign"
        @uploadContent="uploadContent"
      />
      <v-tooltip bottom open-delay="500">
        <template #activator="{ on, attrs }">
          <div class="ml-auto cursor-pointer accordion-arrow" v-bind="attrs" @click="toggleOpen" v-on="on">
            <ArrowUp v-if="open" />
            <ArrowDown v-else />
          </div>
        </template>
        <span>{{ $t('content.tooltip.expand') }}</span>
      </v-tooltip>
    </div>
    <div v-show="open">
      <div class="accordion-content-wrapper">
        <ContentItem
          v-for="render in content.renders"
          :key="render.id"
          :render="render"
          :status="renderStatuses[render.id]"
          :render-progress="renderProgresses[render.id]"
          :template-name="content.templateName"
          :has-cms-upload="hasCmsUpload"
          @downloadClicked="downloadContentItem"
          @copyContentClicked="copyMediaItem"
          @deleteContentItemClicked="deleteContentItem"
          @uploadContentItem="uploadContentItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ArrowDown from '@/assets/images/arrow-down.svg';
import ArrowUp from '@/assets/images/arrow-up.svg';
import { getContentLink } from '@/helpers/helpers';
import ContentItem from '@/components/campaignDetail/contentDetail/ContentItem.vue';
import { FINAL_STATUSES, RENDER_WS_STATUS } from '@/constants/content/contentCreation';
import ContentAccordionTitleRow from '@/components/campaignDetail/contentDetail/ContentAccordionTitleRow.vue';
import { updateContentCampaign } from '@/services/api/content.resource';
import { toast } from '@/services/toast';
import * as CONTENT from '@/constants/content/content';

export default {
  components: {
    ContentItem,
    ContentAccordionTitleRow,
    ArrowDown,
    ArrowUp,
  },
  props: {
    content: {
      type: Object,
      required: true,
      default: null,
    },
    listItemSizeHasIncreased: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: this.content.id === this.$route.query.contentId,
      highlighted: this.content.id === this.$route.query.contentId,
      renderStatuses: {},
      renderProgresses: {},
      overallStatus: FINAL_STATUSES.WAITING,
    };
  },
  computed: {
    ...mapGetters({
      hasCmsUpload: 'content/getHasCmsUpload',
    }),
  },
  watch: {
    content: {
      handler() {
        Object.keys(this.renderStatuses).forEach((renderId) => {
          const matchedRender = this.content.renders.filter((render) => render.id === renderId).pop();
          if (matchedRender.status !== this.renderStatuses[renderId]) {
            if (matchedRender.status === FINAL_STATUSES.CREATED || matchedRender.status === FINAL_STATUSES.FAILED) {
              this.renderStatuses[renderId] = matchedRender.status;
            }
          }
        });
      },
      deep: true,
    },
    renderStatuses: {
      handler() {
        if (this.overallStatus === 'DELETING') {
          return;
        }
        const renderStatusArray = [];
        Object.keys(this.renderStatuses).forEach((renderId) => {
          renderStatusArray.push(this.renderStatuses[renderId]);
        });
        if (renderStatusArray.length === 0) {
          this.overallStatus = FINAL_STATUSES.CREATED;
        } else if (renderStatusArray.every((status) => status === FINAL_STATUSES.CREATED || status === 'DELETING')) {
          this.overallStatus = FINAL_STATUSES.CREATED;
        } else if (renderStatusArray.every((status) => status === FINAL_STATUSES.WAITING)) {
          this.overallStatus = FINAL_STATUSES.WAITING;
        } else if (renderStatusArray.some((status) => status === FINAL_STATUSES.RENDERING)) {
          this.overallStatus = FINAL_STATUSES.RENDERING;
        } else if (renderStatusArray.some((status) => status === FINAL_STATUSES.FAILED)) {
          if (renderStatusArray.some((status) => status === FINAL_STATUSES.CREATED)) {
            this.overallStatus = FINAL_STATUSES.CREATED;
          } else {
            this.overallStatus = FINAL_STATUSES.FAILED;
          }
        }
        this.expandAccordionOnRendering();
      },
      deep: true,
    },
    '$route.query.contentId': {
      handler() {
        this.open = this.content.id === this.$route.query.contentId;
        this.highlighted = this.content.id === this.$route.query.contentId;
      },
      deep: false,
    },
  },
  created() {
    this.content.renders.forEach((render) => {
      if (!render.status) {
        this.$set(this.renderStatuses, render.id, FINAL_STATUSES.WAITING);
      } else {
        this.$set(this.renderStatuses, render.id, render.status);
      }
    });
    this.startRenderStatusListeners();
  },
  methods: {
    expandAccordionOnRendering() {
      if (this.overallStatus !== FINAL_STATUSES.CREATED && this.listItemSizeHasIncreased) {
        this.open = true;
      }
    },
    toggleOpen() {
      this.open = !this.open;
    },
    async deleteContent() {
      this.overallStatus = 'DELETING';
      await this.$store.dispatch('content/deleteContent', this.content.id);
      setTimeout(() => this.$emit('contentDeleted'), 1000);
    },
    async updateCampaign(newCampaignId) {
      const response = await updateContentCampaign(this.content.id, newCampaignId);
      if (response) {
        setTimeout(() => this.$emit('contentMoved', newCampaignId), 1000);
      } else {
        toast.$emit('showToast', {
          type: 'error',
          message: this.$t(`content.general.failedMove`),
        });
      }
    },
    async deleteContentItem(renderId) {
      if (this.content.renders.length === 1) {
        await this.deleteContent();
      } else {
        this.$set(this.renderStatuses, renderId, 'DELETING');
        await this.$store.dispatch('content/deleteRender', renderId);
        setTimeout(() => this.$emit('contentDeleted'), 1000);
      }
    },
    downloadAll() {
      if (this.content.renders.length === 1) {
        window.open(this.content.renders[0].signedUrl);
      } else {
        window.open(getContentLink(this.content.id));
      }
    },
    downloadContentItem(signedUrl) {
      window.open(signedUrl);
    },
    copyContent() {
      const content = this.copyByValue(this.content);
      this.$emit('copyContent', content);
    },
    copyMediaItem(renderId) {
      const content = this.copyByValue(this.content);
      content.renders = [content.renders.filter((render) => render.id === renderId).pop()];
      this.$emit('copyContent', content);
    },
    copyByValue(object) {
      return JSON.parse(JSON.stringify(object));
    },
    startRenderStatusListeners() {
      Object.keys(this.renderStatuses).forEach((renderId) => {
        if (this.content.renders.filter((render) => render.id === renderId).pop().status !== 'CREATED') {
          this.sockets.subscribe(renderId, (jobStatusDto) => {
            const { status, payload } = jobStatusDto;
            if (status === RENDER_WS_STATUS.ACTIVE) {
              this.$set(this.renderStatuses, renderId, FINAL_STATUSES.RENDERING);
            } else if (status === RENDER_WS_STATUS.RENDERING) {
              this.$set(this.renderStatuses, renderId, FINAL_STATUSES.RENDERING);
              this.$set(this.renderProgresses, renderId, payload);
            } else if (status === RENDER_WS_STATUS.FINISHED) {
              this.$set(this.renderStatuses, renderId, FINAL_STATUSES.CREATED);
              this.sockets.unsubscribe(renderId, () => {});
            } else if (status === RENDER_WS_STATUS.FINISHED_ERROR) {
              this.$set(this.renderStatuses, renderId, FINAL_STATUSES.FAILED);
            } else {
              this.$set(this.renderStatuses, renderId, status);
            }
          });
        }
      });
    },
    async uploadContent() {
      if (this.hasCmsUpload) {
        const uploadableRenderIds = this.content.renders
          .filter((render) => render.type === CONTENT.TYPES.VIDEO)
          .map((render) => render.id);
        if (uploadableRenderIds.length > 0) {
          await this.$store.dispatch('content/uploadContents', uploadableRenderIds);
        }
      }
    },
    async uploadContentItem(renderId) {
      if (this.hasCmsUpload) {
        await this.$store.dispatch('content/uploadContents', [renderId]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.accordion-wrapper {
  background-color: #ffffff;
  margin-bottom: rem(24px);
  &.accordion-open {
    .accordion-row {
      border-bottom: rem(1px) solid #d8d8d8;
    }
  }
  &.accordion-highlighted {
    animation: highlighted-fade 3s;
    animation-timing-function: ease-out;
  }
  .accordion-arrow {
    margin-right: rem(24px);
  }

  @keyframes highlighted-fade {
    0% {
      background-color: $cch-color-hippocamp;
    }

    100% {
      background-color: white;
    }
  }
}
</style>
