// eslint-disable-next-line import/no-cycle
import HTTP from '@/services/http';
import getLogger from '@/services/logger';
// eslint-disable-next-line import/no-cycle
import store from '../../store/index';

const LOG = getLogger('resource/content');

export async function checkIfThumbnailIsAlive(id: $TSFixMe) {
  return HTTP.get(
    `${process.env.VUE_APP_API_URL}/api/v${
      process.env.VUE_APP_API_VERSION
    }/content/${id}/thumb.gif?token=${localStorage.getItem('token')}`,
  );
}

export const getContent = async () => {
  try {
    const organizationId = store.getters['auth/getUserOrganization'];
    const items = await HTTP.get(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/content/organization/${organizationId}`,
      {
        // @ts-expect-error TS(2345): Argument of type '{ cache: boolean; forceUpdate: b... Remove this comment to see the full error message
        cache: false,
        forceUpdate: true,
      },
    );

    return items.data;
  } catch (e) {
    LOG.error('Error while fetching content:', e);
    return [];
  }
};

export const getContentsByCampaignId = async (campaignId: string, take: number, skip: number) => {
  try {
    const organizationId = store.getters['auth/getUserOrganization'];
    const url = `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/content/organization/${organizationId}/campaign/${campaignId}?take=${take}&skip=${skip}`;
    const response = await HTTP.get(url, {
      // @ts-expect-error TS(2345): Argument of type '{ cache: boolean; forceUpdate: b... Remove this comment to see the full error message
      cache: false,
      forceUpdate: true,
    });
    return response.data;
  } catch (e) {
    LOG.error('Error while fetching content:', e);
    return [];
  }
};

export const getContentItemShareLinkID = async (contentItemID: $TSFixMe) => {
  try {
    const response = await HTTP.get(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/contentItem/shareLink/${contentItemID}`,
      {
        // @ts-expect-error TS(2345): Argument of type '{ cache: boolean; forceUpdate: b... Remove this comment to see the full error message
        cache: false,
        forceUpdate: true,
      },
    );
    return response.data;
  } catch (e) {
    LOG.error('Error while getting contentItem share link:', e);
    return '';
  }
};

export const getContentShareLinkID = async (contentID: $TSFixMe) => {
  try {
    const response = await HTTP.get(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/content/shareLink/${contentID}`,
      {
        // @ts-expect-error TS(2345): Argument of type '{ cache: boolean; forceUpdate: b... Remove this comment to see the full error message
        cache: false,
        forceUpdate: true,
      },
    );
    return response.data;
  } catch (e) {
    LOG.error('Error while getting content share link ID:', e);
    return '';
  }
};

export const getCampaignShareLinkID = async (campaignID: $TSFixMe) => {
  try {
    const response = await HTTP.get(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/campaign/shareZip/${campaignID}`,
      {
        // @ts-expect-error TS(2345): Argument of type '{ cache: boolean; forceUpdate: b... Remove this comment to see the full error message
        cache: false,
        forceUpdate: true,
      },
    );
    return response.data;
  } catch (e) {
    LOG.error('Error while getting campaign share link ID:', e);
    return '';
  }
};

export const updateContentCampaign = async (contentId: $TSFixMe, newCampaignId: $TSFixMe) => {
  const changeContentCampaignDTO = JSON.stringify({
    campaignId: newCampaignId,
  });
  try {
    LOG.debug(`Attempting to move content ${contentId} to new campaign ${newCampaignId}`);
    const path = `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/content/${contentId}/campaign`;
    const response = await HTTP.patch(path, changeContentCampaignDTO, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (e) {
    LOG.error(`Error while updating content ${contentId} with new campaign:`, e);
    return null;
  }
};

export const deleteContent = async (contentID: $TSFixMe) => {
  try {
    await HTTP.delete(`${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/content/${contentID}`);
    return true;
  } catch (e) {
    LOG.error('Error while deleting content:', e);
    return false;
  }
};

export const deleteRender = async (renderID: $TSFixMe) => {
  try {
    await HTTP.delete(`${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/contentItem/${renderID}`);
    return true;
  } catch (e) {
    LOG.error('Error while deleting contentItem:', e);
    return false;
  }
};

export const getHtml5PreviewUrl = async (renderID: $TSFixMe) => {
  try {
    return await HTTP.get(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/contentItem/html5/preview/${renderID}`,
    );
  } catch (e) {
    LOG.error('Error while getting contentItem URL:', e);
    return false;
  }
};

export const getContentItemSignedUrl = async (renderID: $TSFixMe): Promise<string> => {
  try {
    const response = await HTTP.get(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/contentItem/download/${renderID}`,
    );
    return response.data;
  } catch (e) {
    LOG.error('Error while getting contentItem URL:', e);
    return '';
  }
};
