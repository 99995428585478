import getLogger from '@/services/logger';
import { i18nextBrowserLanguageDetectionOptions } from './i18nextBrowserLanguageDetectionOptions';

const LOG = getLogger('i18next');

const localeConfig = {
  resolve: (url: $TSFixMe) =>
    import(/* webpackChunkName: "i18n/[request]" */ `../../../public/locales/${url.toLowerCase()}.json`),
};

export const i18nextOptions = {
  lng: 'ch_en',
  fallbackLng: 'ch_en',
  interpolation: {
    format: (value: $TSFixMe, format: $TSFixMe) => {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    },
  },
  backend: {
    loadPath: '{{lng}}',
    parse: (data: $TSFixMe) => data,
    ajax: function loadLocales(url: $TSFixMe, _options: $TSFixMe, callback: $TSFixMe) {
      if (url.indexOf('undefined/') < 0) {
        LOG.debug(`Loading locale ${url}`);
        try {
          localeConfig
            .resolve(url)
            .then((locale) => {
              callback(locale, { status: '200' });
            })
            .catch((err) => {
              LOG.error('Failed to load locale', err);
              callback(null, { status: '404' });
            });
        } catch (err) {
          LOG.error('Failed to load locale. Unknown error', err);
          callback(null, { status: '404' });
        }
      } else {
        callback(null, { status: '404' });
      }
    },
  },
  detection: i18nextBrowserLanguageDetectionOptions,
};
