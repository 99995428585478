
import AssetHeader from '@/components/assets/AssetHeader.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import CreateFolderModal from '@/components/assets/CreateFolderModal.vue';
import AssetsGrid from '@/components/assets/AssetsGrid.vue';
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import scssVariables from '@/styles/variables.module.scss';
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';
import { Asset, AssetType } from '@/types';

export default defineComponent({
  components: {
    AssetHeader,
    CreateFolderModal,
    AssetsGrid,
    ModalBase,
    AppButton,
  },
  props: {
    selectedAssetIds: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data() {
    return {
      isCreateModalShown: false,
      modalPage: 1,
      scssVariables,
      AssetType,
    };
  },
  computed: {
    ...mapGetters({
      path: 'assetsMoving/getPath',
      assets: 'assetsMoving/getAssets',
      isShared: 'assetsMoving/getIsShared',
    }),
    filteredAssets() {
      return this.assets.filter((asset: Asset) => !this.selectedAssetIds.includes(asset._id));
    },
    modalTitle(): string {
      return this.selectedAssetIds.length > 1
        ? `${this.$t('assets.general.move')} ${this.selectedAssetIds.length} ${this.$t('assets.general.assets')} `
        : this.$t('assets.general.moveAsset');
    },
  },
  beforeDestroy() {
    this.$store.dispatch('assetsMoving/removeSearchTerm');
  },
  methods: {
    assetClicked(asset: Asset) {
      if (asset.type === AssetType.FOLDER || asset.type === AssetType.SHARED_FOLDER) {
        this.$store.dispatch('assetsMoving/removeSearchTerm');
      }
      this.$store.dispatch('assetsMoving/setPath', [...this.path, asset._id]);
    },
    createFolder(folderData: { name: string }) {
      this.$emit('createFolder', folderData);
    },
    openCreateModal() {
      this.isCreateModalShown = true;
    },
    closeCreateModal() {
      this.isCreateModalShown = false;
    },
    onCloseClicked() {
      this.$emit('closeClick');
    },
    confirmAssetSelection() {
      this.$emit('moveAssets');
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }: any) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.modalPage += 1;
      }
    },
    resetPageContentState() {
      (this.$refs.assetGridContainer as any).scrollTo(0, 0);
      this.modalPage = 1;
    },
    breadcrumbClicked(path: string[]) {
      this.$store.dispatch('assetsMoving/setPath', path);
    },
  },
});
