<template>
  <div class="format-selection-wrapper">
    <span class="title-container">{{ $t('contentWizard.header.formats') }}</span>
    <div class="format-grid">
      <FormatGridTile
        v-for="(format, index) in template.formats"
        :key="index"
        :style="{ 'grid-row-end': getSpan(format) }"
        :format="format"
        :template-id="template._id"
        :is-selected="isSelected(format)"
        @click.native="formatClicked(format)"
        @previewClick="showPreview(format)"
      />
    </div>
    <FormatPreviewModal
      v-if="isFormatPreviewShown"
      :format="selectedFormatForPreview"
      :template-id="template._id"
      :show="isFormatPreviewShown"
      @close="closePreview()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormatGridTile from '@/components/campaignDetail/contentCreation/formatSelection/FormatGridTile.vue';
import FormatPreviewModal from '@/components/campaignDetail/contentCreation/formatSelection/FormatPreviewModal.vue';
import { toast } from '@/services/toast';

export default {
  components: {
    FormatGridTile,
    FormatPreviewModal,
  },
  data() {
    return {
      selectedFormats: [],
      isFormatPreviewShown: false,
      selectedFormatForPreview: null,
    };
  },
  computed: {
    ...mapGetters({
      template: 'contentCreation/getTemplate',
      formats: 'contentCreation/getFormats',
      copiedContent: 'contentCreation/getCopiedContent',
    }),
  },
  mounted() {
    if (this.copiedContent !== null && this.formats.length === 0) {
      const formats = this.copiedContent.renders.map((render) => {
        return render.formatKey;
      });
      this.selectedFormats = this.template.formats.filter((format) => {
        return formats.includes(format.name);
      });
    } else {
      this.selectedFormats = this.formats;
    }
  },
  methods: {
    getSpan(format) {
      if (format.format === 'PT') {
        return 'span 6';
      }
      if (format.format === 'QU') {
        return 'span 4';
      }
      return 'span 3';
    },
    save() {
      this.$store.dispatch('contentCreation/setOrUpdateFormats', this.selectedFormats);
    },
    validate() {
      let isValid = true;

      if (this.selectedFormats.length === 0) {
        isValid = false;
        this.emitErrorMessage('selectFormat');
      }

      return isValid;
    },
    emitErrorMessage(errorMessage) {
      toast.$emit('showToast', {
        type: 'error',
        groupName: 'content-wizard-validation',
        message: this.$t(`contentWizard.validation.${errorMessage}`),
      });
    },
    formatClicked(format) {
      if (this.isSelected(format)) {
        this.selectedFormats.splice(this.findIndex(format), 1);
      } else {
        this.selectedFormats.push(format);
      }
    },
    isSelected(format) {
      return this.selectedFormats
        .map((item) => {
          return item.name;
        })
        .includes(format.name);
    },
    findIndex(format) {
      return this.selectedFormats
        .map((item) => {
          return item.name;
        })
        .indexOf(format.name);
    },
    showPreview(format) {
      this.selectedFormatForPreview = format;
      this.isFormatPreviewShown = true;
    },
    closePreview() {
      this.isFormatPreviewShown = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.format-selection-wrapper {
  .title-container {
    display: block;
    margin-bottom: rem(30px);
    font-family: $cch-font-family;
    font-size: rem(36px);
    font-weight: 800;
    color: #c5cde2;
  }
  .format-grid {
    @apply .fade-in-up;
    display: grid;
    grid-gap: rem(24px);
    grid-template-columns: repeat(auto-fill, minmax(rem(270px), 1fr));
  }
}
</style>
