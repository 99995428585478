import Vue from 'vue';
import Vuetify from 'vuetify';
import '@/styles/vuetify.scss';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    disable: true,
  },
});
