/* eslint-disable import/no-cycle */
import getLogger from '@/services/logger';
import { getAllRoles, getRoleName } from '@/services/api/roles.resource';

const LOG = getLogger('store/roles');

const state = {
  roles: [],
};

const getters = {
  getRoles(state: $TSFixMe) {
    return state.roles;
  },
};

const actions = {
  async loadAllRoles({ commit }: $TSFixMe) {
    LOG.info('Trying to fetch all roles.');
    const roles = await getAllRoles();
    commit('setRoles', roles);
  },
  async loadRoleName({ commit }: $TSFixMe, id: $TSFixMe) {
    const roleName = await getRoleName(id);
    if (roleName) {
      commit('setRoleName', { id, roleName });
    }
  },
};

const mutations = {
  setRoles(state: $TSFixMe, roles: $TSFixMe) {
    state.roles = roles;
  },
  setRoleName(state: $TSFixMe, { id, roleName }: $TSFixMe) {
    if (!state.roles.find((role: $TSFixMe) => role._id === id)) {
      state.roles.push({ _id: id, name: roleName, permissions: [] });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
