// eslint-disable-next-line import/no-cycle
import HTTP from '@/services/http';
import getLogger from '@/services/logger';
import { AssetType } from '@/types';
import { AssetShare } from '@/types/AssetShare';

const LOG = getLogger('resource/assets');
const urlPrefix = `api/v${process.env.VUE_APP_API_VERSION}/asset/asset-structure`;

const mapItems = (items: any[]): any[] => {
  return items.map((item) => {
    const obj = {
      ...item,
      type: item.structureType ? item.structureType.toLowerCase() : item.type,
      children: mapItems(item.entries ?? []),
    };
    delete obj.structureType;
    delete obj.entries;
    return obj;
  });
};

export async function deleteAssets(organizationId: string, ids: string[]) {
  LOG.log(`Attempting to delete asset with ids: ${ids.join(',')}`);
  return HTTP.delete(`${urlPrefix}/organization/${organizationId}`, {
    data: {
      assetIds: ids,
    },
  });
}

export async function getAllAssets(organizationId: string) {
  LOG.log(`Attempting to get all assets for organization: ${organizationId}`);
  const response = await HTTP.get(`${urlPrefix}/organization/${organizationId}`);
  return mapItems(response.data.entries);
}

export async function createAsset(organizationId: string, fileName: string, file: any, path: string[]) {
  LOG.log(`Attempting to create a new asset with given file name ${fileName}`);
  const formData = new FormData();
  formData.append('fileName', fileName);
  formData.append('file', file);
  formData.append('path', path.join('/'));
  return HTTP.post(`${urlPrefix}/organization/${organizationId}/file`, formData, {
    headers: {
      'Content-Type': 'multipart/form',
    },
  });
}

export async function createFolder(organizationId: string, folderName: string, path: string[]) {
  LOG.log(`Attempting to create a new folder with given name ${folderName}`);
  return HTTP.post(`${urlPrefix}/organization/${organizationId}/folder`, {
    path: path.join('/'),
    folderName,
  });
}

export async function updateAsset(
  organizationId: string,
  id: string,
  { name, file, type }: { name: string; file: any; type: AssetType },
) {
  LOG.log(`Attempting to update asset ${id}`);
  const formData = new FormData();
  formData.append('name', name);
  formData.append('file', file);
  formData.append('type', type === AssetType.FOLDER ? 'FOLDER' : 'FILE');
  return HTTP.put(`${urlPrefix}/organization/${organizationId}/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form',
    },
  });
}

export async function moveAssets(organizationId: string, ids: string[], oldPath: string[], newPath: string[]) {
  LOG.log(`Attempting to move assets with ids: ${ids}`);
  const response = await HTTP.put(`${urlPrefix}/organization/${organizationId}/move`, {
    path: newPath.join('/'),
    assetIds: ids,
  });
  return mapItems(response.data.entries);
}

export async function getShareOptions(organizationId: string, assetId: string) {
  LOG.log(`Attempting to get share options for asset with id: ${assetId}`);
  const response = await HTTP.get(
    `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/asset/${organizationId}/share/create-options/${assetId}`,
  );
  return response.data;
}

export async function shareAsset(
  assetId: string,
  senderOrganizationId: string,
  receiverOrganizationId: string,
): Promise<AssetShare> {
  LOG.log(`Attempting to share asset with id: ${assetId}`);
  const response = await HTTP.post(
    `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/asset/share`,
    {
      folderId: assetId,
      senderOrganizationId,
      receiverOrganizationId,
    },
  );
  return response.data;
}

export async function revokeShare(organizationId: string, shareId: string) {
  LOG.log(`Attempting to revoke share with id: ${shareId}`);
  const response = await HTTP.put(
    `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/asset/${organizationId}/share/${shareId}/revoke`,
  );
  return response.data;
}

export async function acceptShare(organizationId: string, shareId: string) {
  LOG.log(`Attempting to accept share with id: ${shareId}`);
  const response = await HTTP.put(
    `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/asset/${organizationId}/share/${shareId}/accept`,
  );
  return response.data;
}

export async function declineShare(organizationId: string, shareId: string) {
  LOG.log(`Attempting to decline share with id: ${shareId}`);
  const response = await HTTP.put(
    `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/asset/${organizationId}/share/${shareId}/decline`,
  );
  return response.data;
}

export async function getSentShares(senderOrganizationId: string): Promise<Array<AssetShare>> {
  LOG.log(`Attempting to get sent shares`);
  const response = await HTTP.get(
    `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/asset/share/sent/${senderOrganizationId}`,
  );
  return response.data;
}

export async function getReceivedShares(receiverOrganizationId: string): Promise<Array<AssetShare>> {
  LOG.log(`Attempting to get received shares`);
  const response = await HTTP.get(
    `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/asset/share/received/${receiverOrganizationId}`,
  );
  return response.data;
}
