
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import CampaignGridTile from '@/components/campaigns/CampaignGridTile.vue';
import CampaignsListOverview from '@/components/campaigns/CampaignsListOverview.vue';
import AppText from '@/components/shared/AppText.vue';
import CampaignHeader from '@/components/campaigns/CampaignHeader.vue';
import CampaignCreationModal from '@/components/campaigns/CampaignCreationModal.vue';
import CampaignUpdateModal from '@/components/campaigns/CampaignUpdateModal.vue';
import ViewModeSwitch from '@/components/shared/ViewModeSwitch.vue';
import DiscardModal from '@/components/shared/DiscardModal.vue';
import ContentShareModal from '@/components/campaignDetail/contentDetail/ContentShareModal.vue';
import AppHeader from '@/components/shared/AppHeader.vue';
import AppLoading from '@/components/shared/AppLoading.vue';
import ErrorBox from '@/components/shared/ErrorBox.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import EmptyContentIcon from '@/assets/images/empty_video.svg';
import { ViewModeValue } from '@/types/ViewModeValue';
import { Campaign } from '@/types';
import { getCampaignLink } from '@/helpers/helpers';
import { getCampaignShareLinkID } from '@/services/api/content.resource';

export default defineComponent({
  components: {
    CampaignGridTile,
    CampaignsListOverview,
    AppText,
    CampaignHeader,
    CampaignCreationModal,
    AppHeader,
    ErrorBox,
    EmptyContentIcon,
    AppLoading,
    ViewModeSwitch,
    CampaignUpdateModal,
    DiscardModal,
    ContentShareModal,
    AppButton,
  },
  data() {
    return {
      isModalOpen: false,
      isUpdateModalOpen: false,
      isCampaignDeletionModalOpen: false,
      isShareModalShown: false,
      campaign: null as Campaign | null,
      getLinkFunc: null as (() => Promise<string>) | null,
    };
  },
  computed: {
    ...mapGetters({
      getFilteredCampaigns: 'campaigns/getFilteredCampaigns',
      getAllCampaigns: 'campaigns/getAllCampaigns',
      token: 'auth/getToken',
      getDeletionError: 'campaigns/getDeletionError',
      isLoading: 'campaigns/getLoading',
      viewMode: 'campaigns/getViewMode',
    }),
  },
  mounted() {
    this.$store.dispatch('campaigns/loadCampaigns');
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    createNewCampaign(value: string) {
      const newCampaign = { name: value, type: 'TEST' };
      this.$store.dispatch('campaigns/createCampaign', newCampaign);
      this.closeModal();
    },
    reloadCampaigns() {
      this.$store.dispatch('campaigns/loadCampaigns');
    },
    reloadCampaignsAfterDeletion(id: string) {
      this.$store.dispatch('campaigns/deleteCampaign', id).then(() => {
        if (!this.getDeletionError) {
          this.reloadCampaigns();
        }
      });
    },
    setDeletionErrorFalse() {
      this.$store.dispatch('campaigns/setDeletionError', false);
    },
    changeViewMode(value: ViewModeValue) {
      this.$store.dispatch('campaigns/setViewMode', value);
    },
    onClickEdit(campaign: Campaign) {
      this.isUpdateModalOpen = true;
      this.campaign = campaign;
    },
    closeUpdateModal() {
      this.isUpdateModalOpen = false;
      this.campaign = null;
    },
    updateCampaign(name: string) {
      if (this.campaign) {
        this.$store.dispatch('campaigns/updateCampaign', { id: this.campaign._id, data: { name } }).then(() => {
          this.closeUpdateModal();
          this.reloadCampaigns();
        });
      }
    },
    onClickDownload(campaign: Campaign) {
      window.open(getCampaignLink(campaign._id));
    },
    onClickDelete(campaign: Campaign) {
      this.isCampaignDeletionModalOpen = true;
      this.campaign = campaign;
    },
    closeDeleteCampaignDialogue() {
      this.isCampaignDeletionModalOpen = false;
      this.campaign = null;
    },
    deleteCampaign() {
      if (this.campaign) {
        this.reloadCampaignsAfterDeletion(this.campaign._id);
        this.closeDeleteCampaignDialogue();
      }
    },
    async onClickShare(campaign: Campaign) {
      this.isShareModalShown = true;
      this.getLinkFunc = async () => await getCampaignShareLinkID(campaign._id);
    },
    closeShareModal() {
      this.isShareModalShown = false;
      this.getLinkFunc = null;
    },
  },
});
