<template>
  <div class="format-detail-container">
    <div v-for="setting in settings" :key="setting.format" class="format-detail">
      <span class="format-detail-label">{{ setting.format }}</span>
      <div class="setting-row">
        <FormInputField
          v-model="setting.meta.duration"
          :placeholder="$t('templates.creation.duration')"
          class="input-field"
          type="number"
          @input="updateData"
        />
        <FormInputField
          v-model="setting.meta.aspectRatio"
          :placeholder="$t('templates.creation.aspectRatio')"
          class="input-field"
          type="number"
          @input="updateData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormInputField from '@/components/shared/formFields/FormInputField.vue';

export default {
  components: {
    FormInputField,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      settings: [],
    };
  },
  mounted() {
    this.settings = this.data;
  },
  methods: {
    updateData() {
      this.$emit('update', this.settings);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
</style>
