
/* eslint-disable no-param-reassign */

import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import VideoPreviewStatus from '@/components/campaignDetail/contentCreation/contentSpecification/VideoPreviewStatus.vue';
import ContentPreviewBase from '@/components/campaignDetail/contentCreation/contentSpecification/ContentPreviewBase.vue';
import { getContentItemSignedUrl } from '@/services/api/content.resource';
import { RENDER_WS_STATUS } from '@/constants/content/contentCreation';
import { QUEUE_STATUS_FETCH_INTERVAL } from '@/constants/queueStatus';

export default defineComponent({
  components: {
    VideoPreviewStatus,
    ContentPreviewBase,
  },
  props: {
    formats: {
      type: Array,
      default: null,
    },
    activeLanguage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      queueStatusFetchInterval: null as number | null,
      videoSrc: null as string | null,
    };
  },
  computed: {
    ...mapGetters({
      activeTab: 'contentCreation/getActiveTab',
      previewObjects: 'contentCreation/getPreviewObjects',
    }),
    previews() {
      if (!this.previewObjects || this.previewObjects.length === 0) {
        return [];
      }
      const previewObject = this.previewObjects.find((object: any) => object.language === this.activeLanguage);
      if (!previewObject) {
        return [];
      }
      return previewObject.previews;
    },
    activePreview() {
      if (this.activeTab < this.previews.length) {
        return this.previews[this.activeTab];
      }
      return this.previews[0];
    },
  },
  watch: {
    previews: {
      handler(previews) {
        if (previews.length > 0) {
          this.setPreviewStatus();
        }
        const hasPreviewsInProgress = previews.some(
          (preview: any) => ![RENDER_WS_STATUS.FINISHED_ERROR, RENDER_WS_STATUS.COMPLETED].includes(preview.status),
        );

        if (!hasPreviewsInProgress) {
          this.clearQueueStatusFetchInterval();
        }
      },
      deep: true,
    },
    activePreview() {
      this.videoSrc = null;
      if (!this.activePreview) {
        this.$nextTick(() => {
          this.videoSrc = null;
        });
      } else if (this.activePreview.status === RENDER_WS_STATUS.FINISHED) {
        this.$nextTick(async () => {
          this.videoSrc = await this.getVideoLink(this.activePreview.id);
        });
      }
    },
  },
  async created() {
    await this.$store.dispatch('contentCreation/initializePreviewObjects');
  },
  beforeDestroy() {
    this.clearQueueStatusFetchInterval();
  },
  methods: {
    onPreviewRemoved(preview: any) {
      this.$store.dispatch('contentCreation/removePreviewObject', preview);
    },
    async getVideoLink(id: string) {
      return await getContentItemSignedUrl(id);
    },
    async startPreviewRendering(activeLanguage: any, activeFormat: any) {
      await this.$store.dispatch('contentCreation/startVideoRendering', {
        language: activeLanguage,
        format: activeFormat,
        isPreview: true,
      });

      if (!this.queueStatusFetchInterval) {
        this.queueStatusFetchInterval = setInterval(this.updateVideoJobQueueStatus, QUEUE_STATUS_FETCH_INTERVAL);
      }
    },
    clearQueueStatusFetchInterval() {
      if (this.queueStatusFetchInterval) {
        clearInterval(this.queueStatusFetchInterval);
        this.queueStatusFetchInterval = null;
      }
    },
    updateVideoJobQueueStatus() {
      this.$store.dispatch('contentCreation/fetchVideoJobQueueStatus');
    },
    setPreviewStatus() {
      this.previews.forEach((preview: any) => {
        this.sockets.subscribe(preview.id, (jobStatusDto) => {
          const { status, payload } = jobStatusDto;

          preview.status = RENDER_WS_STATUS.WAITING;
          preview.attempts = payload?.attempts || preview?.attempts || 0;

          if (status === RENDER_WS_STATUS.WAITING) {
            preview.status = RENDER_WS_STATUS.WAITING;
          }
          preview.status = status;
          if (status === RENDER_WS_STATUS.ACTIVE) {
            preview.status = RENDER_WS_STATUS.ACTIVE;
          }
          if (status === RENDER_WS_STATUS.RENDERING) {
            preview.status = RENDER_WS_STATUS.ACTIVE;
            preview.renderProgress = payload;
          } else if (status === RENDER_WS_STATUS.COMPLETED) {
            preview.status = RENDER_WS_STATUS.COMPLETED;
            preview.renderProgress = 100;
          } else if ([RENDER_WS_STATUS.RENDERING_POSTRENDER, RENDER_WS_STATUS.RENDERING_CLEANUP].includes(status)) {
            preview.renderProgress = 100;
          } else if (status === RENDER_WS_STATUS.FINISHED) {
            preview.status = RENDER_WS_STATUS.FINISHED;
            this.sockets.unsubscribe(preview.id);
            if (this.activePreview.id === preview.id) {
              this.$nextTick(async () => {
                this.videoSrc = await this.getVideoLink(this.activePreview.id);
              });
            }
          } else if (status === RENDER_WS_STATUS.FINISHED_ERROR) {
            preview.status = RENDER_WS_STATUS.FINISHED_ERROR;
            preview.error = payload;
            this.sockets.unsubscribe(preview.id);
          }
        });
      });
    },
  },
});
