<template>
  <ModalBase
    :title="template.name"
    :show="show"
    :override-style="{
      'max-height': scssVariables.modalSideOffset,
      'margin-bottom': '0 rem',
      position: 'relative',
    }"
    :override-header-style="{ 'margin-bottom': '1rem' }"
    @closeClicked="onCloseClicked"
  >
    <template #content>
      <v-carousel @change="setIndex">
        <v-carousel-item v-for="(format, index) in template.formats" :key="index">
          <div class="preview">
            <video
              v-if="show && isCurrentVideoShown(index)"
              :id="`video${index}`"
              class="preview-video"
              autoplay
              controls
              preload
            >
              <source :src="format.videoSignedUrl" />
            </video>
          </div>
        </v-carousel-item>
      </v-carousel>
    </template>
    <template #button-row>
      <div class="format-name">
        <h3>{{ getActivePreview() }}</h3>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import scssVariables from '@/styles/variables.module.scss';

export default {
  components: {
    ModalBase,
  },

  props: {
    template: {
      type: Object,
      default: null,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeIndex: 0,
      scssVariables,
    };
  },

  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    setIndex(index) {
      this.activeIndex = index;
    },
    getActivePreview() {
      return this.template.formats[this.activeIndex].humanReadableName;
    },
    isCurrentVideoShown(index) {
      return index === this.activeIndex;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.carousel {
  position: relative;
  height: 80%;
}
.preview {
  position: relative;
  height: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  .preview-video {
    position: relative;
    top: 0;
    max-height: rem(450px);
    max-width: 70%;
    box-shadow: 0 0 5px 0 rgba(71, 67, 102, 0.19);
  }
}
.format-name {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  font-size: rem(18px);
  color: $cch-dark-grey;
  font-weight: bold;
}
</style>
<style lang="scss">
@import '~@/styles/global.scss';

.v-window__prev {
  left: 0;
  right: auto;
}
.v-window__next {
  right: 0;
}
</style>
