export const TEMPLATE_TYPE = {
  VIDEO: 'VIDEO',
  HTML5: 'HTML5',
};

export default {
  TEMPLATE_TYPE,
};

export const TEMPLATE_LAYER_RAW_TYPE = {
  OPTION: 'option',
  STRING: 'string',
  NUMBER: 'number',
  URL: 'url',
};

export const TEMPLATE_LAYER_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  DATA: 'data',
};
