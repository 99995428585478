<template>
  <div>
    <div class="base-data-wrapper">
      <span class="title-container">{{ $t('contentWizard.header.basedata') }}</span>
      <FormInputField
        ref="firstInput"
        v-model="contentName"
        :placeholder="$t('contentWizard.contentDescription.contentName')"
        class="input-field"
      />
      <FormSelectionField
        :placeholder="$t('contentWizard.contentDescription.belongingCampaign')"
        class="selection-field"
        :list="campaigns"
        :selected-item="selectedCampaign"
        @input="selectCampaign"
      />
      <span class="language-label">{{ $t('contentWizard.contentDescription.languages') }}</span>
      <div class="language-selection-wrapper">
        <label v-for="(language, index) in languages" :key="index" class="languages-container">
          <input v-model="selectedLanguages" type="checkbox" :value="language" />
          <span class="checkmark">{{ capitalizeFirst($t(language)) }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import FormSelectionField from '@/components/shared/formFields/FormSelectionField.vue';
import { helperMixin } from '@/helpers/helpers';
import { toast } from '@/services/toast';

export default {
  components: {
    FormInputField,
    FormSelectionField,
  },
  mixins: [helperMixin],
  data() {
    return {
      contentName: '',
      selectedLanguages: [],
      selectedCampaign: '',
    };
  },
  computed: {
    ...mapGetters({
      languages: 'content/getLanguages',
      campaigns: 'campaigns/getFilteredCampaigns',
      campaign: 'campaigns/getCampaign',
      baseData: 'contentCreation/getBaseData',
      copiedContent: 'contentCreation/getCopiedContent',
    }),
  },
  mounted() {
    this.$refs.firstInput.focus();

    const campaignId = this.$route.params.id;
    this.$store.dispatch('contentCreation/setCampaignId', campaignId);

    if (this.copiedContent !== null) {
      this.baseData.name = this.copiedContent.name;
      const allLanguages = this.copiedContent.renders.map((render) => {
        return render.language;
      });
      const uniqueLanguages = [...new Set(allLanguages)];
      this.baseData.languages = uniqueLanguages;
      this.baseData.campaignId = this.copiedContent.campaignId;
    }

    this.contentName = this.baseData.name;
    this.selectedCampaign = this.baseData.campaignId;
    if (this.baseData.languages.length === 0) {
      this.selectedLanguages = [this.languages[0]];
    } else {
      this.contentName = this.baseData.name;
      this.selectedCampaign = this.baseData.campaignId;

      if (this.baseData.languages.length === 0) {
        this.selectedLanguages = [this.languages[0]];
      } else {
        this.selectedLanguages = this.baseData.languages;
      }
    }
    this.$store.dispatch('contentCreation/setHasUnsavedData', false);
  },
  methods: {
    save() {
      this.$store.dispatch('contentCreation/setOrUpdateBaseData', {
        name: this.contentName,
        campaignId: this.selectedCampaign,
        languages: this.selectedLanguages,
      });
    },
    validate() {
      let isValid = true;

      if (this.contentName.length === 0) {
        isValid = false;
        this.emitErrorMessage('enterContentName');
      }

      if (this.selectedLanguages.length === 0) {
        isValid = false;
        this.emitErrorMessage('selectLanguage');
      }

      return isValid;
    },
    emitErrorMessage(errorMessage) {
      toast.$emit('showToast', {
        type: 'error',
        groupName: 'content-wizard-validation',
        message: this.$t(`contentWizard.validation.${errorMessage}`),
      });
    },
    selectCampaign(val) {
      if (typeof val === 'string') {
        this.selectedCampaign = val;
        this.$store.dispatch('contentCreation/setCampaignId', val);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.base-data-wrapper {
  max-width: rem(600px);
  .title-container {
    display: block;
    margin-bottom: rem(30px);
    font-family: $cch-font-family;
    font-size: rem(36px);
    font-weight: 800;
    color: #c5cde2;
  }
  .input-field {
    margin-bottom: rem(24px);
  }
  .selection-field {
    margin-bottom: rem(24px);
  }
  .language-label {
    display: block;
    margin-bottom: rem(10px);
    font-size: rem(12px);
    color: rgba(51, 51, 51, 0.5);
  }
  .language-selection-wrapper {
    display: flex;
    flex-direction: row;
    .languages-container {
      width: rem(130px);
      height: rem(60px);
      &.languages-container:not(:last-child) {
        margin-right: rem(24px);
      }
    }
    .checkmark {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .languages-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .languages-container input:checked ~ .checkmark {
    color: $cch-buttons-blue;
    border: rem(1px) solid $cch-buttons-blue;
  }
}
</style>
