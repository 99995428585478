
import AssetItem from '@/components/assets/AssetItem.vue';
import BreadCrumbs from '@/components/assets/BreadCrumbs.vue';
import { defineComponent, PropType } from 'vue';
import { Asset, AssetRestrictions, AssetType, Breadcrumb } from '@/types';
import AppLoading from '@/components/shared/AppLoading.vue';
import FolderIcon from '@/assets/images/folder.svg';
import { SHARED_FOLDER_ID } from '@/constants/asset';

export default defineComponent({
  components: {
    AssetItem,
    BreadCrumbs,
    AppLoading,
    FolderIcon,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    assetRestrictions: {
      type: Object as PropType<AssetRestrictions>,
      required: false,
      default: null,
    },
    usedWithinModal: {
      type: Boolean,
      default: false,
    },
    modalPage: {
      type: Number,
      default: 1,
    },
    assets: {
      type: Array as PropType<Asset[]>,
      default: () => [],
    },
    isMovingAssets: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      assetsPerPage: 20,
      page: 1,
    };
  },
  computed: {
    isLoading() {
      return this.isMovingAssets
        ? this.$store.getters['assetsMoving/getLoading']
        : this.$store.getters['assets/getLoading'];
    },
    path() {
      return this.isMovingAssets ? this.$store.getters['assetsMoving/getPath'] : this.$store.getters['assets/getPath'];
    },
    searchTerm() {
      return this.isMovingAssets
        ? this.$store.getters['assetsMoving/getSearchTerm']
        : this.$store.getters['assets/getSearchTerm'];
    },
    getKeyValuePairFilterArray() {
      return this.isMovingAssets
        ? this.$store.getters['assetsMoving/getKeyValuePairFilterArray']
        : this.$store.getters['assets/getKeyValuePairFilterArray'];
    },
    getDateRangeFilter() {
      return this.isMovingAssets
        ? this.$store.getters['assetsMoving/getDateRangeFilter']
        : this.$store.getters['assets/getDateRangeFilter'];
    },
    visibleAssets() {
      let assets = this.assets;
      if (this.type) {
        assets = this.assets.filter((asset) =>
          this.isMovingAssets
            ? [this.type, AssetType.FOLDER].includes(asset.type)
            : [this.type, AssetType.FOLDER, AssetType.SHARED_FOLDER].includes(asset.type),
        );
      }
      return assets;
    },
    breadcrumbs(): Breadcrumb[] {
      const breadcrumbs: Breadcrumb[] = [{ name: 'Assets', path: [] }];
      let link = '/assets';
      this.path.forEach((id: string) => {
        const path = link
          .substring(8)
          .split('/')
          .filter((segment) => segment !== '');

        link = `${link}/${id}`;
        const { name } = (this.isMovingAssets
          ? this.$store.getters['assetsMoving/getAssetById'](id, path)
          : this.$store.getters['assets/getAssetById'](id, path)) || { name: '' };
        path.push(id);
        breadcrumbs.push({ name, path });
      });
      return breadcrumbs;
    },
    isInSharedFolder(): boolean {
      return this.$route.params.path?.startsWith(SHARED_FOLDER_ID);
    },
  },
  watch: {
    searchTerm() {
      this.resetPageContentState();
    },
    getKeyValuePairFilterArray() {
      this.resetPageContentState();
    },
    getDateRangeFilter() {
      this.resetPageContentState();
    },
  },
  mounted() {
    if (this.isMovingAssets) {
      this.$store.dispatch('assetsMoving/getAllAssets');
    } else {
      this.$store.dispatch('assets/getAllAssets');
    }
    this.$store.dispatch('updateSortedBy', {
      key: null,
      reverse: null,
    });
  },
  methods: {
    resetPageContentState() {
      window.scrollTo(0, 0);
      if (this.usedWithinModal) {
        this.$emit('resetPageContentState');
      } else {
        this.page = 1;
      }
    },
    assetClicked(asset: Asset) {
      this.$emit('assetClicked', asset);
    },
    editAsset(id: string): void {
      this.$emit('editAsset', id);
    },
    deleteAsset(id: string): void {
      this.$emit('deleteAsset', id);
    },
    moveAsset(id: string): void {
      this.$emit('moveAsset', id);
    },
    shareAsset(id: string) {
      this.$emit('shareAsset', id);
    },
    assetSelected(id: string) {
      this.$emit('assetSelected', id);
    },
    assetUnselected(id: string) {
      this.$emit('assetUnselected', id);
    },
    breadcrumbClicked(path: string[]) {
      this.$emit('breadcrumbClicked', path);
    },
  },
});
