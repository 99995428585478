<template>
  <div>
    <CreditsRowBase
      is-header
      :name="$t('general.credits.creditsDescription')"
      :price="$t('general.credits.creditsPrice')"
      :amount-of-credits="$t('general.credits.amountRender')"
    />
    <CreditsRowBase
      v-for="(creditsPack, index) in getCreditsPacks"
      :key="index"
      :index="index"
      :name="creditsPack.name"
      :price="creditsPack.price"
      :amount-of-credits="creditsPack.credits"
      @change="selectionUpdated"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CreditsRowBase from '@/components/organizations/credits/CreditsRowBase.vue';

export default {
  name: 'CreditsTable',
  components: { CreditsRowBase },
  data() {
    return {
      selectedPackages: [],
    };
  },
  computed: {
    ...mapGetters({
      getCreditsPacks: 'organizations/getCreditsPacks',
    }),
  },
  mounted() {
    this.$store.dispatch('organizations/loadCreditsPackages');
  },
  methods: {
    selectionUpdated(payload) {
      if (payload.value) {
        this.selectedPackages.push(this.getCreditsPacks[payload.index]);
      } else {
        this.selectedPackages = this.selectedPackages.filter((creditsPackage) => {
          return creditsPackage.id !== this.getCreditsPacks[payload.index].id;
        });
      }
      this.$emit('input', this.selectedPackages);
    },
  },
};
</script>
