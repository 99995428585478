export const FORMATS = {
  LANDSCAPE: 'LS',
  PORTRAIT: 'PT',
  SOCIAL_MEDIA_SQUARE: 'QU',
  HEADER_STATIC: 'HS',
  HEADER_MOBILE: 'HM',
  WIDEBOARD: 'WB',
};

export const RENDER_WS_STATUS = {
  WAITING: 'WAITING',
  ACTIVE: 'ACTIVE',
  RENDERING: 'RENDERING',
  RENDERING_POSTRENDER: 'render:postrender',
  RENDERING_CLEANUP: 'render:cleanup',
  FAILED: 'FAILED',
  FINISHED: 'FINISHED',
  FINISHED_ERROR: 'FINISHED_WITH_ERROR',
  COMPLETED: 'COMPLETED',
};

export const FINAL_STATUSES = {
  WAITING: 'WAITING',
  RENDERING: 'RENDERING',
  FAILED: 'FAILED',
  CREATED: 'CREATED',
};

export const RENDER_HTML5_PREVIEW_STATUS = {
  RENDERING: 'RENDERING',
  CREATED: 'CREATED',
  FAILED: 'FAILED',
};

export const RENDER_ATTEMPTS_MAX = 3;

export default {
  FORMATS,
  FINAL_STATUSES,
  RENDER_HTML5_PREVIEW_STATUS,
  RENDER_ATTEMPTS_MAX,
};
