<template>
  <div class="max-w-6xl mx-auto">
    <div class="w-full h-full">
      <div class="title-wrapper">
        <AppText type="title"> {{ $t('general.credits.buyCreditsTitle') }}</AppText>
      </div>
      <div class="credits-counter my-8">
        <AppText type="navigation-item">
          {{ $t('general.credits.currentlyAvailableRenders') }} <span class="counter"> {{ creditsCount }}</span>
        </AppText>
      </div>
      <CreditsTable v-model="selectedPackages" />
      <div class="flex flex-row justify-end w-full my-5">
        <div class="button-wrapper">
          <AppButton class="upgrade-button" @click="onBuyCreditsClicked">{{
            $t('general.credits.buyCreditsButton')
          }}</AppButton>
        </div>
      </div>
    </div>
    <CreditsConfirmationModal
      :show="showConfirmationForm"
      :number-of-credits="sumOfCredits"
      @close="onCloseConfirmationModal"
      @confirm="onBuyConfirmClicked"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppText from '@/components/shared/AppText.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import CreditsTable from '@/components/organizations/credits/CreditsTable.vue';
import CreditsConfirmationModal from '@/components/organizations/credits/CreditsConfirmationModal.vue';
import { toast } from '@/services/toast';

export default {
  name: 'CreditsPage',
  components: {
    CreditsConfirmationModal,
    CreditsTable,
    AppButton,
    AppText,
  },
  data() {
    return {
      showConfirmationForm: false,
      selectedPackages: [],
    };
  },
  computed: {
    ...mapGetters({
      creditsCount: 'organizations/getRemainingCredits',
    }),
    sumOfCredits() {
      let sumOfCredits = 0;
      this.selectedPackages.forEach((creditPackage) => {
        sumOfCredits += creditPackage.credits;
      });
      return sumOfCredits;
    },
  },
  mounted() {
    this.$store.dispatch('organizations/loadCreditsForLoggedInOrganization');
  },
  methods: {
    onCloseConfirmationModal() {
      this.showConfirmationForm = false;
    },
    onBuyCreditsClicked() {
      this.showConfirmationForm = true;
    },
    async onBuyConfirmClicked() {
      try {
        await this.$store.dispatch('organizations/buyCredits', this.selectedPackages);
        this.onCloseConfirmationModal();
        this.$router.push('/');
      } catch (e) {
        this.onCloseConfirmationModal();
        this.emitErrorMessage('errorBuyingCredits');
      }
    },
    emitErrorMessage(errorMessage) {
      toast.$emit('showToast', {
        type: 'error',
        groupName: 'buying-credits',
        message: this.$t(`campaigns.general.${errorMessage}`),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';

.title-wrapper {
  margin-top: rem(65px);
}

.button-wrapper {
  width: 12rem;
  height: 2rem;
}

.upgrade-button {
  width: 100%;
  height: 100%;
}
</style>
