<template>
  <div>
    <div class="format-tile-wrapper" :class="{ selected: isSelected }" @mouseover="onHover" @mouseleave="offHover">
      <img class="preview-image-container" :src="format.thumbSignedUrl" :alt="format.name" />
      <div class="format-description-container">
        <span class="format-title-container">{{ format.humanReadableName }}</span>
        <span class="format-duration-container">{{ format.duration }}</span>
        <EyeIcon class="eye-icon cursor-pointer" @click="previewClicked()" />
      </div>
    </div>
  </div>
</template>

<script>
import EyeIcon from '@/assets/images/eye.svg';

export default {
  name: 'FormatGridTile',
  components: {
    EyeIcon,
  },
  props: {
    format: {
      type: Object,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    templateId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    previewClicked() {
      this.$emit('previewClick', this.format.name);
    },
    onHover() {
      this.hover = true;
    },
    offHover() {
      this.hover = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.format-tile-wrapper {
  cursor: pointer;
  .preview-image-container {
    object-fit: contain;
    width: 100%;
  }
  .format-description-container {
    position: relative;
    width: 100%;
    height: rem(80px);
    display: flex;
    flex-direction: column;
    padding: rem(0 20px);
    justify-content: center;
    align-items: flex-start;
    background-color: #ffffff;
    .format-title-container {
      @apply .text-black .font-sans .p-2 .font-bold .text-xl;
      margin-bottom: rem(5px);
    }
    .format-duration-container {
      font-family: $cch-font-family;
      font-size: rem(14px);
    }
    .eye-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: rem(20px);
    }
  }
}
.offhover {
  @apply .opacity-100;
}

.onhover {
  @apply .opacity-25;
}
.selected {
  transition: all 0.5s;
  border: rem(2px) solid $cch-buttons-blue;
}
</style>
