import { render, staticRenderFns } from "./AssetsPage.vue?vue&type=template&id=8d14f3b6&scoped=true&"
import script from "./AssetsPage.vue?vue&type=script&lang=ts&"
export * from "./AssetsPage.vue?vue&type=script&lang=ts&"
import style0 from "./AssetsPage.vue?vue&type=style&index=0&id=8d14f3b6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d14f3b6",
  null
  
)

export default component.exports