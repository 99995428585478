
import { defineComponent, PropType } from 'vue';
import { InfoNotificationData, Notification } from '@/types';

export default defineComponent({
  name: 'NotificationItemInfo',
  components: {},
  props: {
    item: {
      type: Object as PropType<Notification>,
      required: true,
      default: null,
    },
  },
  computed: {
    text() {
      return (this.item.data as InfoNotificationData)?.text || '';
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
