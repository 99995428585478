// eslint-disable-next-line import/no-cycle
import HTTP from '@/services/http';

const urlPrefix = process.env.VUE_APP_AUTH_URL;

export async function authenticate(username: $TSFixMe, password: $TSFixMe) {
  return HTTP.post(
    `${urlPrefix}/login`,
    {
      username,
      password,
    },
    {
      // @ts-expect-error TS(2345): Argument of type '{ cache: boolean; }' is not assi... Remove this comment to see the full error message
      cache: false,
    },
  );
}

export const checkPassword = async (username: $TSFixMe, password: $TSFixMe) => {
  const response = await HTTP.post(
    `${urlPrefix}/checkPassword`,
    {
      username,
      password,
    },
    {
      // @ts-expect-error TS(2345): Argument of type '{ cache: boolean; }' is not assi... Remove this comment to see the full error message
      cache: false,
    },
  );
  return response.data;
};

export const sendPasswordResetLink = async (username: string) => {
  await HTTP.post(`${urlPrefix}/sendPasswordResetLink`, {
    accountEmail: username,
  });
};

export const resetPassword = async (resetPasswordJwt: string, newPassword: string) => {
  await HTTP.post(
    `${urlPrefix}/resetPassword`,
    {
      newPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${resetPasswordJwt}`,
      },
    },
  );
};
