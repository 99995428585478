<template>
  <ModalBase
    title="general.users.changePassword"
    :show="show"
    class="password-modal-wrapper"
    :override-style="{ 'background-color': scssVariables.cchLightGray, height: '32rem' }"
    :override-header-style="{ 'margin-bottom': '2rem' }"
    @closeClicked="onCloseClicked"
  >
    <template #content>
      <FormPasswordField
        v-if="!isUserSuperAdmin"
        v-model="currentPassword"
        class="form-field"
        :placeholder="$t('general.users.currentPassword')"
      />
      <FormPasswordField v-model="newPassword" class="form-field" :placeholder="$t('general.users.newPassword')" />
      <FormPasswordField
        v-model="repeatPassword"
        class="form-field"
        :placeholder="$t('general.users.repeatPassword')"
      />
    </template>
    <template #button-row>
      <div class="button-row">
        <AppButton type="outlined" size="small-auto" @click="onCloseClicked">
          {{ $t('general.buttons.cancel') }}
        </AppButton>
        <AppButton size="small-auto" @click="changePassword">{{ $t('general.users.changePassword') }}</AppButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import FormPasswordField from '@/components/shared/formFields/FormPasswordField.vue';
import scssVariables from '@/styles/variables.module.scss';
import { SUPERADMIN } from '@/constants/user/roles';
import { checkPassword } from '@/services/api/auth.resource';
import { toast } from '@/services/toast';
import AppButton from '@/components/shared/buttons/AppButton.vue';

export default {
  components: {
    ModalBase,
    FormPasswordField,
    AppButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: '',
      currentPassword: '',
      newPassword: '',
      repeatPassword: '',
      scssVariables,
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'auth/getUserRole',
    }),
    isUserSuperAdmin() {
      return this.userRole === SUPERADMIN;
    },
  },
  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    async changePassword() {
      if (await this.validatePasswordChange()) {
        const userObject = {
          password: this.newPassword, // this is the only change
          username: this.user.username,
          email: this.user.email,
          isPasswordChangeRequired: this.user.isPasswordChangeRequired,
          organization: this.user.organization,
          role: this.user.role,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
        };
        this.$emit('changePassword', { user: userObject, id: this.user._id });
      }
    },
    async validatePasswordChange() {
      let isValid = true;
      if (!this.isUserSuperAdmin && !(await checkPassword(this.user.username, this.currentPassword))) {
        isValid = false;
        this.emitErrorMessage(this.$t('general.users.falsePassword'));
      }

      if (this.newPassword !== this.repeatPassword) {
        isValid = false;
        this.emitErrorMessage(this.$t('general.users.matchPassword'));
      }

      return isValid;
    },
    emitErrorMessage(errorMessage) {
      toast.$emit('showToast', {
        type: 'error',
        groupName: 'content-wizard-validation',
        message: `${errorMessage}`,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.password-modal-wrapper {
  z-index: 1000;
  .form-field {
    margin-bottom: rem(24px);
  }
  .button-row {
    width: 100%;
    position: relative;
    bottom: rem(48px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
