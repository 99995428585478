<template>
  <div class="popup" @click.stop>
    <h2 class="popup-title">
      <slot />
    </h2>
    <div class="popup-body">
      <FilterPopupRadioField
        is="FilterPopupAllField"
        :category-type="categoryType"
        @resetFilterClicked="onResetFilterClicked"
        >All</FilterPopupRadioField
      >
      <FilterPopupRadioField
        is="FilterPopupRadioField"
        v-for="category in categories"
        :key="category"
        :category="category"
        :category-type="categoryType"
        class="filter-element"
        @exclusiveFilterClicked="onExclusiveFilterClicked"
        >{{ $t(category) }}</FilterPopupRadioField
      >
    </div>
  </div>
</template>

<script>
import FilterPopupRadioField from '@/components/shared/FilterPopupRadioField.vue';
import FilterPopupAllField from '@/components/shared/FilterPopupAllField.vue';

export default {
  name: 'RadioFilterPopup',
  components: {
    FilterPopupAllField,
    FilterPopupRadioField,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    categoryType: {
      type: String,
      required: true,
    },
    buttonType: {
      // Todo: Implement or remove
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    onResetFilterClicked(categoryType) {
      this.$emit('resetFilterClicked', categoryType);
    },
    onExclusiveFilterClicked(filter) {
      this.$emit('exclusiveFilterClicked', filter);
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  @apply .absolute .flex .flex-col .h-auto .bg-white .z-10 .cursor-auto;
  box-shadow: 0 0 5px 0 rgba(71, 67, 102, 0.19);
  top: 11rem;
  filter: drop-shadow(0 0 5px rgba(71, 67, 102, 0.19));
}

.popup::after {
  @apply .absolute .border-solid;
  content: '';
  bottom: 99%;
  /*left: 20%;*/
  margin-left: -5px;
  border-width: 1.5rem;
  border-color: transparent transparent white transparent;
  pointer-events: none;
  @media (min-width: 640px) {
    left: 20%;
  }
  left: 40%;
}

.popup-title {
  @apply .font-extrabold .font-sans .text-center .m-6 .mb-8 .text-black;
}
.popup-body {
  padding: 5px 1.5rem;
  max-height: calc(43px * 10);
  overflow: auto;
}
.filter-element {
  text-transform: capitalize;
}
</style>
