<template>
  <button @click="onClicked">
    <Trash />
  </button>
</template>

<script>
import Trash from '@/assets/images/trash.svg';

export default {
  name: 'TrashButton',
  components: {
    Trash,
  },
  methods: {
    onClicked() {
      this.$emit('clicked');
    },
  },
};
</script>

<style scoped lang="scss">
button {
  @apply .inline-flex .h-4 .w-4 .ml-4 .outline-none;
}
</style>
