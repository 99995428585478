import { AssetShareState } from '@/types';

export enum NotificationTabValue {
  UNREAD = 'unread',
  READ = 'read',
}

export type NotificationType = 'SHARE' | 'RENDER' | 'INFO' | 'EVENT';

export enum NotificationReceiverType {
  USER = 'USER',
  COMPANY = 'COMPANY',
  COMPANY_ROLE = 'COMPANY_ROLE',
}

export interface NotificationSender {
  _id: string;
  user: {
    _id: string;
    username: string;
  };
  organization: {
    _id: string;
    credits: number;
    name: string;
  };
  role: {
    _id: string;
    name: string;
    permissions: Array<string>;
  };
}

export interface RenderNotificationDataRenderItem {
  id: string;
  format: string;
  status: string;
  language: string;
  errorMessage: string | null;
}

export interface RenderNotificationData {
  campaign: {
    id: string;
    name: string;
  };
  content: {
    id: string;
    name: string;
  };
  renders: Array<RenderNotificationDataRenderItem>;
}

export interface InfoNotificationData {
  text: string;
}

export interface EventNotificationData {
  text: string;
}

export interface ShareNotificationData {
  receiverOrganization: {
    id: string;
    name: string;
  };
  senderOrganization: {
    id: string;
    name: string;
  };
  shareId: string;
  sharedFolder: {
    id: string;
    name: string;
  };
  state: AssetShareState;
}

export interface Notification {
  id: string;
  type: NotificationType;
  seen: boolean;
  data: RenderNotificationData | InfoNotificationData | EventNotificationData | ShareNotificationData;
  receiverType: NotificationReceiverType;
  sender: NotificationSender;
}
