import { TEMPLATE_LAYER_RAW_TYPE } from '@/constants/template/template';

const loremIpsum =
  'Lorem Ipsum Dolor Sit Amet Consectetur Adipiscing Elit Ut Ligula Est Tempor Id Vestibulum Quis Tincidunt In Nullam Ultricies Placerat Metus Finibus';
const numberLayerMax = 10;

const generateRandomLoremIpsum = (maxCharacters: $TSFixMe) => {
  return loremIpsum
    .split(' ')
    .sort(() => 0.5 - Math.random())
    .join(' ')
    .slice(0, maxCharacters);
};

const generateStringLayerContent = (textLayers: $TSFixMe) => {
  const updatedLayers: $TSFixMe = [];

  textLayers.forEach((layer: $TSFixMe) => {
    const updatedLayer = { ...layer, value: generateRandomLoremIpsum(layer.settings.maxValue) };
    updatedLayers.push(updatedLayer);
  });
  return updatedLayers;
};

const generateOptionLayerContent = (optionLayers: $TSFixMe) => {
  return optionLayers.map((layer: $TSFixMe) => {
    const randomNumber = Math.floor(Math.random() * layer.options.length);
    const randomOptionValue = layer.options[randomNumber].value;

    return {
      _id: layer._id,
      value: randomOptionValue.toString(),
    };
  });
};

const generateURLLayerContent = (urlLayers: $TSFixMe) => {
  return urlLayers.map((layer: $TSFixMe) => {
    return {
      _id: layer._id,
      value: 'https://example.com',
    };
  });
};

const generateNumberLayerContent = (numberLayers: $TSFixMe) => {
  return numberLayers.map((layer: $TSFixMe) => {
    const min = layer.settings.isMinUnlimited ? 0 : parseInt(layer.settings.minValue, 10);
    const max = layer.settings.isMaxUnlimited ? numberLayerMax : parseInt(layer.settings.maxValue, 10);
    const randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
    return {
      _id: layer._id,
      value: randomInt.toString(),
    };
  });
};

export const generateRandomLayerContent = (templateLayers: $TSFixMe) => {
  return [
    ...generateStringLayerContent(
      templateLayers.filter((template: $TSFixMe) => template.rawType === TEMPLATE_LAYER_RAW_TYPE.STRING),
    ),
    ...generateOptionLayerContent(
      templateLayers.filter((template: $TSFixMe) => template.rawType === TEMPLATE_LAYER_RAW_TYPE.OPTION),
    ),
    ...generateURLLayerContent(
      templateLayers.filter((template: $TSFixMe) => template.rawType === TEMPLATE_LAYER_RAW_TYPE.URL),
    ),
    ...generateNumberLayerContent(
      templateLayers.filter((template: $TSFixMe) => template.rawType === TEMPLATE_LAYER_RAW_TYPE.NUMBER),
    ),
  ];
};
