<template>
  <div class="user-tabs-wrapper">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="user-tab"
      :class="{ active: index === activeTab }"
      @click="onClicked(index)"
    >
      {{ tab.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: null,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onClicked(index) {
      this.$emit('changeTab', index);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.user-tabs-wrapper {
  height: rem(45px);
  border-bottom: solid rem(1px) #dfe5eb;
  display: flex;
  flex-direction: row;
  box-sizing: content-box;
  margin-bottom: 1.5rem;
  .user-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: rem(170px);
    margin-right: rem(5px);
    background-color: rgba(197, 205, 226, 0.25);
    font-family: $cch-font-family;
    font-weight: bold;
    color: $cch-light-steel-blue;
    cursor: pointer;
  }
  .active {
    background-color: $cch-light-gray;
    color: $cch-buttons-blue;
    z-index: 10;
    border-top: solid rem(1px) #dfe5eb;
  }
}
</style>
