// eslint-disable-next-line import/no-cycle
import HTTP from '@/services/http';
import getLogger from '@/services/logger';
// eslint-disable-next-line import/no-cycle
import { getCreditsPackInfo } from '@/services/api/credit.resource';
import { GroupedOrganizations, Organization } from '@/types';

const LOG = getLogger('resource/organization');
const urlPrefix = `${process.env.VUE_APP_AUTH_URL}/organization`;

export const deleteOrganization = async (id: $TSFixMe) => {
  LOG.debug(`Attempting to delete organization with id: ${id}`);
  return HTTP.delete(`${urlPrefix}/${id}`, {
    // @ts-expect-error TS(2345): Argument of type '{ cache: boolean; }' is not assi... Remove this comment to see the full error message
    cache: false,
  });
};

export const getAllOrganizations = async (): Promise<Organization[]> => {
  LOG.debug(`Attempting to get all organizations`);
  try {
    const response = await HTTP.get<Organization[]>(urlPrefix);
    return response.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (e) {
    LOG.error(`Error while getting all organizations`);
    return [];
  }
};

export const getOrganizationsForManagement = async (): Promise<Organization[]> => {
  LOG.debug(`Attempting to get organizations for management`);
  try {
    const response = await HTTP.get<Organization[]>(`${urlPrefix}/management`);
    return response.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (e) {
    LOG.error(`Error while getting organizations for management`);
    return [];
  }
};

export const getGroupedOrganizations = async (): Promise<GroupedOrganizations[]> => {
  LOG.debug(`Attempting to get grouped organizations`);
  try {
    const response = await HTTP.get(`${urlPrefix}/picker`);
    return response.data.organizationGroups;
  } catch (e) {
    LOG.error(`Error while getting grouped organizations`);
    return [];
  }
};

export const getOrganization = async (id: $TSFixMe): Promise<Organization | {}> => {
  LOG.debug(`Attempting to get organization with id: ${id}`);
  try {
    const response = await HTTP.get<Organization>(`${urlPrefix}/${id}`);
    return response.data;
  } catch (e) {
    LOG.error(`Error getting organization with id: ${id}`);
    return {};
  }
};

export const createOrganization = async (params: {
  name: string;
  credits: number;
  organizationGroupId: string | null;
}): Promise<Organization> => {
  LOG.debug(`Attempting to create a new organization with name ${params.name}`);
  const response = await HTTP.post<Organization>(urlPrefix, params);
  return response.data;
};

export const updateOrganization = async (
  id: string,
  params: { name: string; credits: number },
): Promise<Organization> => {
  LOG.debug(`Attempting to update the organization with id ${id}`);
  const response = await HTTP.put<Organization>(`${urlPrefix}/${id}`, {
    name: params.name,
    credits: params.credits,
  });
  return response.data;
};

export const getCreditsPacksInfo = async () => {
  return getCreditsPackInfo();
};
