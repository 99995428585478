export enum AssetShareState {
  OPEN = 'OPEN',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  REVOKED = 'REVOKED',
}

export interface AssetShare {
  id: string;
  folderId: string;
  folderName: string;
  receiverOrganizationId: string;
  receiverOrganizationName: string;
  senderOrganizationId: string;
  senderOrganizationName: string;
  state: AssetShareState;
  actions?: boolean;
}
