<template>
  <div class="header-popup-bar xl:mx-auto items-center justify-between mt-10 flex-wrap sm:flex-no-wrap">
    <div class="flex items-center" />
    <div class="search-input ml-0 mt-6 sm:ml-auto sm:mt-0 w-54 sm:w-64">
      <AppInput v-model="searchTerm" placeholder="general.inputs.search" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppInput from '@/components/shared/AppInput.vue';

export default {
  components: {
    AppInput,
  },
  data() {
    return {
      objectName: null,
      showSideBar: false,
      inputDebounce: null,

      clickOutsidePopupConfig: {
        handler: this.closeAnyPopup,
        middleware: this.middleware,
        events: ['click'],
        isActive: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      getUniqueValuesOfKey: 'campaigns/getUniqueValuesOfKey',
    }),
    searchTerm: {
      get() {
        return this.$store.getters['content/getSearchTerm'];
      },
      set(value) {
        clearTimeout(this.inputDebounce);

        this.inputDebounce = setTimeout(() => {
          this.$store.dispatch('campaigns/addSearchTerm', value);
          this.$store.dispatch('assets/addSearchTerm', value);
          this.$store.dispatch('templates/setSearchTerm', value);
        }, 250);
      },
    },
  },
  methods: {
    middleware(event) {
      try {
        // add class ignore to any elements you want to exclude from closing popups
        return !event.target.className.includes('ignore');
      } catch (e) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';
.header-popup-bar {
  display: flex;
}
.search-input {
  border-bottom: 1px solid $cch-dark-grey;
}
</style>
