// eslint-disable-next-line import/no-cycle
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import HTTP from '@/services/http';
import getLogger from '@/services/logger';

const LOG = getLogger('resource/assets');
const urlPrefix = `api/v${process.env.VUE_APP_API_VERSION}/helper`;

export async function checkURL(url: $TSFixMe) {
  LOG.log(`Check if ${url} is alive...`);
  return HTTP.get(`${urlPrefix}/checkurl?url=${url}`);
}

export async function getImage(url: $TSFixMe) {
  LOG.log(`Download image from ${url} as blob`);
  const httpWithoutAuth = axios.create();
  return httpWithoutAuth.get(url, { responseType: 'blob' }).then((res) => res.data);
}
