<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
};
</script>

<style lang="scss" scoped>
div {
  @apply .text-red-600;
}
</style>
