
import AppHeader from '@/components/shared/AppHeader.vue';
import DiscardModal from '@/components/shared/DiscardModal.vue';
import { defineComponent } from 'vue';
import { getSentShares, getReceivedShares } from '@/services/api/assets.resource';
import { mapGetters } from 'vuex';
import { AssetShare, AssetShareState } from '@/types';
import TrashIcon from '@/assets/images/trash.svg';
import { ITEMS_PER_PAGE_OPTIONS, DEFAULT_ITEMS_PER_PAGE } from '@/constants/pagination';

export default defineComponent({
  components: {
    AppHeader,
    DiscardModal,
    TrashIcon,
  },
  data() {
    return {
      sentShares: [] as Array<AssetShare>,
      receivedShares: [] as Array<AssetShare>,
      headers: [
        {
          text: this.$t('assets.general.shareFolderName'),
          value: 'folderName',
          align: 'start',
        },
        {
          text: this.$t('assets.general.shareReceiverOrganizationName'),
          value: 'receiverOrganizationName',
          align: 'start',
        },
        {
          text: this.$t('assets.general.shareSenderOrganizationName'),
          value: 'senderOrganizationName',
          align: 'start',
        },
        {
          text: this.$t('assets.general.shareState'),
          value: 'state',
          align: 'start',
        },
        {
          text: this.$t('assets.general.shareActions'),
          value: 'actions',
          sortable: false,
          align: 'end',
        },
      ],
      sentSharesSearchTerm: '',
      receivedSharesSearchTerm: '',
      revokingShareId: null as string | null,
      revokingShareTitle: '',
      revokingShareMessage: '',
      decliningShareId: null as string | null,
      decliningShareTitle: '',
      decliningShareMessage: '',
      loadingSentShares: false,
      loadingReceivedShares: false,
      ITEMS_PER_PAGE_OPTIONS,
      DEFAULT_ITEMS_PER_PAGE,
    };
  },
  computed: {
    ...mapGetters({
      organizationId: 'auth/getUserOrganization',
    }),
    visibleSentShares() {
      return this.sentShares.filter(
        (item) =>
          item.id.toLowerCase().includes(this.sentSharesSearchTerm.toLowerCase()) ||
          item.receiverOrganizationName.toLowerCase().includes(this.sentSharesSearchTerm.toLowerCase()) ||
          item.senderOrganizationName.toLowerCase().includes(this.sentSharesSearchTerm.toLowerCase()) ||
          item.folderName.toLowerCase().includes(this.sentSharesSearchTerm.toLowerCase()) ||
          item.state.toLowerCase().includes(this.sentSharesSearchTerm.toLowerCase()),
      );
    },
    visibleReceivedShares() {
      return this.receivedShares.filter(
        (item) =>
          item.id.toLowerCase().includes(this.receivedSharesSearchTerm.toLowerCase()) ||
          item.receiverOrganizationName.toLowerCase().includes(this.receivedSharesSearchTerm.toLowerCase()) ||
          item.senderOrganizationName.toLowerCase().includes(this.receivedSharesSearchTerm.toLowerCase()) ||
          item.folderName.toLowerCase().includes(this.receivedSharesSearchTerm.toLowerCase()) ||
          item.state.toLowerCase().includes(this.receivedSharesSearchTerm.toLowerCase()),
      );
    },
  },
  mounted() {
    this.loadSentShares();
    this.loadReceivedShares();
  },
  methods: {
    async loadSentShares() {
      try {
        this.loadingSentShares = true;
        const sentSharesTemp = await getSentShares(this.organizationId);
        sentSharesTemp.forEach((share) => {
          this.sentShares.push({
            ...share,
            actions: this.isDeletable(share),
          });
        });
      } finally {
        this.loadingSentShares = false;
      }
    },
    async loadReceivedShares() {
      try {
        this.loadingReceivedShares = true;
        const receivedSharesTemp = await getReceivedShares(this.organizationId);
        receivedSharesTemp.forEach((share) => {
          this.receivedShares.push({
            ...share,
            actions: this.isDeletable(share),
          });
        });
      } finally {
        this.loadingReceivedShares = false;
      }
    },
    isDeletable(item: AssetShare) {
      return item.state === AssetShareState.OPEN || item.state === AssetShareState.ACCEPTED;
    },
    onClickRevoke(item: AssetShare) {
      this.revokingShareId = item.id;
      this.revokingShareTitle = this.$t('assets.general.revokeShare');
      this.revokingShareMessage = `${this.$t('assets.general.confirmRevokeShare')} ${item.folderName}`;
    },
    onClickDecline(item: AssetShare) {
      this.decliningShareId = item.id;
      this.decliningShareTitle = this.$t('assets.general.declineShare');
      this.decliningShareMessage = `${this.$t('assets.general.confirmDeclineShare')} ${item.folderName}`;
    },
    async revokeShare() {
      await this.$store.dispatch('assets/revokeShares', {
        shareIds: [this.revokingShareId],
      });
      this.revokingShareId = null;
      this.revokingShareTitle = '';
      this.revokingShareMessage = '';
      this.sentShares = await getSentShares(this.organizationId);
    },
    async declineShare() {
      await this.$store.dispatch('assets/declineShare', {
        shareId: this.decliningShareId,
      });
      this.decliningShareId = null;
      this.decliningShareTitle = '';
      this.decliningShareMessage = '';
      this.receivedShares = await getReceivedShares(this.organizationId);
    },
    cancelRevokeShare() {
      this.revokingShareId = null;
      this.revokingShareTitle = '';
      this.revokingShareMessage = '';
    },
    cancelDeclineShare() {
      this.decliningShareId = null;
      this.decliningShareTitle = '';
      this.decliningShareMessage = '';
    },
  },
});
