<template>
  <div class="content-specification-wrapper">
    <span class="title-container">{{ $t('contentWizard.header.content') }}</span>
    <div class="selection-wrapper">
      <LanguageDropdown
        class="language-selection"
        :languages="languages"
        :disabled="isCropping"
        @changeLanguage="changeLanguage"
      />
      <FormatTabs
        class="format-selection"
        :formats="formats"
        :active-format="activeFormat"
        :disabled="isCropping"
        @changeFormatTab="changeFormatTab"
      />
      <div class="header-toolbar">
        <v-tooltip v-if="isUserSuperAdmin" bottom open-delay="500">
          <template #activator="{ on, attrs }">
            <div class="header-toolbar-icon" v-bind="attrs" v-on="on" @click="fillLayersWithRandomContent">
              <CommonFileTextShuffleIcon />
            </div>
          </template>
          <span>{{ $t('contentWizard.header.fillRandomContent') }}</span>
        </v-tooltip>
        <v-tooltip v-if="formats.length > 1 || languages.length > 1" bottom open-delay="500">
          <template #activator="{ on, attrs }">
            <div
              class="header-toolbar-icon"
              :class="{ disabled: isCopyDisabled }"
              v-bind="attrs"
              v-on="on"
              @click="openCopyModal"
            >
              <GroupIcon />
            </div>
          </template>
          <span>{{ $t('contentWizard.header.copyContent') }}</span>
        </v-tooltip>
      </div>
    </div>
    <div v-if="template.hasApiData" class="grid-form-fields">
      <FormInputField v-model="apiId" placeholder="ID" :style="{ 'grid-column-end': 'span 2' }" />
      <AppButton
        class="load-api-button"
        :style="{ 'grid-column-end': 'span 2' }"
        :disabled="isLoadingApiData"
        @click="loadApiData"
      >
        <span class="load-api-button-label">{{ loadApiButtonLabel }}</span>
        <SmallSpinner v-if="isLoadingApiData" class="spinner" />
      </AppButton>
    </div>
    <div v-if="singleInputLayers.length > 0 || textareaLayers.length > 0" class="grid-form-fields">
      <component
        :is="findFormField(layer[0])"
        v-for="layer in singleInputLayers"
        :key="`${activeLanguage}_${activeFormat}_${layer[0]._id}`"
        :selected-item="getValueForLayer(layer[0]._id)"
        :value="getValueForLayer(layer[0]._id)"
        :placeholder="layer[0].name"
        :list="layer[0].options"
        :style="{ 'grid-column-end': 'span 2' }"
        :layer="layer[0]"
        :min="getMinValueForLayer(layer[0])"
        :max="getMaxValueForLayer(layer[0])"
        @input="updateLayerValue($event, layer[0]._id)"
      />
      <FormTextArea
        v-for="(layer, index) in textareaLayers"
        :key="`${activeLanguage}_${activeFormat}_${layer.layers[0]._id}`"
        :placeholder="getTextareaLabel(layer.layers[0].name)"
        :lines="layer.layers"
        :active-format="activeFormat"
        :style="{ 'grid-row-end': getSpan(layer.layers[0].length), 'grid-column-end': 'span 2' }"
        :rows="getTextAreaRows(index)"
        :value="getTextAreaValues(index)"
        @input="updateTextAreaValue($event, layer.layers)"
      />
    </div>
    <div v-if="imageAssetLayers.length > 0 || videoAssetLayers.length > 0" class="media-form-fields">
      <ImageAssetSelection
        v-for="layer in imageAssetLayers"
        :ref="`${activeLanguage}_${activeFormat}_${layer._id}`"
        :key="`${activeLanguage}_${activeFormat}_${layer._id}`"
        class="asset-selection-field"
        :has-api-data="template.hasApiData"
        :placeholder="layer.name"
        :asset-restrictions="getAssetRestrictions(layer._id)"
        :asset="findRequestLayerById(layer._id)"
        @assetSelected="onImageAssetSelected($event, layer._id)"
        @removeAsset="removeAsset(layer._id)"
        @startedCropping="isCropping = true"
        @finishedCropping="isCropping = false"
      />
      <VideoAssetSelection
        v-for="layer in videoAssetLayers"
        :key="`${activeLanguage}_${activeFormat}_${layer._id}`"
        class="asset-selection-field"
        :placeholder="layer.name"
        :asset="getMediaAsset(layer._id)"
        @assetSelected="onVideoAssetSelected($event, layer._id)"
        @removeAsset="removeAsset(layer._id)"
      />
    </div>
    <div class="button-row">
      <AppButton type="outlined" size="normal-auto" @click="preRenderAllFormats">
        {{ $t('contentWizard.contentSpecification.previewAllFormats') }}
      </AppButton>
      <AppButton type="outlined" size="normal-auto" @click="preRender">
        {{ $t('contentWizard.contentSpecification.preview') }}
      </AppButton>
    </div>
    <CopyFromModal
      v-if="isCopyFromModalShown"
      :show="isCopyFromModalShown"
      placeholder="contentWizard.contentSpecification.copyContentFrom"
      :options="getCopyOptions"
      @closeClick="closeCopyFromModal"
      @copy="copyContentItem"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash-es';
import FormatTabs from '@/components/campaignDetail/contentCreation/contentSpecification/FormatTabs.vue';
import LanguageDropdown from '@/components/campaignDetail/contentCreation/contentSpecification/LanguageDropdown.vue';
import FormAssetSelection from '@/components/shared/formFields/FormAssetSelection.vue';
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import FormURLInputField from '@/components/shared/formFields/FormURLInputField.vue';
import FormNumberInputField from '@/components/shared/formFields/FormNumberInputField.vue';
import FormSelectionField from '@/components/shared/formFields/FormSelectionField.vue';
import FormTextArea from '@/components/shared/formFields/FormTextarea.vue';
import CommonFileTextShuffleIcon from '@/assets/images/common-file-text-shuffle.svg';
import GroupIcon from '@/assets/images/group.svg';
import { helperMixin } from '@/helpers/helpers';
import CharCounter from '@/components/shared/formFields/CharCounter.vue';
import CopyFromModal from '@/components/campaignDetail/contentCreation/contentSpecification/CopyFromModal.vue';
import ImageAssetSelection from '@/components/campaignDetail/contentCreation/contentSpecification/ImageAssetSelection.vue';
import VideoAssetSelection from '@/components/campaignDetail/contentCreation/contentSpecification/VideoAssetSelection.vue';
import { TEMPLATE_LAYER_RAW_TYPE, TEMPLATE_LAYER_TYPE } from '@/constants/template/template';
import { generateRandomLayerContent } from '@/services/content-creation/generateRandomLayerContent';
import { SUPERADMIN } from '@/constants/user/roles';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import { getProductData } from '@/services/api/data.resource';
import { getImage } from '@/services/api/helper.resource';
import getLogger from '@/services/logger';
import SmallSpinner from '@/components/shared/SmallSpinner.vue';
import { toast } from '@/services/toast';

export default {
  components: {
    AppButton,
    FormatTabs,
    LanguageDropdown,
    FormInputField,
    FormSelectionField,
    FormAssetSelection,
    FormTextArea,
    CommonFileTextShuffleIcon,
    GroupIcon,
    CharCounter,
    CopyFromModal,
    ImageAssetSelection,
    VideoAssetSelection,
    FormNumberInputField,
    FormURLInputField,
    SmallSpinner,
  },
  mixins: [helperMixin],
  data() {
    return {
      LOG: getLogger('contentCreation/contentCreationWizard'),
      activeFormat: '',
      activeLanguage: '',
      isCopyFromModalShown: false,
      isCropping: false,
      apiId: '',
      isLoadingApiData: false,
      mediaAsset: null,
    };
  },
  computed: {
    ...mapGetters({
      languages: 'contentCreation/getLanguages',
      contentItems: 'contentCreation/getContentItems',
      formats: 'contentCreation/getFormats',
      imageAssetLayers: 'contentCreation/getImageAssetLayers',
      videoAssetLayers: 'contentCreation/getVideoAssetLayers',
      textareaLayers: 'contentCreation/getTextareaLayers',
      singleInputLayers: 'contentCreation/getSingleInputLayers',
      template: 'contentCreation/getTemplate',
      userRole: 'auth/getUserRole',
      userOrganization: 'auth/getUserOrganization',
    }),
    loadApiButtonLabel() {
      if (this.isLoadingApiData) {
        return this.$t('contentWizard.contentSpecification.loadApiDataInProgress');
      }
      return this.$t('contentWizard.contentSpecification.loadApiData');
    },
    activeContentItem() {
      if (this.contentItems) {
        return this.contentItems
          .filter((data) => {
            return data.language === this.activeLanguage && data.format === this.activeFormat;
          })
          .pop();
      }
      return null;
    },
    contentItemsToCopy() {
      if (this.contentItems) {
        const inactiveContentItems = this.contentItems.filter((data) => {
          return data.language !== this.activeLanguage || data.composition !== this.activeFormat;
        });
        return this.validateForCopy(inactiveContentItems);
      }
      return [];
    },
    getCopyOptions() {
      const contentItemsToCopy = [];
      this.contentItemsToCopy.forEach((contentItem) => {
        const object = {
          language: contentItem.language,
          format: this.getTemplateFormatHumanReadableNameByFormatName(contentItem.format),
        };
        if (contentItem.language !== this.activeLanguage || contentItem.format !== this.activeFormat) {
          contentItemsToCopy.push(object);
        }
      });
      return contentItemsToCopy;
    },

    isUserSuperAdmin() {
      return this.userRole === SUPERADMIN;
    },
    isCopyDisabled() {
      return this.getCopyOptions.length === 0;
    },
  },
  mounted() {
    this.changeActiveFormat(this.formats[0].name);
    this.changeLanguage(this.languages[0]);
  },
  methods: {
    getMinValueForLayer(layer) {
      if (layer.settings) {
        const settings = layer.settings.find((setting) => {
          return setting.format === this.activeFormat;
        });
        if (settings) {
          if (settings.isMinUnlimited) {
            return null;
          }
          return Number(settings.minValue);
        }
        return null;
      }

      return null;
    },
    getMaxValueForLayer(layer) {
      if (layer.settings) {
        const settings = layer.settings.find((setting) => {
          return setting.format === this.activeFormat;
        });

        if (settings) {
          if (settings.isMaxUnlimited) {
            return null;
          }
          return Number(settings.maxValue);
        }
        return null;
      }
      return null;
    },
    getTemplateFormatHumanReadableNameByFormatName(name) {
      const composition = this.template.formats
        .filter((format) => {
          return format.name === name;
        })
        .pop();
      return composition.humanReadableName;
    },
    getTemplateFormatNameFromHumanReadableName(name) {
      const composition = this.template.formats
        .filter((format) => {
          return format.humanReadableName === name;
        })
        .pop();
      return composition.name;
    },
    checkApiResponse(res) {
      const resStatus = res?.status;
      const resMessage = res?.statusText;
      let error = null;

      if (!res) {
        error = 'errorNoData';
      } else if (resStatus && resStatus === 404) {
        error = 'errorApiInvalid';
      } else if (resStatus && resStatus === 500) {
        error = 'errorInternalError';
      } else if (resStatus && resMessage) {
        error = 'errorUndefined';
      }
      if (error) {
        this.LOG.error(res);
      }

      return error;
    },
    async loadApiData() {
      this.isLoadingApiData = true;
      if (!this.apiId) {
        this.emitApiErrorMessage('errorEmptyField', 5000);
        this.isLoadingApiData = false;
        return;
      }

      const data = await getProductData(this.userOrganization, this.apiId);
      const apiError = this.checkApiResponse(data);
      if (apiError) {
        this.emitApiErrorMessage(apiError, 5000);
        this.isLoadingApiData = false;
        return;
      }
      const processedImageLayers = await this.processApiImages(data);

      this.languages.forEach((language) => {
        this.formats.forEach((format) => {
          this.fillApiSingleStringLayers(data, format, language);
          this.fillApiMultiStringLayers(data, format, language);
          this.fillApiImageLayers(processedImageLayers, format, language);
        });
      });

      this.isLoadingApiData = false;
    },
    fillApiSingleStringLayers(data, format, language) {
      const singleStringLayers = this.singleInputLayers.filter((layerGroup) => layerGroup[0].rawType === 'string');

      singleStringLayers.forEach((layerGroup) => {
        layerGroup.forEach((layer) => {
          if (layer.columnName) {
            const newValue = this.getApiStringValue(data, layer.columnName, language);
            this.updateLayerValue(newValue, layer._id, true, language, format.name);
          }
        });
      });
    },
    fillApiMultiStringLayers(data, format, language) {
      const multiStringLayers = [];
      this.textareaLayers.forEach((input) => {
        input.layers.forEach((layer) => multiStringLayers.push(layer));
      });

      multiStringLayers.forEach((layer) => {
        if (layer.columnName) {
          const newValue = this.getApiStringValue(data, layer.columnName, language);
          this.updateLayerValue(newValue, layer._id, true, language, format.name);
        }
      });
    },
    fillApiImageLayers(processedImageLayers, format, language) {
      this.imageAssetLayers.forEach(async (layer) => {
        const processedImageLayer = processedImageLayers.find(
          (imageLayer) => imageLayer.columnName === layer.columnName,
        );

        if (layer.columnName && processedImageLayer && processedImageLayer.imageId !== '') {
          this.updateLayerValue(processedImageLayer.imageId, layer._id, true, language, format.name);
          this.$refs[`${this.activeLanguage}_${this.activeFormat}_${layer._id}`][0].setFinalAsset(
            processedImageLayer.imageId,
          );
        }
      });
    },
    getApiStringValue(apiData, columnName, language) {
      const colData = apiData[columnName];
      const { translated } = colData;
      let newValue = colData;

      if (translated) {
        if (language === 'german') {
          newValue = colData.de;
        } else if (language === 'french') {
          newValue = colData.fr;
        } else if (language === 'italian') {
          newValue = colData.it;
        } else {
          newValue = colData.de;
        }
      }

      return newValue;
    },
    async processApiImages(data) {
      const filteredAssetLayers = this.imageAssetLayers.filter((layer) => layer.columnName);
      const processedImageLayers = [];
      if (filteredAssetLayers && filteredAssetLayers.length > 0) {
        for (let i = 0; i < filteredAssetLayers.length; i += 1) {
          const layer = filteredAssetLayers[i];
          if (
            !processedImageLayers.find((imageLayer) => imageLayer.columnName === layer.columnName) &&
            data[layer.columnName]
          ) {
            // eslint-disable-next-line no-await-in-loop
            const newImageId = await this.createApiImageAsset(data, layer.columnName);
            if (newImageId) {
              processedImageLayers.push({ columnName: layer.columnName, imageId: newImageId });
            }
          }
        }
      }

      return processedImageLayers;
    },
    async createApiImageAsset(apiData, columnName) {
      const editedAssetsStore = this.$store.state.editedAssets;
      const colData = apiData[columnName];
      const fullFilename = colData.split('/').pop();
      const filename = fullFilename.substring(0, fullFilename.lastIndexOf('.'));

      const imageFile = await getImage(colData);
      imageFile.name = filename;
      imageFile.lastModifiedDate = new Date();

      await this.$store.dispatch('assets/createAsset', { fileName: imageFile.name, file: imageFile, path: [] });
      await this.$store.dispatch('editedAssets/createBinary', imageFile);
      if (editedAssetsStore.hasError) {
        this.LOG.error(editedAssetsStore.errorMessage);
        return null;
      }
      return editedAssetsStore.createdEditedAssetId;
    },
    updateLayerValue(
      value,
      layerId,
      isEdited = null,
      activeLanguage = this.activeLanguage,
      activeFormat = this.activeFormat,
    ) {
      const layerData = {
        id: layerId,
        value,
        isEdited,
        language: activeLanguage,
        format: activeFormat,
      };
      this.$store.dispatch('contentCreation/setValueForLayerFormatAndLanguage', layerData);
    },
    updateTextAreaValue(value, layers) {
      const values = value.split('\n');
      layers.forEach((layer, index) => {
        if (typeof values[index] === 'undefined') {
          this.updateLayerValue('', layer._id);
        } else {
          this.updateLayerValue(values[index], layer._id);
        }
      });
    },
    getAssetRestrictions(id) {
      const targetLayer = this.findLayerById(id);
      if (targetLayer.settings) {
        const activeSetting = targetLayer.settings
          .filter((setting) => {
            return setting.format === this.activeFormat;
          })
          .pop();
        if (typeof activeSetting !== 'undefined') {
          return activeSetting.meta;
        }
      }
      return undefined;
    },
    getSpan(length) {
      const span = Math.floor(length / 2) + 1;
      return `span ${span}`;
    },
    copyContentItem(contentToBeCopied) {
      const target = contentToBeCopied;

      target.format = this.getTemplateFormatNameFromHumanReadableName(target.format);
      const copyData = {
        target,
        current: {
          language: this.activeLanguage,
          format: this.activeFormat,
        },
      };
      this.$store.commit('contentCreation/copyContentItemFromTarget', copyData);
    },
    getTextareaLabel(name) {
      const endingNumbersRegex = /\d+$/g;
      return name.replace(endingNumbersRegex, '');
    },
    removeAsset(id) {
      this.updateLayerValue('', id, false);
    },
    async changeFormatTab(value) {
      if (!this.isCropping) {
        this.changeActiveFormat(value.name);
      }
    },
    changeActiveFormat(value) {
      this.activeFormat = value;
      this.$emit('formatChanged', value);
    },
    changeLanguage(value) {
      if (!this.isCropping) {
        this.activeLanguage = value;
        this.$emit('languageChanged', value);
      }
    },
    findLayerById(id) {
      return this.template.layers
        .filter((layer) => {
          return layer._id === id;
        })
        .pop();
    },
    getValueForLayer(id) {
      const foundLayer = this.findRequestLayerById(id);
      if (foundLayer) {
        return foundLayer.value;
      }
      return '';
    },
    getMediaAsset(id) {
      const foundLayer = this.findRequestLayerById(id);
      if (foundLayer) {
        return {
          id: foundLayer.id,
          value: foundLayer.value,
          isEdited: foundLayer.isEdited,
        };
      }
      return null;
    },
    getTextAreaRows(index) {
      const value = [];
      this.textareaLayers[index].layers.forEach((layer) => {
        const stringValue = this.getValueForLayer(layer._id);
        if (stringValue) {
          value.push(stringValue);
        }
      });
      return value;
    },
    getTextAreaValues(index) {
      return this.getTextAreaRows(index).join('\n');
    },
    onImageAssetSelected(assetData, layerId) {
      this.updateLayerValue(assetData.assetId, layerId, true);
    },
    onVideoAssetSelected(assetData, layerId) {
      this.updateLayerValue(assetData.assetId, layerId, assetData.isEdited);
    },
    findRequestLayerById(layerId) {
      if (this.activeContentItem) {
        return this.activeContentItem.layers
          .filter((layer) => {
            return layer.id === layerId;
          })
          .pop();
      }
      return undefined;
    },
    findFormField(layer) {
      switch (layer.rawType) {
        case TEMPLATE_LAYER_RAW_TYPE.OPTION:
          return 'FormSelectionField';
        case TEMPLATE_LAYER_RAW_TYPE.NUMBER:
          return 'FormNumberInputField';
        case TEMPLATE_LAYER_RAW_TYPE.URL:
          return 'FormURLInputField';
        case TEMPLATE_LAYER_RAW_TYPE.STRING:
          return 'FormInputField';
        default:
          return 'FormInputField';
      }
    },
    async preRender() {
      if (this.validatePreview(this.activeContentItem.layers)) {
        this.$emit('previewRequested');
      }
    },
    async preRenderAllFormats() {
      const items = this.contentItems.filter((item) => item.language === this.activeLanguage);
      for (const item of items) {
        if (this.validatePreview(item.layers)) {
          this.$emit('previewRequested', {
            language: this.activeLanguage,
            format: item.format,
          });
        }
      }
    },
    fillLayersWithRandomContent() {
      const activeLayerData = this.template.layers.map((layer) => {
        if (layer.settings) {
          const activeLayerSettings = layer.settings.find((setting) => {
            return setting.format === this.activeFormat;
          });
          return { ...layer, settings: activeLayerSettings };
        }

        return layer;
      });

      const layersWithLoremIpsum = generateRandomLayerContent(cloneDeep(activeLayerData));
      layersWithLoremIpsum.forEach((layer) => {
        this.updateLayerValue(layer.value, layer._id, true);
      });
    },
    openCopyModal() {
      if (!this.isCopyDisabled) {
        this.isCopyFromModalShown = true;
      }
    },
    closeCopyFromModal() {
      this.isCopyFromModalShown = false;
    },
    validate() {
      let isValid = true;
      this.contentItems.forEach((dataObject) => {
        if (!this.validateLayers(dataObject.layers)) {
          isValid = false;
        }
      });
      return isValid;
    },
    validatePreview(layers) {
      return this.validateLayers(layers);
    },
    validateForCopy(contentItems) {
      const itemsToCopy = [];
      contentItems.forEach((contentItem) => {
        let isValid = false;
        contentItem.layers.forEach((layer) => {
          if (layer.value) {
            isValid = true;
          }
        });
        if (isValid) {
          itemsToCopy.push(contentItem);
        }
      });
      return itemsToCopy;
    },
    validateLayers(layers) {
      let isValid = true;
      let error = 'fillField';
      layers.forEach((layer) => {
        if (!layer.value && !layer.isOptional) {
          const templateLayer = this.findLayerById(layer.id);
          if (templateLayer.type === TEMPLATE_LAYER_TYPE.DATA) {
            if (templateLayer.rawType === TEMPLATE_LAYER_RAW_TYPE.OPTION) {
              error = 'selectOption';
            } else if (templateLayer.rawType === TEMPLATE_LAYER_RAW_TYPE.NUMBER) {
              error = 'fillNumber';
            } else {
              error = 'fillField';
            }
          } else if (templateLayer.type === TEMPLATE_LAYER_TYPE.IMAGE) {
            error = 'selectImage';
          } else if (templateLayer.type === TEMPLATE_LAYER_TYPE.VIDEO) {
            error = 'selectVideo';
          } else if (templateLayer.type === TEMPLATE_LAYER_TYPE.AUDIO) {
            error = 'selectVideo';
          } else {
            error = 'fillField';
          }
          this.emitValidationErrorMessage(error, templateLayer.name);
          isValid = false;
        }
      });
      return isValid;
    },
    emitApiErrorMessage(errorMessage, duration) {
      toast.$emit('showToast', {
        type: 'error',
        groupName: 'api-data',
        duration,
        message: this.$t(`contentWizard.layers.${errorMessage}`),
      });
      this.isLoadingApiData = false;
    },
    emitValidationErrorMessage(errorMessage, field = null) {
      toast.$emit('showToast', {
        type: 'error',
        groupName: 'content-wizard-validation',
        message: `${this.$t(`contentWizard.validation.${errorMessage}`)} ${field || ''}`,
      });
    },
    closeAssetSelection() {
      this.isAssetSelectionModalShown = false;
    },
    closeAssetCropping() {
      this.isAssetCroppingModalShown = false;
    },
    backToAssets() {
      this.isAssetCroppingModalShown = false;
      this.isAssetSelectionModalShown = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.content-specification-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title-container {
    display: block;
    margin-bottom: rem(30px);
    font-family: $cch-font-family;
    font-size: rem(36px);
    font-weight: 800;
    color: #c5cde2;
  }
  .selection-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    .language-selection {
      margin-right: rem(20px);
    }
    .format-selection {
      flex-grow: 1;
    }
  }
  .grid-form-fields {
    margin: rem(24px 0);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: rem(24px);

    .spinner {
      @apply ml-4;
      width: rem(22px);
      height: rem(22px);
      margin-left: rem(16px);
    }
  }
  .media-form-fields {
    margin: (0 -0.75rem);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-content: flex-end;
    .asset-selection-field {
      margin: 0.75rem;
    }
  }
  .button-row {
    width: 100%;
    margin: rem(30px 0);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1.3rem;
  }
  .header-toolbar {
    display: flex;
    align-items: center;
    gap: rem(16px);
    justify-content: center;
    padding-left: rem(16px);
    .header-toolbar-icon {
      cursor: pointer;
      &.disabled {
        cursor: auto;
        path {
          fill: $cch-light-steel-blue !important;
        }
      }
    }
  }
}
</style>
