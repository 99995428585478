import { FORMAT } from '@/constants/template/format';
import { AssetType } from '@/types';
import { format as dateFnsFormat } from 'date-fns';

export const capitalizeFirst = (string: $TSFixMe) => string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();

export const getAssetThumbnailSrc = (asset: $TSFixMe) => {
  return `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/assets/${asset._id}/${
    asset.type === 'video' ? 'thumb.gif' : 'thumb.png'
  }?token=${localStorage.getItem('token')}`;
};

// TO BE REMOVED
export const getEditedAssetLink = (id: $TSFixMe) => {
  return `${process.env.VUE_APP_API_URL}/api/v${
    process.env.VUE_APP_API_VERSION
  }/asset/editedAsset/download/${id}?token=${localStorage.getItem('token')}`;
};

export const getEditedAssetThumbnailLink = (id: $TSFixMe) => {
  return `${process.env.VUE_APP_API_URL}/api/v${
    process.env.VUE_APP_API_VERSION
  }/asset/editedAsset/thumb/${id}?token=${localStorage.getItem('token')}`;
};

// TO BE REMOVED
export const getAssetThumbnailSrcByAssetId = (assetID: $TSFixMe, assetType: AssetType) => {
  if (assetType === AssetType.FOLDER) {
    return '';
  }
  return `${process.env.VUE_APP_API_URL}/api/v${
    process.env.VUE_APP_API_VERSION
  }/asset/${assetID}/thumb.png?token=${localStorage.getItem('token')}`;
};

export const getContentThumbnailSrc = (id: $TSFixMe) => {
  return `${process.env.VUE_APP_API_URL}/api/v${
    process.env.VUE_APP_API_VERSION
  }/contentItem/download/${id}/thumb.png?token=${localStorage.getItem('token')}`;
};
export const getContentLink = (id: $TSFixMe) => {
  return `${process.env.VUE_APP_API_URL}/api/v${
    process.env.VUE_APP_API_VERSION
  }/content/downloadZip/${id}?token=${localStorage.getItem('token')}`;
};
export const getCampaignLink = (id: $TSFixMe) => {
  return `${process.env.VUE_APP_API_URL}/api/v${
    process.env.VUE_APP_API_VERSION
  }/campaign/downloadZip/${id}?token=${localStorage.getItem('token')}`;
};

export const getTemplateLayerNames = (template: $TSFixMe) => {
  const result: $TSFixMe = [];
  template.layers.forEach((layer: $TSFixMe) => result.push(layer.layerName));
  return result;
};

export const getLanguageCodeFromLanguage = (language: $TSFixMe) => {
  switch (language) {
    case 'german':
      return 'DE';
    case 'english':
      return 'EN';
    case 'italian':
      return 'IT';
    case 'french':
      return 'FR';
    default:
      throw new Error(`Unsupported language provided: ${language}`);
  }
};

export const translateAndCapitalizeFirst = (string: $TSFixMe) => {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  return capitalizeFirst(this.$t(string));
};

export const dataURItoBlob = (dataURI: $TSFixMe) => {
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

export const getHTML5PreviewSizeFromFormat = (format: $TSFixMe) => {
  let width;
  let height;
  let widthPixels;

  switch (format) {
    case FORMAT.PORTRAIT:
      width = '300px';
      height = '600px';
      break;
    case FORMAT.MOBILE_RECTANGLE:
      width = '300px';
      height = '250px';
      break;
    case FORMAT.LANDSCAPE:
      width = '970px';
      widthPixels = 970;
      height = '250px';
      break;
    case FORMAT.LEADERBOARD:
      width = '730px';
      height = '250px';
      break;
    default:
      width = '600px';
      height = '600px';
  }
  return {
    width,
    widthPixels,
    height,
  };
};

export const getLanguageCode = (language: string): string => {
  switch (language) {
    case 'german':
      return 'DE';
    case 'english':
      return 'EN';
    case 'italian':
      return 'IT';
    case 'french':
      return 'FR';
    default:
      throw new Error(`Unsupported language provided: ${language}`);
  }
};

export const formatDate = (date: string) => {
  return dateFnsFormat(new Date(date), 'dd. MMM yyyy');
};

export const helperMixin = {
  methods: {
    capitalizeFirst,
    getAssetThumbnailSrc,
    getContentThumbnailSrc,
    getAssetThumbnailSrcByAssetId,
    getTemplateLayerNames,
    getLanguageCodeFromLanguage,
    translateAndCapitalizeFirst,
    dataURItoBlob,
    getEditedAssetLink,
    getLanguageCode,
    formatDate,
  },
};
