<template>
  <div class="toast-viewer">
    <transition-group name="fade">
      <div v-for="toast in toasts" :key="toast.message" class="toast" :class="toast.type">
        <span>{{ toast.message }}</span>
        <CloseIcon v-if="toast.isToastPermanent" class="close" @click="clearToastsById(toast.id)" />
      </div>
    </transition-group>
  </div>
</template>
<script>
import CloseIcon from '@/assets/images/close-scalable.svg';
import { DURATION } from '@/constants/duration';
import { toast } from '@/services/toast';

export default {
  name: 'ToastViewer',
  components: {
    CloseIcon,
  },
  data() {
    return {
      toasts: [],
      toastCounter: 0,

      defaultDuration: DURATION.SHORT,
      defaultType: 'info',
      defaultGroupName: 'default',
      defaultMessage: '',
    };
  },
  mounted() {
    toast.$on('showToast', this.showToast);
    toast.$on('clearToasts', this.clearToasts);
    toast.$on('clearToastsByGroupName', this.clearToastsByGroupName);
  },
  methods: {
    clearToastsById(toastId) {
      this.toasts = this.toasts.filter((t) => t.id !== toastId);
    },
    clearToastsByGroupName(groupName) {
      this.toasts = this.toasts.filter((t) => t.groupName !== groupName);
    },
    clearToastsWithMessage(message) {
      this.toasts = this.toasts.filter((t) => t.message !== message);
    },
    clearToasts() {
      this.toasts = [];
    },
    showToast(details = {}) {
      const currentId = this.toastCounter;
      this.clearToastsWithMessage(details.message);

      this.toasts.push({
        id: currentId,
        type: details.type || this.defaultType,
        groupName: details.groupName || this.defaultGroupName,
        message: details.message || this.defaultMessage,
        isToastPermanent: details.isToastPermanent,
      });

      if (!details.isToastPermanent) {
        setTimeout(() => this.clearToastsById(currentId), details.duration || this.defaultDuration);
      }

      this.toastCounter += 1;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.module.scss';

.toast-viewer {
  @apply .fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  width: 30%;

  .toast {
    @apply .text-white .px-4 .mb-2 .flex .justify-between .items-center;
    background-color: $cch-default-mask;
    border-radius: rem(4px);

    min-width: rem(200px);
    height: 50px;

    .close {
      @apply .cursor-pointer .ml-4;
      width: rem(15px);
      height: rem(15px);
      fill: white;

      &:hover {
        fill: $cch-light-gray;
      }
    }

    &.info {
      background-color: $cch-status-color-info;
    }

    &.success {
      background-color: $cch-status-color-success;
    }

    &.warning {
      background-color: $cch-status-color-warning;
      color: black;
    }

    &.error {
      background-color: $cch-status-color-error;
    }
  }
}
</style>
