import getLogger from '@/services/logger';
import { Notification } from '@/types';
import HTTP from '../http';

const LOG = getLogger('resource/notification');

export const getUnreadNotifications = async (organizationId: string): Promise<Notification[]> => {
  LOG.debug(`Attempting to get all unread notifications`);
  try {
    const response = await HTTP.get(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/notification/${organizationId}/new-notifications-paginated?skip=0&take=1000000`,
    );
    return response.data;
  } catch (e) {
    LOG.error(`Error while getting all unread notifications`);
    return [];
  }
};

export const getReadNotifications = async (organizationId: string): Promise<Notification[]> => {
  LOG.debug(`Attempting to get all read notifications`);
  try {
    const response = await HTTP.get(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/notification/${organizationId}/old-notifications-paginated?skip=0&take=1000000`,
    );
    return response.data;
  } catch (e) {
    LOG.error(`Error while getting all read notifications`);
    return [];
  }
};

export const markNotificationSeen = async (organizationId: string, id: string): Promise<void> => {
  LOG.debug(`Attempting to mark notification seen`);
  try {
    await HTTP.post(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/notification/${organizationId}/notification-seen/${id}`,
    );
  } catch (e) {
    LOG.error(`Error while marking notification seen`);
  }
};

export const markAllNotificationsSeen = async (organizationId: string): Promise<void> => {
  LOG.debug(`Attempting to mark all notifications seen`);
  try {
    await HTTP.post(
      `${process.env.VUE_APP_API_URL}/api/v${process.env.VUE_APP_API_VERSION}/notification/${organizationId}/all-notifications-seen`,
    );
  } catch (e) {
    LOG.error(`Error while marking all notifications seen`);
  }
};
