// eslint-disable-next-line import/no-cycle
import HTTP from '@/services/http';
import getLogger from '@/services/logger';
// eslint-disable-next-line import/no-cycle
import store from '../../store/index';

const LOG = getLogger('resource/campaign');
const urlPrefix = `api/v${process.env.VUE_APP_API_VERSION}/campaign`;

export async function deleteCampaign(id: $TSFixMe) {
  LOG.log(`Attempting to delete campaign with id: ${id}`);
  return HTTP.delete(`${urlPrefix}/${id}`, {
    // @ts-expect-error TS(2345): Argument of type '{ cache: boolean; }' is not assi... Remove this comment to see the full error message
    cache: false,
  });
}
export async function updateCampaign(id: $TSFixMe, campaign: $TSFixMe) {
  LOG.log(`Attempting to update campaign with id: ${id}`);
  return HTTP.patch(`${urlPrefix}/${id}`, campaign);
}

export async function getAllCampaigns() {
  const organizationId = store.getters['auth/getUserOrganization'];
  LOG.log(`Attempting to get all campaigns for organization: ${organizationId}`);
  return HTTP.get(`${urlPrefix}/organization/${organizationId}`);
}

export async function getCampaign(id: $TSFixMe) {
  LOG.log(`Attempting to get campaign with id: ${id}`);
  return HTTP.get(`${urlPrefix}/${id}`);
}

export async function createNewCampaign(formData: $TSFixMe) {
  LOG.log(`Attempting to create a new campaign with name ${formData.name}`);
  return HTTP.post(urlPrefix, formData, {});
}
