
import { defineComponent, PropType } from 'vue';
import { TabComponent } from '@/types/TabComponent';
import TabHeader from '@/components/shared/tabComponent/TabHeader.vue';
import TabFooter from '@/components/shared/tabComponent/TabFooter.vue';
import DiscardModal from '@/components/shared/DiscardModal.vue';

export default defineComponent({
  components: {
    TabHeader,
    DiscardModal,
    TabFooter,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array as PropType<Array<TabComponent>>,
      default: null,
    },
  },
  data() {
    return {
      activeTabIndex: 0,
      activeTabConfirmModalIsShown: false,
    };
  },
  beforeDestroy() {
    this.activeTabIndex = 0;
  },
  methods: {
    onActiveTabClickNext() {
      const activeTab = this.tabs[this.activeTabIndex];
      if (activeTab.beforeNextConfirmRequired) {
        this.activeTabConfirmModalIsShown = true;
      } else {
        this.processToNextTab();
      }
    },
    onActiveTabClickPrevious() {
      this.processToPreviousTab();
    },
    onActiveTabConfirmOk() {
      this.activeTabConfirmModalIsShown = false;
      this.processToNextTab();
    },
    onActiveTabConfirmNo() {
      this.activeTabConfirmModalIsShown = false;
    },
    async processToNextTab() {
      const currentTabInstance = this.$refs.currentTab as any;
      if (currentTabInstance.validate()) {
        const beforeNextFunction = this.tabs[this.activeTabIndex].beforeNextFunction;
        if (beforeNextFunction) {
          await currentTabInstance[beforeNextFunction]();
        }
        if (this.activeTabIndex !== this.tabs.length - 1) {
          this.activeTabIndex = (this.activeTabIndex + 1) % this.tabs.length;
        } else {
          this.$emit('lastItem');
        }
      }
    },
    async processToPreviousTab() {
      if (this.activeTabIndex !== 0) {
        const beforePreviousFunction = this.tabs[this.activeTabIndex].beforePreviousFunction;
        if (beforePreviousFunction) {
          const currentTabInstance = this.$refs.currentTab as any;
          await currentTabInstance[beforePreviousFunction]();
        }
        this.activeTabIndex = (this.activeTabIndex - 1) % this.tabs.length;
      }
    },
  },
});
