
import { defineComponent } from 'vue';
import { Organization } from '@/types';
import {
  createOrganization,
  getOrganizationsForManagement,
  updateOrganization,
} from '@/services/api/organizations.resource';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import OrganizationSaveModal from '@/components/organizations/OrganizationSaveModal.vue';
import EditIcon from '@/assets/images/edit.svg';
import { ITEMS_PER_PAGE_OPTIONS, DEFAULT_ITEMS_PER_PAGE } from '@/constants/pagination';

export default defineComponent({
  components: {
    EditIcon,
    AppButton,
    OrganizationSaveModal,
  },
  data() {
    return {
      organizations: [] as Array<Organization>,
      headers: [
        {
          text: this.$t('organizations.general.name'),
          value: 'name',
          align: 'start',
        },
        {
          text: this.$t('organizations.general.organizationGroupName'),
          value: 'organizationGroupName',
          align: 'start',
        },
        {
          text: this.$t('organizations.general.numberOfUsers'),
          value: 'numberOfUsers',
          align: 'end',
        },
        {
          text: this.$t('organizations.general.numberOfCredits'),
          value: 'credits',
          align: 'end',
        },
        {
          text: this.$t('organizations.general.actions'),
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      searchTerm: '',
      isSaveModalShown: false,
      editingOrganization: null as Organization | null,
      isSaving: false,
      isLoading: false,
      ITEMS_PER_PAGE_OPTIONS,
      DEFAULT_ITEMS_PER_PAGE,
    };
  },
  computed: {
    visibleOrganizations() {
      if (this.searchTerm) {
        return this.organizations.filter(
          (item) =>
            item.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            item.organizationGroupName?.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            item.numberOfUsers?.toString().includes(this.searchTerm) ||
            item.credits.toString().includes(this.searchTerm),
        );
      }
      return this.organizations;
    },
  },
  async mounted() {
    try {
      this.isLoading = true;
      this.organizations = await getOrganizationsForManagement();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onClickEdit(item: Organization) {
      this.editingOrganization = item;
      this.isSaveModalShown = true;
    },
    onClickNew() {
      this.isSaveModalShown = true;
    },
    onCloseSaveModal() {
      this.editingOrganization = null;
      this.isSaveModalShown = false;
    },
    async onSubmitSaveModal(payload: { name: string; credits: number; organizationGroupId: string | null }) {
      try {
        this.isSaving = true;
        if (this.editingOrganization) {
          await updateOrganization(this.editingOrganization._id, {
            name: payload.name,
            credits: payload.credits,
          });
        } else {
          await createOrganization({
            name: payload.name,
            credits: payload.credits,
            organizationGroupId: payload.organizationGroupId,
          });
        }
        this.editingOrganization = null;
        this.isSaveModalShown = false;
        this.organizations = await getOrganizationsForManagement();
      } finally {
        this.isSaving = false;
      }
    },
  },
});
