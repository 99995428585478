// eslint-disable-next-line import/no-cycle
import HTTP from '@/services/http';
import getLogger from '@/services/logger';

const LOG = getLogger('resource/roles');
const urlPrefix = `${process.env.VUE_APP_AUTH_URL}/role`;

export const getRoleName = async (id: $TSFixMe) => {
  LOG.debug(`Attempting to get name for role ${id}`);
  try {
    const response = await HTTP.get(`${urlPrefix}/${id}/name`);
    return response.data;
  } catch (e) {
    LOG.error(`Error while getting name for role ${id}`);
    return '';
  }
};

export const getAllRoles = async () => {
  LOG.log(`Attempting to get all roles`);
  try {
    const response = await HTTP.get(urlPrefix);
    return response.data;
  } catch (e) {
    LOG.error(`Error while getting all roles`);
    return [];
  }
};
