export const CONTENT_FETCH_DELAYS = {
  MIN: 500,
  MAX: 10000,
};

export const CONTENT_FETCH_COUNTERS = {
  SHORT_DELAY_FETCHES_ALLOCATED: 5,
};

export default {
  CONTENT_FETCH_DELAYS,
  CONTENT_FETCH_COUNTERS,
};
