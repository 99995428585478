<template>
  <label class="radio-container">
    <slot />
    <input ref="radio" class="radio-input" type="radio" :name="radioGroupName" @click="onRadioSelected" />
    <span class="custom-radio" />
  </label>
</template>

<script>
export default {
  name: 'PopupRadioField',
  props: {
    radioGroupName: {
      type: String,
      default: 'Option',
      required: false,
    },
    linkedData: {
      type: Object,
      required: true,
    },
    isDefault: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  mounted() {
    if (this.isDefault) {
      this.$refs.radio.checked = true;
    }
  },
  methods: {
    onRadioSelected() {
      if (this.linkedData) {
        this.$emit('radioSelected', this.linkedData);
      }
    },
  },
};
</script>

<style scoped>
.radio-container {
  @apply .text-blue-light .font-sans .font-semibold .text-base .mb-6 .relative .text-left .px-10 .block .cursor-pointer;
}

.radio-input {
  @apply .opacity-0 .w-0 .h-0 .absolute;
}

.custom-radio {
  @apply .absolute .w-6 .h-6 .rounded-full .cursor-pointer;
  top: -0.1rem;
  left: 0.1rem;
  border: solid 1.5px #909cb0;
}

.radio-input:checked + .custom-radio {
  border: solid 1.5px black;
}

.radio-input:checked + .custom-radio::after {
  @apply .absolute .bg-black .rounded-full;
  content: '';
  top: 0.2rem;
  left: 0.2rem;
  width: 0.9rem;
  height: 0.9rem;
  border: solid 1.5px black;
}
</style>
