import { cloneDeep, isBoolean } from 'lodash-es';

import jwtDecode from 'jwt-decode';
import getLogger from '@/services/logger';

// eslint-disable-next-line import/no-cycle
import { enqueueRenderJob, getJobQueueStatus, startHtmlRenderJob } from '@/services/api/render.resource';
// eslint-disable-next-line import/no-cycle
import { checkURL } from '@/services/api/helper.resource';

import * as TEMPLATE from '@/constants/template/template';
import { RENDER_HTML5_PREVIEW_STATUS } from '@/constants/content/contentCreation';

const LOG = getLogger('store/contentCreation');

const state = {
  languages: [],
  template: null,
  formats: [],
  content: {
    templateID: '',
    campaignID: '',
    contentName: '',
    contentItems: [],
  },
  hasUnsavedData: false,
  previewObjects: [],
  copiedContent: null,
  activeTab: 0,
  videoJobQueueStatus: {
    isFetchingData: false,
    preview: {
      enqueued: 0,
      waiting: 0,
      active: 0,
    },
    render: {
      enqueued: 0,
      waiting: 0,
      active: 0,
    },
  },
};

const getters = {
  getActiveTab(state: $TSFixMe) {
    return state.activeTab;
  },
  getTemplateType(state: $TSFixMe) {
    return state.template.type;
  },
  getBaseData(state: $TSFixMe, getters: $TSFixMe, rootState: $TSFixMe, rootGetters: $TSFixMe) {
    let languages = [];
    if (state.languages.length === 0) {
      const allLanguages = rootGetters['content/getLanguages'];
      languages = [allLanguages[0]];
    } else {
      languages = state.languages;
    }
    return {
      languages,
      name: state.content.contentName,
      campaignId: state.content.campaignID,
    };
  },
  getTemplate(state: $TSFixMe) {
    return state.template;
  },
  getFormats(state: $TSFixMe) {
    return state.formats;
  },
  getContent(state: $TSFixMe) {
    return state.content;
  },
  getContentItems(state: $TSFixMe) {
    return state.content.contentItems;
  },
  getPreviewObjects(state: $TSFixMe) {
    return state.previewObjects;
  },
  getCopiedContent(state: $TSFixMe) {
    return state.copiedContent;
  },
  getGroupedLayers(state: $TSFixMe) {
    const layerKeys: $TSFixMe = [];
    state.template.layers.forEach((layer: $TSFixMe) => {
      const tmpLayer = layer.layerName;
      layerKeys.push(tmpLayer.replace(new RegExp('[0-9]+$', 'g'), ''));
    });
    const duplicateKeys = [...new Set(layerKeys)];
    const groupedLayers: $TSFixMe = [];
    duplicateKeys.forEach((key) => {
      const tmpLayers: $TSFixMe = [];
      state.template.layers.forEach((layer: $TSFixMe) => {
        if (layer.layerName.includes(key) && layer.type === 'data' && (!layer.rawType || layer.rawType === 'string')) {
          tmpLayers.push(layer);
        } else if (
          layer.layerName.includes(key) &&
          (!layer.rawType || layer.rawType !== 'string') &&
          // @ts-expect-error TS(7006): Parameter 'obj' implicitly has an 'any' type.
          !groupedLayers.some((obj) => obj[0]._id === layer._id)
        ) {
          groupedLayers.push([layer]);
        }
      });
      if (tmpLayers.length !== 0) {
        groupedLayers.push(tmpLayers);
      }
    });
    return groupedLayers;
  },
  getImageAssetLayers(state: $TSFixMe) {
    if (state.template) {
      return state.template.layers.filter((data: $TSFixMe) => {
        return data.type === 'image';
      });
    }
    return [];
  },
  getVideoAssetLayers(state: $TSFixMe) {
    if (state.template) {
      return state.template.layers.filter((data: $TSFixMe) => {
        return data.type === 'video';
      });
    }
    return [];
  },
  getTextareaLayers(state: $TSFixMe, getters: $TSFixMe) {
    const textareaLayers = getters.getGroupedLayers.filter((data: $TSFixMe) => {
      return data.length > 1;
    });
    const textareaObjects: $TSFixMe = [];
    textareaLayers.forEach((layers: $TSFixMe) => {
      const textareaObject = {
        layers,
      };
      textareaObjects.push(textareaObject);
    });
    return textareaObjects;
  },
  getSingleInputLayers(state: $TSFixMe, getters: $TSFixMe) {
    return getters.getGroupedLayers.filter((data: $TSFixMe) => {
      return data.length === 1 && data[0].type === 'data';
    });
  },
  getCopiedContentDataByFormatLanguageAndLayerId(state: $TSFixMe) {
    return (format: $TSFixMe, language: $TSFixMe, id: $TSFixMe) => {
      const foundRender = state.copiedContent.renders
        .filter((render: $TSFixMe) => {
          return render.formatKey === format && render.language === language;
        })
        .pop();
      if (!foundRender) {
        return null;
      }
      return foundRender.data
        .filter((layer: $TSFixMe) => {
          return layer.id === id;
        })
        .pop();
    };
  },
  getLanguages(state: $TSFixMe) {
    return state.languages;
  },
  getHasUnsavedData(state: $TSFixMe) {
    return state.hasUnsavedData;
  },
  getVideoJobQueueStatus(state: $TSFixMe) {
    return state.videoJobQueueStatus;
  },
};

const actions = {
  resetContent({ commit }: $TSFixMe) {
    commit('resetContent');
  },

  startRendering({ state, dispatch }: $TSFixMe) {
    if (state.template.type === TEMPLATE.TEMPLATE_TYPE.VIDEO) {
      dispatch('startVideoRendering', { isPreview: false });
    }
    if (state.template.type === TEMPLATE.TEMPLATE_TYPE.HTML5) {
      dispatch('startHtmlFinalRendering');
    }
  },
  startVideoRendering({ commit, state, dispatch, rootGetters }: $TSFixMe, data: $TSFixMe) {
    LOG.info('Starting rendering!');
    const organizationId = rootGetters['auth/getUserOrganization'];
    if (data.isPreview) {
      const activeContentItem = state.content.contentItems
        .filter((contentItem: $TSFixMe) => {
          return data.language === contentItem.language && data.format === contentItem.format;
        })
        .pop();

      enqueueRenderJob(
        state.template._id,
        state.content.campaignID,
        organizationId,
        [activeContentItem],
        '',
        true,
      ).then((response) => {
        LOG.info(response);
        commit('addPreviewObject', response.data[0]);
      });
    } else {
      enqueueRenderJob(
        state.template._id,
        state.content.campaignID,
        organizationId,
        state.content.contentItems,
        state.content.contentName,
        false,
      ).then(() => {
        dispatch('campaigns/loadCampaigns', null, { root: true });
      });

      dispatch('content/loadContent', null, { root: true });
    }
  },
  async startHtmlPreviewRendering({ commit, state, rootGetters }: $TSFixMe, data: $TSFixMe) {
    LOG.info('Starting HTML Preview rendering!');

    const organizationId = rootGetters['auth/getUserOrganization'];
    const activeContentItem = state.content.contentItems
      .filter((contentItem: $TSFixMe) => {
        return data.language === contentItem.language && data.format === contentItem.format;
      })
      .pop();

    commit('addPreviewObject', {
      tmpId: data.tmpId,
      language: data.language,
      format: data.format,
      status: RENDER_HTML5_PREVIEW_STATUS.RENDERING,
      renderProgress: 0,
    });

    const response = await startHtmlRenderJob(
      state.template._id,
      state.content.campaignID,
      organizationId,
      [activeContentItem],
      state.content.contentName,
      true,
    );
    LOG.info(response);
    return response;
  },
  async startHtmlFinalRendering({ state, dispatch, rootGetters }: $TSFixMe) {
    LOG.info('Starting HTML rendering!');

    const organizationId = rootGetters['auth/getUserOrganization'];
    await startHtmlRenderJob(
      state.template._id,
      state.content.campaignID,
      organizationId,
      state.content.contentItems,
      state.content.contentName,
      false,
    );
    dispatch('campaigns/loadCampaigns', null, { root: true });
    dispatch('content/loadContent', null, { root: true });
  },
  async fetchVideoJobQueueStatus({ state, commit }: $TSFixMe) {
    if (state.videoJobQueueStatus.isFetchingData) {
      LOG.debug('Already fetching QueueStatus, not fetching again...');
      return;
    }

    commit('setIsFetchingJobQueueStatus', true);
    try {
      const { data } = await getJobQueueStatus();
      data.isFetchingData = false;
      commit('setVideoJobQueueStatus', data);
    } catch (e) {
      commit('setIsFetchingJobQueueStatus', false);
      LOG.error('Error while getting the job queue status.', e);
    }
  },
  removePreviewObject({ commit }: $TSFixMe, previewToRemove: $TSFixMe) {
    commit('removePreviewObject', previewToRemove);
  },
  setCampaignId({ commit }: $TSFixMe, campaignId: $TSFixMe) {
    commit('setCampaignId', campaignId);
    commit('setHasUnsavedData', true);
  },
  setOrUpdateBaseData({ state, dispatch, commit }: $TSFixMe, data: $TSFixMe) {
    if (state.languages.length !== 0) {
      commit('setBaseData', data);
      dispatch('syncContentItemsToLanguagesAndFormats');
    } else {
      commit('setBaseData', data);
    }
  },
  setOrUpdateTemplate({ state, commit }: $TSFixMe, template: $TSFixMe) {
    if (state.template === null) {
      commit('setTemplate', template);
    } else if (state.template !== template) {
      commit('setTemplate', template);
      commit('setContentItems', []);
      commit('setFormats', []);
    }
  },
  setOrUpdateFormats({ state, commit, dispatch }: $TSFixMe, formats: $TSFixMe) {
    if (state.formats.length !== 0) {
      commit('setFormats', formats);
      dispatch('syncContentItemsToLanguagesAndFormats');
    } else {
      commit('setFormats', formats);
      dispatch('initializeContentItems');
    }
  },
  initializePreviewObjects({ commit }: $TSFixMe) {
    commit('initializePreviewObjects');
  },
  setCopiedContent({ commit }: $TSFixMe, content: $TSFixMe) {
    commit('setCopiedContent', content);
  },
  resetCopiedContent({ commit }: $TSFixMe) {
    commit('resetCopiedContent');
  },
  setValueForLayerFormatAndLanguage({ commit }: $TSFixMe, layerData: $TSFixMe) {
    commit('setValueForLayerFormatAndLanguage', layerData);
  },
  createAndSaveContentItem({ state, commit, getters }: $TSFixMe, { format, language }: $TSFixMe) {
    const contentItem = { format: format.name, language, layers: [] };
    state.template.layers.forEach((layer: $TSFixMe) => {
      let layerToCopy;
      if (state.copiedContent) {
        layerToCopy = getters.getCopiedContentDataByFormatLanguageAndLayerId(
          contentItem.format,
          contentItem.language,
          layer._id,
        );
      }
      let isOptional;
      if (layerToCopy && isBoolean(layerToCopy.isOptional)) {
        isOptional = layerToCopy.isOptional;
      } else if (layer.isOptional === undefined || layer.isOptional === null) {
        isOptional = layer.rawType === 'string';
      } else if (layer.rawType === 'string') {
        isOptional = true;
      } else {
        isOptional = layer.isOptional;
      }
      const layerObject = {
        id: layer._id,
        value: layerToCopy ? layerToCopy.value : '',
        isEdited: layerToCopy ? layerToCopy.isEdited : null,
        isOptional,
      };
      // @ts-expect-error TS(2345): Argument of type '{ id: any; value: any; isEdited:... Remove this comment to see the full error message
      contentItem.layers.push(layerObject);
    });
    commit('addContentItem', contentItem);
  },
  removeUnneededContentItems({ state, commit }: $TSFixMe) {
    const contentItemsToRemove: $TSFixMe = [];
    state.content.contentItems.forEach((contentItem: $TSFixMe) => {
      if (
        !state.languages.includes(contentItem.language) ||
        !state.formats.map((format: $TSFixMe) => format.name).includes(contentItem.format)
      ) {
        contentItemsToRemove.push(contentItem);
      }
    });
    // @ts-expect-error TS(7006): Parameter 'contentItem' implicitly has an 'any' ty... Remove this comment to see the full error message
    contentItemsToRemove.forEach((contentItem) => {
      commit('removeContentItem', contentItem);
    });
  },
  addNewlyRequestedContentItems({ state, dispatch }: $TSFixMe) {
    state.languages.forEach((language: $TSFixMe) => {
      state.formats.forEach((format: $TSFixMe) => {
        const existingItem = state.content.contentItems
          .filter((contentItem: $TSFixMe) => contentItem.language === language && contentItem.format === format.name)
          .pop();
        if (!existingItem) {
          dispatch('createAndSaveContentItem', { format, language });
        }
      });
    });
  },
  syncContentItemsToLanguagesAndFormats({ dispatch, state }: $TSFixMe) {
    if (isNotReadyToInitializeContentItems(state)) {
      return;
    }
    if (shouldInitializeContentItems(state)) {
      dispatch('initializeContentItems');
      return;
    }
    dispatch('removeUnneededContentItems');
    dispatch('addNewlyRequestedContentItems');
  },
  initializeContentItems({ dispatch, state }: $TSFixMe) {
    state.languages.forEach((language: $TSFixMe) => {
      state.formats.forEach((format: $TSFixMe) => {
        dispatch('createAndSaveContentItem', { format, language });
      });
    });
  },
  setHasUnsavedData({ commit }: $TSFixMe, hasUnsavedData: $TSFixMe) {
    commit('setHasUnsavedData', hasUnsavedData);
  },
  checkURL(state: $TSFixMe, url: $TSFixMe) {
    return checkURL(url)
      .then((response) => {
        if (response.data) {
          return true;
        }
        throw new Error('Invalid url');
      })
      .catch(() => {
        throw new Error('Invalid url');
      });
  },
};

const mutations = {
  resetContent(state: $TSFixMe) {
    state.languages = [];
    state.template = null;
    state.formats = [];
    state.content.templateID = '';
    state.content.contentName = '';
    state.content.contentItems = [];
    state.hasUnsavedData = false;
  },
  setCampaignId(state: $TSFixMe, campaignId: $TSFixMe) {
    state.content.campaignID = campaignId;
  },
  setBaseData(state: $TSFixMe, data: $TSFixMe) {
    state.content.contentName = data.name;
    state.content.campaignID = data.campaignId;
    state.languages = data.languages;
    state.hasUnsavedData = true;
  },
  setTemplate(state: $TSFixMe, template: $TSFixMe) {
    state.template = template;
    state.content.templateID = template._id;
  },
  setFormats(state: $TSFixMe, formats: $TSFixMe) {
    state.formats = formats;
  },
  initializePreviewObjects(state: $TSFixMe) {
    const previewObjects: $TSFixMe = [];
    state.languages.forEach((language: $TSFixMe) => {
      const previewObject = { language, previews: [] };
      previewObjects.push(previewObject);
    });
    state.previewObjects = previewObjects;
  },
  addPreviewObject(state: $TSFixMe, previewObject: $TSFixMe) {
    const newPreviewObject = previewObject;
    newPreviewObject.renderProgress = 5;
    // TODO: Refactor video and html rendering status
    newPreviewObject.status = 'RENDERING';
    state.previewObjects
      .find((obj: $TSFixMe) => {
        return obj.language === previewObject.language;
      })
      .previews.push(newPreviewObject);
  },
  updateHtmlPreviewObject(state: $TSFixMe, previewObject: $TSFixMe) {
    const foundPreviewsIndex = state.previewObjects.findIndex((obj: $TSFixMe) => {
      return obj.language === previewObject.language;
    });
    const foundPreviewObject = state.previewObjects[foundPreviewsIndex].previews.find((obj: $TSFixMe) => {
      return obj.tmpId === previewObject.tmpId;
    });

    if (foundPreviewObject) {
      if (previewObject.status === RENDER_HTML5_PREVIEW_STATUS.CREATED) {
        foundPreviewObject.status = RENDER_HTML5_PREVIEW_STATUS.CREATED;
      } else {
        foundPreviewObject.status = RENDER_HTML5_PREVIEW_STATUS.FAILED;
        foundPreviewObject.errorMessage = previewObject.errorMessage;
      }
      foundPreviewObject.previewURL = previewObject.previewURL;
      foundPreviewObject.id = previewObject.id;
      foundPreviewObject.renderProgress = 100;
    }
  },
  removePreviewObject(state: $TSFixMe, previewToRemove: $TSFixMe) {
    state.previewObjects = state.previewObjects.map(({ previews, ...otherProperties }: $TSFixMe) => {
      return {
        previews: previews.filter((preview: $TSFixMe, index: $TSFixMe) => {
          if (preview.id) {
            return preview.id !== previewToRemove.id && index !== previewToRemove.indexToRemove;
          }
          // preview has not rendered yet
          return index !== previewToRemove.indexToRemove;
        }),
        ...otherProperties,
      };
    });
  },
  setCopiedContent(state: $TSFixMe, content: $TSFixMe) {
    state.copiedContent = content;
  },
  resetCopiedContent(state: $TSFixMe) {
    state.copiedContent = null;
  },
  copyContentItemFromTarget(state: $TSFixMe, data: $TSFixMe) {
    const targetItem = state.content.contentItems
      .filter((item: $TSFixMe) => {
        return item.format === data.target.format && item.language === data.target.language;
      })
      .pop();
    const currentItem = state.content.contentItems
      .filter((item: $TSFixMe) => {
        return item.format === data.current.format && item.language === data.current.language;
      })
      .pop();
    currentItem.layers = cloneDeep(targetItem.layers);
    state.hasUnsavedData = true;
  },
  setValueForLayerFormatAndLanguage(state: $TSFixMe, layerData: $TSFixMe) {
    const { language } = layerData;
    const { format } = layerData;
    const foundContentItem = state.content.contentItems
      .filter((contentItem: $TSFixMe) => {
        return contentItem.language === language && contentItem.format === format;
      })
      .pop();
    if (foundContentItem) {
      const foundLayer = foundContentItem.layers
        .filter((layer: $TSFixMe) => {
          return layer.id === layerData.id;
        })
        .pop();

      if (foundLayer) {
        foundLayer.value = layerData.value;
        foundLayer.isEdited = layerData.isEdited;
        state.hasUnsavedData = true;
      }
    }
  },
  removeContentItem(state: $TSFixMe, contentItemToRemove: $TSFixMe) {
    state.content.contentItems = state.content.contentItems.filter((contentItem: $TSFixMe) => {
      return contentItem.format !== contentItemToRemove.format || contentItem.language !== contentItemToRemove.language;
    });
  },
  addContentItem(state: $TSFixMe, contentItemToAdd: $TSFixMe) {
    state.content.contentItems.push(contentItemToAdd);
  },
  setContentItems(state: $TSFixMe, contentItems: $TSFixMe) {
    state.content.contentItems = contentItems;
  },
  setActiveTab(state: $TSFixMe, index: $TSFixMe) {
    state.activeTab = index;
  },
  setHasUnsavedData(state: $TSFixMe, hasUnsavedData: $TSFixMe) {
    state.hasUnsavedData = hasUnsavedData;
  },
  setIsFetchingJobQueueStatus(state: $TSFixMe, isFetching: $TSFixMe) {
    state.videoJobQueueStatus.isFetchingData = isFetching;
  },
  setVideoJobQueueStatus(state: $TSFixMe, status: $TSFixMe) {
    state.videoJobQueueStatus = status;
  },
};

function shouldInitializeContentItems(state: $TSFixMe) {
  return (
    state.content.contentItems.length === 0 &&
    state.languages.length !== 0 &&
    state.formats.length !== 0 &&
    state.template !== null
  );
}

function isNotReadyToInitializeContentItems(state: $TSFixMe) {
  return state.languages.length === 0 || state.formats.length === 0 || state.template === null;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
