<template>
  <div class="format-detail-container">
    <div class="setting-row">
      <FormSelectionField
        :placeholder="$t('templates.creation.valueIsOptional')"
        :list="['required', 'optional']"
        :selected-item="'required'"
        class="input-field format-type-selection"
        @input="onUpdateValueIsOptional"
      />
      <FormSelectionField
        v-if="hasApiData && apiColumns && apiColumns.length > 0"
        :placeholder="$t('templates.creation.columnNames')"
        :list="apiColumns"
        :selected-item="columnName"
        class="input-field format-type-selection"
        @input="onApiLayerSelected"
      />
    </div>
    <div v-for="setting in settings" :key="setting.format" class="format-detail">
      <span class="format-detail-label">{{ setting.format }}</span>
      <div class="setting-row">
        <FormInputField
          v-model="setting.meta.minWidth"
          :placeholder="$t('templates.creation.minWidth')"
          class="input-field"
          type="number"
          @input="updateData(setting.meta)"
        />
        <FormInputField
          v-model="setting.meta.minHeight"
          :placeholder="$t('templates.creation.minHeight')"
          class="input-field"
          type="number"
          @input="updateData(setting.meta)"
        />
        <FormInputField
          v-model="setting.meta.aspectRatio"
          :placeholder="$t('templates.creation.aspectRatio')"
          class="input-field"
          type="number"
          disabled
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import FormSelectionField from '@/components/shared/formFields/FormSelectionField.vue';

export default {
  components: {
    FormInputField,
    FormSelectionField,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    hasApiData: {
      type: Boolean,
      required: false,
      default: false,
    },
    apiColumns: {
      type: Array,
      required: true,
    },
    columnName: {
      type: String,
      required: false,
      default: '',
    },
    isOptional: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      settings: [],
    };
  },
  mounted() {
    this.settings = this.data;
  },
  methods: {
    updateData(settingMeta) {
      // eslint-disable-next-line no-param-reassign
      settingMeta.aspectRatio = this.calculateAspectRatioAsString(settingMeta);
      this.$emit('update', this.settings);
    },
    onApiLayerSelected(columnName) {
      this.$emit('updateColumnName', columnName);
    },
    onUpdateValueIsOptional(value) {
      this.$emit('updateValueIsOptional', value);
    },
    calculateAspectRatioAsString(settingMeta) {
      return this.secureDivideStrings(settingMeta.minWidth, settingMeta.minHeight).toString();
    },
    secureDivideStrings(numerator, denominator) {
      if (Number.isNaN(numerator) || Number.isNaN(parseFloat(numerator)) || parseFloat(numerator) === 0) {
        return 0;
      }
      if (Number.isNaN(denominator) || Number.isNaN(parseFloat(denominator)) || parseFloat(denominator) === 0) {
        return 0;
      }

      return numerator / denominator;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/templateCreation.scss';
</style>
