// eslint-disable-next-line import/no-cycle
import { createEditedAsset, createEditedAssetAsBinary } from '../../services/api/edit-image.resource';

export const SET_IS_CREATING_ASSET = 'SET_IS_CREATING_ASSET';
export const SET_CREATED_EDITED_ASSET_ID = 'SET_CREATED_EDITED_ASSET_ID';
export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';

const state = {
  isCreatingEditedAsset: false,
  createdEditedAssetId: '',
  hasError: false,
  errorMessage: '',
};

const actions = {
  async create({ commit, rootGetters }: $TSFixMe, cropData: $TSFixMe) {
    commit(SET_IS_CREATING_ASSET, true);
    commit(RESET_ERROR);

    try {
      const organizationId = rootGetters['auth/getUserOrganization'];
      const createdEditedAssetId = await createEditedAsset(cropData, organizationId);
      commit(SET_CREATED_EDITED_ASSET_ID, createdEditedAssetId);
    } catch (error) {
      commit(SET_ERROR, { errorMessage: (error as $TSFixMe)?.data?.message?.message });
    }

    commit(SET_IS_CREATING_ASSET, false);
  },
  async createBinary({ commit, rootGetters }: $TSFixMe, editedAssetAsBinary: $TSFixMe) {
    commit(SET_IS_CREATING_ASSET, true);
    commit(RESET_ERROR);

    try {
      const organizationId = rootGetters['auth/getUserOrganization'];
      const createdEditedAssetId = await createEditedAssetAsBinary(editedAssetAsBinary, organizationId);
      commit(SET_CREATED_EDITED_ASSET_ID, createdEditedAssetId);
    } catch (error) {
      commit(SET_ERROR, { errorMessage: error });
    }

    commit(SET_IS_CREATING_ASSET, false);
  },
};

const mutations = {
  [SET_IS_CREATING_ASSET](state: $TSFixMe, isCreatingEditedAsset: $TSFixMe) {
    state.isCreatingEditedAsset = isCreatingEditedAsset;
  },
  [SET_CREATED_EDITED_ASSET_ID](state: $TSFixMe, createdEditedAssetId: $TSFixMe) {
    state.createdEditedAssetId = createdEditedAssetId;
  },
  [SET_ERROR](state: $TSFixMe, payload: $TSFixMe) {
    state.hasError = true;
    state.errorMessage = payload.errorMessage;
  },
  [RESET_ERROR](state: $TSFixMe) {
    state.hasError = false;
    state.errorMessage = '';
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
