<template>
  <div class="login-page">
    <div class="login-left-side" />
    <div class="login-right-side">
      <div class="right-side-content">
        <h2 class="company-title">jls digital ag</h2>
        <h1 class="login-title">content creation hub</h1>
        <transition name="slide-left" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';
.login-page {
  @apply .w-full .h-screen;
  border: 1rem solid white;
  display: grid;
  grid-auto-columns: 50% 50%;

  .login-left-side {
    @apply .w-full .h-full;
    grid-column: 1;
    grid-row: 1;
    background: $cch-light-blue;
  }

  .login-right-side {
    @apply .w-full .h-full .bg-white;
    grid-column: 2;
    grid-row: 1;

    .right-side-content {
      @apply .ml-20;
      margin-top: 15vh;
      .login-title {
        @apply .font-sans .text-4xl .font-extrabold .mb-20 .w-1/5;
      }

      .company-title {
        @apply .font-sans .text-xl .font-medium;
      }
    }
  }

  .slide-left-enter-active,
  .slide-left-leave-active {
    transition-duration: 0.5s;
    transition-property: opacity, transform;
    overflow: hidden;
  }

  .slide-left-enter {
    transform: translate(2em, 0);
  }

  .slide-left-enter-active {
    @apply .fade-in;
  }

  .slide-left-leave-active {
    @apply .fade-out;
    transform: translate(-2em, 0);
  }
}
</style>
