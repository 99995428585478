<template>
  <div class="header-wrapper">
    <TabHeaderItem
      v-for="(tab, index) in tabs"
      :key="index"
      :name="tab.tabName"
      :icon="tab.tabIcon"
      :is-last="index === tabs.length - 1"
      :active="active === index"
    />
    <CloseIcon class="close-icon" @click="openCancelModal" />
  </div>
</template>

<script>
import TabHeaderItem from '@/components/shared/tabComponent/TabHeaderItem.vue';
import CloseIcon from '@/assets/images/close.svg';

export default {
  components: {
    TabHeaderItem,
    CloseIcon,
  },
  props: {
    active: {
      type: Number,
      default: 0,
    },
    tabs: {
      type: Array,
      default: null,
    },
  },
  methods: {
    openCancelModal() {
      this.$emit('close');
    },
    changeTab(value) {
      this.$emit('changeTab', value);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.header-wrapper {
  @apply box-border flex justify-center items-center w-full bg-white;
  height: rem(110px);
  z-index: 100;
  .close-icon {
    @apply absolute cursor-pointer;
    top: rem(32px);
    right: rem(32px);
  }
}
</style>
