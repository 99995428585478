<template>
  <div class="format-tabs-wrapper">
    <div
      v-for="(format, index) in formats"
      :key="index"
      class="format-tab"
      :class="{ active: format.name === activeFormat }"
      @click="onClicked(index, format.name)"
    >
      {{ format.humanReadableName }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formats: {
      type: Array,
      default: null,
    },
    activeFormat: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClicked(index, name) {
      this.$emit('changeFormatTab', { index, name });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.format-tabs-wrapper {
  height: rem(45px);
  border-bottom: solid rem(1px) #dfe5eb;
  display: flex;
  flex-direction: row;
  box-sizing: content-box;
  .format-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: rem(170px);
    margin-right: rem(5px);
    background-color: rgba(197, 205, 226, 0.25);
    font-family: $cch-font-family;
    font-weight: bold;
    color: $cch-light-steel-blue;
    cursor: pointer;
  }
  .active {
    background-color: $cch-light-gray;
    color: $cch-buttons-blue;
    z-index: 10;
    border-top: solid rem(1px) #dfe5eb;
  }
}
</style>
