<template>
  <div
    v-click-outside="clickOutsidePopupConfig"
    class="header-popup-bar xl:mx-auto items-center justify-between flex-wrap sm:flex-no-wrap"
  >
    <div class="flex items-center">
      <div
        class="flex text-blue-light items-end cursor-pointer popup-trigger"
        @click="onPopupToggle('compositions.format')"
      >
        <transition name="fade-down">
          <RadioFilterPopup
            v-show="objectName === 'compositions.format'"
            button-type="radio"
            category-type="compositions.format"
            :categories="getFormatsFromTemplates"
            @resetFilterClicked="onResetFilterClicked"
            @exclusiveFilterClicked="onExclusiveFilterClicked"
            >{{ $t('home.general.chooseFormats') }}</RadioFilterPopup
          >
        </transition>
        <IconSignage class="text-blue-light" />
        <div class="ml-3 font-sans font-semibold h-4 hidden lg:block">{{ $t('home.general.formats') }}</div>
      </div>
      <div class="flex text-blue-light items-end cursor-pointer popup-trigger ml-12" @click="onPopupToggle('type')">
        <transition name="fade-down">
          <RadioFilterPopup
            v-show="objectName === 'type'"
            button-type="radio"
            category-type="type"
            :categories="getContentTypesFromTemplates"
            @resetFilterClicked="onResetFilterClicked"
            @exclusiveFilterClicked="onExclusiveFilterClicked"
            >{{ $t('templates.general.chooseTypes') }}</RadioFilterPopup
          >
        </transition>
        <IconVideo class="text-blue-light" />
        <div class="ml-3 font-sans font-semibold h-4 hidden lg:block">{{ $t('templates.general.contentType') }}</div>
      </div>
    </div>
    <div class="search-input ml-0 mt-6 sm:ml-auto sm:mt-0 w-54 sm:w-64">
      <AppInput v-model="searchTerm" placeholder="general.inputs.search" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IconSignage from '@/assets/images/signage.svg';
import RadioFilterPopup from '@/components/shared/RadioFilterPopup.vue';
import AppInput from '@/components/shared/AppInput.vue';
import IconVideo from '@/assets/images/video.svg';

export default {
  name: 'TemplateSelectionHeaderRow',
  components: {
    AppInput,
    RadioFilterPopup,
    IconSignage,
    IconVideo,
  },
  data() {
    return {
      objectName: null,
      inputDebounce: null,

      clickOutsidePopupConfig: {
        handler: this.closeAnyPopup,
      },
    };
  },
  computed: {
    ...mapGetters({
      getFormatsFromTemplates: 'templates/getFormatsFromTemplates',
      getContentTypesFromTemplates: 'templates/getContentTypeFromTemplates',
    }),
    searchTerm: {
      get() {
        return this.$store.getters['template/getSearchTerm'];
      },
      set(value) {
        clearTimeout(this.inputDebounce);

        this.inputDebounce = setTimeout(() => {
          this.$store.dispatch('templates/setSearchTerm', value);
        }, 250);
      },
    },
  },
  async beforeDestroy() {
    await this.resetAllFilters();
  },
  methods: {
    async resetAllFilters() {
      await this.$store.dispatch('templates/resetFilters');
    },
    onExclusiveFilterClicked(filter) {
      this.$store.dispatch('templates/addExclusiveFilter', filter);
    },
    onResetFilterClicked(categoryType) {
      this.$store.dispatch('templates/resetFilter', categoryType);
    },
    onPopupToggle(category) {
      if (this.objectName === category) {
        this.objectName = null;
      } else {
        this.objectName = category;
      }
    },
    closeAnyPopup() {
      this.objectName = null;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/global.scss';

.header-popup-bar {
  display: flex;
}
.search-input {
  border-bottom: 1px solid $cch-dark-grey;
}
.popup {
  top: 14rem;
}
</style>
