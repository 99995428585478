
import { defineComponent } from 'vue';
import SmallSpinner from '@/components/shared/SmallSpinner.vue';

export default defineComponent({
  name: 'AppLoading',
  components: {
    SmallSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
