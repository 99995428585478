<template>
  <ModalBase
    :show="isLayerImportModalOpen"
    :header-hidden="headerHidden"
    :title="title"
    :override-style="{ width: 'auto' }"
    :override-header-style="{ 'margin-bottom': '3rem' }"
    @closeClicked="cancelImport"
  >
    <template #content>
      <div class="modal-content-container mb-6">
        <div class="content-wrapper">
          <div class="content-container">
            <ImportIcon class="import-icon" />
            <div class="text-wrapper">{{ $t(message) }}</div>
          </div>
        </div>
      </div>
    </template>
    <template #button-row>
      <div class="modal-button-container">
        <AppButton type="outlined" size="small-auto" @click="cancelImport">
          {{ $t(leftButtonText) }}
        </AppButton>
        <AppButton size="small-auto" @click="importLayers">{{ $t(rightButtonText) }}</AppButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import ImportIcon from '@/assets/images/group.svg';
import AppButton from '@/components/shared/buttons/AppButton.vue';

export default {
  components: {
    ModalBase,
    ImportIcon,
    AppButton,
  },
  props: {
    isLayerImportModalOpen: {
      type: Boolean,
      default: false,
    },
    leftButtonText: {
      type: String,
      default: '',
    },
    rightButtonText: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    headerHidden: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    cancelImport() {
      this.$emit('cancel');
    },
    importLayers() {
      this.$emit('import');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .content-container {
    max-width: rem(312px);
  }
  .import-icon {
    @apply .mx-auto;
    margin-bottom: 1rem;
  }
  .text-wrapper {
    font-family: $cch-font-family;
    font-size: rem(20px);
    font-weight: bold;
    text-align: center;
    color: #c5cde2;
  }
}
.modal-button-container {
  @apply .flex .items-center .justify-between;
}
</style>
