<template>
  <div>
    <div class="container-layer">
      <FormInputField
        v-model="layerName"
        :placeholder="$t('templates.creation.layerName')"
        class="input-field"
        @input="updateData"
      />
      <FormInputField
        v-model="name"
        :placeholder="$t('templates.creation.humanReadable')"
        class="input-field"
        @input="updateData"
      />
      <FormSelectionField
        v-model="type"
        :placeholder="$t('templates.creation.layerType')"
        class="input-field format-type-selection"
        :list="allowedTypes"
        :selected-item="type"
        @input="updateType"
      />
      <div class="action-buttons">
        <div class="copy-button">
          <GroupIcon class="copy-icon" viewBox="0 0 20 23" @click="copyItem()" />
        </div>
        <div class="remove-button">
          <TrashIcon class="trash-icon" viewBox="0 0 16 16" @click="removeItem()" />
        </div>
        <v-tooltip bottom open-delay="500">
          <template #activator="{ on, attrs }">
            <div class="ml-auto cursor-pointer accordion-arrow" v-bind="attrs" @click="toggleOpen" v-on="on">
              <ArrowUp v-if="open" />
              <ArrowDown v-else />
            </div>
          </template>
          <span>{{ $t('content.tooltip.expand') }}</span>
        </v-tooltip>
      </div>
    </div>
    <div v-show="open" class="accordion-layer-wrapper">
      <ImageDetail
        v-if="type === 'image'"
        :data="settings"
        :api-columns="getColumnNames"
        :has-api-data="hasApiData"
        :column-name="layer.columnName"
        :is-optional="layerIsOptional"
        @update="onSettingsUpdated"
        @updateColumnName="onImageUpdated"
        @updateValueIsOptional="onUpdateValueIsOptional"
      />
      <VideoDetail v-if="type === 'video'" :data="settings" @update="onSettingsUpdated" />
      <AudioDetail v-if="type === 'audio'" :data="settings" @update="onSettingsUpdated" />
      <DataDetail
        v-if="type === 'data'"
        :formats="formats"
        :data-detail-input="dataDetailInput"
        :has-api-data="hasApiData"
        :column-name="layer.columnName"
        :is-optional="layerIsOptional"
        @dataDetailUpdated="onDataDetailUpdated"
        @updateValueIsOptional="onUpdateValueIsOptional"
        @updatedColumnName="onUpdatedColumnName"
      />
    </div>
  </div>
</template>

<script>
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import FormSelectionField from '@/components/shared/formFields/FormSelectionField.vue';
import TrashIcon from '@/assets/images/trash.svg';
import ArrowDown from '@/assets/images/arrow-down.svg';
import ArrowUp from '@/assets/images/arrow-up.svg';
import ImageDetail from '@/components/templates/templateCreation/layers/ImageDetail.vue';
import DataDetail from '@/components/templates/templateCreation/layers/DataDetail.vue';
import VideoDetail from '@/components/templates/templateCreation/layers/VideoDetail.vue';
import AudioDetail from '@/components/templates/templateCreation/layers/AudioDetail.vue';
import GroupIcon from '@/assets/images/group.svg';

export default {
  components: {
    FormInputField,
    FormSelectionField,
    TrashIcon,
    ArrowUp,
    ArrowDown,
    ImageDetail,
    DataDetail,
    VideoDetail,
    AudioDetail,
    GroupIcon,
  },
  props: {
    layer: {
      type: Object,
      required: true,
    },
    formats: {
      type: Array,
      required: true,
    },
    allowedTypes: {
      type: Array,
      required: true,
    },
    columnNames: {
      type: Array,
      required: true,
    },
    hasApiData: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      name: null,
      isOptional: null,
      property: null,
      layerName: null,
      type: null,
      settings: [],
      rawType: null,
      options: [],
      open: false,
      layerData: Object,
      columnName: null,
    };
  },
  computed: {
    dataDetailInput() {
      return {
        rawType: this.rawType,
        property: this.property,
        options: this.options,
        settings: this.settings,
        columnNames: this.columnNames,
      };
    },
    getColumnNames() {
      return this.columnNames;
    },
    layerIsOptional() {
      return this.layer?.isOptional === true;
    },
  },
  mounted() {
    this.layerName = this.layer.layerName;
    this.name = this.layer.name;
    this.type = this.layer.type;
    this.isOptional = this.layer.isOptional;
    this.columnName = this.layer.columnName;
    this.property = this.layer.property;
    this.settings = this.layer.settings;
    this.rawType = this.layer.rawType;
    this.options = this.layer.options;

    if (this.settings && this.settings.length > 0) {
      const newSettings = [];
      this.formats.forEach((format) => {
        const existingSetting = this.settings.find((setting) => {
          return setting.format === format.name;
        });
        if (existingSetting) {
          newSettings.push(existingSetting);
        } else {
          newSettings.push(this.defaultSettingByType(this.type, format.name));
        }
      });
      this.onSettingsUpdated(newSettings);
    }
  },
  methods: {
    defaultSettingByType(type, formatName) {
      if (type === 'audio') {
        return this.defaultAudioSettings(formatName);
      }
      if (type === 'image') {
        return this.defaultImageSettings(formatName);
      }
      if (type === 'video') {
        return this.defaultVideoSettings(formatName);
      }
      if (type === 'data') {
        return this.defaultDataSettings(formatName);
      }
      throw new Error(`Unsupported type for layer row provided: ${type}`);
    },
    defaultAudioSettings(formatName) {
      return {
        format: formatName,
        meta: {
          duration: '0',
        },
      };
    },
    defaultImageSettings(formatName) {
      return {
        format: formatName,
        meta: {
          minHeight: 0,
          minWidth: 0,
          aspectRatio: 0,
        },
      };
    },
    defaultVideoSettings(formatName) {
      return {
        format: formatName,
        meta: {
          duration: '0',
        },
      };
    },
    defaultDataSettings(formatName) {
      return {
        format: formatName,
        minValue: '0',
        isMinUnlimited: false,
        maxValue: '0',
        isMaxUnlimited: false,
      };
    },
    onUpdatedColumnName(columnName) {
      this.$emit('updatedColumnName', columnName);
    },
    onImageUpdated(columnName) {
      this.$emit('updatedColumnName', columnName);
    },
    onDataDetailUpdated(dataDetail) {
      this.rawType = dataDetail.rawType;
      this.property = dataDetail.property;
      this.settings = dataDetail.settings;
      this.options = dataDetail.options;
      this.updateData();
    },
    toggleOpen() {
      this.open = !this.open;
    },
    removeItem() {
      this.$emit('removeClick');
    },
    copyItem() {
      this.$emit('copyClick');
    },
    updateType() {
      this.settings = [];
      if (this.type === 'image') {
        this.formats.forEach((format) => {
          this.settings.push(this.defaultImageSettings(format.name));
        });
      } else if (this.type === 'video') {
        this.formats.forEach((format) => {
          this.settings.push(this.defaultVideoSettings(format.name));
        });
      } else if (this.type === 'audio') {
        this.formats.forEach((format) => {
          this.settings.push(this.defaultAudioSettings(format.name));
        });
      } else {
        this.settings = [];
      }

      this.updateData();
      this.open = true;
    },
    updateData() {
      this.$emit('input', {
        id: this.layer.id,
        layerName: this.layerName,
        name: this.name,
        type: this.type,
        isOptional: this.isOptional,
        columnName: this.columnName,
        property: this.property,
        settings: this.settings,
        rawType: this.rawType,
        options: this.options,
      });
    },
    onSettingsUpdated(settings) {
      this.options = [];
      this.settings = settings;
      this.updateData();
    },
    onUpdateValueIsOptional(value) {
      this.isOptional = value === 'optional';

      this.updateData();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';

.container-layer {
  display: flex;
  width: 100%;
  font-family: $cch-font-family;
  font-size: rem(36px);
  font-weight: 800;
  color: #c5cde2;
  justify-content: center;
  align-content: stretch;
  .input-field {
    margin-bottom: rem(24px);
    flex-grow: 3.5;
    border-right: 2px solid $cch-light-gray;
    font-size: 16px;
  }
  .format-type-selection {
    width: 1px;
  }
}

.action-buttons {
  display: flex;
  width: rem(140px);
  background-color: white;
  height: rem(51px);
  .remove-button {
    flex-grow: 1;
    position: relative;
    cursor: pointer;
    padding-right: rem(30px);

    .trash-icon {
      position: absolute;
      width: rem(26px);
      height: rem(25px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .copy-button {
    flex-grow: 0.5;
    position: relative;
    cursor: pointer;
    padding-left: rem(30px);
    margin-right: rem(-15px);
    .copy-icon {
      position: absolute;
      width: rem(26px);
      height: rem(25px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .accordion-arrow {
    background-color: white;
    height: rem(51px);
    flex-grow: 0.5;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

.accordion-layer-wrapper {
  background-color: #e5e9f8;
  margin-top: rem(-30px);
  margin-bottom: rem(30px);
  position: relative;
}
</style>
