<template>
  <ModalBase
    :title="$t('general.users.editUser')"
    :show="show"
    :override-style="{ 'background-color': scssVariables.cchLightGray, height: '32rem' }"
    :override-header-style="{ 'margin-bottom': '2rem' }"
    @closeClicked="onCloseClicked"
  >
    <template #content>
      <FormInputField
        ref="firstInput"
        v-model="firstName"
        class="form-field"
        :placeholder="$t('general.users.firstName')"
      />
      <FormInputField v-model="lastName" class="form-field" :placeholder="$t('general.users.lastName')" />
      <FormInputField v-model="email" class="form-field" :placeholder="$t('general.users.eMail')" />
      <label v-if="isUserSuperAdmin" class="form-field checkbox-label">
        <input v-model="isPasswordChangeRequired" type="checkbox" />
        {{ $t('general.users.requirePasswordChange') }}
      </label>
      <AppButton type="text" @click="openPasswordModal">
        {{ $t('general.users.changePassword') }}
      </AppButton>
    </template>
    <template #button-row>
      <div class="button-row">
        <AppButton type="outlined" size="small-auto" @click="onCloseClicked">
          {{ $t('general.buttons.cancel') }}
        </AppButton>
        <AppButton size="small-auto" @click="editUser">{{ $t('general.buttons.save') }}</AppButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import scssVariables from '@/styles/variables.module.scss';
import { SUPERADMIN } from '@/constants/user/roles';
import { toast } from '@/services/toast';
import AppButton from '@/components/shared/buttons/AppButton.vue';

export default {
  components: {
    ModalBase,
    FormInputField,
    AppButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: '',
      firstName: '',
      lastName: '',
      email: '',
      isPasswordChangeRequired: false,
      scssVariables,
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'auth/getUserRole',
    }),
    isFirstNameProvided() {
      return this.firstName.length > 0;
    },
    isLastNameProvided() {
      return this.lastName.length > 0;
    },
    isEmailProvided() {
      return this.email.length > 0;
    },
    isUserSuperAdmin() {
      return this.userRole === SUPERADMIN;
    },
  },
  mounted() {
    if (this.user !== null) {
      this.setUserFormData();
    }
    this.$refs.firstInput.focus();
  },
  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    openPasswordModal() {
      this.$emit('openPasswordModal');
    },
    editUser() {
      if (this.validate()) {
        const userObject = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          isPasswordChangeRequired: this.isPasswordChangeRequired,
          username: this.email,
        };
        this.$emit('edit', { user: userObject, id: this.user._id });
      }
    },
    setUserFormData() {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.email = this.user.email;
      this.password = this.user.password;
      this.isPasswordChangeRequired = this.user.isPasswordChangeRequired;
    },
    validate() {
      if (!this.isFirstNameProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'user-edit',
          message: this.$t(`general.users.noFirstNameProvided`),
        });
        return false;
      }
      if (!this.isLastNameProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'user-edit',
          message: this.$t(`general.users.noLastNameProvided`),
        });
        return false;
      }
      if (!this.isEmailProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'user-edit',
          message: this.$t(`general.users.noEmailProvided`),
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.form-field {
  margin-bottom: rem(24px);
}
.checkbox-label {
  display: block;
}
.button-row {
  width: 100%;
  position: relative;
  bottom: rem(48px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
