<template>
  <ContentRowBase
    :id="render.id"
    :is-header="false"
    :is-uploadable="isUploadable"
    :name="name"
    :language="language"
    :creation-date="''"
    :template-name="templateName"
    :format-info="render.format"
    :render="render"
    :status="status"
    :render-progress="renderProgress"
    @downloadClicked="onDownload(render.signedUrl)"
    @copyContentClicked="onCopy(render.id)"
    @copyMediaClicked="onCopy(render.id)"
    @deleteContentItemClicked="onDelete(render.id)"
    @uploadContentItem="onUpload(render.id)"
  />
</template>

<script>
import ContentRowBase from '@/components/campaignDetail/contentDetail/ContentRowBase.vue';
import { TYPES } from '@/constants/content/content';
import { getLanguageCodeFromLanguage } from '@/helpers/helpers';

export default {
  name: 'ContentItem',
  components: { ContentRowBase },
  props: {
    render: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    renderProgress: {
      type: Number,
      required: false,
      default: 0,
    },
    templateName: {
      type: String,
      required: true,
    },
    hasCmsUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    name() {
      const compositionName = this.render.format;
      const languageCode = getLanguageCodeFromLanguage(this.render.language);
      return `${compositionName}_${languageCode}`;
    },
    language() {
      return `content.accordion.${this.render.language}`;
    },
    isUploadable() {
      return this.hasCmsUpload && this.render.type === TYPES.VIDEO;
    },
  },
  methods: {
    onDownload(signedUrl) {
      this.$emit('downloadClicked', signedUrl);
    },
    onCopy(renderId) {
      this.$emit('copyContentClicked', renderId);
    },
    onDelete(renderId) {
      this.$emit('deleteContentItemClicked', renderId);
    },
    onUpload(renderId) {
      this.$emit('uploadContentItem', renderId);
    },
  },
};
</script>

<style scoped lang="scss"></style>
