<template>
  <div class="info-container">
    <TileTitle class="tile-title">{{ data.name }}</TileTitle>
    <TileData v-if="data.type">{{ $t(data.type) }}</TileData>
    <TileData v-if="data.dateAdded && type !== 'templates'">{{ data.dateAdded }}</TileData>
    <TileData v-else-if="data.createdAt && type !== 'templates'">{{ formattedCreationDate }}</TileData>
    <template v-if="type === 'templates'">
      <TileData>{{ data.formats.length }} {{ $t('general.languages.formats') }}</TileData>
      <TileData>{{ $t('templates.general.lastEdited') }} {{ formattedUpdatedString }}</TileData>
    </template>
  </div>
</template>

<script>
import { isValid, isSameDay, format } from 'date-fns';
import getLogger from '@/services/logger';
import TileTitle from '@/components/shared/tiles/TileTitle.vue';
import TileData from '@/components/shared/tiles/TileData.vue';

const LOG = getLogger('components/TitleInfo');

export default {
  name: 'TileInfo',
  components: { TileData, TileTitle },
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedCreationDate() {
      if (!this.data || !this.data.createdAt) return '';

      return isSameDay(new Date(this.data.createdAt), new Date())
        ? format(new Date(this.data.createdAt), 'HH:mm')
        : format(new Date(this.data.createdAt), 'dd MMM yyyy HH:mm');
    },
    formattedUpdatedString() {
      if (!this.data || !this.data.updatedAt) {
        LOG.warn('Data or data.updatedAt is not defined');
        return '';
      }
      return this.formatDateString(this.data.updatedAt);
    },
  },
  methods: {
    formatDateString(dateString) {
      if (isValid(new Date(dateString))) {
        return isSameDay(new Date(dateString), new Date())
          ? format(new Date(dateString), 'HH:mm')
          : format(new Date(dateString), 'dd.MM.yyyy');
      }
      LOG.warn('Datestring has invalid format');
      return '';
    },
  },
};
</script>

<style scoped>
.info-container {
  @apply .bg-white .w-full;
}
.tile-title {
  word-wrap: break-word;
}
</style>
