<template>
  <div class="modal-message-viewer">
    <transition-group name="fade">
      <ModalBase
        v-for="modalMessage in modalMessages"
        :key="modalMessage.id"
        :override-style="{ width: 'auto', 'min-width': '300px' }"
        :override-header-style="{ 'margin-bottom': '3rem' }"
        header-hidden
        show
      >
        <template #content>
          <div class="modal-content-container mb-8">{{ modalMessage.message }}</div>
        </template>
        <template #button-row>
          <div class="modal-button-container">
            <AppButton @click="closeModalMessage(modalMessage)">{{ $t('ok') }}</AppButton>
          </div>
        </template>
      </ModalBase>
    </transition-group>
  </div>
</template>
<script>
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import AppButton from '../buttons/AppButton.vue';

export default {
  name: 'ModalMessageViewer',
  components: {
    ModalBase,
    AppButton,
  },
  data() {
    return {
      modalMessages: [],
      modalMessageCounter: 0,
    };
  },
  mounted() {
    this.$modal.$on('showModalMessage', this.showModalMessage);
  },
  methods: {
    closeModalMessage(modalMessage) {
      if (!modalMessage || (!modalMessage.id && modalMessage.id !== 0)) return;
      this.modalMessages = this.modalMessages.filter((message) => modalMessage.id !== message.id);
    },
    showModalMessage(payload) {
      this.modalMessages.push({
        id: this.modalMessageCounter,
        message: typeof payload === 'object' ? payload.message : payload,
      });

      this.modalMessageCounter += 1;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.module.scss';

.modal-message-viewer {
  @apply .fixed;
  z-index: 600;

  .modal-button-container {
    @apply .flex .items-center .justify-end;
  }
}
</style>
