
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import AppInput from '@/components/shared/AppInput.vue';

export default defineComponent({
  components: {
    AppInput,
  },
  data() {
    return {
      inputDebounce: null as number | null,
    };
  },
  computed: {
    ...mapGetters({
      viewMode: 'templates/getViewMode',
    }),
    searchTerm: {
      get() {
        return this.$store.getters['templates/getSearchTerm'];
      },
      set(value: string) {
        if (this.inputDebounce) {
          clearTimeout(this.inputDebounce);
        }
        this.inputDebounce = setTimeout(() => {
          this.$store.dispatch('templates/setSearchTerm', value);
        }, 250);
      },
    },
  },
});
