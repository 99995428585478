
import { mapGetters } from 'vuex';
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import OrganizationOverview from '@/components/organizations/OrganizationOverview.vue';
import { SUPERADMIN } from '@/constants/user/roles';
import scssVariables from '@/styles/variables.module.scss';
import { defineComponent } from 'vue';
import { GroupedOrganizations } from '@/types';
import { getGroupedOrganizations } from '@/services/api/organizations.resource';

export default defineComponent({
  components: {
    ModalBase,
    OrganizationOverview,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scssVariables,
      groups: [] as Array<GroupedOrganizations>,
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'auth/getUserRole',
    }),
  },
  async mounted() {
    if (this.userRole === SUPERADMIN) {
      this.groups = await getGroupedOrganizations();
    }
  },
  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    async selectOrganization(id: string) {
      this.$store.dispatch('auth/updateOrganizationId', id);
      if (this.$route.name !== 'campaignOverview') {
        await this.$router.push({ name: 'campaignOverview' });
      }
      this.$router.go(0);
    },
  },
});
