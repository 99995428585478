import * as log from 'loglevel';
import * as lpre from 'loglevel-plugin-prefix';

lpre.reg(log);

const customLevel = parseInt(process.env.VUE_APP_LOG_LEVEL, 10);
let defaultLevel = log.levels.ERROR;
if (process.env.NODE_ENV !== 'production') {
  // @ts-expect-error TS(2322): Type '1' is not assignable to type '4'.
  defaultLevel = log.levels.DEBUG;
}

// @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
log.setLevel(Number.isNaN(customLevel) ? defaultLevel : customLevel, false);

lpre.apply(log, {
  template: '[%t] %l (%n): ',
});

export default log.getLogger;
