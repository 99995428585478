
import { mapGetters } from 'vuex';
import DiscardModal from '@/components/shared/DiscardModal.vue';
import UserTitleRow from '@/components/users/UserTitleRow.vue';
import UserHeader from '@/components/users/UserHeader.vue';
import AppHeader from '@/components/shared/AppHeader.vue';
import UserCreationModal from '@/components/users/UserCreationModal.vue';
import { defineComponent } from 'vue';
import { User } from '@/types';
import EditIcon from '@/assets/images/edit.svg';
import ActivateIcon from '@/assets/images/activate.svg';
import DeactivatingIcon from '@/assets/images/deactivate.svg';
import TrashIcon from '@/assets/images/trash.svg';
import { toast } from '@/services/toast';
import { PREFERENCE_SHOW_INACTIVE_USERS } from '@/constants/store/auth';
import { ITEMS_PER_PAGE_OPTIONS, DEFAULT_ITEMS_PER_PAGE } from '@/constants/pagination';

export default defineComponent({
  components: {
    UserTitleRow,
    UserHeader,
    AppHeader,
    UserCreationModal,
    EditIcon,
    TrashIcon,
    ActivateIcon,
    DeactivatingIcon,
    DiscardModal,
  },
  data() {
    return {
      isUserCreationModalOpen: false,
      type: '',
      userToEdit: null as User | null,
      isDeleteUserModalOpen: false,
      isDeactiveUserModalOpen: false,
      inputDebounce: null as number | null,
      showingInactiveUsers: localStorage.getItem(PREFERENCE_SHOW_INACTIVE_USERS) === 'true' ? true : false,
      ITEMS_PER_PAGE_OPTIONS,
      DEFAULT_ITEMS_PER_PAGE,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'users/getLoading',
      organizations: 'organizations/getOrganizations',
      roles: 'roles/getRoles',
    }),
    searchTerm: {
      get() {
        return this.$store.getters['users/getSearchTerm'];
      },
      set(value: string) {
        if (this.inputDebounce) {
          clearTimeout(this.inputDebounce);
        }

        this.inputDebounce = setTimeout(() => {
          this.$store.dispatch('users/setSearchTerm', value);
        }, 250);
      },
    },
    headers() {
      const headers: {
        text: string;
        value: string;
        sortable?: boolean;
        align: 'start' | 'end';
      }[] = [
        {
          text: this.$t('general.users.lastName'),
          value: 'lastName',
          align: 'start',
        },
        {
          text: this.$t('general.users.firstName'),
          value: 'firstName',
          align: 'start',
        },
        {
          text: this.$t('general.users.eMail'),
          value: 'email',
          align: 'start',
        },
        {
          text: this.$t('general.users.client'),
          value: 'organization.name',
          align: 'start',
        },
        {
          text: this.$t('general.users.role'),
          value: 'role.name',
          align: 'start',
        },
      ];
      if (this.showingInactiveUsers) {
        headers.push({
          text: this.$t('general.users.state'),
          value: 'isActive',
          align: 'start',
        });
      }
      headers.push({
        text: this.$t('general.users.actions'),
        value: 'actions',
        sortable: false,
        align: 'end',
      });
      return headers;
    },
    users() {
      const users: User[] = this.$store.getters['users/getUsers'];
      for (const user of users) {
        if (user.deletedAt) {
          user.isActive = false;
        } else {
          user.isActive = true;
        }
      }
      if (this.showingInactiveUsers) {
        return users;
      }
      return users.filter((user) => !user.deletedAt);
    },
  },
  async mounted() {
    await this.$store.dispatch('organizations/loadOrganizations');
    await this.$store.dispatch('roles/loadAllRoles');
    await this.$store.dispatch('users/loadAllUsers');
  },
  methods: {
    onEditUserClicked(user: User) {
      this.$router.push({ name: 'user', params: { id: user.id } });
    },
    openUserCreationModal() {
      this.userToEdit = null;
      this.type = '';
      this.isUserCreationModalOpen = true;
    },
    closeUserCreationModal() {
      this.isUserCreationModalOpen = false;
      this.type = '';
      this.userToEdit = null;
    },
    editUser(userObject: User) {
      this.$store.dispatch('users/editUser', userObject).then(() => {
        this.closeUserCreationModal();
      });
    },
    async createUser(userObject: User) {
      await this.$store.dispatch('users/createUser', userObject);
      if (!this.$store.state.users.isErrorReturned) {
        this.closeUserCreationModal();
      }
      if (this.$store.state.users.doesUserEmailAlreadyExist) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'content-wizard-validation',
          message: this.$t(`general.users.emailAlreadyInUse`),
        });
      }
    },
    onDeleteUserClicked() {
      this.isDeleteUserModalOpen = false;
      this.$store.dispatch('users/deleteUser', this.userToEdit);
    },
    onDeactivateUserClicked() {
      this.isDeactiveUserModalOpen = false;
      this.$store.dispatch('users/deactivateUser', this.userToEdit);
    },
    closeDeleteUserModal() {
      this.userToEdit = null;
      this.isDeleteUserModalOpen = false;
    },
    openDeletionModal(user: User) {
      this.userToEdit = user;
      this.isDeleteUserModalOpen = true;
    },
    openDeactivatingModal(user: User) {
      this.userToEdit = user;
      this.isDeactiveUserModalOpen = true;
    },
    closeDeactivateUserModal() {
      this.userToEdit = null;
      this.isDeactiveUserModalOpen = false;
    },
    onChangeShowingInactiveUsers(value: boolean) {
      this.showingInactiveUsers = value;
      localStorage.setItem(PREFERENCE_SHOW_INACTIVE_USERS, String(value));
    },
    onClickActivateUser(user: User) {
      this.$store.dispatch('users/activateUser', user);
    },
  },
});
