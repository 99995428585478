<template>
  <div class="header-wrapper">
    <div class="title-container">
      <AppText type="title">{{ $t('general.users.users') }}</AppText>
    </div>

    <div class="action-container">
      <v-switch
        v-model="checked"
        :label="$t('general.users.showingInactiveUsers')"
        @change="changeShowingInactiveUsers"
      />
      <AppButton @click="openUserCreation">{{ $t('general.users.newUser') }}</AppButton>
    </div>
  </div>
</template>

<script>
import AppText from '@/components/shared/AppText.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';

export default {
  components: {
    AppText,
    AppButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    showingInactiveUsers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.showingInactiveUsers,
    };
  },
  methods: {
    openUserCreation() {
      this.$emit('open');
    },
    changeShowingInactiveUsers() {
      this.$emit('changeShowingInactiveUsers', !this.showingInactiveUsers);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: rem(36px 0 24px 0);
  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .action-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
}
</style>
