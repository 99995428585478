
import { mapGetters } from 'vuex';
import AssetHeader from '@/components/assets/AssetHeader.vue';
import AppText from '@/components/shared/AppText.vue';
import AssetModal from '@/components/assets/AssetModal.vue';
import EditAssetModal from '@/components/assets/EditAssetModal.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import CreateAssetModal from '@/components/assets/CreateAssetModal.vue';
import AssetsGrid from '@/components/assets/AssetsGrid.vue';
import AppHeader from '@/components/shared/AppHeader.vue';
import DiscardModal from '@/components/shared/DiscardModal.vue';
import CreateFolderModal from '@/components/assets/CreateFolderModal.vue';
import ShareAssetModal from '@/components/assets/ShareAssetModal.vue';
import ShareAssetCreatedModal from '@/components/assets/ShareAssetCreatedModal.vue';
import MoveModal from '@/components/assets/MoveModal.vue';
import { defineComponent } from 'vue';
import FileIcon from '@/assets/images/contact_page_FILL0_wght200_GRAD0_opsz24.svg';
import FolderIcon from '@/assets/images/folder_FILL0_wght200_GRAD0_opsz24.svg';
import OptionsBox from '../campaigns/OptionsBox.vue';
import { t } from 'i18next';
import { Asset, AssetType } from '@/types';
import { SHARED_FOLDER_ID } from '@/constants/asset';
import { AssetShare } from '@/types/AssetShare';

export default defineComponent({
  components: {
    AppText,
    AssetHeader,
    AssetModal,
    EditAssetModal,
    AppButton,
    CreateAssetModal,
    AssetsGrid,
    AppHeader,
    DiscardModal,
    CreateFolderModal,
    MoveModal,
    OptionsBox,
    FolderIcon,
    FileIcon,
    ShareAssetModal,
    ShareAssetCreatedModal,
  },
  data() {
    return {
      isAddOptionBoxOpen: false,
      isDeleteModalShown: false,
      isCreateAssetModalShown: false,
      isCreateFolderModalShown: false,
      isEditModalShown: false,
      isPreviewModalShown: false,
      sharedAssetId: '',
      sharedAssetName: '',
      shareResults: [] as Array<AssetShare>,
      sharedRecipients: [],
      isMoveModalShown: false,
      isShareModalShown: false,
      isShareCreatedModalShown: false,
      errors: {
        fileError: null,
        nameError: null,
      },
      selectedAssetId: '',
      selectedAssetIds: [] as string[],
      t,
      moveFromPath: [] as string[],
    };
  },
  computed: {
    ...mapGetters({
      getAssetView: 'crossSession/getAssetView',
      getAssetById: 'assets/getAssetById',
      getAssetChildrenCount: 'assets/getAssetChildrenCount',
      path: 'assets/getPath',
      getAssets: 'assets/getAssets',
      getLoading: 'assets/getLoading',
    }),
    assets(): Asset[] {
      if (!this.selectedAssetIds.length) {
        return this.getAssets;
      }
      const assets: Asset[] = [];
      this.getAssets.forEach((asset: Asset) => {
        assets.push({ ...asset, selected: this.selectedAssetIds.includes(asset._id) });
      });
      return assets;
    },
    vClickConfig() {
      return {
        handler: () => {
          this.isAddOptionBoxOpen = false;
        },
        events: ['contextmenu', 'click'],
      };
    },
    itemsSelectedLabel() {
      return `${this.selectedAssetIds.length} ${this.t('general.inputs.selected')}`;
    },
    selectedAsset(): Asset {
      return this.getAssetById(this.selectedAssetId);
    },
    selectedAssetType(): AssetType {
      return this.selectedAsset.type;
    },
    selectedAssetIdArray(): string[] {
      return this.selectedAssetId ? [this.selectedAssetId] : this.selectedAssetIds;
    },
    deleteTitle(): string {
      return `${t('general.buttons.delete')} ${
        this.selectedAssetIdArray.length === 1
          ? this.getAssetById(this.selectedAssetIdArray[0]).name
          : `${this.selectedAssetIdArray.length} ${t('general.sections.folders')}/${t('general.sections.assets')}`
      }`;
    },
    deleteMessage(): string {
      return `${t('assets.general.deletionMessage')} ${
        this.selectedAssetIdArray.length === 1
          ? this.getAssetById(this.selectedAssetIdArray[0]).name
          : `${this.selectedAssetIdArray.length} ${t('general.sections.folders')}/${t('general.sections.assets')}`
      }?`;
    },
    deleteExtraMessage(): string | undefined {
      let affectedCount = 0;
      this.selectedAssetIdArray.forEach((id) => {
        affectedCount += this.getAssetChildrenCount(id);
      });
      return affectedCount
        ? `${t('assets.general.deleteNotEmptyFolderMessage')}. ${affectedCount} ${t(
            'assets.general.affectedContentDeleted',
          )}`
        : undefined;
    },
    isAllSelected(): boolean {
      return this.selectedAssetIds.length === this.assets.length;
    },
    isInSharedFolder(): boolean {
      return this.$route.params.path?.startsWith(SHARED_FOLDER_ID);
    },
  },
  watch: {
    '$route.params.path': function routeParamsPath(value) {
      this.closeEveryModal();
      this.$store.dispatch('assets/setPath', value ? value.split('/') : []);
    },
  },
  beforeMount() {
    if (localStorage.assetsView) {
      this.$store.commit('assets/setView', localStorage.assetsView);
    }
    const urlPath = this.$route.params.path ? this.$route.params.path.split('/') : [];
    if (urlPath !== this.path) this.$store.dispatch('assets/setPath', urlPath);
  },
  beforeDestroy() {
    this.$store.dispatch('assets/removeSearchTerm');
  },
  methods: {
    assetClicked(asset: Asset): void {
      const id = asset._id;
      const selectedAsset: Asset = this.getAssetById(id);
      if (selectedAsset.type === AssetType.FOLDER || selectedAsset.type === AssetType.SHARED_FOLDER) {
        this.$store.dispatch('assets/removeSearchTerm');
        this.$router.push({ name: 'assets', params: { path: [...this.path, id].join('/') } });
        this.clearSelection();
      } else {
        this.selectedAssetId = id;
        this.isPreviewModalShown = true;
      }
    },
    assetSelected(id: string): void {
      if (!this.selectedAssetIds.find((i) => i === id)) {
        this.selectedAssetIds.push(id);
      }
    },
    assetUnselected(id: string): void {
      var index = this.selectedAssetIds.indexOf(id);
      if (index > -1) {
        this.selectedAssetIds.splice(index, 1);
      }
    },
    closePreviewModal(): void {
      this.isPreviewModalShown = false;
    },
    createAsset(assetData: { file: any; name: string }): void {
      if (!assetData.file || !assetData.name) {
        console.error('file or name missing.');
        return;
      }
      this.closeCreateAssetModal();
      this.$store.dispatch('assets/createAsset', { fileName: assetData.name, file: assetData.file, path: this.path });
    },
    createFolder(folderData: { name: string }): void {
      this.$store.dispatch('assets/createFolder', { folderName: folderData.name, path: this.path }).then(() => {
        this.closeCreateFolderModal();
        this.$store.dispatch('assets/getAllAssets');
      });
    },
    deleteAssets(): void {
      this.isDeleteModalShown = false;
      this.$store.dispatch('assets/deleteAssets', { ids: this.selectedAssetIdArray, path: this.path });
      this.clearSelection();
    },
    openEditModal(id: string): void {
      this.selectedAssetId = id;
      this.closePreviewModal();
      this.isEditModalShown = true;
    },
    closeEditModal(): void {
      this.clearSelection();
      this.isEditModalShown = false;
    },
    editAsset(assetData: { file: any; name: string; id: string; type: AssetType }): void {
      this.$store.dispatch('assets/updateAsset', assetData).then(() => {
        this.closeEditModal();
      });
    },
    openCreateAssetModal(): void {
      this.closePreviewModal();
      this.isCreateAssetModalShown = true;
    },
    closeCreateAssetModal(): void {
      this.isCreateAssetModalShown = false;
    },
    openCreateFolderModal(): void {
      this.closePreviewModal();
      this.isCreateFolderModalShown = true;
    },
    closeCreateFolderModal(): void {
      this.isCreateFolderModalShown = false;
    },
    openDeleteModal(id: string): void {
      this.selectedAssetId = id;
      this.closePreviewModal();
      this.isDeleteModalShown = true;
    },
    closeDeleteModal(): void {
      this.isDeleteModalShown = false;
    },
    closeEveryModal(): void {
      this.isDeleteModalShown = false;
      this.isCreateAssetModalShown = false;
      this.isCreateFolderModalShown = false;
      this.isEditModalShown = false;
      this.isPreviewModalShown = false;
      this.isShareModalShown = false;
      this.isShareCreatedModalShown = false;
    },
    toggleAddOptionBox(): void {
      this.isAddOptionBoxOpen = !this.isAddOptionBoxOpen;
    },
    closeAddOptionBox(): void {
      this.isAddOptionBoxOpen = false;
    },
    openMoveModal(id: string): void {
      this.moveFromPath = [...this.path];
      this.selectedAssetId = id;
      this.isMoveModalShown = true;
    },
    closeMoveModal(): void {
      if (this.path.join('') !== this.moveFromPath.join('')) {
        this.$router.push({
          name: 'assets',
          params: this.moveFromPath.length ? { path: this.moveFromPath.join('/') } : {},
        });
      }
      this.isMoveModalShown = false;
    },
    clearSelection(): void {
      this.selectedAssetId = '';
      this.selectedAssetIds = [];
    },
    moveAssets(): void {
      this.closeMoveModal();
      const newPath = this.$store.getters['assetsMoving/getPath'];
      if (this.moveFromPath.join('') === newPath.join('')) {
        return;
      }
      this.$store.dispatch('assetsMoving/moveAssets', {
        ids: this.selectedAssetIdArray,
        oldPath: this.moveFromPath,
        newPath: newPath,
      });
      this.clearSelection();
    },
    breadcrumbClicked(path: string[]) {
      if (path.join('/') === this.$route.params.path) {
        return;
      }
      this.$router.push({ name: 'assets', params: path.length ? { path: path.join('/') } : {} });
    },
    onToggleAllSelection() {
      if (this.isAllSelected) {
        this.selectedAssetIds = [];
      } else {
        this.selectedAssetIds = [];
        for (const asset of this.assets) {
          this.selectedAssetIds.push(asset._id);
        }
      }
    },
    openShareModal(id: string): void {
      this.sharedAssetId = id;
      this.sharedRecipients = [];
      this.isPreviewModalShown = false;
      this.isShareModalShown = true;
    },
    closeShareModal(): void {
      this.isShareModalShown = false;
      this.sharedAssetId = '';
      this.sharedAssetName = '';
      this.sharedRecipients = [];
    },
    closeShareCreatedModal() {
      this.isShareCreatedModalShown = false;
      this.shareResults = [];
      this.sharedAssetId = '';
      this.sharedAssetName = '';
      this.sharedRecipients = [];
    },
    shareAsset(params: { recipients: Array<{ id: string; name: string }> }) {
      this.$store
        .dispatch('assets/shareAsset', {
          assetId: this.sharedAssetId,
          recipientIds: params.recipients.map((recipient) => recipient.id),
        })
        .then((shareResults) => {
          this.shareResults = shareResults;
          this.isShareCreatedModalShown = true;
          this.isShareModalShown = false;
          this.sharedAssetName = this.assets.find((asset) => asset._id === this.sharedAssetId)?.name || '';
          this.sharedRecipients = params.recipients as any;
        });
    },
    undoShare() {
      this.$store
        .dispatch('assets/revokeShares', {
          shareIds: this.shareResults.map((share) => share.id),
        })
        .then(() => {
          this.shareResults = [];
          this.sharedAssetId = '';
          this.sharedAssetName = '';
          this.sharedRecipients = [];
          this.isShareCreatedModalShown = false;
        });
    },
  },
});
