
import { GroupedOrganizations } from '@/types';
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  props: {
    groups: {
      type: Array as PropType<Array<GroupedOrganizations>>,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      userOrganizationId: 'auth/getUserOrganization',
    }),
  },
  methods: {
    isSelected(organizationId: string) {
      return organizationId === this.userOrganizationId;
    },
    selectItem(id: string) {
      this.$emit('itemSelected', id);
    },
  },
});
