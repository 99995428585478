/* eslint-disable import/no-cycle */
import * as uuid from 'uuid';
import { TEMPLATE_TYPE } from '@/constants/template/template';
import getLogger from '@/services/logger';
import { createTemplate, updateTemplate } from '@/services/api/template.resource';
import { SUPERADMIN } from '@/constants/user/roles';
import { getColumnNames } from '@/services/api/data.resource';

const LOG = getLogger('store/templateCreation');

const getDefaultState = () => {
  return {
    template: {
      _id: null,
      type: null,
      name: null,
      organizationIds: [],
      compositions: [],
      layers: [],
      defaultAssets: [],
      thumb: null,
      template: null,
      fonts: null,
      functionKey: null,
      hasApiData: false,
      takePreviewScreenshotAtMS: null,
    },
    options: {
      baseDataOptions: { organizations: [] },
    },
    templateUploadProgress: 0,
    isLoading: true,
    hasUnsavedData: false,
    isEditing: false,
    isSaving: false,
    columnNames: [],
  };
};

const state = getDefaultState();

const getters = {
  getIsLoading(state: $TSFixMe) {
    return state.isLoading;
  },
  getTemplate(state: $TSFixMe) {
    return state.template;
  },
  getBaseData(state: $TSFixMe) {
    return {
      type: state.template.type,
      name: state.template.name,
      organizationIds: state.template.organizationIds,
      thumb: state.template.thumb,
      template: state.template.template,
      functionKey: state.template.functionKey,
      hasApiData: state.template.hasApiData,
      takePreviewScreenshotAtMS: state.template.takePreviewScreenshotAtMS,
      fonts: state.template.fonts,
      templateFileName: state.template.templateFileName,
    };
  },
  getBaseDataOptions(state: $TSFixMe) {
    return state.options.baseDataOptions;
  },
  getFormats(state: $TSFixMe) {
    return state.template.compositions;
  },
  getLayers(state: $TSFixMe) {
    return state.template.layers;
  },
  getDefaultAssets(state: $TSFixMe) {
    return state.template.defaultAssets;
  },
  getHasUnsavedData(state: $TSFixMe) {
    return state.hasUnsavedData;
  },
  getIsEditing(state: $TSFixMe) {
    return state.isEditing;
  },
  getTemplateType(state: $TSFixMe) {
    return state.template.type;
  },
  getApiLayers: (state: $TSFixMe) => {
    return state.columnNames;
  },
};

const actions = {
  addLayer({ commit }: $TSFixMe, layer: $TSFixMe) {
    commit('addLayer', layer);
  },
  addFormat({ commit }: $TSFixMe, format: $TSFixMe) {
    commit('addFormat', format);
  },
  updateLayer({ commit }: $TSFixMe, layerObject: $TSFixMe) {
    commit('updateLayer', layerObject);
  },
  updateColumnName({ commit }: $TSFixMe, updateObject: $TSFixMe) {
    commit('updateLayer', updateObject);
  },
  updateFormat({ commit }: $TSFixMe, formatObject: $TSFixMe) {
    commit('updateFormat', formatObject);
  },
  removeLayer({ commit }: $TSFixMe, index: $TSFixMe) {
    commit('removeLayer', index);
  },
  removeFormat({ commit }: $TSFixMe, index: $TSFixMe) {
    commit('removeFormat', index);
  },
  async create({ state, commit }: $TSFixMe) {
    try {
      commit('setIsSaving', true);
      const response = await createTemplate(state.template, (progress: $TSFixMe) =>
        commit('setTemplateUploadProgress', progress),
      );
      commit('setIsSaving', false);
      LOG.debug(response);
    } catch (e) {
      commit('setIsSaving', false);
      LOG.error(e);
      throw e;
    }
  },
  async update({ state, commit }: $TSFixMe) {
    try {
      commit('setIsSaving', true);
      const response = await updateTemplate(state.template, (progress: $TSFixMe) =>
        commit('setTemplateUploadProgress', progress),
      );
      commit('setIsSaving', false);
      LOG.debug(response);
    } catch (e) {
      commit('setIsSaving', false);
      LOG.error(e);
      throw e;
    }
  },
  reset({ commit }: $TSFixMe) {
    commit('reset');
  },
  async setTemplateToEdit({ commit, dispatch }: $TSFixMe, templateToEdit: $TSFixMe) {
    commit('setIsEditing', true);
    const template = {
      _id: templateToEdit._id,
      type: templateToEdit.type,
      compositions: mapCompositionsToEdit(templateToEdit),
      functionKey: templateToEdit.functionKey,
      hasApiData: templateToEdit.hasApiData,
      takePreviewScreenshotAtMS: templateToEdit.takePreviewScreenshotAtMS,
      layers: mapLayersToEdit(templateToEdit),
      name: templateToEdit.name,
      organizationIds: templateToEdit.organizationIds,
      template: null,
      thumb: null,
      templateFileName: templateToEdit.templateFileName,
    };
    if (templateToEdit.defaultAssets) {
      (template as $TSFixMe).defaultAssets = mapDefaultAssetsToEdit(templateToEdit);
    } else {
      (template as $TSFixMe).defaultAssets = [];
    }
    commit('setTemplate', template);
    await dispatch('loadOptions');
  },
  async loadOptions({ commit, rootGetters, dispatch }: $TSFixMe) {
    commit('setIsLoading', true);

    await loadBaseDataOptions(rootGetters, dispatch, commit);

    commit('setIsLoading', false);
  },
  setHasUnsavedData({ commit }: $TSFixMe, hasUnsavedData: $TSFixMe) {
    commit('setHasUnsavedData', hasUnsavedData);
  },
  async loadColumnNames({ commit }: $TSFixMe, organizationId: $TSFixMe) {
    const columnNames = await getColumnNames(organizationId);
    if (columnNames) {
      commit('setColumnNames', Object.keys((columnNames as $TSFixMe).data));
    }
  },
};

const mutations = {
  addLayer(state: $TSFixMe, layer: $TSFixMe) {
    state.template.layers.push(layer);
  },
  addFormat(state: $TSFixMe, format: $TSFixMe) {
    state.template.compositions.push(format);
  },
  updateLayer(state: $TSFixMe, layerObject: $TSFixMe) {
    state.template.layers.splice(layerObject.updateAt, 1, layerObject.newLayer);
  },
  updateColumnName(state: $TSFixMe, updateObject: $TSFixMe) {
    state.template.layers[updateObject.updateAt].columnName = updateObject.columnName;
  },
  updateFormat(state: $TSFixMe, formatObject: $TSFixMe) {
    state.template.compositions.splice(formatObject.updateAt, 1, formatObject.newFormat);
  },
  removeLayer(state: $TSFixMe, index: $TSFixMe) {
    state.template.layers.splice(index, 1);
  },
  removeFormat(state: $TSFixMe, index: $TSFixMe) {
    state.template.compositions.splice(index, 1);
  },
  setTemplate(state: $TSFixMe, template: $TSFixMe) {
    state.template = template;
  },
  setBaseData(state: $TSFixMe, baseData: $TSFixMe) {
    state.template.type = baseData.type;
    state.template.name = baseData.name;
    state.template.thumb = baseData.thumb;
    state.template.template = baseData.template;
    state.template.organizationIds = baseData.organizations;
    state.template.hasApiData = baseData.hasApiData;
    if (baseData.fonts) {
      state.template.fonts = baseData.fonts;
    }
    if (baseData.type === TEMPLATE_TYPE.HTML5) {
      if (baseData.functionKey) {
        state.template.functionKey = baseData.functionKey;
      } else {
        state.template.functionKey = null;
      }
      if (baseData.hasApiData) {
        state.template.hasApiData = baseData.hasApiData;
      } else {
        state.template.hasApiData = false;
      }
      if (baseData.takePreviewScreenshotAtMS) {
        state.template.takePreviewScreenshotAtMS = baseData.takePreviewScreenshotAtMS;
      } else {
        state.takePreviewScreenshotAtMS = null;
      }
    }
    state.hasUnsavedData = true;
  },
  setBaseDataOptions(state: $TSFixMe, baseDataOptions: $TSFixMe) {
    state.options.baseDataOptions = baseDataOptions;
  },
  setFormats(state: $TSFixMe, compositions: $TSFixMe) {
    state.template.compositions = compositions;
  },
  setLayers(state: $TSFixMe, layers: $TSFixMe) {
    state.template.layers = layers;
  },
  setDefaultAssets(state: $TSFixMe, defaultAssets: $TSFixMe) {
    state.template.defaultAssets = defaultAssets;
  },
  setTemplateUploadProgress(state: $TSFixMe, templateUploadProgress: $TSFixMe) {
    state.templateUploadProgress = templateUploadProgress;
  },
  setIsLoading(state: $TSFixMe, isLoading: $TSFixMe) {
    state.isLoading = isLoading;
  },
  setHasUnsavedData(state: $TSFixMe, hasUnsavedData: $TSFixMe) {
    state.hasUnsavedData = hasUnsavedData;
  },
  setIsEditing(state: $TSFixMe, isEditing: $TSFixMe) {
    state.isEditing = isEditing;
  },
  setIsSaving(state: $TSFixMe, isSaving: $TSFixMe) {
    state.isSaving = isSaving;
  },
  setColumnNames(state: $TSFixMe, layers: $TSFixMe) {
    state.columnNames = layers;
  },
  reset(state: $TSFixMe) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

async function loadBaseDataOptions(rootGetters: $TSFixMe, dispatch: $TSFixMe, commit: $TSFixMe) {
  const userRole = rootGetters['auth/getUserRole'];
  let organizations;

  if (userRole === SUPERADMIN) {
    await dispatch('organizations/loadOrganizations', null, { root: true });
    organizations = rootGetters['organizations/getOrganizations'];
  } else {
    organizations = [rootGetters['auth/getUserOrganization']];
  }

  commit('setBaseDataOptions', { organizations });
}

function mapCompositionsToEdit(templateToEdit: $TSFixMe) {
  return templateToEdit.compositions.map(({ humanReadableName, name, format }: $TSFixMe) => {
    return {
      id: uuid.v1(),
      humanReadableName,
      name,
      type: format,
      isNew: false,
      video: null,
    };
  });
}

function mapDefaultAssetsToEdit(templateToEdit: $TSFixMe) {
  return templateToEdit.defaultAssets.map(({ layerName, type }: $TSFixMe) => {
    return {
      layerName,
      type,
      file: undefined,
      isNew: false,
    };
  });
}

function mapLayerOptions(layer: $TSFixMe) {
  return layer.options.map((option: $TSFixMe) => {
    return {
      key: option.key,
      value: option.value,
    };
  });
}

function mapLayerSettings(layer: $TSFixMe) {
  return layer.settings.map((setting: $TSFixMe) => {
    let meta: $TSFixMe = null;
    if (setting.meta) {
      meta = setting.meta;
      Object.keys(meta).forEach((key) => {
        meta[key] = meta[key].toString();
      });
    }
    const minValue = setting.minValue ? setting.minValue.toString() : null;
    const maxValue = setting.maxValue ? setting.maxValue.toString() : null;
    return {
      format: setting.format,
      meta,
      minValue,
      isMinUnlimited: setting.isMinUnlimited,
      maxValue,
      isMaxUnlimited: setting.isMaxUnlimited,
    };
  });
}

function mapLayersToEdit(templateToEdit: $TSFixMe) {
  return templateToEdit.layers.map((layer: $TSFixMe) => {
    const mappedLayer = {
      id: layer._id,
      layerName: layer.layerName,
      name: layer.name,
      property: layer.property,
      rawType: layer.rawType,
      type: layer.type,
      columnName: layer.columnName,
      isOptional: layer.isOptional,
      options: [],
      settings: [],
    };
    if (layer.options) {
      mappedLayer.options = mapLayerOptions(layer);
    }
    if (layer.settings) {
      mappedLayer.settings = mapLayerSettings(layer);
    }
    return mappedLayer;
  });
}
