
import { mapGetters } from 'vuex';
import DiscardModal from '@/components/shared/DiscardModal.vue';
import DownloadIcon from '@/assets/images/download-content.svg';
import MoveIcon from '@/assets/images/move.svg';
import GroupIcon from '@/assets/images/group.svg';
import TrashIcon from '@/assets/images/trash.svg';
import CloseIcon from '@/assets/images/close.svg';
import ContentModal from '@/components/campaignDetail/contentDetail/ContentModal.vue';
import SmallSpinner from '@/components/shared/SmallSpinner.vue';
import SharingIcon from '@/assets/images/share_FILL0_wght200_GRAD0_opsz24.svg';
import UploadIcon from '@/assets/images/cloud-upload.svg';
import { FINAL_STATUSES, RENDER_WS_STATUS } from '@/constants/content/contentCreation';
import { getContentItemShareLinkID, getContentShareLinkID } from '@/services/api/content.resource';
import ContentShareModal from '@/components/campaignDetail/contentDetail/ContentShareModal.vue';
import ContentMoveModal from '@/components/campaignDetail/contentDetail/ContentMoveModal.vue';
import ContentErrorMessageModal from '@/components/campaignDetail/contentDetail/ContentErrorMessageModal.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ContentErrorMessageModal,
    ContentShareModal,
    ContentMoveModal,
    MoveIcon,
    DownloadIcon,
    GroupIcon,
    TrashIcon,
    ContentModal,
    DiscardModal,
    SmallSpinner,
    CloseIcon,
    SharingIcon,
    UploadIcon,
  },
  props: {
    isHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUploadable: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    campaign: {
      type: String,
      required: false,
      default: null,
    },
    language: {
      type: String,
      required: true,
    },
    creationDate: {
      type: String,
      required: false,
      default: '',
    },
    templateName: {
      type: String,
      required: true,
    },
    formatInfo: {
      type: String,
      required: false,
      default: null,
    },
    render: {
      type: Object,
      required: false,
      default: null,
    },
    status: {
      type: String,
      required: true,
      default: '',
    },
    renderProgress: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      isModalOpen: false,
      isContentDeletionModalShown: false,
      isShareModalShown: false,
      isMoveModalShown: false,
      isErrorMessageModalShown: false,
      isConfirmUploadShown: false,
      getLinkFunc: null as (() => Promise<string>) | null,
    };
  },
  computed: {
    ...mapGetters({
      hasCreditsLeft: 'organizations/getHasCreditsLeft',
      hasCmsUpload: 'content/getHasCmsUpload',
    }),
    statusMessage() {
      if (this.status === FINAL_STATUSES.RENDERING) {
        return this.$t('content.general.rendering');
      }
      if (this.status === RENDER_WS_STATUS.WAITING) {
        return this.$t('content.general.waiting');
      }
      if (this.status === RENDER_WS_STATUS.COMPLETED) {
        return this.$t('content.general.completed');
      }
      if (this.status === 'DELETING') {
        return this.$t('content.general.deleting');
      }
      return this.status;
    },
    isWaiting() {
      return this.status === FINAL_STATUSES.WAITING;
    },
    isRendering() {
      return !this.isWaiting && !this.hasFailed && !this.isCreated && !this.isDeleting;
    },
    hasFailed() {
      return this.status === FINAL_STATUSES.FAILED;
    },
    isCreated() {
      return this.status === FINAL_STATUSES.CREATED;
    },
    isDeleting() {
      return this.status === 'DELETING';
    },
    showStatusContainer() {
      return !this.isCreated && !this.hasFailed;
    },
  },
  methods: {
    copyMediaClicked() {
      this.$emit('copyMediaClicked');
    },
    openModal() {
      if (this.isCreated) {
        this.isModalOpen = true;
      }
    },
    closeModal() {
      this.isModalOpen = false;
    },
    showContentDeletionModal() {
      this.isContentDeletionModalShown = true;
    },
    closeContentDeletionModal() {
      this.isContentDeletionModalShown = false;
    },
    showShareModal() {
      this.isShareModalShown = true;
    },
    closeShareModal() {
      this.isShareModalShown = false;
      this.getLinkFunc = null;
    },
    showMoveModal() {
      this.isMoveModalShown = true;
    },
    closeMoveModal() {
      this.isMoveModalShown = false;
    },
    showErrorMessageModal() {
      if (!this.isHeader && this.render.errorMessage) {
        this.isErrorMessageModalShown = true;
      }
    },
    closeErrorMessageModal() {
      this.isErrorMessageModalShown = false;
    },
    async copyShareLink() {
      this.showShareModal();
      this.getLinkFunc = async () => {
        if (this.isHeader) {
          return await getContentShareLinkID(this.id);
        } else {
          return await getContentItemShareLinkID(this.id);
        }
      };
    },
    titleClicked() {
      this.$emit('titleClicked');
    },
    downloadClicked() {
      this.$emit('downloadClicked');
    },
    copyContentClicked() {
      if (this.hasCreditsLeft) {
        this.$emit('copyContentClicked');
      }
    },
    deleteContentClicked() {
      this.closeContentDeletionModal();
      this.$emit('deleteContentClicked');
    },
    deleteContentItemClicked() {
      this.closeContentDeletionModal();
      this.$emit('deleteContentItemClicked', this.render.id);
    },
    changeCampaign(newCampaignId: string) {
      this.$emit('changeCampaignTriggered', newCampaignId);
    },
    handleClickUpload() {
      this.isConfirmUploadShown = true;
    },
    closeConfirmUploadModal() {
      this.isConfirmUploadShown = false;
    },
    uploadContent() {
      this.closeConfirmUploadModal();
      this.$emit('uploadContent');
    },
    uploadContentItem() {
      this.closeConfirmUploadModal();
      this.$emit('uploadContentItem');
    },
  },
});
