<template>
  <div
    v-click-outside="clickOutsidePopupConfig"
    class="header-popup-bar xl:mx-auto items-center justify-between mt-10 flex-wrap sm:flex-no-wrap"
  >
    <div class="flex items-center mt-9">
      <div class="flex text-blue-light items-end cursor-pointer popup-trigger" @click="onPopupToggle('organizations')">
        <transition name="fade-down">
          <CheckboxFilterPopup
            v-show="objectName === 'organizations'"
            button-type="radio"
            :options="organizationNames"
            :rounded-checkbox="false"
            category-type="organizations"
            @inclusiveFilterClicked="updateUsersOrganizationFilter"
            >{{ $t('home.general.clients') }}</CheckboxFilterPopup
          >
        </transition>
        <IconOrganizations class="stroke-current fill-white" />
        <div class="ml-3 font-sans font-semibold h-4 hidden lg:block">{{ $t('home.general.clients') }}</div>
      </div>

      <div class="flex text-blue-light items-end cursor-pointer popup-trigger ml-12" @click="onPopupToggle('roles')">
        <transition name="fade-down">
          <CheckboxFilterPopup
            v-show="objectName === 'roles'"
            button-type="radio"
            :options="roleNames"
            :rounded-checkbox="false"
            category-type="roles"
            @resetFilterClicked="onResetFilterClicked"
            @inclusiveFilterClicked="updateUsersRoleFilter"
            >{{ $t('home.general.roles') }}</CheckboxFilterPopup
          >
        </transition>
        <IconRoles class="text-blue-light" />
        <div class="ml-3 font-sans font-semibold h-4 hidden lg:block">{{ $t('home.general.roles') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IconOrganizations from '@/assets/images/desktop-monitor-smiley.svg';
import IconRoles from '@/assets/images/roles.svg';
import CheckboxFilterPopup from '@/components/shared/CheckboxFilterPopup.vue';

export default {
  components: {
    CheckboxFilterPopup,
    IconOrganizations,
    IconRoles,
  },
  props: {
    organizations: {
      type: Array,
      default: undefined,
    },
    roles: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      objectName: null,
      showSideBar: false,
      inputDebounce: null,

      clickOutsidePopupConfig: {
        handler: this.closeAnyPopup,
      },
    };
  },
  computed: {
    ...mapGetters({
      getUniqueValuesOfKey: 'campaigns/getUniqueValuesOfKey',
    }),
    organizationNames() {
      return this.organizations ? this.organizations.map((orgnization) => orgnization.name) : undefined;
    },
    roleNames() {
      return this.roles ? this.roles.map((role) => role.name) : undefined;
    },
  },
  methods: {
    onPopupToggle(category) {
      this.objectName = this.objectName === category ? null : category;
    },
    closeAnyPopup() {
      this.objectName = null;
    },
    onSelectionMade() {
      this.showSideBar = false;
    },
    onResetFilterClicked(categoryType) {
      this.$store.dispatch('campaigns/resetFilter', categoryType);
    },
    onExclusiveFilterClicked(filter) {
      this.$store.dispatch('campaigns/addExclusiveFilter', filter);
    },
    updateUsersRoleFilter(payload) {
      if (!payload.isChecked) {
        this.$store.dispatch('users/addRoleFilter', payload.filter.value);
      } else {
        this.$store.dispatch('users/removeRoleFilter', payload.filter.value);
      }
    },
    updateUsersOrganizationFilter(payload) {
      if (!payload.isChecked) {
        this.$store.dispatch('users/addClientFilter', payload.filter.value);
      } else {
        this.$store.dispatch('users/removeClientFilter', payload.filter.value);
      }
    },
    onDateFilterClicked(dateRange) {
      this.$store.dispatch('campaigns/addDateRangeFilter', dateRange);
    },
    onDateFilterCleared() {
      this.$store.dispatch('campaigns/clearDateRangeFilter');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';

.header-popup-bar {
  display: flex;
}
</style>
