<template>
  <div>
    <ContentSpecificationForm
      ref="contentSpecificationForm"
      @previewRequested="onPreviewRequested"
      @languageChanged="onLanguageChanged"
      @formatChanged="onFormatChanged"
    />
    <VideoContentPreview ref="videoContentPreview" :formats="formats" :active-language="activeLanguage" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ContentSpecificationForm from '@/components/campaignDetail/contentCreation/contentSpecification/ContentSpecificationForm.vue';
import VideoContentPreview from '@/components/campaignDetail/contentCreation/contentSpecification/VideoContentPreview.vue';

export default {
  components: {
    ContentSpecificationForm,
    VideoContentPreview,
  },
  data() {
    return {
      activeFormat: '',
      activeLanguage: '',
    };
  },
  computed: {
    ...mapGetters({
      formats: 'contentCreation/getFormats',
    }),
  },
  methods: {
    onPreviewRequested(payload) {
      this.$refs.videoContentPreview.startPreviewRendering(
        payload?.language || this.activeLanguage,
        payload?.format || this.activeFormat,
      );
    },
    onLanguageChanged(value) {
      this.activeLanguage = value;
    },
    onFormatChanged(value) {
      this.activeFormat = value;
    },
    validate() {
      return this.$refs.contentSpecificationForm.validate();
    },
  },
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
