<template>
  <ModalBase
    :title="$t('general.users.userModal')"
    :show="show"
    :override-style="{
      width: '500px',
      overflow: 'auto',
      'background-color': scssVariables.cchLightGray,
    }"
    :override-header-style="{ 'margin-bottom': '2rem' }"
    @closeClicked="onCloseClicked"
  >
    <template #content>
      <FormInputField
        ref="firstInput"
        v-model="firstName"
        class="form-field"
        :placeholder="$t('general.users.firstName')"
      />
      <FormInputField v-model="lastName" class="form-field" :placeholder="$t('general.users.lastName')" />
      <FormInputField v-model="email" class="form-field" :placeholder="$t('general.users.eMail')" />
      <FormInputField v-model="password" class="form-field" :placeholder="$t('general.users.password')" />
      <label class="form-field checkbox-label">
        <input v-model="isPasswordChangeRequired" type="checkbox" />
        {{ $t('general.users.requirePasswordChange') }}
      </label>
      <FormSelectionField
        v-model="client"
        class="form-field"
        :placeholder="$t('general.users.client')"
        :list="organizationNames"
        :selected-item="findOrganizationName()"
      />
      <FormSelectionField
        v-model="role"
        class="form-field"
        :placeholder="$t('general.users.role')"
        :list="roleNames"
        :selected-item="findRoleName()"
      />
    </template>
    <template #button-row>
      <div class="button-row">
        <AppButton type="outlined" size="small-auto" @click="onCloseClicked">
          {{ $t('general.buttons.cancel') }}
        </AppButton>
        <AppButton v-if="!type" size="small-auto" @click="createUser">{{ $t('general.buttons.create') }}</AppButton>
        <AppButton v-else size="small-auto" @click="editUser">{{ $t('general.buttons.edit') }}</AppButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import FormSelectionField from '@/components/shared/formFields/FormSelectionField.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import scssVariables from '@/styles/variables.module.scss';
import { toast } from '@/services/toast';

export default {
  components: {
    ModalBase,
    FormInputField,
    FormSelectionField,
    AppButton,
  },
  props: {
    roles: {
      type: Array,
      default: null,
    },
    organizations: {
      type: Array,
      default: null,
    },
    user: {
      type: Object,
      required: false,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      isPasswordChangeRequired: false,
      client: '',
      role: '',
      scssVariables,
    };
  },
  computed: {
    isFirstNameProvided() {
      return this.firstName.length > 0;
    },
    isLastNameProvided() {
      return this.lastName.length > 0;
    },
    isEmailProvided() {
      return this.email.length > 0;
    },
    isPasswordProvided() {
      return this.password.length > 0;
    },
    isClientProvided() {
      return this.client.length > 0;
    },
    isRoleProvided() {
      return this.role.length > 0;
    },
    roleNames() {
      return this.roles.map((role) => {
        return role.name;
      });
    },
    organizationNames() {
      return this.organizations.map((organization) => {
        return organization.name;
      });
    },
  },
  mounted() {
    if (this.user !== null) {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.email = this.user.email;
      this.password = this.user.password;
      this.isPasswordChangeRequired = this.user.isPasswordChangeRequired;
      this.role = this.findRoleName(this.user.role);
      this.client = this.findOrganizationName(this.user.organization);
    }
    this.$refs.firstInput.focus();
  },
  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    createUser() {
      if (this.validate()) {
        const roleID = this.roles
          .filter((role) => {
            return role.name === this.role;
          })
          .pop()._id;
        const organizationID = this.organizations
          .filter((organization) => {
            return organization.name === this.client;
          })
          .pop()._id;
        const userObject = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          isPasswordChangeRequired: this.isPasswordChangeRequired,
          role: roleID,
          organization: organizationID,
          username: this.email,
        };
        this.$emit('create', userObject);
      }
    },
    editUser() {
      if (this.validate()) {
        const roleID = this.roles
          .filter((role) => {
            return role.name === this.role;
          })
          .pop()._id;
        const organizationID = this.organizations
          .filter((organization) => {
            return organization.name === this.client;
          })
          .pop()._id;
        const userObject = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          isPasswordChangeRequired: this.isPasswordChangeRequired,
          role: roleID,
          organization: organizationID,
        };
        this.$emit('edit', { user: userObject, id: this.user._id });
      }
    },
    findRoleName() {
      if (this.user !== null) {
        const roleName = this.roles
          .filter((role) => {
            return role._id === this.user.role;
          })
          .pop();
        if (typeof roleName !== 'undefined' && roleName) {
          return roleName.name;
        }
      }
      return undefined;
    },
    findOrganizationName() {
      if (this.user !== null) {
        const organizationName = this.organizations
          .filter((organization) => {
            return organization._id === this.user.organization;
          })
          .pop();
        if (typeof organizationName !== 'undefined' && organizationName) {
          return organizationName.name;
        }
      }
      return undefined;
    },
    validate() {
      if (!this.isFirstNameProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'user-create',
          message: this.$t(`general.users.noFirstNameProvided`),
        });
        return false;
      }
      if (!this.isLastNameProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'user-create',
          message: this.$t(`general.users.noLastNameProvided`),
        });
        return false;
      }
      if (!this.isEmailProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'user-create',
          message: this.$t(`general.users.noEmailProvided`),
        });
        return false;
      }
      if (!this.isPasswordProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'user-create',
          message: this.$t(`general.users.noPasswordProvided`),
        });
        return false;
      }
      if (!this.isClientProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'user-create',
          message: this.$t(`general.users.noClientProvided`),
        });
        return false;
      }
      if (!this.isRoleProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'user-create',
          message: this.$t(`general.users.noRoleProvided`),
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.form-field {
  margin-bottom: rem(24px);
}
.checkbox-label {
  display: block;
}
.button-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
