import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import CampaignDetailMainView from '@/components/campaignDetail/CampaignDetailMainView.vue';
import ContentCreationWizard from '@/components/campaignDetail/contentCreation/ContentCreationWizard.vue';
import TemplatesMainView from '@/components/templates/TemplatesMainView.vue';
import TemplateCreationWizard from '@/components/templates/templateCreation/TemplateCreationWizard.vue';
import LoginForm from '@/components/login/LoginForm.vue';
import LoginPasswordChangePrompt from '@/components/login/LoginPasswordChangePrompt.vue';
import ResetPasswordForm from '@/components/login/ResetPasswordForm.vue';
import CreditsPage from '@/components/organizations/credits/CreditsPage.vue';
import Templates from '@/components/templates/TemplatesPage.vue';
import CampaignOverview from '@/components/campaigns/CampaignsPage.vue';
import CampaignDetail from '@/components/campaignDetail/CampaignDetailPage.vue';
import UserOverview from '@/components/users/UsersPage.vue';
import Assets from '@/components/assets/AssetsPage.vue';
import LoginPage from '@/components/login/LoginPage.vue';
import UserDetail from '@/components/userDetail/UserDetailPage.vue';
import ErrorsPage from '@/components/errors/ErrorsPage.vue';
import ShareManagement from '@/components/shares/ShareManagement.vue';
import OrganizationsPage from '@/components/organizations/OrganizationsPage.vue';
import RenderConfigView from '@/components/renderConfig/RenderConfigView.vue';
import CmsConfigView from '@/components/cmsConfig/CmsConfigView.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/campaigns',
      name: 'home',
      component: CampaignOverview,
    },
    {
      path: '/login',
      component: LoginPage,
      children: [
        {
          path: '',
          name: 'login',
          component: LoginForm,
          meta: { header: { hidden: true } },
        },
        {
          path: '/login/passwordChangePrompt',
          name: 'passwordChangePrompt',
          meta: { header: { hidden: true }, navigation: { hidden: true } },
          props: true,
          component: LoginPasswordChangePrompt,
        },
      ],
    },
    {
      path: '/reset-password/:token',
      component: LoginPage,
      children: [
        {
          path: '',
          name: 'resetPassword',
          meta: { header: { hidden: true } },
          props: true,
          component: ResetPasswordForm,
        },
      ],
    },
    {
      path: '/campaigns',
      component: CampaignOverview,
      meta: { header: { hidden: true } },
      name: 'campaignOverview',
    },
    {
      path: '/campaigns/:id',
      component: CampaignDetail,
      children: [
        {
          path: 'contentCreation',
          name: 'contentCreation',
          component: ContentCreationWizard,
          meta: { header: { hidden: true } },
          props: true,
        },
        {
          path: '',
          name: 'campaign',
          component: CampaignDetailMainView,
          meta: { header: { hidden: true } },
          props: true,
        },
      ],
    },
    {
      path: '/assets/:path*',
      name: 'assets',
      meta: { header: { hidden: true } },
      component: Assets,
    },
    {
      path: '/templates',
      component: Templates,
      children: [
        {
          path: 'templateCreation',
          name: 'templateCreation',
          meta: { header: { hidden: true } },
          component: TemplateCreationWizard,
        },
        {
          path: '',
          name: 'templates',
          component: TemplatesMainView,
          meta: { header: { hidden: true } },
          props: true,
        },
      ],
    },
    {
      path: '/shared-folders',
      name: 'shared-folders',
      meta: { header: { hidden: true } },
      component: ShareManagement,
    },
    {
      path: '/users',
      name: 'users',
      meta: { header: { hidden: true } },
      component: UserOverview,
    },
    {
      path: '/organizations',
      name: 'organizations',
      meta: { header: { hidden: true } },
      component: OrganizationsPage,
    },
    {
      path: '/render-config',
      name: 'render-config',
      meta: { header: { hidden: true } },
      component: RenderConfigView,
    },
    {
      path: '/cms-config',
      name: 'cms-config',
      meta: { header: { hidden: true } },
      component: CmsConfigView,
    },
    {
      path: '/user/:id',
      name: 'user',
      meta: { header: { hidden: true } },
      props: true,
      component: UserDetail,
    },
    {
      path: '/credits',
      name: 'credits',
      component: CreditsPage,
    },
    {
      path: '/errors',
      name: 'errors',
      component: ErrorsPage,
    },
  ],
});

router.beforeEach((to, from, next) => {
  store
    .dispatch('auth/checkCredentials')
    .then(() => {
      if (to.name === 'login') {
        next({
          name: 'home',
        });
      } else {
        next();
      }
    })
    .catch(() => {
      if (
        to.name !== 'login' &&
        to.name !== 'errors' &&
        to.name !== 'resetPassword' &&
        to.name !== 'passwordChangePrompt'
      ) {
        next({
          name: 'login',
        });
      } else {
        next();
      }
    });
});

export default router;
