<template>
  <div class="popup" @click.stop>
    <h2 class="popup-title">
      <slot />
    </h2>
    <div class="popup-body">
      <PopupRadioField
        v-for="option in radioOptions"
        :key="option.name"
        :radio-group-name="radioGroupName"
        :linked-data="option"
        :is-default="option.isDefault"
        class="radio-option"
        @radioSelected="onRadioSelected"
      >
        {{ $t(option.name) }}
      </PopupRadioField>
    </div>
  </div>
</template>

<script>
import PopupRadioField from '@/components/shared/popup/PopupRadioField.vue';

export default {
  name: 'RadioPopup',
  components: {
    PopupRadioField,
  },
  props: {
    radioGroupName: {
      type: String,
      default: 'radio',
      required: false,
    },
    radioOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onRadioSelected(category) {
      this.$emit('radioSelected', category);
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  @apply .absolute .flex .flex-col .h-auto .bg-white .z-10 .cursor-auto;
  box-shadow: 0 0 5px 0 rgba(71, 67, 102, 0.19);
  top: 11rem;
  filter: drop-shadow(0 0 5px rgba(71, 67, 102, 0.19));

  .popup-body {
    padding: 0 1.5rem;
  }
}

.popup::after {
  @apply .absolute .border-solid;
  content: '';
  bottom: 99%;
  margin-left: -5px;
  border-width: 1.5rem;
  border-color: transparent transparent white transparent;
  pointer-events: none;
  @media (min-width: 640px) {
    left: 20%;
  }
  left: 40%;
}

.popup-title {
  @apply .font-extrabold .font-sans .text-center .m-6 .mb-8 .text-black;
}

.radio-option {
  text-transform: capitalize;
}
</style>
