<template>
  <div class="app-selection">
    <Multiselect
      v-model="selection"
      class="selection-field"
      :placeholder="$t('general.inputs.selectOption')"
      :multiple="true"
      :clear-on-select="false"
      :options="list"
      :searchable="false"
      :option-height="80"
    >
      <template slot="selection" slot-scope="{ isOpen }">
        <span v-if="selection.length && selection.length > 1 && !isOpen" class="selected-items">
          {{ selection.length }} {{ placeholder }} {{ $t('general.inputs.selected') }}
        </span>
        <div v-if="selection.length && selection.length === 1 && !isOpen" class="selected-item">
          <span>
            {{ selection[0] }}
          </span>
        </div>
      </template>
      <template slot="option" slot-scope="props"
        ><span v-if="!isSelected(props.option)" class="selection-item">{{ props.option }}</span>
        <div v-if="isSelected(props.option)" class="selection-item selected">
          <span>{{ props.option }}</span>
          <span class="trash">
            <TrashButton class="trash-button" />
          </span>
        </div>
      </template>
      <template slot="caret">
        <ArrowDownIcon class="selection-icon" />
      </template>
      <template slot="noOptions">
        {{ $t('general.inputs.noOptions') }}
      </template>
      <template slot="beforeList">
        <div class="white-space"> &nbsp; </div>
      </template>
      <template slot="afterList">
        <div class="white-space"> &nbsp; </div>
      </template>
    </Multiselect>

    <label class="app-selection-label">{{ placeholder }}</label>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import ArrowDownIcon from '@/assets/images/arrow-down.svg';
import TrashButton from '@/components/shared/buttons/TrashButton.vue';

export default {
  components: {
    ArrowDownIcon,
    Multiselect,
    TrashButton,
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: false,
      default: null,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedItems: {
      type: Array,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      selection: [],
      items: [],
    };
  },
  watch: {
    selection() {
      this.$emit('selected', this.selection);
    },
    selectedItems() {
      this.selection = this.selectedItems;
    },
  },
  mounted() {
    this.selection = this.selectedItems;
  },
  methods: {
    isSelected(item) {
      return this.selection.includes(item);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.app-selection {
  @apply .font-sans  .text-blue-light .w-full .relative .bg-white;
  height: rem(51px);
  outline: none;
  color: black;

  .app-selection-label {
    @apply .absolute .font-semibold;
    color: rgba(51, 51, 51, 0.5);
    top: 0.5rem;
    left: 1rem;
    z-index: 0;
    pointer-events: none;
    font-size: 0.75rem;
    text-transform: capitalize;
  }

  .selection-field {
    position: relative;
    top: rem(20px);
    overflow: auto;
    padding-left: rem(15px);
    background-color: #ffffff;
    padding-top: 0.5rem;
    outline: none;
  }

  .disabled {
    cursor: not-allowed;
  }
  select:focus {
    outline: none;
  }

  .selection-item {
    position: relative;
    background-color: #ffffff;
    width: 33.7rem;
    display: flex;
    justify-content: space-between;
    &:hover {
      background-color: $cch-lighter-blue;
    }
    z-index: 60;
  }

  .selected-items {
    position: relative;
    bottom: rem(4px);
    color: black;
    font-size: rem(16px);
  }

  .selected:hover {
    background-color: red !important;
    color: white;
  }

  .selection-icon {
    float: right;
    pointer-events: none;
    position: relative;
    right: rem(15px);
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
  }
}
.trash {
  position: relative;
  left: 2rem;
}
.white-space {
  height: 1rem;
  background-color: white;
}
</style>
