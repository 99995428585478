
import AppText from '@/components/shared/AppText.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    AppText,
    AppButton,
  },
  props: {
    canReset: {
      type: Boolean,
      required: true,
      default: false,
    },
    canSave: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    handleClickReset() {
      this.$emit('clickReset');
    },
    handleClickSave() {
      this.$emit('clickSave');
    },
  },
});
