<template>
  <ModalBase
    :title="$t('campaigns.general.newCampaign')"
    :show="show"
    :override-style="{
      width: scssVariables.cchMediumExtended,
      height: '22rem',
      'background-color': scssVariables.cchLightGray,
    }"
    :override-header-style="{ 'margin-bottom': '2rem' }"
    @closeClicked="onCloseClicked"
  >
    <template #content>
      <FormInputField ref="firstInput" v-model="campaignName" :placeholder="$t('campaigns.general.campaignName')" />
    </template>
    <template #button-row>
      <div class="button-row">
        <AppButton size="small-auto" @click="createCampaign">{{ $t('campaigns.general.createCampaign') }}</AppButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import scssVariables from '@/styles/variables.module.scss';
import { toast } from '@/services/toast';

export default {
  components: {
    ModalBase,
    FormInputField,
    AppButton,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      campaignName: '',
      scssVariables,
    };
  },
  computed: {
    isCampaignNameProvided() {
      return this.campaignName.length > 0;
    },
  },
  mounted() {
    this.$refs.firstInput.focus();
  },
  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    createCampaign() {
      if (this.validate()) {
        this.$emit('create', this.campaignName);
        this.campaignName = '';
      }
    },
    validate() {
      if (!this.isCampaignNameProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'campaign-creation',
          message: this.$t(`campaigns.general.noCampaignNameProvided`),
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.button-row {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: rem(79px);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  padding-right: rem(24px);
}
</style>
