
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotificationButton',
  components: {},
  props: {
    unreadCount: {
      type: Number,
      required: false,
      default: 0,
    },
  },
});
