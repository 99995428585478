<template>
  <div>
    <div v-for="(option, index) in displayedOptions" :key="option.id" class="format-detail">
      <div class="option-row">
        <FormInputField
          v-model="option.key"
          :placeholder="$t('templates.creation.optionKey')"
          class="key-field"
          @input="updateData"
        />
        <FormInputField
          v-model="option.value"
          :placeholder="$t('templates.creation.optionValue')"
          class="value-field"
          @input="updateData"
        />

        <div class="remove-button">
          <TrashIcon class="trash-icon" viewBox="0 0 16 16" @click="removeItem(index)" />
        </div>
      </div>
    </div>
    <AppButton class="add-option-button" @click="addOption">+ {{ $t('templates.creation.addOption') }}</AppButton>
  </div>
</template>

<script>
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import TrashIcon from '@/assets/images/trash.svg';
import AppButton from '@/components/shared/buttons/AppButton.vue';

export default {
  components: {
    FormInputField,
    TrashIcon,
    AppButton,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: [],
    };
  },
  computed: {
    displayedOptions() {
      if (this.options.length === 0) {
        this.addOption();
      }

      return this.options;
    },
  },
  mounted() {
    this.options = this.data;
  },
  methods: {
    updateData() {
      this.$emit('update', this.options);
    },
    addOption() {
      this.options.push({
        id: Math.floor(Math.random() * 99999) + 1,
        key: null,
        value: null,
      });
    },
    removeItem(index) {
      this.options.splice(index, 1);
      this.updateData();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/templateCreation.scss';

.add-option-button {
  width: rem(160px);
  height: rem(50px);
}

.option-row {
  display: flex;
  width: 100%;
}

.key-field {
  margin-right: 25px;
  flex-grow: 1;
  font-size: 16px;
}

.value-field {
  flex-grow: 1;
  font-size: 16px;
}
.remove-button {
  flex: 0 0 rem(55px);
  margin: auto;
  width: 50%;
  padding: 10px;

  .trash-icon {
    width: rem(25px);
    height: rem(25px);
    cursor: pointer;
  }
}
</style>
