import * as uuid from 'uuid';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import getLogger from '@/services/logger';
import { asyncCopyToClipboard, asyncReadFromClipboard } from '@/services/shared/index';
import { TEMPLATE_LAYER_RAW_TYPE, TEMPLATE_LAYER_TYPE } from '@/constants/template/template';

const LOG = getLogger('content-creation/layers');

export function prepareCopiedLayer(layer: $TSFixMe) {
  const newLayer = layer;
  newLayer.id = uuid.v1();

  return newLayer;
}

export function exportLayers(layersToExport: $TSFixMe) {
  LOG.log('Exporting layers...');
  const layers: $TSFixMe = [];
  layersToExport.forEach((layer: $TSFixMe) => {
    const copiedLayer = _cloneDeep(layer);
    delete copiedLayer.id;
    layers.push(JSON.stringify(copiedLayer));
  });
  const layersObject = {
    layers,
  };

  asyncCopyToClipboard(JSON.stringify(layersObject));
}

export async function importLayers() {
  LOG.log('Importing layers...');
  const readLayers = JSON.parse(await asyncReadFromClipboard());
  const layers: $TSFixMe = [];
  readLayers.layers.forEach((layer: $TSFixMe) => {
    const newLayer = prepareCopiedLayer(JSON.parse(layer));
    layers.push(newLayer);
  });
  return layers;
}

export function validateLayers(layers: $TSFixMe) {
  const collectedErrors = new Set();
  layers.forEach((layer: $TSFixMe) => {
    if (!hasNameAndLayerType(layer, collectedErrors)) {
      collectedErrors.add('nameLayerTypeMissing');
    }

    if (!hasRawType(layer, collectedErrors)) {
      collectedErrors.add('rawTypeMissing');
    }

    if (!hasOption(layer, collectedErrors)) {
      collectedErrors.add('emptyOptions');
    }

    if (!hasSettings(layer, collectedErrors)) {
      collectedErrors.add('emptySettings');
    }

    if (!hasImageType(layer, collectedErrors)) {
      collectedErrors.add('emptySettings');
    }

    if (!hasAudioType(layer, collectedErrors)) {
      collectedErrors.add('emptySettings');
    }

    if (!hasVideoType(layer, collectedErrors)) {
      collectedErrors.add('emptySettings');
    }
  });
  return [...collectedErrors];
}

function hasNameAndLayerType(layer: $TSFixMe, collectedErrors: $TSFixMe) {
  if (!layer.name || !layer.layerName || !layer.type) {
    return collectedErrors.has('nameLayerTypeMissing');
  }
  return true;
}

function hasRawType(layer: $TSFixMe, collectedErrors: $TSFixMe) {
  if (layer.type === TEMPLATE_LAYER_TYPE.DATA && (!layer.property || !layer.rawType)) {
    return collectedErrors.has('rawTypeMissing');
  }
  return true;
}

function hasOption(layer: $TSFixMe, collectedErrors: $TSFixMe) {
  if (layer.type === TEMPLATE_LAYER_TYPE.DATA && layer.rawType === TEMPLATE_LAYER_RAW_TYPE.OPTION) {
    if (layer.options.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const option of layer.options) {
        if (!option.key || !option.value) {
          return collectedErrors.has('emptyOptions');
        }
      }
    } else {
      return collectedErrors.has('emptyOptions');
    }
  }
  return true;
}

function hasSettings(layer: $TSFixMe, collectedErrors: $TSFixMe) {
  if (layer.type === TEMPLATE_LAYER_TYPE.DATA && layer.rawType !== TEMPLATE_LAYER_RAW_TYPE.OPTION) {
    if (layer.settings.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const setting of layer.settings) {
        if (!setting.maxValue || !setting.minValue) {
          return collectedErrors.has('emptySettings');
        }
      }
    } else {
      return collectedErrors.has('emptySettings');
    }
  }
  return true;
}

function hasImageType(layer: $TSFixMe, collectedErrors: $TSFixMe) {
  if (layer.type === TEMPLATE_LAYER_TYPE.IMAGE) {
    if (layer.settings.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const setting of layer.settings) {
        if (!setting.meta.minHeight || !setting.meta.minWidth || !setting.meta.aspectRatio) {
          return collectedErrors.has('emptySettings');
        }
      }
    } else {
      return collectedErrors.has('emptySettings');
    }
  }
  return true;
}

function hasAudioType(layer: $TSFixMe, collectedErrors: $TSFixMe) {
  if (layer.type === TEMPLATE_LAYER_TYPE.AUDIO) {
    if (layer.settings.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const setting of layer.settings) {
        if (!setting.meta.duration) {
          return collectedErrors.has('emptySettings');
        }
      }
    } else {
      return collectedErrors.has('emptySettings');
    }
  }
  return true;
}

function hasVideoType(layer: $TSFixMe, collectedErrors: $TSFixMe) {
  if (layer.type === TEMPLATE_LAYER_TYPE.VIDEO) {
    if (layer.settings.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const setting of layer.settings) {
        if (!setting.meta.aspectRatio || !setting.meta.duration) {
          return collectedErrors.has('emptySettings');
        }
      }
    } else {
      return collectedErrors.has('emptySettings');
    }
  }
  return true;
}
