
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import { toast } from '@/services/toast';
import scssVariables from '@/styles/variables.module.scss';
import { defineComponent } from 'vue';
import { getShareOptions } from '@/services/api/assets.resource';
import { mapGetters } from 'vuex';

export default defineComponent({
  components: {
    ModalBase,
    AppButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    assetId: {
      type: String,
      default: null,
    },
  },
  data(): {
    organizations: Array<{ id: string; name: string }>;
    selectedOrganizations: Array<{ id: string; name: string }>;
    scssVariables: any;
  } {
    return {
      organizations: [],
      selectedOrganizations: [],
      scssVariables,
    };
  },
  computed: {
    ...mapGetters({
      organizationId: 'auth/getUserOrganization',
    }),
    isRecipientsProvided() {
      return this.selectedOrganizations.length > 0;
    },
    organizationNames() {
      return this.organizations.map((organization) => {
        return organization.name;
      });
    },
  },
  async mounted() {
    const options = await getShareOptions(this.organizationId, this.assetId);
    this.organizations = options.availableRecipients;
  },
  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    onSubmitClick() {
      if (this.validate()) {
        this.$emit('submit', {
          recipients: this.selectedOrganizations,
        });
      }
    },
    validate() {
      if (!this.isRecipientsProvided) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'asset-sharing',
          message: this.$t(`assets.general.noRecipientProvided`),
        });
        return false;
      }
      return true;
    },
  },
});
