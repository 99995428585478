
import { defineComponent, PropType } from 'vue';
import { CmsConfigEntity, Organization } from '@/types';
import AppLoading from '@/components/shared/AppLoading.vue';
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import FormSelectionField from '@/components/shared/formFields/FormSelectionField.vue';
import FormPasswordField from '@/components/shared/formFields/FormPasswordField.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import SmallSpinner from '@/components/shared/SmallSpinner.vue';
import scssVariables from '@/styles/variables.module.scss';
import { getNonCmsConfiguredOrganizations } from '@/services/api/cms.resource';

export interface CmsConfigProfileSubmitParams {
  id?: string;
  organizationId: string;
  cmsPathId: string;
  cmsUsername: string;
  newPassword: string;
}

export default defineComponent({
  components: {
    AppLoading,
    ModalBase,
    AppButton,
    SmallSpinner,
    FormInputField,
    FormSelectionField,
    FormPasswordField,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Object as PropType<CmsConfigEntity | null>,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'cancel', 'submit'],
  data() {
    return {
      scssVariables,
      isInitializing: false,
      cmsPathId: '',
      cmsUsername: '',
      isChangePasswordChecked: false,
      newPassword: '',
      confirmNewPassword: '',
      organizations: [] as Array<Organization>,
      selectedOrganizationId: undefined as string | undefined,
    };
  },
  computed: {
    isUpdateMode(): boolean {
      return Boolean(this.entity);
    },
    isFormValid(): boolean {
      if (this.isUpdateMode) {
        const updateConditionsValid =
          Boolean(this.selectedOrganizationId) && Boolean(this.cmsPathId) && Boolean(this.cmsUsername);
        if (this.isChangePasswordChecked) {
          return (
            updateConditionsValid &&
            Boolean(this.newPassword) &&
            Boolean(this.confirmNewPassword) &&
            this.newPassword === this.confirmNewPassword
          );
        } else {
          return updateConditionsValid;
        }
      } else {
        return (
          Boolean(this.selectedOrganizationId) &&
          Boolean(this.cmsPathId) &&
          Boolean(this.cmsUsername) &&
          Boolean(this.newPassword) &&
          Boolean(this.confirmNewPassword) &&
          this.newPassword === this.confirmNewPassword
        );
      }
    },
    isFormDirty(): boolean {
      if (this.isUpdateMode) {
        return (
          this.isChangePasswordChecked ||
          this.cmsPathId !== String(this.entity?.cmsPathId) ||
          this.cmsUsername !== String(this.entity?.cmsUsername)
        );
      }
      return true;
    },
    isPasswordsNotMatch(): boolean {
      if (this.newPassword && this.confirmNewPassword && this.newPassword !== this.confirmNewPassword) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    await this.initializeForm();
  },
  methods: {
    async initializeForm() {
      try {
        this.isInitializing = true;
        const organizations = await getNonCmsConfiguredOrganizations();
        this.organizations = organizations;

        if (this.entity) {
          this.selectedOrganizationId = this.entity.organizationId;
          this.cmsPathId = String(this.entity.cmsPathId);
          this.cmsUsername = this.entity.cmsUsername;
        }
      } finally {
        this.isInitializing = false;
      }
    },
    onChangeOrganization(orgId: string) {
      this.selectedOrganizationId = orgId;
    },
    onToggleChangePassword() {
      this.newPassword = '';
      this.confirmNewPassword = '';
      this.isChangePasswordChecked = !this.isChangePasswordChecked;
    },
    onClose() {
      this.$emit('close');
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSubmit() {
      if (this.isFormValid && this.selectedOrganizationId) {
        const params: CmsConfigProfileSubmitParams = {
          id: this.entity?.id,
          organizationId: this.selectedOrganizationId,
          cmsPathId: this.cmsPathId,
          cmsUsername: this.cmsUsername,
          newPassword: this.newPassword,
        };
        this.$emit('submit', params);
      }
    },
  },
});
