import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import crossSession from '@/store/modules/crossSession';
// eslint-disable-next-line import/no-cycle
import editedAssets from '@/store/modules/editedAssets';
import editedMedia from '@/store/modules/editedMedia';
import general from '@/store/modules/general';
// eslint-disable-next-line import/no-cycle
import auth from '@/store/modules/auth';
// eslint-disable-next-line import/no-cycle
import campaigns from '@/store/modules/campaigns';
// eslint-disable-next-line import/no-cycle
import content from '@/store/modules/content';
// eslint-disable-next-line import/no-cycle
import assets from '@/store/modules/assets';
// eslint-disable-next-line import/no-cycle
import templates from '@/store/modules/templates';
// eslint-disable-next-line import/no-cycle
import organizations from './modules/organizations';
// eslint-disable-next-line import/no-cycle
import contentCreation from './modules/contentCreation';
// eslint-disable-next-line import/no-cycle
import users from './modules/users';
// eslint-disable-next-line import/no-cycle
import roles from './modules/roles';
// eslint-disable-next-line import/no-cycle
import templateCreation from './modules/templateCreation';
// eslint-disable-next-line import/no-cycle
import assetsMoving from '@/store/modules/assetsMoving';
import notifications from '@/store/modules/notifications';
import renders from '@/store/modules/renders';
import renderConfig from '@/store/modules/renderConfig';
import cms from '@/store/modules/cms';

Vue.use(Vuex);

// persist across sessions
const persistCrossSession = new VuexPersistence({
  key: 'crossSessionStore',
  storage: window.localStorage,
  reducer: (state) => ({
    // add other stores you want to persist across sessions here
    // moduleName: state.moduleName
    crossSession: (state as $TSFixMe).crossSession,
  }),
  // add other mutations you want to trigger a persitent write OR gated
  // filter: mutation => mutation.type === "mutationName" || mutation.type === "otherMutationName" ...
  filter: (mutation) => mutation.type === 'crossSession/setAssetView' || mutation.type === 'crossSession/setLanguage',
});

const persistSession = new VuexPersistence({
  key: 'sessionStore',
  storage: window.sessionStorage,
  reducer: (state) => ({
    // add other stores you want to persist during sessions here
    // moduleName: state.moduleName
    session: (state as $TSFixMe).session,
  }),
  // add mutations you want to trigger a persitent write OR gated
  // filter: mutation => mutation.type === "mutationName" || mutation.type === "otherMutationName" ...
  // or leave blank to include all mutations
});

export default new Vuex.Store({
  modules: {
    crossSession,
    general,
    auth,
    campaigns,
    assets,
    templates,
    content,
    organizations,
    contentCreation,
    editedAssets,
    editedMedia,
    users,
    roles,
    templateCreation,
    assetsMoving,
    notifications,
    renders,
    renderConfig,
    cms,
  },
  plugins: [persistCrossSession.plugin, persistSession.plugin],
});
