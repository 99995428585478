<template>
  <div class="asset-select-container">
    <div class="asset-selection-field" :class="{ 'has-asset': hasAsset }">
      <div class="media-label-container" :class="{ 'with-asset': hasAsset }">
        <span class="media-label" :class="{ 'label-with-asset': hasAsset }">{{ placeholder }}</span>
        <RemoveMediaIcon v-if="hasAsset" class="close-icon" @click="removeAsset" />
      </div>
      <img v-if="finalAsset" class="thumbnail" :src="finalAsset" alt="Preview" @click="previewAsset" />
      <div v-else-if="isEditing">
        <div class="spinner" />
      </div>
      <div v-else class="plus-sign" @click="selectAsset()">
        <PlusIcon />
      </div>
    </div>
    <AssetSelectionModal
      v-if="isAssetSelectionModalShown"
      :show="isAssetSelectionModalShown"
      :type="assetSelectionMediaType"
      @selectedAsset="onAssetSelected"
      @closeClick="closeAssetSelection()"
    />
    <AssetEditingModal
      v-if="isAssetEditingModalShown"
      :show="isAssetEditingModalShown"
      :asset="selectedAsset"
      :loading="isEditing"
      @submit="submitAssetEditing"
      @cancel="closeAssetEditing()"
      @backToAssets="navigateBackToAssetSelection"
    />
  </div>
</template>

<script>
import PlusIcon from '@/assets/images/plus.svg';
import RemoveMediaIcon from '@/assets/images/remove_media.svg';
import AssetSelectionModal from '@/components/assets/AssetSelectionModal.vue';
import AssetEditingModal from '@/components/campaignDetail/contentCreation/contentSpecification/AssetEditingModal.vue';
import { getAssetThumbnailSrcByAssetId, getEditedAssetThumbnailLink } from '@/helpers/helpers';
import { toast } from '@/services/toast';
import { mapGetters } from 'vuex';

export default {
  components: {
    PlusIcon,
    RemoveMediaIcon,
    AssetSelectionModal,
    AssetEditingModal,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    asset: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      isAssetSelectionModalShown: false,
      isAssetEditingModalShown: false,
      selectedAsset: null,
      assetSelectionMediaType: 'video',
      hasAsset: false,
      finalAsset: null,
    };
  },
  computed: {
    ...mapGetters({
      getAssetById: 'assets/getAssetById',
    }),
    isEditing() {
      return this.$store.state.editedMedia.isEditing;
    },
  },
  watch: {
    asset() {
      if (this.asset.value) {
        this.hasAsset = true;
        if (this.asset.isEdited) {
          this.finalAsset = getEditedAssetThumbnailLink(this.asset.value);
        } else {
          this.finalAsset = getAssetThumbnailSrcByAssetId(this.asset.value, this.asset.type);
        }
      }
    },
  },
  mounted() {
    if (this.asset && this.asset.value) {
      this.hasAsset = true;
      if (this.asset.isEdited) {
        this.finalAsset = getEditedAssetThumbnailLink(this.asset.value);
      } else {
        this.finalAsset = getAssetThumbnailSrcByAssetId(this.asset.value, this.asset.type);
      }
    }
  },
  methods: {
    removeAsset() {
      this.$emit('removeAsset');
      this.hasAsset = false;
      this.finalAsset = null;
    },
    previewAsset() {
      if (!this.asset.isEdited) {
        const foundAsset = this.getAssetById(this.asset.id);
        if (foundAsset) {
          this.selectAsset = foundAsset;
          this.isAssetEditingModalShown = true;
        }
      }
    },
    selectAsset() {
      this.isAssetSelectionModalShown = true;
    },
    closeAssetSelection() {
      this.isAssetSelectionModalShown = false;
    },
    onAssetSelected(selectedAsset) {
      this.selectedAsset = selectedAsset;
      this.isAssetSelectionModalShown = false;
      this.isAssetEditingModalShown = true;
    },
    navigateBackToAssetSelection() {
      this.isAssetEditingModalShown = false;
    },
    closeAssetEditing() {
      this.isAssetEditingModalShown = false;
    },
    async submitAssetEditing(params) {
      this.isAssetEditingModalShown = false;
      if (!params.startTimestamp && !params.endTimestamp) {
        this.$emit('assetSelected', {
          assetId: params.asset._id,
          isEdited: false,
        });
        return;
      }
      await this.$store.dispatch('editedMedia/editMedia', {
        id: params.asset._id,
        timeline: {
          startTimestamp: params.startTimestamp,
          endTimestamp: params.endTimestamp,
        },
      });
      const { errorMessage } = this.$store.state.editedMedia;
      if (errorMessage) {
        toast.$emit('showToast', {
          type: 'error',
          groupName: 'content-wizard-validation',
          message: errorMessage,
        });
      } else {
        this.$emit('assetSelected', {
          assetId: this.$store.state.editedMedia.editedMediaId,
          isEdited: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';
.asset-select-container {
}

.asset-selection-field {
  @apply .flex .justify-center .items-center;
  cursor: pointer;
  position: relative;
  width: rem(280px);
  height: rem(175px);
  color: rgba(51, 51, 51, 0.5);
  background-color: #ffffff;
  .media-label-container {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(50px);
    &.with-asset {
      background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
    }
  }
  .media-label {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-family: $cch-font-family;
    font-size: 0.75rem;
  }
  .label-with-asset {
    color: #ffffff;
  }

  &.has-asset {
    background: $cch-light-steel-blue;
    cursor: default !important;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
  }
  .close-icon {
    position: absolute;
    color: #ffffff;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    cursor: pointer;
  }
}

input {
  display: none;
}
.plus-sign {
  @apply .text-xl .font-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $cch-light-blue;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: rem(50px);
  height: rem(50px);
}
.spinner {
  @apply .spin;
  position: relative;
  border-top: rem(4px) solid $cch-light-gray;
  border-right: rem(4px) solid $cch-light-blue;
  border-bottom: rem(4px) solid $cch-light-blue;
  border-left: rem(4px) solid $cch-light-blue;
  transform: translateZ(0);
}
</style>
