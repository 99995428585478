<template>
  <ModalBase
    :title="getName()"
    :show="show"
    :override-style="{
      width: 'auto',
      'min-width': '300px',
      height: 'auto',
      'margin-bottom': '0 rem',
      overflow: 'hidden',
    }"
    :override-header-style="{ 'margin-bottom': '1rem' }"
    @closeClicked="onCloseClicked"
  >
    <template #content>
      <div class="content">
        <video v-if="isVideoContentType" autoplay class="preview-content video-player" controls>
          <source :src="previewSource" />
        </video>
        <iframe
          v-if="isHtml5ContentType && !isImageTemplatePreview"
          class="preview-content iframe-html"
          :width="contentWidth"
          :height="contentHeight"
          :src="previewSource"
        />
        <img v-if="isHtml5ContentType && isImageTemplatePreview" :src="render.signedUrl" alt="Preview" />
      </div>
    </template>
    <template #button-row>
      <div class="actions-row">
        <div class="actions-row-item" @click="startDownload()">
          <div class="actions-row-item-label">{{ $t('content.general.download') }}</div>
          <DownloadIcon viewBox="0 0 20 20" class="actions-row-item-icon" />
        </div>
        <div class="actions-row-item" @click="copyMediaItem()">
          <div class="actions-row-item-label">{{ $t('content.general.copyAndModify') }}</div>
          <GroupIcon class="actions-row-item-icon" viewBox="0 0 20 23" />
        </div>
        <div class="actions-row-item" @click="showContentDeletionModal($event)">
          <div class="actions-row-item-label">{{ $t('delete') }}</div>
          <TrashIcon viewBox="0 0 16 16" class="actions-row-item-icon trash-icon" />
        </div>
      </div>
      <DiscardModal
        v-if="isContentDeletionModalShown"
        message="content.general.deletionMessage"
        left-button-text="general.buttons.delete"
        :right-button-text="$t('cancel')"
        :title="$t('content.general.deletionTitle')"
        :is-discard-modal-open="isContentDeletionModalShown"
        @clickLeftButton="deleteContent"
        @clickRightButton="closeContentDeletionModal"
        @closeModal="closeContentDeletionModal"
      />
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import DownloadIcon from '@/assets/images/download-content.svg';
import GroupIcon from '@/assets/images/group.svg';
import TrashIcon from '@/assets/images/trash.svg';
import { getHTML5PreviewSizeFromFormat } from '@/helpers/helpers';
import * as CONTENT from '@/constants/content/content';
import DiscardModal from '@/components/shared/DiscardModal.vue';

export default {
  components: {
    ModalBase,
    DownloadIcon,
    GroupIcon,
    TrashIcon,
    DiscardModal,
  },
  props: {
    render: {
      type: Object,
      default: null,
      required: true,
    },
    name: {
      type: String,
      default: '',
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    templateName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isContentDeletionModalShown: false,
      previewSource: '',
    };
  },
  computed: {
    // TODO: This check is outdated. Should remove it after release
    isImageTemplatePreview() {
      return (
        this.templateName.toLowerCase().includes('migros') ||
        this.templateName.toLowerCase().includes('do it_daily deal')
      );
    },
    contentWidth() {
      return getHTML5PreviewSizeFromFormat(this.render.formatKey).width;
    },
    contentHeight() {
      return getHTML5PreviewSizeFromFormat(this.render.formatKey).height;
    },

    isVideoContentType() {
      return this.render.type === CONTENT.TYPES.VIDEO;
    },
    isHtml5ContentType() {
      return this.render.type === CONTENT.TYPES.HTML5;
    },
  },
  mounted() {
    if (this.isVideoContentType) {
      this.setVideoSource();
    }
    if (this.isHtml5ContentType) {
      this.setHTMLSource();
    }
  },

  methods: {
    copyMediaItem() {
      this.onCloseClicked();
      this.$emit('copyMediaClicked');
    },
    onCloseClicked() {
      this.$emit('close');
    },
    getName() {
      return this.name.concat(`_${this.render.format}_${this.render.language}`);
    },
    setVideoSource() {
      this.previewSource = this.render.signedUrl;
    },
    async setHTMLSource() {
      const response = await this.$store.dispatch('content/getContentItemHtml5PreviewUrl', this.render.id);
      this.previewSource = response.data.previewURL;
    },
    showContentDeletionModal() {
      this.isContentDeletionModalShown = true;
    },
    closeContentDeletionModal() {
      this.isContentDeletionModalShown = false;
    },
    deleteContent() {
      this.$store.dispatch('content/deleteRender', this.render.id).then(() => {
        this.$emit('deleteContentItemClicked');
        this.closeContentDeletionModal();
        this.onCloseClicked();
      });
    },
    startDownload() {
      window.open(this.render.signedUrl);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.content {
  padding-top: rem(10px);
  .open-preview-button {
    width: rem(150px);
    height: rem(50px);
  }
  .preview-content {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .video-player {
    max-width: 80%;
    max-height: 70vh;
    box-shadow: 0 0 5px 0 rgba(71, 67, 102, 0.19);
  }
}

.actions-row {
  height: rem(70px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $cch-light-gray;
  margin: rem(-31px);
  margin-top: rem(31px);
  padding: rem(31px);
  width: auto;
  .actions-row-item:first-child {
    margin-right: 4.5rem;
  }
  .actions-row-item:last-child {
    margin-left: 4.5rem;
  }
  .actions-row-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    max-width: 150px;
    overflow: hidden;

    .actions-row-item-label {
      padding-right: rem(10px);
      font-size: rem(16px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: rem(0.53px);
      text-transform: capitalize;
      color: $cch-dark-grey;
    }
    .actions-row-item-icon {
      object-fit: contain;
      align-self: center;
      position: relative;
      width: rem(20px);
      height: rem(20px);
    }
  }
}
</style>
