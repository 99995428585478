<template>
  <div class="slideout-pane">
    <transition name="fade">
      <div v-if="value" class="overlay-mask" @click="$emit('input', !value)">
        <transition name="slide">
          <div :class="`main-pane main-pane--${type}`" @click="$event.stopPropagation()">
            <div class="main-pane-header">
              <AppText type="pane">{{ title }}</AppText>
              <div class="icon-wrapper">
                <CloseIcon class="header-icon" @click="$emit('input', false)" />
              </div>
            </div>
            <div class="main-pane-content">
              <slot />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
import CloseIcon from '@/assets/images/close-scalable.svg';
import AppText from '@/components/shared/AppText.vue';

export default {
  name: 'SlideoutPane',
  components: {
    CloseIcon,
    AppText,
  },
  props: {
    type: {
      type: String,
      default: 'assets',
    },
    isPaneLeftAligned: {
      type: Boolean,
      default: false,
      required: false,
    },
    title: {
      type: String,
      default: '',
      required: false,
    },
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/global.scss';

.slideout-pane {
  .overlay-mask {
    top: 0;
    left: 0;
    @apply .w-full .h-full .fixed;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);

    .main-pane {
      @apply .h-full;
      background-color: white;
      &.main-pane--menu {
        width: 20%;
        float: left;
        animation: slideInFromLeft 0.75s ease;
      }
      &.main-pane--assets {
        width: 50%;
        float: right;
        animation: slideInFromRight 0.75s;
      }

      .main-pane-header {
        @apply .flex .justify-between .items-center .m-6 .h-8;
        .icon-wrapper {
          .header-icon {
            @apply .h-2.5 .w-2.5 .block .cursor-pointer .text-blue-light;
            fill: $cch-light-steel-blue;
          }
        }
      }

      .main-pane-content {
        overflow: auto;
        // Subtracted value consists of header's height and top/bottom paddings
        height: calc(100% - 5rem);
      }
    }
  }
}
</style>
