
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import FileIcon from '@/assets/images/contact_page_FILL0_wght200_GRAD0_opsz24.svg';
import VideoIcon from '@/assets/images/video.svg';
import AudioIcon from '@/assets/images/audio.svg';
import ImageIcon from '@/assets/images/image.svg';
import FolderIcon from '@/assets/images/folder.svg';
import OptionsIcon from '@/assets/images/options.svg';
import { defineComponent, PropType } from 'vue';
import { Asset, AssetRestrictions, AssetType } from '@/types';
import EditIcon from '@/assets/images/edit_FILL0_wght200_GRAD0_opsz24.svg';
import DeleteIcon from '@/assets/images/delete_FILL0_wght200_GRAD0_opsz24.svg';
import DownloadIcon from '@/assets/images/file_download_FILL0_wght200_GRAD0_opsz24.svg';
import MoveIcon from '@/assets/images/drive_file_move_FILL0_wght200_GRAD0_opsz24.svg';
import ShareIcon from '@/assets/images/share_FILL0_wght200_GRAD0_opsz24.svg';
import OptionsBox from '../campaigns/OptionsBox.vue';

export default defineComponent({
  components: {
    FileIcon,
    ImageIcon,
    VideoIcon,
    AudioIcon,
    OptionsIcon,
    FolderIcon,
    EditIcon,
    DeleteIcon,
    DownloadIcon,
    MoveIcon,
    ShareIcon,
    OptionsBox,
  },
  props: {
    asset: {
      type: Object as PropType<Asset>,
      required: true,
    },
    assetRestrictions: {
      type: Object as PropType<AssetRestrictions>,
      required: false,
      default: null,
    },
    usedWithinModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOptionsBoxOpen: false,
      AssetType,
      thumbUri: '',
    };
  },
  computed: {
    ...mapGetters({
      path: 'assets/getPath',
    }),
    vClickConfig() {
      return { handler: this.closeOptionsBox, events: ['contextmenu', 'click'] };
    },
    metadataText(): string {
      if (this.asset.type === AssetType.IMAGE && this.asset.metadata) {
        return `image ${this.asset.metadata.width}x${this.asset.metadata.height}`;
      }
      if (this.asset.type === AssetType.VIDEO && this.asset.metadata) {
        return `video ${this.asset.metadata.width}x${this.asset.metadata.height}, ${this.secondsToMinutesLabel(
          this.asset.metadata.duration!,
        )}`;
      }
      if (this.asset.type === AssetType.AUDIO && this.asset.metadata) {
        return `audio ${this.secondsToMinutesLabel(this.asset.metadata.duration!)}`;
      }
      return '';
    },
    assetType(): AssetType {
      if ([AssetType.VIDEO, AssetType.AUDIO, AssetType.FOLDER, AssetType.SHARED_FOLDER].includes(this.asset.type)) {
        return this.asset.type;
      }
      if (this.asset.type === AssetType.IMAGE && this.asset.extension !== 'psd') {
        return AssetType.IMAGE;
      }
      return AssetType.FILE;
    },
    date(): string {
      return this.asset.createdAt ? format(new Date(this.asset.createdAt), 'dd. MMM yyyy  HH:mm') : '';
    },
    isOptionsVisible(): boolean {
      if (this.usedWithinModal) {
        return false;
      }
      if (this.assetType === AssetType.SHARED_FOLDER) {
        return false;
      }
      if (this.asset.isShared && this.assetType === AssetType.FOLDER) {
        return false;
      }
      return true;
    },
  },
  watch: {
    asset: {
      handler() {
        this.setThumbUri();
      },
      deep: true,
    },
  },
  mounted() {
    this.setThumbUri();
  },
  methods: {
    setThumbUri() {
      this.thumbUri = this.asset.thumbSignedUrl;
    },
    clickAsset(): void {
      if (this.assetType === AssetType.FOLDER || this.assetType === AssetType.SHARED_FOLDER) {
        this.$emit('assetClicked', this.asset);
      } else if (!this.areRestrictionsInvalid() || !this.assetRestrictions) {
        this.$emit('assetClicked', this.asset);
      }
    },
    secondsToMinutesLabel(seconds: number): string {
      const minutes = Math.floor(seconds / 60);
      const remainderSeconds = Math.floor(seconds - minutes * 60);
      return `${minutes}:${remainderSeconds} min`;
    },
    areRestrictionsInvalid(): boolean {
      if (this.asset.type === AssetType.FOLDER || this.asset.type === AssetType.SHARED_FOLDER) {
        return false;
      }
      if (typeof this.assetRestrictions !== 'undefined' && this.assetRestrictions !== null) {
        if (
          this.asset.metadata &&
          this.assetRestrictions.minHeight <= this.asset.metadata.height! &&
          this.assetRestrictions.minWidth <= this.asset.metadata.width!
        ) {
          return false;
        }
        return true;
      }
      return true;
    },
    toggleOptionsBox(): void {
      this.isOptionsBoxOpen = !this.isOptionsBoxOpen;
    },
    openOptionsBox(): void {
      this.isOptionsBoxOpen = true;
    },
    closeOptionsBox(): void {
      this.isOptionsBoxOpen = false;
    },
    downloadAsset(): void {
      window.open(this.asset.thumbSignedUrl);
    },
    editAsset(): void {
      this.$emit('editAsset', this.asset._id);
    },
    deleteAsset(): void {
      this.$emit('deleteAsset', this.asset._id);
    },
    moveAsset(): void {
      this.$emit('moveAsset', this.asset._id);
    },
    shareAsset(): void {
      this.$emit('shareAsset', this.asset._id);
    },
    checkboxSelect(): void {
      this.$emit(
        (this.$refs.checkbox as HTMLInputElement).checked ? 'assetSelected' : 'assetUnselected',
        this.asset._id,
      );
    },
    clickCheckboxSelect(event: Event): void {
      (event.target as HTMLElement).click();
      event.stopPropagation();
    },
  },
});
