<template>
  <div
    v-click-outside="clickOutsidePopupConfig"
    class="header-popup-bar xl:mx-auto items-center justify-between mt-10 flex-wrap sm:flex-no-wrap"
  >
    <div class="flex items-center mt-9">
      <div class="flex text-blue-light items-end cursor-pointer popup-trigger">
        <transition name="fade-down">
          <DateFilterPopup
            class="datepicker-popup"
            :show="objectName === 'createdAt'"
            @dateFilterClicked="onDateFilterClicked"
            @dateFilterCleared="onDateFilterCleared"
          />
        </transition>
        <IconCalendar class="text-blue-light" />
        <div class="ml-3 font-sans font-semibold h-4 hidden lg:block" @click.self="onPopupToggle('createdAt')">{{
          $t('home.general.publishingDate')
        }}</div>
      </div>

      <div
        v-if="showMediaTypes"
        class="flex text-blue-light items-end cursor-pointer popup-trigger ml-12"
        @click="onPopupToggle('type')"
      >
        <transition name="fade-down">
          <RadioFilterPopup
            v-show="objectName === 'type'"
            :categories="['image', 'audio', 'video']"
            type="assets"
            category-type="type"
            @exclusiveFilterClicked="onExclusiveFilterClicked"
            @resetFilterClicked="onResetFilterClicked"
          />
        </transition>
        <IconMediaType class="text-blue-light" />
        <div class="ml-3 font-sans font-semibold h-4 hidden lg:block">{{ $t('assets.assetSelection.mediaType') }}</div>
      </div>
    </div>

    <div class="search-input ml-0 mt-9 sm:ml-auto sm:mt-0 w-54 sm:w-64">
      <AppInput v-model="searchTerm" placeholder="general.inputs.search" />
    </div>
  </div>
</template>

<script>
import IconCalendar from '@/assets/images/calendar.svg';
import IconMediaType from '@/assets/images/media-type.svg';
import AppInput from '@/components/shared/AppInput.vue';
import RadioFilterPopup from '@/components/shared/RadioFilterPopup.vue';
import DateFilterPopup from '@/components/shared/DateFilterPopup.vue';

export default {
  components: {
    DateFilterPopup,
    RadioFilterPopup,
    AppInput,
    IconCalendar,
    IconMediaType,
  },
  props: {
    showMediaTypes: {
      type: Boolean,
      required: false,
      default: true,
    },
    isMovingAssets: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      objectName: null,
      showSideBar: false,
      inputDebounce: null,

      clickOutsidePopupConfig: {
        handler: this.closeAnyPopup,
      },
    };
  },
  computed: {
    searchTerm: {
      get() {
        return this.isMovingAssets
          ? this.$store.getters['assetsMoving/getSearchTerm']
          : this.$store.getters['assets/getSearchTerm'];
      },
      set(value) {
        clearTimeout(this.inputDebounce);
        this.inputDebounce = setTimeout(() => {
          if (this.isMovingAssets) {
            this.$store.dispatch('assetsMoving/addSearchTerm', value);
          } else {
            this.$store.dispatch('assets/addSearchTerm', value);
          }
        }, 250);
      },
    },
  },
  beforeDestroy() {
    this.onDateFilterCleared();
  },
  methods: {
    onPopupToggle(category) {
      if (this.objectName === category) {
        this.objectName = null;
      } else {
        this.objectName = category;
      }
    },
    closeAnyPopup() {
      this.objectName = null;
    },
    onResetFilterClicked(categoryType) {
      if (this.isMovingAssets) {
        this.$store.dispatch('assetsMoving/resetFilter', categoryType);
      } else {
        this.$store.dispatch('assets/resetFilter', categoryType);
      }
    },
    onExclusiveFilterClicked(filter) {
      if (this.isMovingAssets) {
        this.$store.dispatch('assetsMoving/addExclusiveFilter', filter);
      } else {
        this.$store.dispatch('assets/addExclusiveFilter', filter);
      }
    },

    onDateFilterClicked(dateRange) {
      if (this.isMovingAssets) {
        this.$store.dispatch('assetsMoving/addDateRangeFilter', dateRange);
      } else {
        this.$store.dispatch('assets/addDateRangeFilter', dateRange);
      }
    },
    onDateFilterCleared() {
      if (this.objectName) this.objectName = null;
      if (this.isMovingAssets) {
        this.$store.dispatch('assetsMoving/clearDateRangeFilter');
      } else {
        this.$store.dispatch('assets/clearDateRangeFilter');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';

.datepicker-popup {
  z-index: 2;
}

.header-popup-bar {
  display: flex;
}

.search-input {
  border-bottom: 1px solid $cch-dark-grey;
}
</style>
