<template>
  <div class="menu-container">
    <SlideoutPane v-model="isMenuOpen" type="menu" :title="$t('home.general.menu')">
      <div class="menu-item-container">
        <NavigationItem
          name="general.sections.campaigns"
          path="/campaigns"
          icon="campaigns"
          @clicked="handleNavigationItemClicked"
        />
        <NavigationItem
          name="general.sections.assets"
          path="/assets"
          icon="assets"
          @clicked="handleNavigationItemClicked"
        />
        <NavigationItem
          v-if="isUserSuperAdmin"
          name="general.sections.templates"
          path="/templates"
          icon="templates"
          @clicked="handleNavigationItemClicked"
        />
        <NavigationItem
          name="general.sections.sharedFolders"
          path="/shared-folders"
          icon="shared-folders"
          @clicked="handleNavigationItemClicked"
        />
        <NavigationItem
          v-if="isUserSuperAdmin"
          name="general.sections.clients"
          icon="clients"
          @clicked="onOrganizationsClicked"
        />
        <NavigationItem
          v-if="isUserSuperAdmin"
          name="general.sections.users"
          path="/users"
          icon="users"
          @clicked="handleNavigationItemClicked"
        />
        <NavigationItem
          v-if="isUserSuperAdmin"
          name="general.sections.organizations"
          path="/organizations"
          icon="organizations"
          @clicked="handleNavigationItemClicked"
        />
        <NavigationItem
          v-if="isUserSuperAdmin"
          name="general.sections.renderConfiguration"
          path="/render-config"
          icon="render-config"
          @clicked="handleNavigationItemClicked"
        />
        <NavigationItem
          v-if="isUserSuperAdmin"
          name="general.sections.cmsConfiguration"
          path="/cms-config"
          icon="cms-config"
          @clicked="handleNavigationItemClicked"
        />
        <CreditsCounter @closeMenu="closeMenu" />
      </div>
      <div class="menu-bottom-row">
        <button>
          <div class="button-content" @click="onProfileClicked">
            <ProfileIcon class="navigation-item--icon" color="black" />
            <AppText type="navigation-item">{{ $t('home.general.profile') }}</AppText>
          </div>
        </button>
        <button @click="handleLogOutClicked">
          <div class="button-content">
            <AppText type="navigation-item">{{ $t('general.sections.logout') }}</AppText>
          </div>
        </button>
      </div>
    </SlideoutPane>
    <OrganizationModal :show="isOrganizationModelOpen" @close="onCloseOrganizationModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProfileIcon from '@/assets/images/profile.svg';
import AppText from '@/components/shared/AppText.vue';
import OrganizationModal from '@/components/organizations/OrganizationModal.vue';
import { SUPERADMIN } from '@/constants/user/roles';
import CreditsCounter from '@/components/organizations/credits/CreditsCounter.vue';
import NavigationItem from '@/components/shared/NavigationItem.vue';
import SlideoutPane from '@/components/shared/fixed/SlideoutPane.vue';

export default {
  name: 'AppMenu',
  components: {
    CreditsCounter,
    NavigationItem,
    SlideoutPane,
    ProfileIcon,
    AppText,
    OrganizationModal,
  },
  data() {
    return {
      isMenuOpen: false,
      isOrganizationModelOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'auth/getUserRole',
      userId: 'auth/getUserId',
    }),
    isUserSuperAdmin() {
      return this.userRole === SUPERADMIN;
    },
  },
  methods: {
    closeMenu() {
      this.isMenuOpen = false;
    },
    openMenu() {
      this.isMenuOpen = true;
    },
    handleLogOutClicked() {
      this.$store.dispatch('auth/wipe');
      this.$router.push({ path: '/login' });
      this.closeMenu();
    },
    handleNavigationItemClicked() {
      this.closeMenu();
    },
    onOrganizationsClicked() {
      this.closeMenu();
      this.isOrganizationModelOpen = true;
    },
    onCloseOrganizationModal() {
      this.isOrganizationModelOpen = false;
    },
    onProfileClicked() {
      this.closeMenu();
      this.$router.push({ name: 'user', params: { id: this.userId } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.module.scss';
.menu-container {
  .menu-item-container {
    @apply .px-6 .pb-6;
    height: calc(100% - 4rem);
    overflow: auto;
  }

  .menu-bottom-row {
    @apply .flex .flex-row .justify-between .w-full .px-6;
    height: 4rem;
    align-items: center;
    background: #fff;
    z-index: 1;
    position: relative;
    .button-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .navigation-item--icon {
        margin-right: rem(10px);
      }
    }
  }
}
</style>
