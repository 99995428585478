<template>
  <div class="side-bar-container">
    <h1 class="side-bar-title">{{ $t('menu') }}</h1>
    <div class="side-bar-submenu">
      <div class="submenu-title-container">
        <IconSignage class="icon" />
        <h2 class="submenu-title">content creation hub</h2>
      </div>
      <div class="submenu-items-container">
        <p id="sidebar-submenu-item-campaign" class="submenu-item" @click="onCampaignsClicked">
          {{ $t('campaign_plural') }}
        </p>
        <p id="sidebar-submenu-item-content" class="submenu-item" @click="onContentClicked">
          {{ $t('content') }}
        </p>
        <p id="sidebar-submenu-item-assets" class="submenu-item" @click="onAssetsClicked">
          {{ $t('assets.general.headerLabel') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import IconSignage from '@/assets/images/signage.svg';

export default {
  name: 'SideBar',
  components: {
    IconSignage,
  },
  methods: {
    onCampaignsClicked() {
      this.$router.push({ name: 'home' });
      this.$emit('selectionMade');
    },
    onContentClicked() {
      this.$router.push({ name: 'content' });
      this.$emit('selectionMade');
    },
    onAssetsClicked() {
      this.$router.push({ name: 'assets' });
      this.$emit('selectionMade');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.side-bar-container {
  @apply .h-full .bg-white .fixed;
  top: 0;
  left: 0;
  width: 20.25rem;
  box-shadow: 0 2px 8px 1px rgba(61, 85, 118, 0.24);
  z-index: 100;
}
.side-bar-title {
  @apply .font-sans .text-2xl .font-extrabold .leading-none .h-6 .ml-6 .mb-10;
  width: 3.9rem;
  color: #2b2b2b;
  margin-top: 1.75rem;
}
.side-bar-submenu {
}
.submenu-title-container {
  @apply .flex .items-end .mb-6;
}
.icon {
  @apply .ml-6 .mr-4 .mb-1;
  transform: scale(1.5);
}
.submenu-title {
  @apply .font-sans .text-base .font-semibold;
  color: #2b2b2b;
}

.submenu-items-container {
  @apply .pb-8 .pt-1;
  background-color: $cch-light-gray;
}

.submenu-item {
  @apply .font-sans .text-base .font-medium .cursor-pointer;
  margin-left: 3.5rem;
  color: #2b2b2b;
  margin-top: 1.8rem;
}
</style>
