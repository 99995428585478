import { orderBy as _orderBy } from 'lodash-es';
import Fuse from 'fuse.js';
import getLogger from '@/services/logger';
// eslint-disable-next-line import/no-cycle
import {
  getAllCampaigns,
  getCampaign,
  createNewCampaign,
  deleteCampaign,
  updateCampaign,
} from '@/services/api/campaign.resource';
import {
  areFiltersApplied,
  determineIfTogglingToAscendingOrDescendingSort,
  filterObjectArrayByDateRange,
  filterObjectArrayByKeyValueArray,
  getIndexOfKeyValueObjectInArray,
  getUniqueValues,
  isDateRangeFilterApplied,
  isSearchApplied,
  isSortApplied,
} from '@/services/store/shared';
import { ViewModeValue } from '@/types/ViewModeValue';
import { CAMPAIGNS_VIEW_MODE } from '@/constants/store/references';
import { setLocalstorageItem } from '@/services/localstorage';

const LOG = getLogger('store/campaigns');

const state = {
  campaigns: [],
  campaign: {},
  keyValuePairFilterArray: [],
  dateRangeFilter: null,
  searchTerm: null,
  hasDeletionError: false,
  isLoadingCampaignDetail: false,

  searchOptions: {
    // fuse.js search options. Reference: https://fusejs.io/
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name', 'type', 'channels'],
  },
  titles: {
    name: 'campaign_name',
    type: 'home.general.campaignType',
    contents: 'content',
    datePublished: 'home.general.publishingDate',
    channels: 'formats',
  },
  loading: false,
  viewMode: null as ViewModeValue | null,
};

const getters = {
  /**
   * Getter for campaigns, applying filters. Campaigns can be filtered by keywords, date range and search result.
   * Campaigns can be sorted by keyword ascending and descending
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @returns result: filtered / sorted campaigns
   */
  getFilteredCampaigns: (state: $TSFixMe, getters: $TSFixMe, rootState: $TSFixMe, rootGetters: $TSFixMe) => {
    if (state.campaigns) {
      LOG.log('Getting all campaigns');

      LOG.log("Mapping out fields that shouldn't be displayed");
      let result = state.campaigns;

      if (areFiltersApplied(state.keyValuePairFilterArray)) {
        LOG.log('Applying filters');
        result = filterObjectArrayByKeyValueArray(result, state.keyValuePairFilterArray);
      }
      if (isDateRangeFilterApplied(state.dateRangeFilter)) {
        LOG.log('Applying date range filter');
        result = filterObjectArrayByDateRange(result, state.dateRangeFilter, 'createdAt');
      }
      if (isSortApplied(rootGetters.getSortedBy.key)) {
        LOG.log('Applying sort');
        result = _orderBy(result, [rootGetters.getSortedBy.key], rootGetters.getSortedBy.reverse ? ['desc'] : ['asc']);
      }
      if (isSearchApplied(state.searchTerm)) {
        LOG.log('Applying search filter');
        const fuse = new Fuse(result, state.searchOptions);
        result = fuse.search(state.searchTerm);
      }
      return result;
    }

    return [];
  },

  /**
   * Getter for all campaigns. Returns empty list when not initialized
   * @param state
   * @returns {[]|*[]}
   */
  getAllCampaigns: (state: $TSFixMe) => {
    if (!state.campaigns) {
      return [];
    }
    return state.campaigns;
  },

  getFirstCampaignId: (state: $TSFixMe) => () => {
    return state.campaigns[0]._id;
  },

  getCampaignById: (state: $TSFixMe) => (id: $TSFixMe) =>
    state.campaigns.find((campaign: $TSFixMe) => campaign._id === id),

  getTitles: (state: $TSFixMe) => state.titles,

  getCampaign: (state: $TSFixMe) => state.campaign,
  getDeletionError: (state: $TSFixMe) => state.hasDeletionError,

  isLoadingCampaignDetail: (state: $TSFixMe) => state.isLoadingCampaignDetail,
  getLoading: (state: $TSFixMe) => state.loading,

  /**
   * Returns an array containing all unique values of a key (category)
   * e.g. getUniqueKeys('type') returns all different campaign type values
   * ['Feed', 'Manuell', 'Eventbasiert'...]
   * Used by the filters popup to create the appropriate checkboxes
   * @param state, key: String
   * @returns Array
   */
  getUniqueValuesOfKey: (state: $TSFixMe) => (key: $TSFixMe) => {
    if (state.campaigns) {
      return getUniqueValues(state.campaigns, key).sort();
    }

    return [];
  },
  getViewMode: (state: $TSFixMe) => state.viewMode || localStorage.getItem(CAMPAIGNS_VIEW_MODE) || 'grid',
  getSearchTerm: (state: $TSFixMe) => state.searchTerm,
};

const actions = {
  updateSortKey(context: $TSFixMe, payload: $TSFixMe) {
    const reverse = determineIfTogglingToAscendingOrDescendingSort(payload.key, context.rootGetters.getSortedBy);
    context.commit(
      'updateSortedBy',
      {
        key: payload.key,
        reverse,
      },
      { root: true },
    );
  },
  async loadCampaigns({ commit }: $TSFixMe) {
    try {
      commit('setLoading', true);
      const campaigns = await getAllCampaigns();
      commit('setCampaigns', campaigns.data);
    } catch (err) {
      LOG.error(err);
    } finally {
      commit('setLoading', false);
    }
  },
  updateCampaign(_: $TSFixMe, { id, data }: $TSFixMe) {
    return new Promise((resolve) => {
      updateCampaign(id, data).then((response) => {
        resolve(response.data);
      });
    });
  },
  createCampaign({ rootGetters, commit }: $TSFixMe, data: $TSFixMe) {
    const newCampaign = data;
    newCampaign.organizationId = rootGetters['auth/getUserOrganization'];
    createNewCampaign(newCampaign).then((response) => {
      commit('addNewCampaign', response.data);
    });
  },
  async getCampaignDetail({ dispatch, commit }: $TSFixMe, id: $TSFixMe) {
    commit('setIsLoadingCampaignDetail', true);

    await dispatch('templates/loadTemplateList', null, { root: true }).then(() => {
      getCampaign(id).then((campaign) => {
        commit('setCampaign', campaign.data);
      });
    });

    commit('setIsLoadingCampaignDetail', false);
  },

  async deleteCampaign(context: $TSFixMe, id: $TSFixMe) {
    await deleteCampaign(id).catch(() => {
      context.commit('setDeletionError', true);
    });
  },
  resetCampaign({ commit }: $TSFixMe) {
    commit('resetCampaign');
  },
  addExclusiveFilter({ commit }: $TSFixMe, payload: $TSFixMe) {
    commit('removeFiltersFromSameCategory', payload);
    commit('addExclusiveFilter', payload);
  },

  clearDateRangeFilter({ commit }: $TSFixMe) {
    commit('addDateRangeFilter', null);
  },

  addDateRangeFilter({ commit }: $TSFixMe, payload: $TSFixMe) {
    commit('addDateRangeFilter', payload);
  },

  addInclusiveFilter({ commit }: $TSFixMe, payload: $TSFixMe) {
    commit('addInclusiveFilter', payload);
  },

  removeFilter({ commit }: $TSFixMe, payload: $TSFixMe) {
    commit('removeFilter', payload);
  },

  resetFilter({ commit }: $TSFixMe, category: $TSFixMe) {
    commit('resetFilter', category);
  },

  addSearchTerm({ commit }: $TSFixMe, term: $TSFixMe) {
    commit('addSearchTerm', term);
  },

  removeSearchTerm({ commit }: $TSFixMe) {
    commit('removeSearchTerm');
  },
  setDeletionError({ commit }: $TSFixMe, value: $TSFixMe) {
    commit('setDeletionError', value);
  },
  setViewMode(context: $TSFixMe, value: ViewModeValue) {
    setLocalstorageItem(CAMPAIGNS_VIEW_MODE, value);
    context.commit('setViewMode', value);
  },
};

const mutations = {
  setDeletionError(state: $TSFixMe, value: $TSFixMe) {
    state.hasDeletionError = value;
  },
  removeFiltersFromSameCategory(state: $TSFixMe, payload: $TSFixMe) {
    state.keyValuePairFilterArray = state.keyValuePairFilterArray.filter(
      (filter: $TSFixMe) => filter.key !== payload.key,
    );
  },

  addExclusiveFilter(state: $TSFixMe, payload: $TSFixMe) {
    state.keyValuePairFilterArray.push(payload);
  },

  addDateRangeFilter(state: $TSFixMe, payload: $TSFixMe) {
    state.dateRangeFilter = payload;
  },

  addInclusiveFilter(state: $TSFixMe, payload: $TSFixMe) {
    state.keyValuePairFilterArray.push(payload);
  },

  removeFilter(state: $TSFixMe, payload: $TSFixMe) {
    const index = getIndexOfKeyValueObjectInArray(payload, state.keyValuePairFilterArray);
    if (index !== -1) {
      state.keyValuePairFilterArray.splice(index, 1);
    }
  },

  resetFilter(state: $TSFixMe, category: $TSFixMe) {
    state.keyValuePairFilterArray = state.keyValuePairFilterArray.filter((filter: $TSFixMe) => filter.key !== category);
  },

  addSearchTerm(state: $TSFixMe, term: $TSFixMe) {
    state.searchTerm = term;
  },

  removeSearchTerm(state: $TSFixMe) {
    state.searchTerm = null;
  },
  setCampaigns(state: $TSFixMe, campaigns: $TSFixMe) {
    state.campaigns = campaigns;
  },
  addNewCampaign(state: $TSFixMe, campaign: $TSFixMe) {
    state.campaigns.push(campaign);
  },
  setCampaign(state: $TSFixMe, campaign: $TSFixMe) {
    state.campaign = campaign;
  },
  resetCampaign(state: $TSFixMe) {
    state.campaign = {};
  },
  setIsLoadingCampaignDetail(state: $TSFixMe, isLoadingCampaignDetail: $TSFixMe) {
    state.isLoadingCampaignDetail = isLoadingCampaignDetail;
  },
  setViewMode(state: $TSFixMe, value: ViewModeValue) {
    state.viewMode = value;
  },
  setLoading(state: $TSFixMe, value: boolean) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
