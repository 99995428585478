var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalBase',{attrs:{"title":_vm.modalTitle,"show":true,"override-style":{
    width: _vm.scssVariables.modalSideOffset,
    height: _vm.scssVariables.modalSideOffset,
    'margin-bottom': '0 rem',
    position: 'absolute',
    'z-index': 1000,
  },"override-header-style":{ 'margin-bottom': '1rem' }},on:{"closeClicked":_vm.onCloseClicked},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('AssetHeader',{staticClass:"sticky-header",attrs:{"show-media-types":false,"is-moving-assets":true}}),_c('div',{ref:"assetGridContainer",staticClass:"asset-grid-container",on:{"scroll":_vm.onScroll}},[_c('AssetsGrid',{attrs:{"type":_vm.AssetType.FOLDER,"modal-page":_vm.modalPage,"used-within-modal":true,"is-moving-assets":true,"assets":_vm.filteredAssets},on:{"assetClicked":_vm.assetClicked,"resetPageContentState":_vm.resetPageContentState,"breadcrumbClicked":_vm.breadcrumbClicked}})],1),(_vm.isCreateModalShown)?_c('CreateFolderModal',{attrs:{"show":_vm.isCreateModalShown},on:{"close":function($event){return _vm.closeCreateModal()},"cancelClick":function($event){return _vm.closeCreateModal()},"createClick":_vm.createFolder}}):_vm._e()]},proxy:true},{key:"button-row",fn:function(){return [_c('div',{staticClass:"actions-row"},[_c('AppButton',{attrs:{"size":"small-auto","type":"outlined"},on:{"click":function($event){return _vm.onCloseClicked()}}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.cancel'))+" ")]),_c('div',{staticClass:"buttons-right"},[(!_vm.isShared)?_c('AppButton',{attrs:{"text":"","size":"small-auto"},on:{"click":function($event){return _vm.openCreateModal()}}},[_vm._v(" "+_vm._s(_vm.$t('assets.assetSelection.createFolder'))+" ")]):_vm._e(),_c('AppButton',{attrs:{"size":"small-auto"},on:{"click":function($event){return _vm.confirmAssetSelection()}}},[_vm._v(_vm._s(_vm.$t('assets.general.move')))])],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }