
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import DownloadIcon from '@/assets/images/download-content.svg';
import EditIcon from '@/assets/images/edit.svg';
import TrashIcon from '@/assets/images/trash.svg';
import scssVariables from '@/styles/variables.module.scss';
import { Asset, AssetType } from '@/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    ModalBase,
    DownloadIcon,
    EditIcon,
    TrashIcon,
  },
  props: {
    asset: {
      type: Object as PropType<Asset>,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAssetDeletionModalShown: false,
      scssVariables,
    };
  },
  computed: {
    isVideo() {
      return this.asset.type === AssetType.VIDEO;
    },
    isAudio() {
      return this.asset.type === AssetType.AUDIO;
    },
    isImage() {
      return this.asset.type === AssetType.IMAGE && this.asset.extension !== 'psd';
    },
  },
  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    getName() {
      return `${this.asset.name}.${this.asset.extension}`;
    },
    getThumbnailUrl() {
      return this.asset.thumbSignedUrl;
    },
    getAssetUrl() {
      return this.asset.signedUrl;
    },
    deleteAsset() {
      this.$emit('deleteClick', this.asset._id);
    },
    editAsset() {
      this.$emit('editClick', this.asset._id);
    },
    startDownload() {
      window.open(this.getThumbnailUrl());
    },
  },
});
