<template>
  <component :is="path ? 'a' : 'div'" :href="path" class="navigation-item-wrapper">
    <div class="navigation-item" :class="{ 'navigation-item--current': isCurrentRoute(path) }" @click="handleClicked">
      <CampaignsIcon v-if="icon === 'campaigns'" class="navigation-item--icon" />
      <AssetsIcon v-if="icon === 'assets'" class="navigation-item--icon" />
      <TemplatesIcon v-if="icon === 'templates'" class="navigation-item--icon" />
      <ShareIcon v-if="icon === 'shared-folders'" class="navigation-item--icon" />
      <ProfileIcon v-if="icon === 'users'" class="navigation-item--icon" />
      <OrganizationsIcon v-if="icon === 'clients'" class="navigation-item--icon" />
      <HomeIcon v-if="icon === 'organizations'" class="navigation-item--icon" />
      <GearIcon v-if="icon === 'render-config'" class="navigation-item--icon" />
      <CmsConfigIcon v-if="icon === 'cms-config'" class="navigation-item--icon" />
      <AppText type="navigation-item">{{ $t(name) }}</AppText>
    </div>
  </component>
</template>
<script>
import AppText from '@/components/shared/AppText.vue';
import CampaignsIcon from '@/assets/images/analytics.svg';
import AssetsIcon from '@/assets/images/image.svg';
import TemplatesIcon from '@/assets/images/video.svg';
import ProfileIcon from '@/assets/images/profile.svg';
import OrganizationsIcon from '@/assets/images/desktop-monitor-smiley.svg';
import ShareIcon from '@/assets/images/media-type.svg';
import HomeIcon from '@/assets/images/home.svg';
import GearIcon from '@/assets/images/gear.svg';
import CmsConfigIcon from '@/assets/images/cms-config.svg';

export default {
  components: {
    AppText,
    CampaignsIcon,
    AssetsIcon,
    TemplatesIcon,
    ProfileIcon,
    OrganizationsIcon,
    ShareIcon,
    HomeIcon,
    GearIcon,
    CmsConfigIcon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      default: '',
      required: true,
    },
  },
  computed: {
    isCurrentRoute() {
      return (route) => this.$router.currentRoute.path === route;
    },
  },
  methods: {
    handleClicked() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';

.navigation-item-wrapper {
  cursor: pointer;
}
.navigation-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: rem(50px);

  .navigation-item--icon {
    margin-right: rem(10px);
  }
}
.navigation-item:hover,
.navigation-item--current {
  color: $cch-light-blue;
  .fill-white {
    stroke: $cch-light-blue;
  }
}
</style>
