var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModalBase',{attrs:{"title":_vm.$t('assets.general.addAsset'),"show":_vm.show,"override-style":{
    width: _vm.scssVariables.modalSideOffset,
    height: _vm.scssVariables.modalSideOffset,
    'margin-bottom': '0 rem',
    position: 'absolute',
    'z-index': 1000,
  },"override-header-style":{ 'margin-bottom': '1rem' },"override-header-title-style":{ 'margin-left': '0', 'font-size': '1.5rem' }},on:{"closeClicked":_vm.handleClose},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"content-container"},[_c('span',{staticClass:"content-container-title"},[_vm._v(_vm._s(_vm.assetName))]),_c('div',{staticClass:"preview-box"},[(_vm.isVideo && _vm.show)?_c('video',{ref:"videoPlayer",staticClass:"asset-video",attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.getMediaSource()}})]):_vm._e(),(_vm.isAudio && _vm.show)?_c('audio',{ref:"audioPlayer",staticClass:"asset-audio",attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.getMediaSource()}})]):_vm._e(),(_vm.getMarkerContainerWidth())?_c('div',{staticClass:"markers",style:({ width: `${_vm.getMarkerContainerWidth()}px` })},[_c('div',{staticClass:"start-marker",style:({ left: `${_vm.getStartMarkerPosition()}px` })}),_c('div',{staticClass:"end-marker",style:({ left: `${_vm.getEndMarkerPosition()}px` })})]):_vm._e()]),(_vm.duration)?_c('MediaEditor',{attrs:{"duration":_vm.duration,"disabled":_vm.loading},on:{"change":_vm.handleChangeSelection}}):_vm._e()],1),(_vm.isShowingOverlay)?_c('div',{staticClass:"overlay-loader"},[_c('div',{staticClass:"spinner"})]):_vm._e()]},proxy:true},{key:"button-row",fn:function(){return [_c('div',{staticClass:"actions-row"},[_c('AppButton',{attrs:{"size":"small-auto","type":"outlined"},on:{"click":function($event){return _vm.backToAssets()}}},[_vm._v(" "+_vm._s(_vm.$t('contentWizard.assets.backToAssets'))+" ")]),_c('AppButton',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.handleSubmit()}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('general.buttons.saving'))+" "),(_vm.loading)?_c('SmallSpinner',{staticClass:"spinner"}):_vm._e()]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('contentWizard.assets.selectSection'))+" ")])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }