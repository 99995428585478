<template>
  <div
    v-click-outside="clickOutsidePopupConfig"
    class="header-popup-bar xl:mx-auto items-center justify-between mt-10 flex-wrap sm:flex-no-wrap"
  >
    <div class="flex items-center mt-9">
      <div id="popup-toggler-date" class="flex text-blue-light items-end cursor-pointer popup-trigger">
        <transition name="fade-down">
          <DateFilterPopup
            :show="objectName === 'createdAt'"
            @dateFilterClicked="onDateFilterClicked"
            @dateFilterCleared="onDateFilterCleared"
          />
        </transition>
        <IconCalendar class="text-blue-light" />
        <div class="ml-3 font-sans font-semibold h-4 hidden lg:block" @click.self="onPopupToggle('createdAt')">{{
          $t('home.general.publishingDate')
        }}</div>
      </div>
      <div
        v-show="viewMode === 'grid'"
        class="flex text-blue-light items-end cursor-pointer popup-trigger ml-12"
        @click="onPopupToggle('sort')"
      >
        <transition name="fade-down">
          <RadioPopup
            v-show="objectName === 'sort'"
            category-type="campaigns"
            :radio-options="sortOptions"
            radio-groupname="campaigns"
            @radioSelected="onSortOptionSelected"
            >{{ $t('home.general.chooseSortOrder') }}</RadioPopup
          >
        </transition>
        <IconOptions class="text-blue-light" />
        <div class="ml-3 font-sans font-semibold h-4 hidden lg:block">{{ $t('general.sorting.sortingName') }}</div>
      </div>
    </div>

    <div v-show="viewMode === 'grid'" class="search-input ml-0 mt-9 sm:ml-auto sm:mt-0 w-54 sm:w-64">
      <AppInput v-model="searchTerm" placeholder="general.inputs.search" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IconCalendar from '@/assets/images/calendar.svg';
import IconOptions from '@/assets/images/options.svg';
import RadioPopup from '@/components/shared/popup/RadioPopup.vue';
import AppInput from '@/components/shared/AppInput.vue';
import DateFilterPopup from '@/components/shared/DateFilterPopup.vue';

export default {
  components: {
    RadioPopup,
    DateFilterPopup,
    AppInput,
    IconCalendar,
    IconOptions,
  },
  data() {
    return {
      objectName: null,
      showSideBar: false,
      inputDebounce: null,

      clickOutsidePopupConfig: {
        handler: this.closeAnyPopup,
      },

      sortOptions: [
        {
          name: this.$t('general.sorting.modifiedDESC'),
          sortBy: 'updatedAt',
          reverse: true,
          isDefault: true,
        },
        {
          name: this.$t('general.sorting.modifiedASC'),
          sortBy: 'updatedAt',
          reverse: false,
          isDefault: false,
        },
        {
          name: this.$t('general.sorting.nameASC'),
          sortBy: (o) => o.name.toLowerCase(),
          reverse: false,
          isDefault: false,
        },
        {
          name: this.$t('general.sorting.nameDESC'),
          sortBy: (o) => o.name.toLowerCase(),
          reverse: true,
          isDefault: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getUniqueValuesOfKey: 'campaigns/getUniqueValuesOfKey',
      viewMode: 'campaigns/getViewMode',
    }),
    searchTerm: {
      get() {
        return this.$store.getters['campaigns/getSearchTerm'];
      },
      set(value) {
        if (this.inputDebounce) {
          clearTimeout(this.inputDebounce);
        }
        this.inputDebounce = setTimeout(() => {
          this.$store.dispatch('campaigns/addSearchTerm', value);
        }, 250);
      },
    },
  },
  beforeDestroy() {
    this.onDateFilterCleared();
  },
  mounted() {
    const defaultSort = this.sortOptions.find((option) => option.isDefault);
    const sortBy = {
      key: defaultSort.sortBy,
      reverse: defaultSort.reverse,
    };
    this.updateSort(sortBy);
  },
  methods: {
    updateSort(sortBy) {
      this.$store.dispatch('updateSortedBy', sortBy);
    },
    onPopupToggle(category) {
      if (this.objectName === category) {
        this.objectName = null;
      } else {
        this.objectName = category;
      }
    },
    closeAnyPopup() {
      this.objectName = null;
    },
    setDefaultSortingOption(activeSortOption) {
      const inactiveOptions = this.sortOptions.filter((option) => option.name !== activeSortOption.name);

      this.sortOptions.find((option) => option.name === activeSortOption.name).isDefault = true;
      inactiveOptions.forEach((option) => {
        // eslint-disable-next-line no-param-reassign
        option.isDefault = false;
      });
    },
    onSortOptionSelected(sortOption) {
      this.setDefaultSortingOption(sortOption);
      const sortBy = {
        key: sortOption.sortBy,
        reverse: sortOption.reverse,
      };
      this.updateSort(sortBy);
    },
    onResetFilterClicked(categoryType) {
      this.$store.dispatch('campaigns/resetFilter', categoryType);
    },
    onExclusiveFilterClicked(filter) {
      this.$store.dispatch('campaigns/addExclusiveFilter', filter);
    },
    onInclusiveFilterClicked(payload) {
      if (!payload.isChecked) {
        this.$store.dispatch('campaigns/addInclusiveFilter', payload.filter);
      } else {
        this.$store.dispatch('campaigns/removeFilter', payload.filter);
      }
    },
    onDateFilterCleared() {
      this.$store.dispatch('campaigns/clearDateRangeFilter');
    },
    onDateFilterClicked(dateRange) {
      this.$store.dispatch('campaigns/addDateRangeFilter', dateRange);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';

.header-popup-bar {
  display: flex;
  min-height: 48px;
}

.search-input {
  border-bottom: 1px solid $cch-dark-grey;
}
</style>
