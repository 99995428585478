<template>
  <ModalBase
    title="contentWizard.contentSpecification.copyFrom"
    :show="show"
    :override-style="{
      width: '35%',
      height: '40%',
      'margin-bottom': '0 rem',
      position: 'absolute',
      'max-width': '80%',
      overflow: 'hidden',
      'z-index': 1000,
      'background-color': scssVariables.cchLightGray,
    }"
    :override-header-style="{ 'margin-bottom': '1rem' }"
    @closeClicked="onCloseClicked"
  >
    <template #content>
      <div class="content-wrapper">
        <FormSelectionField :placeholder="placeholder" :list="getOptions()" @input="selectTarget" />
      </div>
    </template>
    <template #button-row>
      <div class="actions-row">
        <button class="copy-button" @click="copyContent">
          {{ $t('contentWizard.contentSpecification.copyContent') }}
        </button>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import FormSelectionField from '@/components/shared/formFields/FormSelectionField.vue';
import scssVariables from '@/styles/variables.module.scss';

export default {
  components: {
    FormSelectionField,
    ModalBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      target: { language: '', format: '' },
      scssVariables,
    };
  },
  methods: {
    onCloseClicked() {
      this.$emit('closeClick');
    },
    copyContent() {
      this.$emit('copy', this.target);
      this.$emit('closeClick');
    },
    getOptions() {
      const optionItems = [];
      this.options.forEach((option) => {
        const obj = option.format.concat(`_${this.getLanguageAbbreviation(option.language)}`);
        optionItems.push(obj);
      });
      return optionItems;
    },
    getLanguageAbbreviation(language) {
      switch (language) {
        case 'german':
          return 'DE';
        case 'english':
          return 'EN';
        case 'italian':
          return 'IT';
        case 'french':
          return 'FR';
        default:
          throw new Error(`Unsupported language provided: ${language}`);
      }
    },
    getLanguageFromAbbreviation(abbreviation) {
      switch (abbreviation) {
        case 'DE':
          return 'german';
        case 'EN':
          return 'english';
        case 'IT':
          return 'italian';
        case 'FR':
          return 'french';
        default:
          throw new Error(`Unsupported language abbreviation provided: ${abbreviation}`);
      }
    },
    selectTarget(value) {
      const splitValue = value.split('_');
      [this.target.format] = splitValue;
      this.target.language = this.getLanguageFromAbbreviation(splitValue[1]);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.content-wrapper {
  margin-top: 2rem;
}
.copy-button {
  background-color: $cch-buttons-blue;
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  width: rem(141px);
  height: rem(40px);
  font-family: $cch-font-family;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}
</style>
