import {
  getUnreadNotifications,
  getReadNotifications,
  markNotificationSeen,
  markAllNotificationsSeen,
} from '@/services/api/notifications.resource';
import { Notification } from '@/types';
import { ActionContext, Commit } from 'vuex';

interface State {
  unreadNotifications: Notification[];
  readNotifications: Notification[];
}

const state: State = {
  unreadNotifications: [],
  readNotifications: [],
};

type Context = ActionContext<State, any>;

const getters = {
  getUnreadNotifications(state: State) {
    return state.unreadNotifications;
  },
  getReadNotifications(state: State) {
    return state.readNotifications;
  },
};

const actions = {
  async loadUnreadNotifications(context: Context) {
    const organizationId = context.rootGetters['auth/getUserOrganization'];
    const notifications = await getUnreadNotifications(organizationId);
    context.commit('setUnreadNotifications', notifications);
  },
  async loadReadNotifications(context: Context) {
    const organizationId = context.rootGetters['auth/getUserOrganization'];
    const notifications = await getReadNotifications(organizationId);
    context.commit('setReadNotifications', notifications);
  },
  async markNotificationSeen(context: Context, params: { notificationId: string }) {
    const organizationId = context.rootGetters['auth/getUserOrganization'];
    await markNotificationSeen(organizationId, params.notificationId);
    context.commit('setNotificationSeen', params.notificationId);
  },
  async markAllNotificationsSeen(context: Context) {
    const organizationId = context.rootGetters['auth/getUserOrganization'];
    await markAllNotificationsSeen(organizationId);
    context.commit('setAllNotificationsSeen');
  },
};

const mutations = {
  setUnreadNotifications(state: State, notifications: Notification[]) {
    state.unreadNotifications = notifications;
  },
  setReadNotifications(state: State, notifications: Notification[]) {
    state.readNotifications = notifications;
  },
  setNotificationSeen(state: State, notificationId: string) {
    const newUnreadNotifications = state.unreadNotifications.filter(
      (notification) => notification.id !== notificationId,
    );
    state.unreadNotifications = [...newUnreadNotifications];
  },
  setAllNotificationsSeen(state: State) {
    state.unreadNotifications = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
