import HTTP from '@/services/http';
import { EditedAsset } from '@/types/EditedAsset';

const urlPrefix = `api/v${process.env.VUE_APP_API_VERSION}/asset/edit-media`;

export interface EditMediaRequestParams {
  organizationId: string;
  id: string;
  timeline: {
    startTimestamp: string;
    endTimestamp: string;
  };
}

export async function editMedia(params: EditMediaRequestParams): Promise<EditedAsset> {
  const response = await HTTP.post<EditedAsset>(urlPrefix, params);
  return response.data;
}
