<template>
  <div class="grid-container mx-auto w-full">
    <div class="list-container">
      <GridTile
        v-for="tile in items"
        :key="tile._id"
        :data="tile"
        :type="type"
        :view="view"
        :selected="selected === tile._id"
        class="grid-tile"
        :class="{ selected: selected === tile._id }"
        @trashClicked="onTrashClicked"
        @editClicked="onEditClicked"
        @importLayersClicked="onImportLayersClicked"
        @exportLayersClicked="onExportLayersClicked"
        @templateSelected="templateSelected"
        @previewClick="previewClick"
      />
    </div>
  </div>
</template>

<script>
import GridTile from '@/components/shared/tiles/GridTile.vue';

export default {
  name: 'GridOverview',
  components: { GridTile },
  props: {
    items: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    view: {
      type: String,
      default: '',
    },
    active: {
      type: String,
      default: '',
    },
  },
  data() {
    return { selected: '' };
  },
  watch: {
    active(newValue) {
      this.selected = newValue;
    },
  },
  mounted() {
    this.selected = this.active;
  },
  methods: {
    onTrashClicked(id) {
      this.$emit('trashClicked', id);
    },
    onEditClicked(id) {
      this.$emit('editClicked', id);
    },
    onImportLayersClicked(id) {
      this.$emit('importLayersClicked', id);
    },
    onExportLayersClicked(id) {
      this.$emit('exportLayersClicked', id);
    },
    templateSelected(template) {
      this.$emit('templateSelected', template);
    },
    previewClick(template) {
      this.$emit('previewClick', template);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';
.grid-container {
  @apply .pt-2 .pb-2 .relative;
}

.list-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(rem(260px), 1fr));
  grid-gap: 24px;
}

.list-container::after {
  content: '';
  flex: auto;
}

.grid-container::-webkit-scrollbar-thumb {
  @apply .rounded-lg .bg-blue-lightest;
}

.grid-container::-webkit-scrollbar {
  @apply w-4;
}

.grid-container::-webkit-scrollbar-thumb {
  @apply .rounded-lg .bg-blue-lightest;
  background-clip: padding-box;
  border: 5px solid rgba(0, 0, 0, 0);
}
</style>
