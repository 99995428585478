<template>
  <div class="char-counter-wrapper">
    <span class="char-counter" :class="{ invalid: getCurrentCount() > maxCount }"
      >{{ getCurrentCount() }}/{{ maxCount }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    currentInput: {
      type: String,
      default: '',
    },
    maxCount: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    currentInput() {
      if (this.getCurrentCount() > this.maxCount) {
        this.$emit('maxValueExceeded');
      } else {
        this.$emit('maxValueObeyed');
      }
    },
  },
  methods: {
    getCurrentCount() {
      return this.currentInput?.length;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.char-counter-wrapper {
  color: #c5cde2;
  .char-counter {
    text-align: right;
    font-family: $cch-font-family;
    font-size: 1rem;
  }
  .invalid {
    color: #ef3c4c;
  }
}
</style>
