<template>
  <ModalBase
    title="campaigns.general.updateCampaign"
    :show="show"
    :override-style="{ width: '36rem', height: '22rem', 'background-color': scssVariables.cchLightGray }"
    :override-header-style="{ 'margin-bottom': '2rem' }"
    @closeClicked="onCloseClicked"
  >
    <template #content>
      <FormInputField ref="firstInput" v-model="newCampaignName" :placeholder="$t('campaigns.general.campaignName')" />
    </template>
    <template #button-row>
      <div class="button-row">
        <AppButton size="small-auto" @click="updateCampaign">{{ $t('campaigns.general.save') }}</AppButton>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/shared/modal/ModalBase.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import scssVariables from '@/styles/variables.module.scss';

export default {
  components: {
    ModalBase,
    FormInputField,
    AppButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    campaign: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      newCampaignName: this.campaign.name,
      scssVariables,
    };
  },

  mounted() {
    this.$refs.firstInput.focus();
  },

  methods: {
    onCloseClicked() {
      this.$emit('close');
    },
    updateCampaign() {
      this.$emit('update', this.newCampaignName);
      this.newCampaignName = '';
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.button-row {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: rem(80px);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: rem(24px);
}
</style>
