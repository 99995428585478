<template>
  <div class="error-box-container">
    <span class="error-message">{{ $t(message) }}</span>
    <CloseIcon v-if="isClosable" class="close-icon" viewBox="0 0 16 16" @click="closeErrorBox" />
  </div>
</template>

<script>
import CloseIcon from '@/assets/images/close.svg';

export default {
  components: {
    CloseIcon,
  },
  props: {
    isClosable: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeErrorBox() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.error-box-container {
  position: relative;
  box-shadow: rem(0 0 5px 0) rgba(71, 67, 102, 0.19);
  border: solid rem(1px) #ef3c4c;
  background-color: #fff2f2;
  padding: rem(12px 16px);
  display: flex;
  align-items: center;
  .error-message {
    font-family: $cch-font-family;
    font-weight: 600;
    color: $cch-dark-grey;
    margin-right: rem(32px);
  }
  .close-icon {
    width: rem(12px);
    height: rem(12px);
    position: absolute;
    top: rem(12px);
    right: rem(12px);
  }
}
</style>
