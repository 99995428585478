import { RENDER_WS_STATUS } from '@/constants/content/contentCreation';
import { Render } from '@/types';
import { ActionContext } from 'vuex';

interface State {
  renders: Render[];
}

const state: State = {
  renders: [],
};

type Context = ActionContext<State, any>;

const getters = {
  getRenders(state: State) {
    return state.renders;
  },
};

const actions = {
  async setRenderIds(
    context: Context,
    params: {
      ids: Array<string>;
      sockets: any;
    },
  ) {
    const { ids, sockets } = params;
    for (const render of context.state.renders) {
      sockets.unsubscribe(render.id);
    }
    for (const id of ids) {
      const newRenders = [...context.state.renders];
      const foundIndex = newRenders.findIndex((i) => i.id === id);
      if (newRenders[foundIndex]?.progress !== 100) {
        sockets.subscribe(id, (jobStatusDto: { status: string; payload: any }) => {
          const { status, payload } = jobStatusDto;
          const render: Render = {
            id,
            status,
            progress: 0,
            error: '',
          };
          if (status === RENDER_WS_STATUS.RENDERING) {
            render.progress = payload;
          }

          if (
            status === RENDER_WS_STATUS.RENDERING_POSTRENDER ||
            status === RENDER_WS_STATUS.RENDERING_CLEANUP ||
            status === RENDER_WS_STATUS.COMPLETED ||
            status === RENDER_WS_STATUS.FINISHED
          ) {
            render.progress = 100;
          }

          if (status === RENDER_WS_STATUS.FINISHED_ERROR) {
            render.error = payload;
          }

          if (status === RENDER_WS_STATUS.FINISHED || status === RENDER_WS_STATUS.FINISHED_ERROR) {
            sockets.unsubscribe(id);
          }

          if (newRenders[foundIndex]) {
            if (render.status) {
              newRenders[foundIndex].status = render.status;
            }
            if (render.progress) {
              newRenders[foundIndex].progress = render.progress;
            }
            if (render.error) {
              newRenders[foundIndex].error = render.error;
            }
            context.commit('setRenders', newRenders);
          } else {
            context.commit('setRenders', [...newRenders, render]);
          }
        });
      }
    }
  },
};

const mutations = {
  removeRender(state: State, id: string) {
    state.renders = state.renders.filter((render) => render.id !== id);
  },
  setRenders(state: State, renders: Render[]) {
    state.renders = renders;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
