<template>
  <div class="templates">
    <AppHeader class="sticky-header">
      <template #options-bar>
        <TemplateHeader class="max-w-6xl" />
      </template>
    </AppHeader>
    <div class="overview">
      <div class="title-container">
        <h1 class="templates-title">{{ $t('templates.general.headerLabel') }}</h1>
        <div class="toolbar">
          <ViewModeSwitch :value="viewMode" @change="changeViewMode" />
          <AppButton v-if="canCreateTemplates" class="create-button" @click="openTemplateCreationWizard">
            + {{ $t('templates.general.newTemplate') }}
          </AppButton>
        </div>
      </div>
      <AppLoading :loading="isLoading" />
      <template v-if="!isLoading">
        <GridOverview
          v-if="viewMode === 'grid'"
          :items="getTemplatesForDisplay"
          type="templates"
          class="max-w-6xl xl:mx-auto"
          view="overview"
          @trashClicked="showTemplateDeletionModal"
          @editClicked="openTemplateCreationWizardAsUpdate"
          @importLayersClicked="showLayerImportModal"
          @exportLayersClicked="exportLayers"
        />
        <TemplatesListOverview
          v-if="viewMode === 'list'"
          :items="getTemplatesForDisplay"
          class="max-w-6xl mx-auto"
          @trashClicked="showTemplateDeletionModal"
          @editClicked="openTemplateCreationWizardAsUpdate"
          @importLayersClicked="showLayerImportModal"
          @exportLayersClicked="exportLayers"
        />
      </template>
    </div>
    <DiscardModal
      v-if="isTemplateDeletionModalShown"
      message="templates.general.deletionMessage"
      left-button-text="general.buttons.delete"
      :right-button-text="$t('cancel')"
      :title="$t('templates.general.deletionTitle')"
      :is-discard-modal-open="isTemplateDeletionModalShown"
      @clickLeftButton="deleteTemplate"
      @clickRightButton="closeTemplateDeletionModal"
      @closeModal="closeTemplateDeletionModal"
    />
    <LayerImportModal
      v-if="isLayerImportModalShown"
      message="contentWizard.layers.importPrompt"
      left-button-text="contentWizard.layers.cancelButton"
      right-button-text="contentWizard.layers.importShort"
      :header-hidden="true"
      :is-layer-import-modal-open="isLayerImportModalShown"
      @import="importLayers"
      @cancel="closeLayerImportModal"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { CAN_CREATE_ANY_TEMPLATE } from '@/constants/user/permissions';
import GridOverview from '@/components/shared/GridOverview.vue';
import DiscardModal from '@/components/shared/DiscardModal.vue';
import LayerImportModal from '@/components/templates/templateCreation/layers/LayerImportModal.vue';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import AppLoading from '@/components/shared/AppLoading.vue';
import { importLayers, exportLayers, validateLayers } from '@/services/content-creation/layers';
import { toast } from '@/services/toast';
import ViewModeSwitch from '@/components/shared/ViewModeSwitch.vue';
import TemplatesListOverview from './TemplatesListOverview.vue';
import AppHeader from '@/components/shared/AppHeader.vue';
import TemplateHeader from './TemplateHeader.vue';

export default {
  name: 'TemplatesMainView',
  components: {
    GridOverview,
    DiscardModal,
    LayerImportModal,
    AppButton,
    AppLoading,
    ViewModeSwitch,
    TemplatesListOverview,
    AppHeader,
    TemplateHeader,
  },
  data() {
    return {
      currentlySelectedTemplateID: null,
      isTemplateDeletionModalShown: false,
      isLayerImportModalShown: false,
    };
  },
  computed: {
    ...mapGetters({
      userPermissions: 'auth/getUserPermissions',
      getTemplatesForDisplay: 'templates/getTemplatesForDisplay',
      isLoading: 'templates/getLoading',
      viewMode: 'templates/getViewMode',
    }),
    canCreateTemplates() {
      return this.userPermissions.includes(CAN_CREATE_ANY_TEMPLATE);
    },
  },
  mounted() {
    this.$store.dispatch('templates/loadTemplateList');
  },
  methods: {
    async openTemplateCreationWizard() {
      await this.$store.dispatch('templateCreation/loadOptions');
      this.$router.push({ name: 'templateCreation' });
    },
    async openTemplateCreationWizardAsUpdate(id) {
      const template = await this.$store.dispatch('templates/loadTemplateById', id);
      await this.$store.dispatch('templateCreation/setTemplateToEdit', template);
      this.$router.push({ name: 'templateCreation' });
    },
    closeLayerImportModal() {
      this.currentlySelectedTemplateID = null;
      this.isLayerImportModalShown = false;
    },
    showLayerImportModal(templateID) {
      if (!templateID) {
        return;
      }
      this.currentlySelectedTemplateID = templateID;
      this.isLayerImportModalShown = true;
    },
    async importLayers() {
      const id = this.currentlySelectedTemplateID;
      const template = await this.$store.dispatch('templates/loadTemplateById', id);
      const importedLayers = await importLayers();

      const collectedErrors = validateLayers(importedLayers);
      this.showValidationErrors(collectedErrors);

      if (collectedErrors.length === 0) {
        template.layers = template.layers.concat(importedLayers);
        await this.saveTemplate(template);
        this.closeLayerImportModal();

        toast.$emit('showToast', {
          type: 'success',
          groupName: 'template-creation',
          message: this.$t('contentWizard.layers.importSuccess'),
        });
      }
    },
    showValidationErrors(errorMessages) {
      if (errorMessages.length > 0) {
        errorMessages.forEach((errorMessage) => {
          toast.$emit('showToast', {
            type: 'error',
            groupName: 'template-creation-validation',
            message: this.$t(`templates.errors.${errorMessage}`),
          });
        });
      }
    },
    async exportLayers(id) {
      const template = await this.$store.dispatch('templates/loadTemplateById', id);
      exportLayers(template.layers);

      toast.$emit('showToast', {
        type: 'success',
        groupName: 'template-creation',
        message: this.$t('contentWizard.layers.exportSuccess'),
      });
    },
    async saveTemplate(template) {
      await this.$store.dispatch('templateCreation/setTemplateToEdit', template);
      await this.$store.dispatch('templateCreation/update');
      await this.$store.dispatch('templateCreation/reset');
    },
    closeTemplateDeletionModal() {
      this.currentlySelectedTemplateID = null;
      this.isTemplateDeletionModalShown = false;
    },
    showTemplateDeletionModal(templateID) {
      if (!templateID) {
        return;
      }
      this.currentlySelectedTemplateID = templateID;
      this.isTemplateDeletionModalShown = true;
    },
    deleteTemplate() {
      if (!this.currentlySelectedTemplateID) {
        this.closeTemplateDeletionModal();
        return;
      }

      this.$store.dispatch('templates/deleteTemplate', this.currentlySelectedTemplateID).then(() => {
        this.$store.dispatch('templates/loadTemplateList');
      });

      this.closeTemplateDeletionModal();
    },
    updateGlobalSortKey(globalSortKey) {
      this.$store.dispatch('templates/updateGlobalSortKey', globalSortKey);
    },
    changeViewMode(value) {
      this.$store.dispatch('templates/setViewMode', value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/global.scss';

.templates {
  padding-bottom: rem(40px);

  @media (max-width: 1800px) {
    .sticky-header {
      position: fixed;
      width: 100%;
      top: 0;
    }
  }

  .sticky-header {
    position: -webkit-sticky;
    position: sticky;
    top: -4.4rem;
    z-index: 50;
  }
  .overview {
    @apply .fade-in-up;
  }
  .templates-title {
    @apply .text-4xl .text-blue-lighter .font-sans .font-extrabold;
  }
  .title-container {
    @apply .max-w-6xl;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: rem(36px auto 24px auto);
  }
  .toolbar {
    display: flex;
    gap: 15px;
  }
}
.wizard-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
