var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalBase',{attrs:{"title":_vm.$t('assets.general.addAsset'),"show":_vm.show,"override-style":{
    width: _vm.scssVariables.modalSideOffset,
    height: _vm.scssVariables.modalSideOffset,
    'margin-bottom': '0 rem',
    position: 'absolute',
    'z-index': 1000,
  },"override-header-style":{ 'margin-bottom': '1rem' }},on:{"closeClicked":_vm.onCloseClicked},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"asset-selection-container"},[_c('AssetHeader',{staticClass:"sticky-header",attrs:{"show-media-types":_vm.showMediaTypes}}),_c('div',{ref:"assetGridContainer",staticClass:"asset-grid-container",on:{"scroll":_vm.onScroll}},[_c('AssetsGrid',{attrs:{"asset-restrictions":_vm.assetRestrictions,"type":_vm.type,"modal-page":_vm.modalPage,"used-within-modal":true,"assets":_vm.assets},on:{"assetClicked":_vm.assetSelected,"assetSelected":_vm.assetSelected,"assetUnselected":function($event){return _vm.assetUnselected()},"resetPageContentState":_vm.resetPageContentState,"breadcrumbClicked":_vm.breadcrumbClicked}})],1),(_vm.isCreateModalShown)?_c('CreateAssetModal',{attrs:{"show":_vm.isCreateModalShown},on:{"close":function($event){return _vm.closeCreateModal()},"cancelClick":function($event){return _vm.closeCreateModal()},"createClick":_vm.createAsset}}):_vm._e()],1)]},proxy:true},{key:"button-row",fn:function(){return [_c('div',{staticClass:"actions-row"},[_c('AppButton',{attrs:{"size":"small-auto","type":"outlined"},on:{"click":function($event){return _vm.onCloseClicked()}}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.cancel'))+" ")]),_c('div',{staticClass:"asset-actions"},[(!_vm.isShared && !_vm.isLoading)?_c('AppButton',{staticClass:"create-button",attrs:{"type":"outlined","text":"","size":"small-auto"},on:{"click":function($event){return _vm.openCreateModal()}}},[_vm._v(" "+_vm._s(_vm.$t('assets.assetSelection.uploadAsset'))+" ")]):_vm._e(),_c('AppButton',{attrs:{"disabled":!_vm.selectedAsset,"size":"small-auto"},on:{"click":function($event){return _vm.confirmAssetSelection()}}},[_vm._v(" "+_vm._s(_vm.$t('assets.assetSelection.selectAsset'))+" ")])],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }