<template>
  <div class="templates">
    <TemplateSelectionHeaderRow class="mb-2" />
    <span class="title-container">{{ $t('templates.general.headerLabel') }}</span>
    <GridOverview
      :active="getSelectedTemplate()"
      :items="templates"
      type="templates"
      view="wizard"
      @templateSelected="templateSelected"
      @previewClick="showPreview"
    />
    <TemplateFormatsPreviewModal
      v-if="isTemplatePreviewShown"
      :template="selectedTemplateForPreview"
      :show="isTemplatePreviewShown"
      @close="closePreview()"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import GridOverview from '@/components/shared/GridOverview.vue';
import TemplateFormatsPreviewModal from '@/components/campaignDetail/contentCreation/templateSelection/TemplateFormatsPreviewModal.vue';
import { toast } from '@/services/toast';
import TemplateSelectionHeaderRow from '@/components/campaignDetail/contentCreation/templateSelection/TemplateSelectionHeaderRow.vue';

export default {
  name: 'TemplateSelection',
  components: {
    TemplateSelectionHeaderRow,
    GridOverview,
    TemplateFormatsPreviewModal,
  },
  data() {
    return {
      selectedTemplate: null,
      selectedTemplateForPreview: null,
      isTemplatePreviewShown: false,
    };
  },
  computed: {
    ...mapGetters({
      templates: 'templates/getFilteredTemplates',
      template: 'contentCreation/getTemplate',
      copiedContent: 'contentCreation/getCopiedContent',
    }),
  },
  async beforeMount() {
    await this.$store.dispatch('templates/loadTemplateList');
  },
  mounted() {
    if (this.copiedContent !== null && !this.template) {
      this.selectedTemplate = this.templates
        .filter((template) => {
          return template._id === this.copiedContent.templateId;
        })
        .pop();
    } else {
      this.selectedTemplate = this.template;
    }
  },
  methods: {
    templateSelected(template) {
      this.selectedTemplate = template;
    },
    async save() {
      const template = await this.$store.dispatch('templates/loadTemplateById', this.selectedTemplate._id);
      this.$store.dispatch('contentCreation/setOrUpdateTemplate', {
        _id: template._id,
        name: template.name,
        createdAt: new Date(template.createdAt),
        updatedAt: template.updatedAt,
        formats: template.compositions,
        layers: template.layers,
        meta: template.meta,
        type: template.type,
        hasApiData: template.hasApiData,
        thumbSignedUrl: template.thumbSignedUrl,
      });
    },
    validate() {
      let isValid = true;

      if (
        typeof this.selectedTemplate === 'undefined' ||
        !this.selectedTemplate ||
        this.selectedTemplate.length === 0
      ) {
        this.emitErrorMessage('selectTemplate');
        isValid = false;
      }

      return isValid;
    },
    emitErrorMessage(errorMessage) {
      toast.$emit('showToast', {
        type: 'error',
        groupName: 'content-wizard-validation',
        message: this.$t(`contentWizard.validation.${errorMessage}`),
      });
    },
    getSelectedTemplate() {
      if (this.selectedTemplate) {
        return this.selectedTemplate._id;
      }
      return '';
    },
    async showPreview(template) {
      const templateDetails = await this.$store.dispatch('templates/loadTemplateById', template._id);
      this.selectedTemplateForPreview = {
        _id: templateDetails._id,
        name: templateDetails.name,
        createdAt: new Date(templateDetails.createdAt),
        updatedAt: templateDetails.updatedAt,
        formats: templateDetails.compositions,
        layers: templateDetails.layers,
        meta: templateDetails.meta,
        type: templateDetails.type,
        hasApiData: templateDetails.hasApiData,
        thumbSignedUrl: templateDetails.thumbSignedUrl,
      };
      this.isTemplatePreviewShown = true;
    },
    closePreview() {
      this.isTemplatePreviewShown = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/global.scss';

.templates {
  @apply .w-full;

  .title-container {
    display: block;
    margin-bottom: 1%;
    font-family: $cch-font-family;
    font-size: rem(36px);
    font-weight: 800;
    color: #c5cde2;
  }
}
</style>
