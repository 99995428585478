
import { defineComponent, PropType } from 'vue';
import { Notification, NotificationTabValue, RenderNotificationData } from '@/types';
import NotificationItemRender from '@/components/shared/notifications/NotificationItemRender.vue';
import NotificationItemShare from '@/components/shared/notifications/NotificationItemShare.vue';
import NotificationItemEvent from '@/components/shared/notifications/NotificationItemEvent.vue';
import NotificationItemInfo from '@/components/shared/notifications/NotificationItemInfo.vue';
import CloseIcon from '@/assets/images/close-scalable.svg';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'NotificationPanel',
  components: {
    NotificationItemRender,
    NotificationItemShare,
    NotificationItemEvent,
    NotificationItemInfo,
    CloseIcon,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    unreadItems: {
      type: Array as PropType<Array<Notification>>,
      required: true,
      default: () => [],
    },
    readItems: {
      type: Array as PropType<Array<Notification>>,
      required: true,
      default: () => [],
    },
  },
  emits: ['close', 'changeTab'],
  data() {
    return {
      NotificationTabValue,
      tab: null,
    };
  },
  computed: {
    ...mapGetters({
      renders: 'renders/getRenders',
    }),
  },
  watch: {
    visible() {
      this.toggleBodyScroll(!this.visible);
    },
    unreadItems() {
      const renderIds = this.unreadItems
        .filter((notification) => notification.type === 'RENDER')
        .map((notification) => (notification.data as RenderNotificationData).renders)
        .flat()
        .map((item) => item.id);

      this.$store.dispatch('renders/setRenderIds', {
        ids: renderIds,
        sockets: this.sockets,
      });
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onChangeTab(tab: number) {
      this.$emit('changeTab', tab);
    },
    toggleBodyScroll(scrollable: boolean) {
      if (scrollable) {
        document.body.style.overflow = 'initial';
        document.body.style.height = 'initial';
      } else {
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100vh';
      }
    },
    onMarkAllAsSeen() {
      this.$store.dispatch('notifications/markAllNotificationsSeen');
    },
  },
});
