<template>
  <div class="container-format">
    <FormSelectionField
      v-model="type"
      :placeholder="$t('templates.creation.formatType')"
      :list="types"
      :selected-item="type"
      class="input-field format-type-selection"
      @input="updateData"
    />
    <FormInputField
      v-model="name"
      :placeholder="formatNamePlaceholder"
      :disabled="isEditing && !format.isNew"
      :title="isEditing && !format.isNew ? $t('templates.creation.noChangeAllowed') : ''"
      class="input-field"
      @input="updateData"
    />
    <FormInputField
      v-model="humanReadableName"
      :placeholder="$t('templates.creation.humanReadable')"
      class="input-field"
      @input="updateData"
    />
    <FileInputFormField
      :value="video"
      :type-description="$t('templates.creation.video')"
      :accept="previewVideoAcceptTypes"
      :placeholder="formatPreviewPlaceholder"
      style="width: 20%"
      class="input-field"
      @input="handlePreviewUpload($event)"
    />

    <v-tooltip bottom open-delay="500">
      <template #activator="{ on, attrs }">
        <div class="remove-button">
          <TrashIcon class="trash-icon" viewBox="0 0 16 16" v-bind="attrs" @click="removeItem()" v-on="on" />
        </div>
      </template>
      <span>{{ $t('content.tooltip.delete') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormInputField from '@/components/shared/formFields/FormInputField.vue';
import FormSelectionField from '@/components/shared/formFields/FormSelectionField.vue';
import TrashIcon from '@/assets/images/trash.svg';
import FileInputFormField from '@/components/shared/formFields/FileInputFormField.vue';
import { TEMPLATE_TYPE } from '@/constants/template/template';

export default {
  components: {
    FileInputFormField,
    FormInputField,
    FormSelectionField,
    TrashIcon,
  },
  props: {
    format: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      type: null,
      name: null,
      humanReadableName: null,
      video: null,
    };
  },
  computed: {
    ...mapGetters('templateCreation', {
      isEditing: 'getIsEditing',
      templateType: 'getTemplateType',
    }),
    types() {
      return ['LS', 'PT', 'QU', 'HS', 'HM', 'WM', 'MR'];
    },
    previewVideoAcceptTypes() {
      return ['video/*'];
    },
    formatNamePlaceholder() {
      if (this.templateType === TEMPLATE_TYPE.VIDEO) {
        return this.$t('templates.creation.compositionName');
      }
      return this.$t('templates.creation.formatName');
    },
    formatPreviewPlaceholder() {
      if (this.isEditing && !this.format.isNew) {
        return this.$t('templates.creation.noChanges');
      }
      return this.$t('templates.creation.noFileSelected');
    },
  },
  mounted() {
    this.type = this.format.type;
    this.name = this.format.name;
    this.humanReadableName = this.format.humanReadableName;
    this.video = this.format.video;
  },
  methods: {
    removeItem() {
      this.$emit('removeClick');
    },
    updateData() {
      this.$emit('input', {
        id: this.format.id,
        isNew: this.format.isNew,
        type: this.type,
        name: this.name,
        humanReadableName: this.humanReadableName,
        video: this.video,
      });
    },
    handlePreviewUpload(file) {
      this.video = file;
      this.updateData();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';

.container-format {
  display: flex;
  width: 100%;
  font-family: $cch-font-family;
  font-size: rem(36px);
  font-weight: 800;
  color: #c5cde2;
  justify-content: center;
  align-content: stretch;
}
.remove-button {
  background-color: white;
  height: rem(51px);
  flex-grow: 1;
  position: relative;
  cursor: pointer;

  .trash-icon {
    position: absolute;
    width: rem(26px);
    height: rem(25px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.input-field {
  margin-bottom: rem(24px);
  flex-grow: 3.5;
  border-right: 2px solid $cch-light-gray;
  font-size: 16px;
}
.format-type-selection {
  width: 1px;
}
</style>
