import Fuse from 'fuse.js';
import getLogger from '@/services/logger';
// eslint-disable-next-line import/no-cycle
import {
  createAsset,
  createFolder,
  deleteAssets,
  getAllAssets,
  updateAsset,
  shareAsset,
  acceptShare,
  declineShare,
  revokeShare,
} from '@/services/api/assets.resource';
import {
  isSearchApplied,
  areFiltersApplied,
  filterObjectArrayByDateRange,
  filterObjectArrayByKeyValueArray,
  isDateRangeFilterApplied,
} from '@/services/store/shared';
import { Asset, AssetType, DateRangeFilter, KeyValuePairFilter } from '@/types';
import { ActionContext, Commit } from 'vuex';

const LOG = getLogger('store/assets');

const countAssetChildrenRecursively = (asset: Asset) => {
  let count = 0;
  asset.children?.forEach((child) => {
    count++;
    count += countAssetChildrenRecursively(child);
  });
  return count;
};

interface SearchOptions {
  shouldSort: boolean;
  threshold: number;
  location: number;
  distance: number;
  maxPatternLength: number;
  minMatchCharLength: number;
  keys: string[];
}

interface State {
  assets: Asset[];
  path: string[];
  isShared: boolean;
  searchTerm: string | null;
  keyValuePairFilterArray: KeyValuePairFilter[];
  dateRangeFilter: DateRangeFilter | null;
  searchOptions: SearchOptions;
  isLoading: boolean;
}

type Context = ActionContext<State, State>;

const state: State = {
  assets: [],
  path: [],
  isShared: false,
  searchTerm: null,
  keyValuePairFilterArray: [],
  dateRangeFilter: null,
  searchOptions: {
    // fuse.js search options. Reference: https://fusejs.io/
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name', 'type'],
  },
  isLoading: false,
};

const getters = {
  getAssets: (state: State, getters: any) => {
    let result = getters.getAssetsByPath(state.path);
    if (areFiltersApplied(state.keyValuePairFilterArray)) {
      result = filterObjectArrayByKeyValueArray(result, state.keyValuePairFilterArray);
    }
    if (isDateRangeFilterApplied(state.dateRangeFilter)) {
      result = filterObjectArrayByDateRange(result, state.dateRangeFilter!, 'createdAt');
    }

    if (isSearchApplied(state.searchTerm)) {
      const fuse = new Fuse(result, state.searchOptions);
      result = fuse.search(state.searchTerm!);
    }

    return result;
  },

  getAssetsByPath: (state: State) => (path: string[]) => {
    let result = state.assets;
    path.forEach((id: string) => {
      const folder = result.find((asset: Asset) => {
        return (asset.type === AssetType.FOLDER || asset.type === AssetType.SHARED_FOLDER) && asset._id === id;
      });
      result = folder ? folder.children! : [];
    });
    return result;
  },

  getAssetById:
    (state: State, getters: any) =>
    (id: string, path: string[] = state.path) => {
      return getters.getAssetsByPath(path).find((asset: Asset) => asset._id === id);
    },

  getAssetChildrenCount:
    (state: State, getters: any) =>
    (id: string, path: string[] = state.path) => {
      const selectedAsset = getters.getAssetsByPath(path).find((asset: Asset) => asset._id === id) as Asset;
      return countAssetChildrenRecursively(selectedAsset);
    },

  getPath: (state: State) => state.path,

  getIsShared: (state: State) => state.isShared,

  getSearchTerm: (state: State) => state.searchTerm,

  getKeyValuePairFilterArray: (state: State) => state.keyValuePairFilterArray,

  getDateRangeFilter: (state: State) => state.dateRangeFilter,

  getLoading: (state: State) => state.isLoading,
};

const actions = {
  async getAllAssets(context: Context) {
    try {
      context.commit('setLoading', true);
      const organizationId = context.rootGetters['auth/getUserOrganization'];
      const response = await getAllAssets(organizationId);
      context.commit('setAssets', response);
    } catch (err) {
      LOG.error(err);
    } finally {
      context.commit('setLoading', false);
    }
  },
  async deleteAssets(context: Context, { ids, path }: { ids: string[]; path: string[] }) {
    try {
      context.commit('setLoading', true);
      const organizationId = context.rootGetters['auth/getUserOrganization'];
      await deleteAssets(organizationId, ids);
      context.commit('deleteAssets', { ids, path });
    } catch (err) {
      LOG.error(err);
    } finally {
      context.commit('setLoading', false);
    }
  },
  async createAsset(context: Context, { fileName, file, path }: { fileName: string; file: any; path: string[] }) {
    try {
      context.commit('setLoading', true);
      const organizationId = context.rootGetters['auth/getUserOrganization'];
      await createAsset(organizationId, fileName, file, path);
      context.dispatch('getAllAssets');
    } catch (err) {
      LOG.error(err);
    } finally {
      context.commit('setLoading', false);
    }
  },
  async createFolder(context: Context, { folderName, path }: { folderName: string; path: string[] }) {
    try {
      context.commit('setLoading', true);
      const organizationId = context.rootGetters['auth/getUserOrganization'];
      await createFolder(organizationId, folderName, path);
      context.dispatch('getAllAssets');
    } catch (err) {
      LOG.error(err);
    } finally {
      context.commit('setLoading', false);
    }
  },
  async updateAsset(
    context: Context,
    { id, name, file, type }: { file: any; name: string; id: string; type: AssetType },
  ) {
    try {
      context.commit('setLoading', true);
      const organizationId = context.rootGetters['auth/getUserOrganization'];
      await updateAsset(organizationId, id, { name, file, type });
      context.dispatch('getAllAssets');
    } catch (err) {
      LOG.error(err);
    } finally {
      context.commit('setLoading', false);
    }
  },
  async shareAsset(context: Context, { assetId, recipientIds }: { assetId: string; recipientIds: Array<string> }) {
    try {
      context.commit('setLoading', true);
      const organizationId = context.rootGetters['auth/getUserOrganization'];
      return await Promise.all(recipientIds.map((recipientId) => shareAsset(assetId, organizationId, recipientId)));
    } catch (err) {
      LOG.error(err);
      throw err;
    } finally {
      context.commit('setLoading', false);
    }
  },
  async acceptShare(context: Context, { shareId }: { shareId: string }) {
    try {
      context.commit('setLoading', true);
      const organizationId = context.rootGetters['auth/getUserOrganization'];
      await acceptShare(organizationId, shareId);
    } catch (err) {
      LOG.error(err);
      throw err;
    } finally {
      context.commit('setLoading', false);
    }
  },
  async declineShare(context: Context, { shareId }: { shareId: string }) {
    try {
      context.commit('setLoading', true);
      const organizationId = context.rootGetters['auth/getUserOrganization'];
      await declineShare(organizationId, shareId);
    } catch (err) {
      LOG.error(err);
      throw err;
    } finally {
      context.commit('setLoading', false);
    }
  },
  async revokeShares(context: Context, { shareIds }: { shareIds: Array<string> }) {
    try {
      context.commit('setLoading', true);
      const organizationId = context.rootGetters['auth/getUserOrganization'];
      for (const shareId of shareIds) {
        await revokeShare(organizationId, shareId);
      }
    } catch (err) {
      LOG.error(err);
    } finally {
      context.commit('setLoading', false);
    }
  },
  addSearchTerm({ commit }: { commit: Commit }, searchTerm: string) {
    commit('setSearchTerm', searchTerm);
  },

  removeSearchTerm({ commit }: { commit: Commit }) {
    commit('setSearchTerm', null);
  },

  addExclusiveFilter({ commit }: { commit: Commit }, payload: KeyValuePairFilter) {
    commit('removeFiltersFromSameCategory', payload);
    commit('addExclusiveFilter', payload);
  },

  resetFilter({ commit }: { commit: Commit }, category: string) {
    commit('resetFilter', category);
  },

  addDateRangeFilter({ commit }: { commit: Commit }, payload: DateRangeFilter) {
    commit('addDateRangeFilter', payload);
  },

  clearDateRangeFilter({ commit }: { commit: Commit }) {
    commit('addDateRangeFilter', null);
  },

  setPath(context: Context, path: string[]) {
    context.commit('setIsShared', false);
    if (path.length > 0) {
      const lastAssetId = path[path.length - 1];
      const pathToFindAsset = path.slice(0, -1);
      const foundAsset = context.getters
        .getAssetsByPath(pathToFindAsset)
        .find((asset: Asset) => asset._id === lastAssetId);
      if (foundAsset) {
        context.commit('setIsShared', Boolean(foundAsset.isShared));
      }
    }
    context.commit('setPath', path);
  },
};

const mutations = {
  setSearchTerm(state: State, searchTerm: string | null) {
    state.searchTerm = searchTerm;
  },
  setAssets(state: State, assets: Asset[]) {
    state.assets = assets;
  },
  setPath(state: State, path: string[]) {
    state.path = path;
  },

  setIsShared(state: State, isShared: boolean) {
    state.isShared = isShared;
  },

  deleteAssets(state: State, { ids, path }: { ids: string[]; path: string[] }) {
    let assetsAtPath = state.assets;
    let parentFolder: Asset | undefined;
    path.forEach((id: string) => {
      parentFolder = assetsAtPath.find((asset: Asset) => {
        return asset.type === AssetType.FOLDER && asset._id === id;
      });
      assetsAtPath = parentFolder ? parentFolder.children! : [];
    });

    parentFolder
      ? (parentFolder.children = assetsAtPath.filter((asset) => !ids.includes(asset._id)))
      : (state.assets = assetsAtPath.filter((asset) => !ids.includes(asset._id)));
  },

  removeFiltersFromSameCategory(state: State, payload: KeyValuePairFilter) {
    state.keyValuePairFilterArray = state.keyValuePairFilterArray.filter((filter) => filter.key !== payload.key);
  },

  addExclusiveFilter(state: State, payload: KeyValuePairFilter) {
    state.keyValuePairFilterArray.push(payload);
  },

  resetFilter(state: State, category: string) {
    state.keyValuePairFilterArray = state.keyValuePairFilterArray.filter((filter) => filter.key !== category);
  },

  addDateRangeFilter(state: State, payload: DateRangeFilter | null) {
    state.dateRangeFilter = payload;
  },

  setLoading(state: State, payload: boolean) {
    state.isLoading = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
