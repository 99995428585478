import HTTP from '@/services/http';

const urlPrefix = `api/v${process.env.VUE_APP_API_VERSION}/asset/edit-image`;

export async function createEditedAsset(editedAsset: $TSFixMe, organizationId: $TSFixMe) {
  const data = { ...editedAsset, organizationId };

  const response = await HTTP.post(urlPrefix, data);

  return response.data;
}

export async function createEditedAssetAsBinary(editedAssetAsBinary: $TSFixMe, organizationId: $TSFixMe) {
  const formData = new FormData();
  formData.append('file', editedAssetAsBinary);
  formData.append('organizationId', organizationId);

  const url = `${urlPrefix}/binary`;

  const response = await HTTP.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form',
    },
  });

  return response.data;
}
