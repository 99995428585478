import {
  getCmsConfigEntities,
  createCmsConfigEntity,
  updateCmsConfigEntity,
  deleteCmsConfigEntity,
} from '@/services/api/cms.resource';
import { CmsConfigEntity, CreateCmsConfigEntity, UpdateCmsConfigEntity } from '@/types';
import { ActionContext } from 'vuex';

interface State {
  entities: CmsConfigEntity[];
  loading: boolean;
  saving: boolean;
  searchTerm: string;
}

const state: State = {
  entities: [],
  loading: false,
  saving: false,
  searchTerm: '',
};

type Context = ActionContext<State, any>;

const getters = {
  getEntities(state: State) {
    if (state.searchTerm) {
      return state.entities.filter(
        (entity) =>
          entity.organizationName.toLowerCase().includes(state.searchTerm.toLowerCase()) ||
          String(entity.cmsPathId).toLowerCase().includes(state.searchTerm.toLowerCase()) ||
          entity.cmsUsername.toLowerCase().includes(state.searchTerm.toLowerCase()),
      );
    }
    return state.entities;
  },
  getLoading(state: State) {
    return state.loading;
  },
  getSaving(state: State) {
    return state.saving;
  },
  getSearchTerm(state: State) {
    return state.searchTerm;
  },
};

const actions = {
  async loadCmsConfigEntities(context: Context) {
    try {
      context.commit('setLoading', true);
      const entities = await getCmsConfigEntities();
      context.commit('setEntities', entities);
    } finally {
      context.commit('setLoading', false);
    }
  },
  async createCmsConfigEntity(context: Context, params: CreateCmsConfigEntity) {
    try {
      context.commit('setSaving', true);
      await createCmsConfigEntity(params);
      const entities = await getCmsConfigEntities();
      context.commit('setEntities', entities);
    } finally {
      context.commit('setSaving', false);
    }
  },
  async updateCmsConfigEntity(context: Context, { id, params }: { id: string; params: UpdateCmsConfigEntity }) {
    try {
      context.commit('setSaving', true);
      await updateCmsConfigEntity(id, params);
      const entities = await getCmsConfigEntities();
      context.commit('setEntities', entities);
    } finally {
      context.commit('setSaving', false);
    }
  },
  async deleteCmsConfigEntity(context: Context, id: string) {
    try {
      context.commit('setSaving', true);
      await deleteCmsConfigEntity(id);
      const entities = await getCmsConfigEntities();
      context.commit('setEntities', entities);
    } finally {
      context.commit('setSaving', false);
    }
  },
  setSearchTerm(context: Context, value: string) {
    context.commit('setSearchTerm', value);
  },
};

const mutations = {
  setEntities(state: State, entities: CmsConfigEntity[]) {
    state.entities = entities;
  },
  setLoading(state: State, value: boolean) {
    state.loading = value;
  },
  setSaving(state: State, value: boolean) {
    state.saving = value;
  },
  setSearchTerm(state: State, value: string) {
    state.searchTerm = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
