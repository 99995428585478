// eslint-disable-next-line import/no-cycle
import HTTP from '@/services/http';
import getLogger from '@/services/logger';

import { TEMPLATE_LAYER_RAW_TYPE, TEMPLATE_LAYER_TYPE } from '@/constants/template/template';
// eslint-disable-next-line import/no-cycle
import store from '../../store';

const LOG = getLogger('resource/template');
const urlPrefix = `api/v${process.env.VUE_APP_API_VERSION}/template`;

export async function getAvailableTemplateList() {
  const organizationId = store.getters['auth/getUserOrganization'];
  LOG.info(`Attempting to get all templates for organization: ${organizationId}`);
  let templates = null;
  await HTTP.get(`${urlPrefix}/organization/${organizationId}`, {
    // @ts-expect-error TS(2345): Argument of type '{ cache: boolean; }' is not assi... Remove this comment to see the full error message
    cache: false,
  }).then((response) => {
    templates = response.data;
  });
  return templates;
}

export async function getTemplate(id: string) {
  LOG.info(`Attempting to get template with id: ${id}`);
  const response = await HTTP.get(`${urlPrefix}/${id}`);
  return response.data;
}

function boolToString(bool: $TSFixMe) {
  if (bool) {
    return 'true';
  }
  return 'false';
}

function createFormData(template: $TSFixMe) {
  const formData = new FormData();
  formData.append('type', template.type);
  formData.append('name', template.name);
  if (template._id) {
    formData.append('id', template._id);
  }
  if (template.template) {
    formData.append('template', template.template);
    formData.append('templateFileName', template.template.name);
  }
  if (template.thumb) {
    formData.append('thumb', template.thumb);
  }
  template.organizationIds.forEach((organizationId: $TSFixMe) => {
    formData.append('organizationIds[]', organizationId);
  });
  if (template.functionKey) {
    formData.append('functionKey', template.functionKey);
  }
  formData.append('hasApiData', template.hasApiData);
  if (template.takePreviewScreenshotAtMS) {
    formData.append('takePreviewScreenshotAtMS', template.takePreviewScreenshotAtMS);
  }
  if (template.fonts) {
    formData.append('fonts', template.fonts);
  }
  let compositionIndex = 0;
  template.compositions.forEach((composition: $TSFixMe) => {
    formData.append(`compositions[${compositionIndex}][format]`, composition.type);
    formData.append(`compositions[${compositionIndex}][name]`, composition.name);
    formData.append(`compositions[${compositionIndex}][humanReadableName]`, composition.humanReadableName);
    if (composition.video) {
      formData.append(composition.name, composition.video);
    }
    compositionIndex += 1;
  });

  if (template.defaultAssets && template.defaultAssets.length > 0) {
    let defaultAssetsIndex = 0;
    template.defaultAssets.forEach((defaultAsset: $TSFixMe) => {
      if (defaultAsset.file) {
        formData.append(`defaultAssets[${defaultAssetsIndex}][file]`, defaultAsset.file);
      }
      formData.append(`defaultAssets[${defaultAssetsIndex}][type]`, defaultAsset.type);
      formData.append(`defaultAssets[${defaultAssetsIndex}][layerName]`, defaultAsset.layerName);
      defaultAssetsIndex += 1;
    });
  }

  let layerIndex = 0;
  template.layers.forEach((layer: $TSFixMe) => {
    if (layer.id) {
      formData.append(`layers[${layerIndex}][_id]`, layer.id);
    }
    if (layer.columnName) {
      formData.append(`layers[${layerIndex}][columnName]`, layer.columnName);
    }
    formData.append(`layers[${layerIndex}][name]`, layer.name);
    formData.append(`layers[${layerIndex}][layerName]`, layer.layerName);
    formData.append(`layers[${layerIndex}][type]`, layer.type);
    formData.append(`layers[${layerIndex}][isOptional]`, layer.isOptional);

    if (layer.type === TEMPLATE_LAYER_TYPE.DATA) {
      formData.append(`layers[${layerIndex}][property]`, layer.property);
      formData.append(`layers[${layerIndex}][rawType]`, layer.rawType);
    }

    if (layer.options && layer.options.length > 0) {
      let optionIndex = 0;
      layer.options.forEach((option: $TSFixMe) => {
        formData.append(`layers[${layerIndex}][options][${optionIndex}][key]`, option.key);
        formData.append(`layers[${layerIndex}][options][${optionIndex}][value]`, option.value);
        optionIndex += 1;
      });
    }

    if (layer.settings && layer.settings.length > 0) {
      let metaIndex = 0;
      layer.settings.forEach((setting: $TSFixMe) => {
        formData.append(`layers[${layerIndex}][settings][${metaIndex}][format]`, setting.format);

        if (layer.rawType && layer.rawType !== TEMPLATE_LAYER_RAW_TYPE.OPTION) {
          formData.append(`layers[${layerIndex}][settings][${metaIndex}][minValue]`, setting.minValue);
          formData.append(`layers[${layerIndex}][settings][${metaIndex}][maxValue]`, setting.maxValue);
          if (layer.rawType === TEMPLATE_LAYER_RAW_TYPE.NUMBER) {
            formData.append(
              `layers[${layerIndex}][settings][${metaIndex}][isMinUnlimited]`,
              boolToString(setting.isMinUnlimited),
            );
            formData.append(
              `layers[${layerIndex}][settings][${metaIndex}][isMaxUnlimited]`,
              boolToString(setting.isMaxUnlimited),
            );
          }
        } else if (layer.type === TEMPLATE_LAYER_TYPE.IMAGE) {
          formData.append(`layers[${layerIndex}][settings][${metaIndex}][meta][minWidth]`, setting.meta.minWidth);
          formData.append(`layers[${layerIndex}][settings][${metaIndex}][meta][minHeight]`, setting.meta.minHeight);
          formData.append(`layers[${layerIndex}][settings][${metaIndex}][meta][aspectRatio]`, setting.meta.aspectRatio);
        } else if (layer.type === TEMPLATE_LAYER_TYPE.AUDIO) {
          formData.append(`layers[${layerIndex}][settings][${metaIndex}][meta][duration]`, setting.meta.duration);
        } else if (layer.type === TEMPLATE_LAYER_TYPE.VIDEO) {
          formData.append(`layers[${layerIndex}][settings][${metaIndex}][meta][aspectRatio]`, setting.meta.aspectRatio);
          formData.append(`layers[${layerIndex}][settings][${metaIndex}][meta][duration]`, setting.meta.duration);
        }

        metaIndex += 1;
      });
    }
    layerIndex += 1;
  });
  return formData;
}

export async function createTemplate(template: $TSFixMe, onProgress: $TSFixMe) {
  LOG.info(`Attempting to create template`);
  const formData = createFormData(template);

  return HTTP.post(urlPrefix, formData, {
    onUploadProgress(progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (onProgress) onProgress(percentCompleted);
    },
    headers: {
      'Content-Type': 'multipart/form',
    },
  });
}

export async function updateTemplate(template: $TSFixMe, onProgress: $TSFixMe) {
  LOG.info('Attempting to update template');
  const formData = createFormData(template);
  const path = `${urlPrefix}/${template._id}`;
  return HTTP.patch(path, formData, {
    onUploadProgress(progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (onProgress) onProgress(percentCompleted);
    },
    headers: {
      'Content-Type': 'multipart/form',
    },
  });
}

export const deleteTemplate = async (templateID: $TSFixMe) => {
  try {
    await HTTP.delete(`${urlPrefix}/${templateID}`);
    return true;
  } catch (e) {
    LOG.error('Error while deleting template:', e);
    return false;
  }
};
