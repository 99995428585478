import Fuse from 'fuse.js';
import getLogger from '@/services/logger';
// eslint-disable-next-line import/no-cycle
import { deleteTemplate, getAvailableTemplateList, getTemplate } from '@/services/api/template.resource';

import {
  determineIfTogglingToAscendingOrDescendingSort,
  isSearchApplied,
  areFiltersApplied,
} from '@/services/store/shared';
import { setLocalstorageItem } from '@/services/localstorage';
import { TEMPLATES_VIEW_MODE } from '@/constants/store/references';
import { ViewModeValue } from '@/types/ViewModeValue';
import { Template } from '@/types';

const LOG = getLogger('store/templates');

const searchOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['compositions', 'createdAt', 'deletedAt', 'layers', 'name', 'updatedAt'],
};

const state = {
  templates: [],
  searchTerm: null,
  viewMode: null as ViewModeValue | null,
  loading: false,
  keyValuePairFilterArray: [],
};

const getters = {
  getTemplatesForDisplay: (state: $TSFixMe) => {
    let templates = [...state.templates];

    if (isSearchApplied(state.searchTerm)) {
      const fuse = new Fuse(templates, searchOptions);
      templates = fuse.search(state.searchTerm);
    }

    return templates.map((template) => ({
      _id: template._id,
      name: template.name,
      createdAt: new Date(template.createdAt),
      updatedAt: template.updatedAt,
      formats: template.compositions,
      layers: template.layers,
      meta: template.meta,
      type: template.type,
      hasApiData: template.hasApiData,
      thumbSignedUrl: template.thumbSignedUrl,
    }));
  },

  getFilteredTemplates: (state: $TSFixMe) => {
    let templates = [...state.templates];
    if (areFiltersApplied(state.keyValuePairFilterArray)) {
      state.keyValuePairFilterArray.forEach((filter: $TSFixMe) => {
        let threshold = 0.2;
        if (filter.key === 'compositions.format' || filter.key === 'type') {
          threshold = -1;
        }
        const fuse = new Fuse(templates, {
          keys: [filter.key],
          threshold,
        });
        templates = [...fuse.search(filter.value)];
      });
    }

    if (isSearchApplied(state.searchTerm)) {
      const fuse = new Fuse(templates, searchOptions);
      templates = fuse.search(state.searchTerm);
    }

    return templates.map((template) => ({
      _id: template._id,
      name: template.name,
      createdAt: new Date(template.createdAt),
      updatedAt: template.updatedAt,
      formats: template.compositions,
      layers: template.layers,
      meta: template.meta,
      type: template.type,
      hasApiData: template.hasApiData,
      thumbSignedUrl: template.thumbSignedUrl,
    }));
  },

  getTemplates: (state: $TSFixMe) => state.templates,

  getViewMode: (state: $TSFixMe) => state.viewMode || localStorage.getItem(TEMPLATES_VIEW_MODE) || 'grid',
  getLoading: (state: $TSFixMe) => state.loading,
  getFormatsFromTemplates: (state: $TSFixMe) => {
    if (state.templates) {
      const formats = new Set<string>();
      state.templates.map((template: $TSFixMe) => {
        for (const composition of template.compositions) {
          formats.add(composition.format);
        }
      });
      return Array.from(formats.values());
    }
    return [];
  },
  getContentTypeFromTemplates: (state: $TSFixMe) => {
    if (state.templates) {
      const types = new Set<string>();
      state.templates.map((template: $TSFixMe) => {
        types.add(template.type);
      });
      return Array.from(types.values());
    }
    return [];
  },

  getSearchTerm: (state: $TSFixMe) => state.searchTerm,
};

const actions = {
  async loadTemplateList({ commit }: $TSFixMe) {
    try {
      commit('setLoading', true);
      const response = await getAvailableTemplateList();
      commit('setTemplates', response);
    } catch (err) {
      LOG.error(err);
    } finally {
      commit('setLoading', false);
    }
  },
  async loadTemplateById({ commit }: $TSFixMe, templateId: string): Promise<Template | null> {
    try {
      commit('setLoading', true);
      const template = await getTemplate(templateId);
      return template;
    } catch (err) {
      LOG.error(err);
      return null;
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteTemplate({ commit }: $TSFixMe, contentID: $TSFixMe) {
    try {
      commit('setLoading', false);
      await deleteTemplate(contentID);
    } finally {
      commit('setLoading', false);
    }
  },
  setSearchTerm({ commit }: $TSFixMe, searchTerm: $TSFixMe) {
    commit('setSearchTerm', searchTerm);
  },
  updateGlobalSortKey(context: $TSFixMe, globalSortKey: $TSFixMe) {
    const reverse = determineIfTogglingToAscendingOrDescendingSort(globalSortKey, context.rootGetters.getSortedBy);
    context.commit(
      'updateSortedBy',
      {
        key: globalSortKey,
        reverse,
      },
      { root: true },
    );
  },
  addExclusiveFilter({ commit }: $TSFixMe, payload: $TSFixMe) {
    commit('removeFiltersFromSameCategory', payload);
    commit('addExclusiveFilter', payload);
  },
  resetFilter({ commit }: $TSFixMe, category: $TSFixMe) {
    commit('resetFilter', category);
  },
  resetFilters({ commit }: $TSFixMe) {
    commit('resetFilters');
  },
  setViewMode(context: $TSFixMe, value: ViewModeValue) {
    setLocalstorageItem(TEMPLATES_VIEW_MODE, value);
    context.commit('setViewMode', value);
  },
};

const mutations = {
  setSearchTerm(state: $TSFixMe, searchTerm: $TSFixMe) {
    state.searchTerm = searchTerm;
  },
  setTemplates(state: $TSFixMe, templates: $TSFixMe) {
    state.templates = templates;
  },
  setLoading(state: $TSFixMe, payload: boolean) {
    state.loading = payload;
  },
  setViewMode(state: $TSFixMe, value: ViewModeValue) {
    state.viewMode = value;
  },
  addExclusiveFilter(state: $TSFixMe, payload: $TSFixMe) {
    state.keyValuePairFilterArray.push(payload);
  },
  resetFilter(state: $TSFixMe, category: $TSFixMe) {
    state.keyValuePairFilterArray = state.keyValuePairFilterArray.filter((filter: $TSFixMe) => filter.key !== category);
  },
  resetFilters(state: $TSFixMe) {
    state.keyValuePairFilterArray = [];
  },
  removeFiltersFromSameCategory(state: $TSFixMe, payload: $TSFixMe) {
    state.keyValuePairFilterArray = state.keyValuePairFilterArray.filter(
      (filter: $TSFixMe) => filter.key !== payload.key,
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
