import { render, staticRenderFns } from "./TemplatesPage.vue?vue&type=template&id=6b50fe93&scoped=true&"
import script from "./TemplatesPage.vue?vue&type=script&lang=js&"
export * from "./TemplatesPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b50fe93",
  null
  
)

export default component.exports