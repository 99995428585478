
import { defineComponent, PropType } from 'vue';
import { Notification, NotificationReceiverType, ShareNotificationData, AssetShareState } from '@/types';
import { t } from 'i18next';

export default defineComponent({
  name: 'NotificationItemShare',
  components: {},
  props: {
    item: {
      type: Object as PropType<Notification>,
      required: true,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      AssetShareState,
    };
  },
  computed: {
    title() {
      return t('general.notification.folderShareInvitation');
    },
    text() {
      const data = this.item.data as ShareNotificationData;
      return `${data.senderOrganization.name} ${t('general.notification.wouldLikeToShareTheFolder')} ${
        data.sharedFolder.name
      } ${
        this.item.receiverType === NotificationReceiverType.COMPANY || NotificationReceiverType.COMPANY_ROLE
          ? t('general.notification.withYourOrganization')
          : t('general.notification.withYou')
      }`;
    },
    state() {
      const data = this.item.data as ShareNotificationData;
      return data.state;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    acceptShare(notification: Notification) {
      this.$store
        .dispatch('assets/acceptShare', {
          shareId: (notification.data as ShareNotificationData).shareId,
        })
        .then(() => {
          if (!notification.seen) {
            this.$store.dispatch('notifications/markNotificationSeen', {
              notificationId: notification.id,
            });
          }
          this.$store.dispatch('assets/getAllAssets');
        });
    },
    declineShare(notification: Notification) {
      this.$store
        .dispatch('assets/declineShare', {
          shareId: (notification.data as ShareNotificationData).shareId,
        })
        .then(() => {
          if (!notification.seen) {
            this.$store.dispatch('notifications/markNotificationSeen', {
              notificationId: notification.id,
            });
          }
        });
    },
    onClickHide(notification: Notification) {
      if (!notification.seen) {
        this.$store.dispatch('notifications/markNotificationSeen', {
          notificationId: notification.id,
        });
      }
    },
  },
});
