import { render, staticRenderFns } from "./NotificationItemShare.vue?vue&type=template&id=0c26b5eb&scoped=true&"
import script from "./NotificationItemShare.vue?vue&type=script&lang=ts&"
export * from "./NotificationItemShare.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationItemShare.vue?vue&type=style&index=0&id=0c26b5eb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c26b5eb",
  null
  
)

export default component.exports