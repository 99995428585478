import { AssetType, DateRangeFilter, KeyValuePairFilter } from '@/types';
import { isWithinInterval } from 'date-fns';

export function Job(this: $TSFixMe, contentName: $TSFixMe, language: $TSFixMe, template: $TSFixMe, formats: $TSFixMe) {
  this.contentName = contentName;
  this.language = language;
  this.data = {
    contentName,
    language,
    templateID: template._id,
    campaignID: 'mockCampaignID',
    compositions: formats,
    layers:
      template && template.layers
        ? template.layers.map((layer: $TSFixMe) => {
            return {
              key: layer.layerName,
              value: null,
              type: layer.type,
              id: layer._id,
            };
          })
        : [],
  };
}

export function TemplateData(this: $TSFixMe, language: $TSFixMe, template: $TSFixMe) {
  this.language = language;
  this.assets = template.layers.map((layer: $TSFixMe) => {
    return {
      key: layer.layerName,
      value: null,
      type: layer.type,
    };
  });
}

export function areFiltersApplied(filters: KeyValuePairFilter[]) {
  return filters.length !== 0;
}

export function isDateRangeFilterApplied(dateRangeFilter: DateRangeFilter | null) {
  return !!dateRangeFilter;
}

export function isSortApplied(sortKey: $TSFixMe) {
  return !!sortKey;
}

export function fillArrayValuesBasedOnFilteredResult(filtered: $TSFixMe, filters: $TSFixMe, array: $TSFixMe) {
  if (filtered.length > 0) {
    return filtered;
  }
  return filters.length > 0 ? [] : array;
}

export function isSearchApplied(searchTerm: string | null) {
  return !!searchTerm;
}

function objectValueMatchesFilter(object: { [key: string]: any }, filter: KeyValuePairFilter): boolean {
  if (object[filter.key] === AssetType.FOLDER) {
    return true;
  }
  return object[filter.key] === filter.value;
}

function objectValueIsArray(object: { [key: string]: any }, filter: KeyValuePairFilter): boolean {
  return Array.isArray(object[filter.key]);
}

function objectValueContainsFilter(object: { [key: string]: any }, filter: KeyValuePairFilter): boolean {
  return object[filter.key].includes(filter.value);
}

function objectMatchesFilterRecursively(object: { [key: string]: any }, filter: KeyValuePairFilter): boolean {
  return (
    objectValueMatchesFilter(object, filter) ||
    (objectValueIsArray(object, filter) && objectValueContainsFilter(object, filter))
  );
}

export function filterObjectArrayByKeyValueArray(objectArray: any[], keyValueArray: KeyValuePairFilter[]) {
  return objectArray.filter((object: {}) => {
    return keyValueArray.every((filter: KeyValuePairFilter) => objectMatchesFilterRecursively(object, filter));
  });
}

export function filterObjectArrayByDateRange(
  objectArray: any[],
  dateRangeFilter: DateRangeFilter,
  property = 'datePublished',
) {
  return objectArray.filter((object: { [key: string]: any }) =>
    isWithinInterval(new Date(object[property]), {
      start: new Date(dateRangeFilter.startDate),
      end: new Date(dateRangeFilter.endDate),
    }),
  );
}

export function determineIfTogglingToAscendingOrDescendingSort(key: $TSFixMe, sortedBy: $TSFixMe) {
  if (key === sortedBy.key) {
    return !sortedBy.reverse;
  }
  return false;
}

export function getUniqueValuesOfArrays(objectArray: $TSFixMe, key: $TSFixMe) {
  const result: $TSFixMe = [];
  objectArray.forEach((object: $TSFixMe) => {
    object[key].forEach((value: $TSFixMe) => {
      if (!result.includes(value)) {
        result.push(value);
      }
    });
  });
  return result;
}

export function getUniqueValues(objectArray: $TSFixMe, key: $TSFixMe) {
  const result: $TSFixMe = [];
  objectArray.forEach((object: $TSFixMe) => {
    if (!result.includes(object[key])) {
      result.push(object[key]);
    }
  });
  return result;
}

export function getIndexOfKeyValueObjectInArray(object: $TSFixMe, array: $TSFixMe) {
  return array.findIndex((item: $TSFixMe) => item.key === object.key && item.value === object.value);
}
