<template>
  <div class="campaign-info">
    <AppText>{{ $t('campaigns.general.lastUpdated') }}</AppText>
    <AppText type="date">{{ date }}</AppText>
    <AppText type="year">{{ year }}</AppText>
  </div>
</template>

<script>
import AppText from '@/components/shared/AppText.vue';

export default {
  components: {
    AppText,
  },
  props: {
    date: {
      type: String,
      default: '',
    },
    year: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.campaign-info {
  max-width: rem(120px);
  height: rem(55px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}
</style>
