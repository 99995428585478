<template>
  <div class="textarea-wrapper">
    <div
      class="text-wrapper"
      :style="{ height: `${lines.length + 3}rem` }"
      :class="{ 'invalid-input': invalidRows.length > 0 }"
    >
      <label class="text-area-label">{{ placeholder }}</label>
      <textarea
        :id="`my-textarea_${lines[0]._id}`"
        :rows="lines.length"
        :value="value"
        @input="$emit('input', $event.target.value), $emit('updateRows')"
        @keydown.enter="checkLines($event)"
      />
      <div class="char-counter-container">
        <CharCounter
          v-for="(line, index) in lines"
          :key="index"
          :current-input="rows[index]"
          :max-count="parseMaxCount(line)"
          @maxValueExceeded="exceededMaxValue(index)"
          @maxValueObeyed="obeyedMaxValue(index)"
        />
      </div>
    </div>
    <div v-if="invalidRows.length !== 0" class="error-wrapper">
      <span class="error-message">{{ $t('contentWizard.contentSpecification.rowError') }} {{ getInvalidRows() }}"</span>
    </div>
  </div>
</template>

<script>
import CharCounter from '@/components/shared/formFields/CharCounter.vue';

export default {
  components: {
    CharCounter,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    lines: {
      type: Array,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: true,
    },
    rows: {
      type: Array,
      required: false,
      default: null,
    },
    activeFormat: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      allRows: [],
      inputValue: '',
      invalidRows: [],
    };
  },
  methods: {
    parseMaxCount(layer) {
      const layerFormat = layer.settings
        .filter((setting) => {
          return setting.format === this.activeFormat;
        })
        .pop();
      if (layerFormat) {
        return parseInt(layerFormat.maxValue, 10);
      }
      return undefined;
    },
    exceededMaxValue(index) {
      if (!this.invalidRows.includes(index + 1)) {
        this.invalidRows.push(index + 1);
      }
    },
    obeyedMaxValue(index) {
      this.invalidRows = this.invalidRows.filter((row) => {
        return row !== index + 1;
      });
    },

    getInvalidRows() {
      const string = this.invalidRows.join(', ');
      if (this.invalidRows.length !== 1) {
        const index = string.lastIndexOf(',');
        return `${string.substring(0, index)} and ${string.substring(index + 1)}`;
      }
      return this.invalidRows[0];
    },
    checkLines(event) {
      if (this.rows.length >= this.lines.length) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
.textarea-wrapper {
  position: relative;
  height: fit-content;
  .text-wrapper {
    @apply .font-semibold;
    position: relative;
    width: 100%;
    background-color: #ffffff;
    textarea:focus {
      outline: none;
    }
    textarea {
      white-space: pre;
      overflow-wrap: normal;
      overflow-x: auto;
      resize: none;
      position: absolute;
      bottom: 0;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      padding-right: rem(65px);
      width: calc(100% - 60px);
      height: calc(100% - 1.5rem);
    }
    .text-area-label {
      @apply .absolute;
      top: 0.5rem;
      left: 1rem;
      font-size: 0.75rem;
      color: rgba(51, 51, 51, 0.5);
      z-index: 0;
      pointer-events: none;
    }
    .char-counter-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: absolute;
      right: 0.25rem;
      top: rem(25px);
    }
  }
  .error-wrapper {
    position: absolute;
    bottom: -1.25rem;
    .error-message {
      font-family: $cch-font-family;
      font-size: 0.75rem;
      font-weight: 500;
      color: #ef3c4c;
    }
  }
  .invalid-input {
    outline: #ef3c4c rem(1px) solid;
  }
}
</style>
