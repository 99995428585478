<template>
  <div class="credits-wrapper">
    <CreditCard class="navigation-item--icon fill-white stroke-black" />
    <div class="credits-counter">
      <AppText type="navigation-item">
        {{ $t('general.credits.creditsRemaining') }} <span class="counter"> {{ creditsCount }}</span>
      </AppText>
      <div v-if="isAdminOrSuperAdmin" class="right-section">
        <AppButton size="small-auto" @click="goToCreditsPage">
          {{ $t('general.credits.getMoreCredits') }}
        </AppButton>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AppButton from '@/components/shared/buttons/AppButton.vue';
import AppText from '@/components/shared/AppText.vue';
import CreditCard from '@/assets/images/credit-card-give.svg';
import { ADMIN, SUPERADMIN } from '@/constants/user/roles';

export default {
  name: 'CreditsCounter',
  components: { AppButton, AppText, CreditCard },
  computed: {
    ...mapGetters({
      creditsCount: 'organizations/getRemainingCredits',
      userRole: 'auth/getUserRole',
    }),
    isAdminOrSuperAdmin() {
      return this.userRole === ADMIN || this.userRole === SUPERADMIN;
    },
  },
  mounted() {
    this.$store.dispatch('organizations/loadCreditsForLoggedInOrganization');
  },
  methods: {
    goToCreditsPage() {
      this.$router.push({ name: 'credits' });
      this.$emit('closeMenu');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/global.scss';

.right-section {
  margin-top: 1rem;
  width: 8.375rem;
  height: 2rem;
  flex-basis: auto;
  .upgrade-button {
    width: 100%;
    height: 100%;
  }
}

.counter {
  color: $cch-buttons-blue;
  margin-left: 1rem;
}
.credits-wrapper {
  @apply flex;
  .navigation-item--icon {
    height: rem(18px);
    width: rem(18px);
  }
  .credits-counter {
    margin-left: 0.7rem;
  }
}
</style>
